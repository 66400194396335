import {makeVar} from "@apollo/client";
import {PAYMENT_METHOD} from "../../../Constants/globals";

/**
 * This is a hack to advance the payment form in Components/Booking/Payment/Components/ChoosePaymentMethod.js on successful inline sign up in Auth/SignUp/WithEmail.js
 * Without this hack, guests need to click the "Next" button twice after completing the inline signup form.
 * We'll have to rewrite the messy checkout structure as context gets lost between components
 */
export const triggerPaymentMethodSelectionVar = makeVar(null);
// augments paymentMethod in usePaymentContext during sign up
export const paymentMethodVar = makeVar(PAYMENT_METHOD.CREDIT_CARD);