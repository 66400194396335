import TagManager from "react-gtm-module";

/*
  GTM container ID Constants
*/
const GTM_DEV = "GTM-ML8WXCL";
const GTM_PROD = "GTM-NVCPCPJ";

/*
  Select GTM ID based on Environment
*/
let GTM_ID_SELECTED;
switch (process.env.REACT_APP_ENV) {
  case "production": {
    GTM_ID_SELECTED = GTM_PROD;
    break;
  }
  case "staging": {
    GTM_ID_SELECTED = GTM_DEV;
    break;
  }
  default: {
    GTM_ID_SELECTED = null;
  }
}

/*
  Select GTM options
*/
const tagManagerArgs = {
  gtmId: GTM_ID_SELECTED,
};

/*
  Init Tag Manger
*/
if (GTM_ID_SELECTED) {
  TagManager.initialize(tagManagerArgs);
}
