import React, { useEffect, useMemo, useRef, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FormInput from "Components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import FileInput from "./FileInput";
import { isNonEmptyObj } from "../../helpers/isNoneEmptyObject";
import { FiChevronDown } from "react-icons/fi";
import PropTypes from "prop-types";
import { IoWarningOutline } from "react-icons/io5";
import { PiWarningCircle } from "react-icons/pi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import "./index.scss";

/**
 * UploadGuestDocumentsCard Component
 * A card component for uploading guest documents with form inputs and file uploads.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.title - The title of the card
 * @param {function} props.handleRemoveGuest - Function to handle the removal of the guest card
 * @param {string} props.name - The base name for form fields
 * @param {object} [props.errors] - Validation errors for the form fields
 * @param {object} [props.field] - the form current field data
 * @param {string} [props.rejectionRemarksTranslationKey] - document rejection remarks translation key
 * @param {string} [props.country] - The country of the property
 * @returns {JSX.Element}
 */
function UploadGuestDocumentsCard({
  title,
  handleRemoveGuest,
  name,
  errors,
  field,
  rejectionRemarksTranslationKey,
  country,
}) {
  const [isOpen, setIsOpen] = useState(true); // State to manage accordion open/close state

  const contentRef = useRef(null); // Ref to measure content height

  const [height, setHeight] = useState(contentRef.current?.scrollHeight); // State to manage content height

  const { t } = useTranslation(); // Translation hook

  const { control, setValue, register } = useFormContext();

  // Watch form values dynamically
  // const values = field;

  const values = useWatch({
    control,
    name: `${name}`,
    defaultValue: { ...field },
  });

  const initialDocumentType =
    field?.documentType ?? values?.documentType ?? "passport";
  const [documentType, setDocumentType] = useState(initialDocumentType);

  const doc = useWatch({
    control,
    name: `${name}.docs.0`,
  });

  // Calculate and set content height
  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight + 16 || 0);
    }
  }, [
    contentRef.current?.scrollHeight,
    errors,
    values?.documentType,
    rejectionRemarksTranslationKey,
    doc?.url,
  ]);

  // Memoize guest name display
  const guestName = useMemo(() => {
    const firstName = values?.firstName ?? "";
    const lastName = values?.lastName ?? "";
    return firstName || lastName
      ? `: ${firstName} ${lastName}`.trimStart()
      : "";
  }, [values]);

  const isRejectionError = errors?.docs
    ?.flatMap((item) => item.type)
    .includes("manual");
  // Toggle accordion open/close state

  const handleAccordionToggle = () => setIsOpen((prev) => !prev);

  // Style for the accordion content holder
  const accordionStyle = useMemo(() => {
    return { height: isOpen ? `${height}px` : "0px" };
  }, [height, isOpen]);

  return (
    <div
      className={`guest-card ${
        isNonEmptyObj(errors) ? "guest-card--error" : ""
      }`}
    >
      <button
        className="guest-card__title remove-button-background"
        data-toggle={isOpen}
        type="button"
        aria-expanded={isOpen}
        onClick={handleAccordionToggle}
      >
        <div className="flex-grow-1 text-start py-4 online-check-in__info--dark-gray online-check-in__info--lg d-flex align-items-lg-center gap-2 overflow-hidden">
          <div className="d-flex align-items-center gap-2">
            {isRejectionError && (
              <IoMdCheckmarkCircleOutline size={20} color={"#E04646"} />
            )}

            {field?.rejectionRemarksTranslationKey === null && (
              <IoMdCheckmarkCircleOutline size={20} color={"#49B338"} />
            )}

            {title}
          </div>
          <div
            className="text-truncate "
            style={{ maxWidth: window.innerWidth < 450 ? "140px" : "unset" }}
          >
            {guestName}
          </div>
        </div>
        <span
          className="d-flex align-items-center gap-2"
          onClick={(e) => e.stopPropagation()}
        >
          <AiOutlineDelete
            size={20}
            color="#828282"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveGuest();
            }}
            role="button"
            aria-label={t("Remove Guest")}
          />
          {/* Accordion Icon */}
          <span onClick={handleAccordionToggle}>
            {isOpen ? (
              <BsChevronUp className="chevron" size={16} aria-hidden="true" />
            ) : (
              <BsChevronDown className="chevron" size={16} aria-hidden="true" />
            )}
          </span>
        </span>
      </button>

      {/* Accordion Content */}
      <div className="guest-card__content-holder" style={accordionStyle}>
        <div className="guest-card__content" ref={contentRef}>
          {/* Name Inputs */}
          <section className="row flex-column flex-lg-row justify-content-between w-100">
            {/* First Name Input */}
            <div className="col-12 col-lg-6">
              <FormInput
                name={`${name}.firstName`}
                label={t("first_name")}
                type={"text"}
                defaultValue={values.firstName || ""}
                register={register({
                  required: t("error_first_name_missing"),
                  // control: control,
                })}
                error={errors?.firstName}
                // labelClassSuffix={"text-font font-normal"}
              />
            </div>
            {/* Last Name Input */}
            <div className="col-12 col-lg-6">
              <FormInput
                name={`${name}.lastName`}
                label={t("last_name")}
                type={"text"}
                defaultValue={values?.lastName || ""}
                register={register({
                  required: t("error_last_name_missing"),
                  // control: control,
                })}
                error={errors?.lastName}
                // labelClassSuffix={"text-font font-normal"}
              />
            </div>
          </section>

          {/* Document Type Select */}
          <section className="row w-100">
            <Controller
              name={`${name}.documentType`}
              control={control}
              defaultValue={documentType || "nationalID"}
              render={(props) => {
                return (
                  <div
                    className={`form-group floating-label show-floating-label white-bg mt-1 `}
                  >
                    <div className="floating-label__input-area">
                      <select
                        className="form-control floating-label__text-input"
                        id={`${name}.documentType`}
                        {...props}
                        onChange={(e) => {
                          setDocumentType(e.target.value);
                          setValue(`${name}.documentType`, e.target.value);
                          setValue(`${name}.docs`, []);
                        }}
                      >
                        <option disabled value="">
                          {t("choose_id_type")}
                        </option>
                        <option value="nationalID">{t("national_id")}</option>
                        <option value="passport">{t("passport")}</option>
                      </select>
                      <span>
                        <FiChevronDown />
                      </span>
                      <label
                        htmlFor={`${name}.documentType`}
                        className="floating-label__label /text-font /font-normal "
                      >
                        {t("choose_id_type")}
                      </label>
                    </div>
                  </div>
                );
              }}
              rules={{ required: t("error_doc_type_missing") }}
            />

            {documentType === "nationalID" && (
              <div
                style={{ marginTop: "-10px" }}
                className="col-12 d-flex align-items-center gap-2 mb-3 online-check-in__info--primary-bold"
              >
                <PiWarningCircle size={"1rem"} />
                <span>
                  {country === "saudi" || country === "emirates"
                    ? t(`only_valid_${country}_or_gcc_id_accepted`)
                    : country === "turkey"
                    ? t(`only_valid_turkish_or_eu_id_accepted`)
                    : null}
                </span>
              </div>
            )}
          </section>

          {/* Passport File Input */}
          {documentType === "passport" && (
            <section className="row w-100">
              <FileInput
                name={`${name}.docs.0`}
                label={t("upload_image")}
                docType="passport"
                error={errors?.docs?.[0]}
                rejectionRemarksTranslationKey={rejectionRemarksTranslationKey}
                field={values?.docs?.[0]}
              />
            </section>
          )}

          {/* NationalID FIle Input */}
          {documentType === "nationalID" && (
            <section className="row flex-column flex-lg-row justify-content-between w-100 gap-2 gap-lg-0">
              <FileInput
                name={`${name}.docs.0`}
                label={t("front_image")}
                docType="id-front"
                containerClassName="col-lg-6"
                error={errors?.docs?.[0]}
                rejectionRemarksTranslationKey={rejectionRemarksTranslationKey}
                field={values?.docs?.[0]}
              />
              <FileInput
                name={`${name}.docs.1`}
                label={t("back_image")}
                docType="id-back"
                containerClassName="col-lg-6"
                error={errors?.docs?.[1]}
                rejectionRemarksTranslationKey={rejectionRemarksTranslationKey}
                field={values?.docs?.[1]}
              />
            </section>
          )}

          {/* show the rejection remarks if it exists */}
          {isRejectionError && (
            <section className="row w-100 mt-2">
              <section className="col-12 online-check-in__info--error--text d-flex align-items-center gap-2 ">
                <IoWarningOutline size={"1rem"} />
                <span>
                  {t(
                    String(
                      rejectionRemarksTranslationKey ?? "image_upload_error"
                    )
                  )}
                </span>
              </section>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

// PropTypes for component props validation
UploadGuestDocumentsCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleRemoveGuest: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  field: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        docType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  rejectionRemarksTranslationKey: PropTypes.string,
  country: PropTypes.string,
};

UploadGuestDocumentsCard.defaultProps = {
  errors: {},
  field: null,
  rejectionRemarksTranslationKey: null,
};

export default UploadGuestDocumentsCard;
