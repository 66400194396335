import React, { useEffect } from "react";

import { Portal } from "react-portal";
import { RemoveScroll } from "react-remove-scroll";

import CloseButton from "../../../Buttons/Close";
import seedGenerator from "../../../Utils/functions/seedGenerator";
import composeJsxClass from "../../../Utils/functions/composeJsxClass";
import useEscapeButton from "../../../Utils/hooks/useEscapeButton";

const getGrids = (images) => {
  let grids = [];
  let seeds = [];

  Object.values(images).forEach((image) => {
    let seed = seedGenerator(image.id).toString().replace("0.", "").charAt(0);
    seed = parseInt(seed);

    let size;

    let bigs = [0, 1, 2, 3];
    let mids = [4, 5, 6, 7];
    let smalls = [8, 9];

    if (mids.includes(seed)) {
      size = 2;
    }
    if (smalls.includes(seed)) {
      size = 1;
    }
    if (bigs.includes(seed)) {
      size = 4;
    }
    seeds.push(size);
  });

  let gridIndex = 0;
  seeds.forEach((seed) => {
    if (grids[gridIndex] === undefined) {
      grids[gridIndex] = [];
    }

    let sumOfGrid = grids[gridIndex].reduce((a, b) => a + b, 0);

    if (sumOfGrid + seed >= 4) {
      gridIndex = gridIndex + 1;
      grids[gridIndex] = [];

      seed = 4 - sumOfGrid;
      grids[gridIndex - 1].push(seed);
    } else {
      grids[gridIndex].push(seed);
    }
  });

  grids = grids
    .filter((grid) => grid.length > 0)
    .map((grid) => {
      if (grid.includes(3)) {
        grid = [2, 2];
      }
      return grid;
    })
    .map((grid) => {
      if (grid.includes(2) && grid.length === 3) {
        let y = seedGenerator(grid[1]);
        if (y < 0.5) {
          grid = [1, 1, 2];
        } else {
          grid = [2, 1, 1];
        }
      }
      return grid;
    });

  // Fix missing items
  if (grids.flat(1).length !== images.length) {
    let missingAmount = images.length - grids.flat(1).length;
    if (missingAmount === 1) {
      grids.push(images.slice(images.length - 2, images.length - 1));
    }
  }

  return grids;
};

const getGridArea = (grid, imageIndex) => {
  let gridArea = [1, 1, 3, 3];
  let gridLength = grid.length;

  let gridSize = grid[imageIndex];

  let grids = {
    full: [1, 1, 3, 3],
    half: {
      left: [1, 1, 3, 2],
      right: [1, 2, 3, 3],
    },
    quarter: {
      topLeft: [1, 1, 2, 2],
      topRight: [1, 2, 2, 3],
      bottomLeft: [2, 1, 3, 2],
      bottomRight: [2, 2, 3, 3],
    },
  };

  if (gridLength === 1) gridArea = grids.full;

  if (gridLength === 2) {
    if (imageIndex === 0) gridArea = grids.half.left;
    else gridArea = grids.half.right;
  }

  if (gridLength === 3) {
    // Half
    if (gridSize === 2) {
      if (imageIndex === 0 || imageIndex === 1) gridArea = grids.half.left;
      else gridArea = grids.half.right;
    }
    if (gridSize === 1) {
      // Quarters
      if (grid[0] === 2) {
        if (imageIndex === 1) gridArea = grids.quarter.topRight;
        if (imageIndex === 2) gridArea = grids.quarter.bottomRight;
      }

      if (grid[2] === 2) {
        if (imageIndex === 0) gridArea = grids.quarter.topLeft;
        if (imageIndex === 1) gridArea = grids.quarter.bottomLeft;
      }
    }
  }

  if (gridLength === 4) {
    if (imageIndex === 0) gridArea = grids.quarter.topLeft;
    if (imageIndex === 1) gridArea = grids.quarter.topRight;
    if (imageIndex === 2) gridArea = grids.quarter.bottomLeft;
    if (imageIndex === 3) gridArea = grids.quarter.bottomRight;
  }

  return gridArea.join(" / ");
};

const getGridImages = (grids, gridIndex, images) => {
  let shownGridImages = 0;
  grids.forEach((grid, index) => {
    if (index < gridIndex) {
      shownGridImages = shownGridImages + grid.length;
    }
  });

  let gridImages = images.slice(
    shownGridImages,
    shownGridImages + grids[gridIndex].length
  );

  return { gridImages: gridImages, shownGridImages: shownGridImages };
};

const ScrollGallery = ({ carousel, setCarousel, images }) => {
  const handleOnCloseClick = () => {
    if (carousel.fullScreen) return;
    setCarousel((carousel) => ({ ...carousel, scrollGallery: false }));
  };

  const grids = getGrids(images);

  const galleryClass = composeJsxClass("suite__scroll-gallery", {
    active: carousel.scrollGallery,
  });

  useEffect(() => {
    if (carousel.scrollGallery === false) return;
    const node = document.getElementById(
      `scroll-gallery-item--${carousel.scrollGalleryScrollInto}`
    );
    node.scrollIntoView();
  }, [carousel.scrollGalleryScrollInto, carousel.scrollGallery]);

  useEscapeButton(() => handleOnCloseClick());

  return (
    <RemoveScroll
      allowPinchZoom={true}
      enabled={carousel.scrollGallery && !carousel.fullScreen}
    >
      <Portal>
        <div className={galleryClass}>
          <div className="gallery">
            <div className="gallery__top">
              <CloseButton
                className="close-button"
                color="#000000"
                onClick={handleOnCloseClick}
              />
            </div>
            <div className="gallery__container">
              <div className="container container-max-md ">
                <div className="gallery__content" id="gallery-content">
                  {grids.map((grid, gridIndex) => {
                    const { gridImages, shownGridImages } = getGridImages(
                      grids,
                      gridIndex,
                      images
                    );
                    return (
                      <div className="gallery__grid" key={gridIndex}>
                        {gridImages.map((image, imageIndex) => {
                          const gridArea = getGridArea(grid, imageIndex);

                          const handleOnImageClick = () => {
                            setCarousel((carousel) => ({
                              ...carousel,
                              fullScreenIndex: shownGridImages + imageIndex,
                              fullScreen: true,
                            }));
                          };
                          return (
                            <div
                              className="gallery-item"
                              style={{
                                gridArea: gridArea,
                              }}
                              key={image.id}
                              tabIndex={0}
                              id={`scroll-gallery-item--${image.id}`}
                              role="button"
                              aria-pressed={
                                carousel.fullScreen &&
                                carousel.current === imageIndex
                              }
                              onClick={handleOnImageClick}
                            >
                              <img
                                className="gallery-image"
                                src={image.url}
                                alt={image.id}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    </RemoveScroll>
  );
};
export default ScrollGallery;
