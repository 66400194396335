import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { AiOutlineCloudUpload, AiOutlineFileImage } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";

import { BsExclamationCircleFill } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";

const FileInput = (props) => {
  const {
    name,
    label,
    errorMessage,
    maxFiles = 1,
    maxSize = 5242880,
    multiple = false,
  } = props;
  // Translation hook
  const { t } = useTranslation();

  const { register, unregister, setValue, watch, errors } = useFormContext();
  const files = watch(name);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setValue(name, acceptedFiles, { shouldValidate: true });
    },
    [name, setValue]
  );
  const { getRootProps, getInputProps, isDragActive, rejectedFiles } =
    useDropzone({
      onDrop,
      accept: props.accept,
      maxFiles: maxFiles,
      maxSize: maxSize,
      multiple: multiple,
    });
  useEffect(() => {
    register({ name: name, required: true });
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <div className="form-group floating-label mb-0" role="button">
      {label && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2 capitalize"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div {...getRootProps()} style={{ background: "#f5f5f5" }}>
        <input
          {...props}
          className="shadow appearance-none border rounded w-100 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id={name}
          {...getInputProps()}
        />
        <div
          className={"w-100 border rounded-2 py-4 px-4"}
          style={{
            transition: "all 400ms ease-out",
            background: isDragActive ? "#d9e5e9" : "#f6f8f9",
          }}
        >
          {!files?.length && (
            <p className="text-center mb-0">
              <AiOutlineCloudUpload /> {t("form_attach_files")}
              <br />
              <small>{t("form_input_file_accept_list")}</small>
            </p>
          )}

          {rejectedFiles?.length > 0 && (
            <div className="col-12 d-flex justify-content-center mt-4">
              <div
                className="alert alert-danger d-inline-flex justify-content-center align-items-center rounded-pill mb-0 px-2 py-1 text-center"
                role="alert"
              >
                <small>{t("form_input_check_file_size")}</small>
              </div>
            </div>
          )}

          {/* Optionally you may display a preview of the file(s) */}
          {!!files?.length && (
            <div className="col-12 col-md-8 offset-md-2 d-flex justify-content-center">
              {files.map((file, i) => {
                let split = file?.name?.split(".");
                let filename = "";
                let extension = "";

                if (Array.isArray(split) && split?.length > 1) {
                  filename = split[0];
                  extension = split[1];
                }

                if (filename?.length > 20) {
                  filename = `${filename.substring(0, 20)}...`;
                }
                return (
                  <div
                    className="alert alert-primary align-items-center d-inline-flex rounded w-100"
                    role="alert"
                    key={i}
                  >
                    {file?.type.includes("image") ? (
                      <AiOutlineFileImage
                        size={24}
                        height={"24px"}
                        width={"24px"}
                      />
                    ) : (
                      <VscFilePdf size={24} height={"24px"} width={"24px"} />
                    )}
                    <div className="mx-2 w-100 overflow-hidden">
                      {filename}.{extension}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {errors?.file && (
        <div className="error-text" hidden={errors?.file?.message === ""}>
          <span className="error-text__icon">
            <BsExclamationCircleFill className="icon" color="red" />
          </span>
          {t(errorMessage) || t(errors?.file?.message)}
        </div>
      )}
    </div>
  );
};

export default FileInput;
