import { useMutation } from "@apollo/client";
import mobileAppCallback from "Components/Booking/Payment/Forms/functions/mobileAppCallback";
import { usePaymentContext } from "Components/Booking/Payment/index";
import FormInput from "Components/Form/FormInput";
import { PAYMENT_CONTEXT, PAYMENT_METHOD } from "Constants/globals";
import QUERIES from "Constants/Queries";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiInfoCircle } from "react-icons/bi";
import { useParams } from "react-router-dom";
import BookingAgreementConfirmation from "../../Components/TermsAgreement";
import { validateForm as validateContactForm } from "../ContactReview/validateForm";
import BankTransferSuccessful from "./BankTransferSuccessful";
import FileInput from "Components/Form/FileInput";
import StellaBankAccount from "./StellaBankAccount";
import PriceNoticeBox from "../../Components/PriceNoticeBox";
import toMoney from "Components/Utils/functions/toMoney";
import { paymentErrorCodes } from "../errorCodes";

// Translation Package
import { useTranslation } from "react-i18next";

function BankTransfer() {
  // Translation hook
  const { t } = useTranslation();
  const { context, propertyData, invCode, quotationData, showSDSeparate } =
    usePaymentContext();

  const { id: propertyID, startDate, endDate, numberOfGuests } = useParams();
  const [isComplete, setIsComplete] = useState(false);
  const [reservationID, setReservationID] = useState(null);
  const [confirmCode, setConfirmCode] = useState(null);

  const methods = useForm({
    mode: "all",
  });

  const [stellaStaysUploadProof, { error: errorOnUpload, loading: loadingUp }] =
    useMutation(QUERIES.STELLA_STAYS_UPLOAD_BANK_PROOF);

  const [
    stellaStaysCreateReservation,
    { error: errorOnCreate, loading: loadingCreate },
  ] = useMutation(QUERIES.STELLA_STAYS_CREATE_RESERVATION, {
    onCompleted: async (data) => {
      // Upload Proof
      setReservationID(data.result.reservationID);
      setConfirmCode(data.result.confirmationCode);
      submitUploadProof(
        data.result.reservationID,
        data.result.confirmationCode
      );
    },
  });

  const submitUploadProof = async (reservationID, confirmationCode) => {
    const bankProofVariables = {
      ...methods.getValues(),
      reservationID: reservationID,
    };

    try {
      await stellaStaysUploadProof({
        variables: {
          payload: { ...bankProofVariables, file: methods.getValues().file[0] },
        },
      });
      setIsComplete(true);

      const message = {
        reservation_id: reservationID,
        confirmationCode: confirmationCode,
        method: PAYMENT_METHOD.BANK,
        status: "success",
      };

      if (context === PAYMENT_CONTEXT.MOBILE_APP) {
        mobileAppCallback(message);
      }
    } catch (error) {}
  };

  const onSubmitProof = async () => {
    if (!methods.formState.isValid) return;
    const { isFormValid, ...userProfile } = validateContactForm();

    const mutationVariables = {
      payload: {
        propertyID,
        startDate,
        endDate,
        guests: parseInt(numberOfGuests),
        userProfile: {...userProfile, birthdate: userProfile.birthdate ?? null},
        isBankTransferReservation: true,
        reservationCode: invCode,
      },
    };

    // Submit
    if (!reservationID) {
      try {
        await stellaStaysCreateReservation({
          variables: mutationVariables,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      submitUploadProof(reservationID);
    }
  };

  const onSubmit = methods.handleSubmit((values) => {
    if (!values?.file || values?.file?.length === 0) {
      methods.setError("file", {
        type: "custom",
        message: "form_input_attach_required_file",
      });
      document.getElementById("bankFileField")?.scrollIntoView();
      return;
    }

    // Trigger Manual revalidate, If file had error and then uploaded isValid was still false.
    methods?.trigger();

    if (methods?.formState?.isValid) {
      onSubmitProof();
    }
  });

  // Calc Total
  let total = quotationData.totalPriceIncludingVAT || quotationData.totalPrice;
  if (!showSDSeparate) {
    total = total + quotationData?.securityDepositAmount;
  }

  return (
    <div className="confirm-and-pay__form">
      {/* Form */}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          {/* Bank Info */}
          <div
            className="container booking-confirmation p-0"
            id="confirmation-page"
          >
            <div className="row confirmation-content">
              <div className="col-12">
                <div className="shadow-card journey-area border">
                  <div className="before-check_in mt-0">
                    {/* Notice */}
                    <div className="col-12">
                      <div
                        className="alert alert-warning d-flex align-items-center title"
                        role="alert"
                      >
                        <BiInfoCircle size={24} />

                        <div className="mx-2">
                          <small>{t("bank_approval_notice")}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorOnCreate ||
                    (errorOnUpload && (
                      <div className="alert alert-warning" role="alert">
                        {t(paymentErrorCodes.DEFAULT)}
                      </div>
                    ))}

                  <div className="col-12 mb-4">
                    <PriceNoticeBox
                      title={t("total")}
                      price={`${toMoney(total, quotationData.currency)}`}
                    />
                  </div>

                  {/* Bank Step 1 */}
                  <div className="step step_one">
                    <div className="step__indicator">
                      <div className="indicator__text">1</div>
                      <div className="indicator__line"></div>
                    </div>
                    <div className="step__content">
                      <h6 className="step__title">{t("bank_step_transfer")}</h6>

                      <div className="card d-inline-flex">
                        <div className="card-body step__description">
                          <p className="mb-0">
                            <StellaBankAccount city={propertyData?.city} />
                          </p>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>

                  {/* Bank Step 2 */}
                  <div className="step step_two" id="bankFileField">
                    <div className="step__indicator">
                      <div className="indicator__text">2</div>
                      <div className="indicator__line"></div>
                    </div>
                    <div className="step__content w-100">
                      <h6 className="step__title">
                        {t("bank_form_file_field_label")}
                      </h6>

                      <FileInput
                        accept="image/png, image/jpg, image/jpeg, image/webp, application/pdf"
                        name="file"
                      />
                      <br />
                    </div>
                  </div>

                  {/* Form fields */}
                  {/* Bank Step 3 */}
                  <div className="step step_two">
                    <div className="step__indicator">
                      <div className="indicator__text">3</div>
                    </div>

                    <div className="step__content">
                      <h6 className="step__title">
                        {t("bank_step_enter_details")}
                      </h6>
                      <div className="row my-2">
                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankBeneficiaryName"
                            label={t("bank_form_beneficiary_name")}
                            error={methods.errors.bankBeneficiaryName}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankAccountNumber"
                            label={t("bank_form_account_number")}
                            error={methods.errors.bankAccountNumber}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankName"
                            label={t("bank_form_bank_name")}
                            error={methods.errors.bankName}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankBranch"
                            label={t("bank_form_bank_branch")}
                            error={methods.errors.bankBranch}
                          />
                        </div>
                        <div className="col-12">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankAddress"
                            label={t("bank_form_branch_address")}
                            error={methods.errors.bankAddress}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankCountry"
                            label={t("bank_form_bank_country")}
                            error={methods.errors.bankCountry}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankIban"
                            label={t("bank_form_iban")}
                            error={methods.errors.bankIban}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankSwiftCode"
                            label={t("bank_form_swift_code")}
                            error={methods.errors.bankSwiftCode}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FormInput
                            register={methods.register({
                              required: true,
                            })}
                            type="text"
                            name="bankAccountCurrency"
                            label={t("bank_form_account_currency")}
                            error={methods.errors.bankAccountCurrency}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Button */}
          <div className="row">
            <div className="col-12 mb-4">
              <BookingAgreementConfirmation
                isPaymentInProgress={loadingUp || loadingCreate}
                disabled={!methods?.formState?.isValid}
                onClick={null}
              />
            </div>
          </div>
        </form>
      </FormProvider>

      {isComplete && (
        <BankTransferSuccessful
          propertyData={propertyData}
          startDate={startDate}
          endDate={endDate}
          reservationID={confirmCode}
        />
      )}
    </div>
  );
}

export default BankTransfer;
