import React from "react";
import { BsX } from "react-icons/bs";

import { SEARCH_TYPES } from "../../Booking/Search/search_constants";

// Translation Package
import { useTranslation } from "react-i18next";

const Header = ({
  header,
  allowFlexible,
  onClose,
  compact,
  isMobile,
  selectType,
  setSelectType,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const isCalendar = selectType === SEARCH_TYPES.calendar;
  const isFlexible = selectType === SEARCH_TYPES.flexible;

  const onCalendar = () => {
    setSelectType(SEARCH_TYPES.calendar);
  };

  const onFlexible = () => {
    setSelectType(SEARCH_TYPES.flexible);
  };

  return (
    <div className="custom_datepicker__header">
      {header && <h1 className="custom_datepicker__title">{header}</h1>}

      {allowFlexible && (
        <div className="custom_datepicker__button-group">
          <div className="button-group__holder">
            <button
              className={`custom_datepicker__button button__flex_choice button__calendar 
					 ${isCalendar ? "active" : ""} `}
              type="button"
              onClick={onCalendar}
            >
              {t("calendar")}
            </button>
            <button
              className={`custom_datepicker__button button__flex_choice button__flexible
					 ${isFlexible ? "active" : ""} `}
              type="button"
              onClick={onFlexible}
            >
              {t("flexible")}
            </button>
          </div>
        </div>
      )}

      <button
        className="custom_datepicker__button button__close"
        type="button"
        onClick={onClose}
        hidden={compact && !isMobile}
      >
        <BsX className="icon" />
      </button>
    </div>
  );
};

export default Header;
