import React from "react";

import MobileContent from "./Mobile";
import DesktopContent from "./Desktop";

const TopbarContent = (props) => {
  const { mobileMenu, isMobile } = props;

  const mobileMenuClass = mobileMenu ? "mobile-menu-open" : "";

  return (
    <div className={`topbar__holder ${mobileMenuClass}`}>
      <div className="topbar__content">
        <div className="topbar__items">
          {isMobile ? <MobileContent /> : <DesktopContent />}
        </div>
      </div>
    </div>
  );
};

export default TopbarContent;
