import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import omitDeep from "omit-deep";
import i18n from "i18next";
import * as Sentry from "@sentry/react";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import {RetryLink} from "@apollo/client/link/retry";

/**
Add errorLink to report GraphQL errors to Sentry.
 */
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext("apolloGraphQLOperation", {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    });

    graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: "error",
        fingerprint: ["{{ default }}", "{{ transaction }}"],
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      });
    });

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: "error",
        contexts: {
          apolloNetworkError: {
            error: networkError,
            extensions: networkError.extensions,
          },
        },
      });
    }
  });
});

// Link options
const linkOptions = {
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace(
    "%host",
    window.location.hostname
  ),
  fetch: (uri, options) => {
    options.headers["x-token"] = localStorage.getItem("token");
    options.headers["language"] = i18n.languages[0];
    return fetch(uri, options);
  },
};

const apolloMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation);
});

const retryLink = new RetryLink({
    attempts: {
        max: 10,
        retryIf: (error, _operation) => !!error,
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
            factor: 2
        }
    }
});

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace(
    "%host",
    window.location.hostname
  ),
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    new SentryLink(),
    errorLink,
    retryLink,
    apolloMiddleware.concat(createUploadLink(linkOptions)),
  ]),
});

export default client;
