import TermsAndConditions from "Components/Legals/TermsAndConditions";
import FloatingModal from "Components/Modals/FloatingModal";
import React, { useState } from "react";
import { BiCheck } from "react-icons/bi";
import PrimaryButton from "../../../Buttons/Primary";
import Loading from "../../../Utils/Loading";
import { PaymentContext } from "../index";

// Translation Package
import { Trans, useTranslation } from "react-i18next";

const BookingAgreementConfirmation = ({
  isPaymentInProgress,
  buttonTitle = "confirm_booking",
  disabled = false,
  onClick = () => {},
}) => {
  // Translation hook
  const { t } = useTranslation();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showCancTermsModal, setShowCancTermsModal] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const handleAgreement = (e) => {
    e.preventDefault();
    setIsChecked(!isChecked);
  };
  const paymentContext = React.useContext(PaymentContext);
  const isLocationCanada =
  paymentContext?.propertyData?.location?.address?.toLowerCase()?.indexOf("canada") > -1;

  return (
    <>
      <div className="mt-2 mb-2 confirm-and-pay__agreement pb-3">
        <div className="agreement d-flex gap-2">
          <div className="agreement__button">
            <button
              className={`check-button my-1 ${
                isChecked ? "check-button--checked" : ""
              }`}
              onClick={handleAgreement}
              style={{ border: "0px" }}
            >
              {isChecked && <BiCheck className="icon" />}
            </button>
          </div>
          <div className="agreement__text d-flex flex-row align-items-center">
            <p className="text mb-0">
              <Trans i18nKey="agreement_notice">
                {/* Add empty string to act as placeholder for <0><0> */}{" "}
                <span
                  className="text-primary"
                  onClick={() => setShowCancTermsModal(!showCancTermsModal)}
                  role="button"
                >
                  {/* Add empty string to act as placeholder for <1><1> */}{" "}
                </span>
                {/* Add empty string to act as placeholder for <2><2> */}{" "}
                <span
                  className="text-primary"
                  onClick={() => setShowTermsModal(!showTermsModal)}
                  role="button"
                >
                  {/* Add empty string to act as placeholder for <3><3> */}{" "}
                </span>
                {/* Add empty string to act as placeholder for <4><4> */}{" "}
              </Trans>
            </p>
            {/* Add message for ID Photo for Canada properties */}
            {isLocationCanada && (
              <p className="text">{t("agreement_notice_selfie")}</p>
            )}
          </div>
        </div>
      </div>
      {isPaymentInProgress && (
        <div className="d-flex mb-4">
          <Loading />
        </div>
      )}
      {!isPaymentInProgress && (
        <PrimaryButton
          label={t(buttonTitle)}
          disabled={!isChecked || disabled}
          onClick={onClick}
        />
      )}
      {/* TermsAndConditions */}
      <FloatingModal
        state={showTermsModal}
        toggle={() => setShowTermsModal(!showTermsModal)}
      >
        <div
          className="d-flex flex-column px-4"
          style={{ maxHeight: "80vh", height: "100%", overflowY: "scroll" }}
        >
          <TermsAndConditions showCompact />
        </div>
      </FloatingModal>

      {/* Cancellation Policy Modal */}
      <FloatingModal
        state={showCancTermsModal}
        toggle={() => setShowCancTermsModal(!showCancTermsModal)}
      >
        <div
          className="d-flex flex-column py-5 px-4"
          style={{ maxHeight: "80vh", height: "100%", overflowY: "scroll" }}
        >
          <h5 className="mb-4">{t("cancellation_policy_title")}</h5>
          <p className="text">
            <span className="d-flex line mb-3">
              {t("cancellation_policy_100")}{" "}
            </span>

            <span className="d-flex line mb-3">
              {t("cancellation_policy_50")}{" "}
            </span>

            <span className="d-flex line mb-3">
              {t("cancellation_policy_0")}{" "}
            </span>

            <span className="d-flex text mt-3">
              {t("agreement_notice_crypto")}
            </span>
          </p>
        </div>
      </FloatingModal>
    </>
  );
};

export default React.memo(BookingAgreementConfirmation);
