import React from "react";
import { Route } from "react-router-dom";
import PageTranslationHOC from "Components/Utils/i18n/PageTranslationHOC";

const TranslatedRoute = ({ component: RouteComponent, lookupKey, componentProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <PageTranslationHOC pageName={lookupKey}>
          <RouteComponent {...routeProps} {...componentProps} />
        </PageTranslationHOC>
      )}
    />
  );
};

export default TranslatedRoute;
