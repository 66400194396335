import React from "react";
import LoadingLogo from "Components/Utils/LoadingLogo";

const LoadingLogoFullScreen = () => {
  return (
    <div className="spinner min-vw-100 min-vh-100 d-flex justify-content-center align-items-center position-fixed start-0 top-0 end-0 bottom-0 bg-white">
      <LoadingLogo />
    </div>
  );
};

export default LoadingLogoFullScreen;
