import toMoney from "../../../../Utils/functions/toMoney";

const getPrice = (suite, quotationData) => {
  let price;

  if (quotationData) {
    price = toMoney(quotationData.avgPrice, quotationData.currency);
  } else if (suite) {
    let suitePrice = suite.avgPrice || suite.basePrice;
    price = toMoney(suitePrice, suite.displayCurrency, 0);
  }

  return {
    from: quotationData ? `` : `from`,
    price: price || "N/A",
  };
};
export default getPrice;
