import React from "react";

// Translation Package
import { useTranslation } from "react-i18next";

import { CiAnnouncement } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";

import getParagraphs from "../functions/getParagraphs";

const ThingsToNote = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  if (!suite.specialAnnouncement) return <></>;

  return (
    <div className="suite__things-to-note">
      <div className="suite__section">
        <SectionTitle
          icon={<CiAnnouncement size={22} />}
          title={t("things_to_note")}
        />
        <div className="section__content things-to-note__content">
          {getParagraphs(suite.specialAnnouncement)}
        </div>
      </div>
    </div>
  );
};
export default ThingsToNote;
