import React, { useState } from "react";

// Layout
import Topbar from "../../Layout/Topbar";
import Footer from "../../Layout/Footer";

// Functions
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import SuiteContent from "./Content";

const Suite = () => {
  const isMobile = useMobileDetect(768);

  const [dataLoaded, setDataLoaded] = useState(false);

  return (
    <>
      <Topbar
        transparentAtTop={dataLoaded && isMobile}
        hideFindAStella={true}
      />
      <SuiteContent setDataLoaded={setDataLoaded} />
      <Footer container="xl" />
    </>
  );
};

export default Suite;
