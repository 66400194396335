import hasUpperCase from "./hasUpperCase";
import hasLowerCase from "./hasLowerCase";
import checkPasswordStrength from "./checkPasswordStrength";
const checkNewPassword = (pass) => {
  const inputValueLength = pass.length;
  const minDigits = 8;

  const minDigitValidation = inputValueLength >= minDigits;
  const upperCaseValidation = hasUpperCase(pass);
  const lowerCaseValidation = hasLowerCase(pass);
  const validation = {
    minDigit: minDigitValidation,
    upperCase: upperCaseValidation,
    lowerCase: lowerCaseValidation,
  };

  const isValid =
    minDigitValidation && upperCaseValidation && lowerCaseValidation;

  const strength = checkPasswordStrength(pass);

  return {
    validation: validation,
    strength: strength,
    isValid: isValid,
  };
};

export default checkNewPassword;
