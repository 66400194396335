import React from "react";
import Dropdown from "./Dropdown";

import { useUser } from "../../../../Providers/UserProvider";

import { AUTH_TITLES, MENU_TITLES } from "../../../../Constants/globals";
import {
  MODALS,
  useUpdateModal,
} from "../../../../Providers/FloatingModalProvider";
import { ABOUT_ITEMS, USER_ITEMS } from "../items";
import LanguageSwitcher from "../../../i18n/LanguageSwitcher";
import { Link } from "react-router-dom";
import * as ROUTES from "Constants/Routes";

// Translation Package
import { useTranslation } from "react-i18next";

const TopbarDesktopContent = () => {
  // Translation hook
  const { t } = useTranslation();

  const { user, loading } = useUser();

  const userName = user ? `${user?.firstName[0]}${user?.lastName[0]}` : "";

  const updateModal = useUpdateModal();
  const loginClick = () => updateModal({ modal: MODALS.LOGIN, props: null });
  const signUpClick = () => updateModal({ modal: MODALS.SIGN_UP, props: null });

  return (
    <div className="topbar__desktop">
      {/* Language Selector dropdown */}
      <div className="topbar__language_switcher">
        <LanguageSwitcher />
      </div>

      {/* About dropdown */}
      <Dropdown
        label={t("nav_menu_about")}
        items={ABOUT_ITEMS}
        noLabelBorder={true}
      />

      <Link to={ROUTES.CORPORATE} className="navlink">
        {t("for_business")}
      </Link>

      <Link to={ROUTES.CONTACT_US} className="navlink">
        {t(MENU_TITLES.CONTACT_US)}
      </Link>

      <Link to={ROUTES.FOR_REAL_ESTATE_PARTNERS} className="navlink">
        {t("for_real_estate_partners_short")}
      </Link>

      {/* User items */}
      <Dropdown
        label={userName}
        items={USER_ITEMS}
        loading={loading}
        condition={user}
        primary={true}
      />

      {/* Guest items */}
      {!user && !loading && (
        <div className="topbar__guest gap-2">
          <button
            className="navlink"
            onClick={loginClick}
            data-test-id="my-account-login"
          >
            {t(AUTH_TITLES.LOG_IN)}
          </button>

          <button
            className="guest__button guest__button--login guest__button--primary"
            onClick={signUpClick}
            data-test-id="my-account-signup"
          >
            {t(AUTH_TITLES.SIGN_UP)}
          </button>
        </div>
      )}
    </div>
  );
};

export default TopbarDesktopContent;
