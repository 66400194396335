import React from "react";
import styles from "./index.module.scss";
// Translation Package
import { useTranslation } from "react-i18next";
import buildingPic from "./assets/building_hero.webp";

// BuildingHero showcase component
const BuildingHero = () => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div
      className={`container ${styles.buildingHero}`}
      style={{ background: `url(${buildingPic})` }}
    >
      <div className={styles.overlay}></div>
      <div className={`row gx-lg-4`}>
        <div className="col-12">
          <div
            className={`header d-flex flex-column justify-content-center align-items-center h-100 text-center ${styles.textBlock}`}
          >
            <h2 className="stella_h2">{t("the_stella_way")}</h2>
            <p className="stella_h4 mb-0">{t("subtitle_the_stella_way")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingHero;
