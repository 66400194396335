import React, { useState, useEffect, Fragment, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import { Portal } from "react-portal";
import * as ROUTES from "../../../Constants/Routes";
import { Link } from "react-router-dom";
import { RemoveScroll } from "react-remove-scroll";

// Hooks
import TopbarContent from "./TopbarContent";
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import useWindowSize from "../../Utils/hooks/useWindowSize";
import ConditionalWrapper from "../../Utils/ConditionalWrapper";
import composeJsxClass from "../../Utils/functions/composeJsxClass";

// Translation Package
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "Components/i18n/LanguageSwitcher";

// Misc
import Logo from "Components/Layout/Logo/header-logo.svg";
import LogoAR from "Components/Layout/Logo/header-logo-ar.svg";
import LogoMobile from "Components/Layout/Logo/header-logo-en-mobile.svg";
import LogoARMobile from "Components/Layout/Logo/header-logo-ar-mobile.svg";
import { BsList, BsX } from "react-icons/bs";

import Filters from "../../Booking/Search/Filters";

const Topbar = (props, ref) => {
  const {
    transparentAtTop,
    fixed,
    hideFindAStella,
    hideFindAStellaTemporarily,
  } = props;
  const isMobile = useMobileDetect(1400);
  const isMobileDetectForLogo = useMobileDetect(1800);
  const isSmartPhone = useMobileDetect(576);
  const windowSize = useWindowSize(500).width;

  const location = useLocation();

  // Mobile menu
  const [mobileMenu, showMobileMenu] = useState(false);
  const toggleMobileMenu = () => showMobileMenu(!mobileMenu);

  // Auto hide
  useEffect(() => showMobileMenu(false), [windowSize, location]);

  const topbarClass = composeJsxClass("topbar", {
    transparentAtTop: transparentAtTop && !mobileMenu,
    fixed: fixed,
  });

  // Check for direction and show appropriate logo
  const { i18n } = useTranslation();
  let LogoForHeader = Logo;
  switch (i18n.dir()) {
    case "rtl": {
      if (isMobileDetectForLogo) {
        LogoForHeader = LogoARMobile;
      } else {
        LogoForHeader = LogoAR;
      }
      break;
    }
    default: {
      if (isMobileDetectForLogo) {
        LogoForHeader = LogoMobile;
      } else {
        LogoForHeader = Logo;
      }
    }
  }

  return (
    <nav className={topbarClass} id="topbar" ref={ref}>
      <div className="container-fluid topbar__container">
        <div className="topbar__logo">
          <Link to={ROUTES.HOME}>
            <img
              src={LogoForHeader}
              alt="stellastays-logo"
              className="logo_image"
            />
          </Link>
        </div>

        {!isMobile && !hideFindAStella && (
          <div className="topbar__find-a-stella">
            <Filters
              noParamUpdate={true}
              topbarButton
              hideTemporarily={hideFindAStellaTemporarily}
              guestStartEmpty={true}
            />
          </div>
        )}

        <div className="d-inline-flex flex-row align-items-center">
          {/* Language Selector dropdown */}
          {isMobile && (
            <div className="topbar__language_switcher">
              <LanguageSwitcher />
            </div>
          )}

          {/* Mobile button */}
          <button
            className="topbar__mobile-menu-button topbar__mobile-only-item"
            onClick={toggleMobileMenu}
            aria-label="Menu"
          >
            {mobileMenu ? (
              <BsX className="icon" />
            ) : (
              <BsList className="icon" />
            )}
          </button>
        </div>

        {/* Content */}
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <Portal>
              <RemoveScroll enabled={isSmartPhone && mobileMenu}>
                {children}
              </RemoveScroll>
            </Portal>
          )}
        >
          <Fragment>
            <TopbarContent mobileMenu={mobileMenu} isMobile={isMobile} />
          </Fragment>
        </ConditionalWrapper>
      </div>
    </nav>
  );
};

export default forwardRef(Topbar);
