import React, { useRef } from "react";

import ListingCard from "../../Listings/ListingCard";
import { DEFAULT_MARKER_STATES } from "../map_constants";
import useClickOutside from "./useClickOutside";
import useWindowSize from "../../../../Utils/hooks/useWindowSize";
import useCardPosition from "./useCardPosition";

const ListingMarker = (props) => {
  const {
    label,
    hovered,
    selected,
    onClick,
    data,
    setMarkerStates,
    map,
    intactListingData,
  } = props;
  const allowNodeToSpawn = useWindowSize().width >= 768;

  const hoveredClass = hovered ? "hovered" : "";
  const selectedClass = selected ? "selected" : "";

  const image = data.images && data.images[0].url;

  return (
    <>
      <div
        className={`search-map-markers__listing-marker ${hoveredClass} ${selectedClass}`}
        onClick={onClick}
      >
        <div className="listing-image">
          <img src={image} alt={data.listingName} className="image" />
        </div>

        <div className="listing-label">{label}</div>
      </div>

      {allowNodeToSpawn && selected && (
        <FloatingListingCard
          data={data}
          intactListingData={intactListingData}
          setMarkerStates={setMarkerStates}
          map={map}
        />
      )}
    </>
  );
};

const FloatingListingCard = ({
  data,
  setMarkerStates,
  map,
  intactListingData,
}) => {
  const cardRef = useRef();

  useClickOutside(cardRef, () =>
    setMarkerStates((states) => ({
      ...DEFAULT_MARKER_STATES.LISTING,
      hovered: states.hovered,
    }))
  );

  const coordinates = {
    lat: data.location.lat,
    lng: data.location.lng,
  };

  const cardPosition = useCardPosition(map, coordinates);

  return (
    <div
      className={`search-map-markers__floating-listing ${cardPosition}`}
      ref={cardRef}
    >
      <div className="search-map-markers__floating-item">
        <ListingCard
          data={data}
          index={0}
          markerStates={null}
          clickOrigin="mapPin"
          fullList={intactListingData}
          setMarkerStates={() => {}}
          forceShowCarousel={true}
        />
      </div>
    </div>
  );
};

export default ListingMarker;
