const getMapBounds = (map) => {
  const bounds = map.getBounds();

  if (!bounds) return null;
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();
  const swA = [sw.lng(), sw.lat()];
  const neA = [ne.lng(), ne.lat()];
  // Supercluster don't work in some cases, fix it
  if (swA[0] > neA[0] && swA[0] - neA[0] < 0.00001) {
    swA[0] = -180;
    neA[0] = 180;
  }

  return [...swA, ...neA];
};
export default getMapBounds;
