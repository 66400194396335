import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { FILTERS } from "../search_constants";

const getFilter = (filter, params) => {
  if (filter === undefined) return {};
  return {
    [filter.paramKey]: params.get(filter.paramKey) || filter.defaultValue,
  };
};

const useFilters = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const [filters, setFilters] = useState({
    ...getFilter(FILTERS.city, params),
    ...getFilter(FILTERS.guests, params),
    ...getFilter(FILTERS.searchType, params),
    ...getFilter(FILTERS.flexibility_type, params),
    ...getFilter(FILTERS.flexibility_months, params),
    ...getFilter(FILTERS.startDate, params),
    ...getFilter(FILTERS.endDate, params),
    ...getFilter(FILTERS.flexibility, params),
  });

  useEffect(() => {
    const params = new URLSearchParams(search);

    setFilters({
      ...getFilter(FILTERS.city, params),
      ...getFilter(FILTERS.guests, params),
      ...getFilter(FILTERS.searchType, params),
      ...getFilter(FILTERS.flexibility_type, params),
      ...getFilter(FILTERS.flexibility_months, params),
      ...getFilter(FILTERS.startDate, params),
      ...getFilter(FILTERS.endDate, params),
      ...getFilter(FILTERS.flexibility, params),
    });
  }, [search]);

  return filters;
};

export default useFilters;
