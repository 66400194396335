import React, { useEffect, useRef, useState } from "react";
import { contactReviewFormState } from "./formState";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { contactReviewFormValidity } from "./formValidity";
import { useReactiveVar } from "@apollo/client";
import { BsExclamationCircleFill } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "Components/Form/PhoneNumberInput";

function ContactReviewForm({ profile }) {
  // Translation hook
  const { t } = useTranslation();
  const mobileRef = useRef();
  const countryRef = useRef();

  const initialFormValues = {
    ...profile,
    mobile:
      (profile.mobile ?? "").length === 0
        ? "+"
        : profile.mobile?.replace(/\s+/g, ""),
    country: "",
  };
  try {
    if (!!initialFormValues.mobile) {
      initialFormValues.mobile = parsePhoneNumber(
        initialFormValues.mobile.replace(/ /g, "")
      ).formatInternational();
    }
  } catch (err) {
    //invalid phone numbers throw an Exception!
  }

  const [formData, setFormData] = useState(initialFormValues);
  const formValidity = useReactiveVar(contactReviewFormValidity);

  /**
   * On form change
   */
  const onFormChange = ({ target }) => {
    const data = { ...formData, [target.id]: target.value };
    if (target.id === "mobile") {
      let val = data.mobile?.replace(/\s+/g, "");

      if (!val.includes("+")) {
        val = `+${val}`;
      }

      const transformedVal = val.length === 1 ? `+${val}` : val;
      const asYouType = new AsYouType().input(transformedVal);
      data.mobile = asYouType;
    }
    setFormData(data);
    contactReviewFormState(data);
  };

  /**
   * If form has errors focus on first element with error
   */
  useEffect(() => {
    for (const key of Object.keys(formValidity)) {
      if (!formValidity[key]) {
        document.getElementById(key)?.focus();
        break;
      }
    }
  }, [formValidity]);
  return (
    <div>
      <div className="confirm-and-pay__form">
        {/* Title */}
        <div className="form-title">
          <h5 className="form-title__title">{t("inline_signup_personal_information")}</h5>
        </div>

        {/* Form */}
        <div className="row">
          {/* First name */}
          <div className="col-12 col-md-6">
            <div className="form-group floating-label">
              <div className="floating-label__input-area">
                <input
                  type="text"
                  className="form-control floating-label__text-input"
                  id="firstName"
                  placeholder=""
                  value={formData.firstName}
                  onChange={onFormChange}
                />
                <label
                  className="floating-label__label"
                  htmlFor="contactFirstName"
                >
                  {t("first_name")}
                </label>
              </div>
              {formValidity.firstName === false && (
                <div className="error-text">
                  <span className="error-text__icon">
                    <BsExclamationCircleFill className="icon" />
                  </span>
                  {t("error_first_name")}
                </div>
              )}
            </div>
          </div>

          {/* Last name */}
          <div className="col-12 col-md-6">
            <div className="form-group floating-label">
              <div className="floating-label__input-area">
                <input
                  type="text"
                  className="form-control floating-label__text-input"
                  id="lastName"
                  placeholder=""
                  value={formData.lastName}
                  onChange={onFormChange}
                />
                <label
                  className="floating-label__label"
                  htmlFor="contactLastName"
                >
                  {t("last_name")}
                </label>
              </div>
              {formValidity.lastName === false && (
                <div className="error-text">
                  <span className="error-text__icon">
                    <BsExclamationCircleFill className="icon" />
                  </span>
                  {t("error_last_name")}
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <p className="info">{t("name_id_notice")}</p>
          </div>
          {/* Mobile */}
          <div className="col-12">
            <PhoneNumberInput
              phoneNumberRegister={mobileRef}
              phoneNumberError={formValidity.mobile === false ? {message: t("error_phone_number_valid")} : false}
              countryError={formValidity.country === false ? {message: t("error_country_code")} : false}
              getCountry={() => formData.country}
              countryRegister={countryRef}
              triggerCountryValidation={() => {}}
              defaultNumber={formData.mobile}
              onCountryChange={(value) => onFormChange({target: {id: 'country', value}})}
              onPhoneNumberChange={(value) => onFormChange({target: {id: 'mobile', value}})}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactReviewForm;
