import React, { useState, useEffect, useMemo } from "react";
import { Map, Overlay, Marker } from "rgm";
import isTouchDevice from "is-touch-device";

// Map
import { useGoogleApiLoader } from "../../../../Utils/GoogleMap/hooks";
import ErrorMaps from "../../../../Utils/GoogleMap/ErrorMaps";
import LoadingMaps from "../../../../Utils/GoogleMap/LoadingMaps";
import { MAP_OPTIONS } from "../../../../Utils/GoogleMap/constants";
import {
  DEFAULT_MARKER_STATES,
  CLUSTER_ZOOM_THRESHOLD,
} from "../map_constants";

// Components
import ClusterMarker from "../Markers/Cluster";
import MapMarker from "../Markers/Listing";
import MobileListingCard from "../MobileListingCard";
import AutoPan from "./AutoPan";
import ZoomControl from "./ZoomControl";
import handleClusterOnClick from "./functions/handleClusterOnClick";
import detectClusterHover from "./functions/detectClusterHover";
import useClusters from "./useClusters";
import CloseButton from "./CloseButton";

const GoogleMap = (props) => {
  const {
    error,
    loading,
    markers,
    defaultZoom,
    setMarkerStates,
    intactListingData,
    handleMarkerOnClick,
    markerStates,
    isMobile,
    isMapOpen,
    toggleMap,
  } = props;

  const location = useMemo(() => {
    if (!props.location) return;
    if (!props.location.lat || !props.location.lng) return;

    return {
      lat: Number(props.location.lat),
      lng: Number(props.location.lng),
    };
  }, [props.location]);

  const api = useGoogleApiLoader();
  const [map, setMap] = useState(null);
  const isTouch = isTouchDevice();

  const [clusterStates, setClusterStates] = useState(
    DEFAULT_MARKER_STATES.CLUSTER
  );
  const { clusters, supercluster } = useClusters(map, markers);

  // Change location
  useEffect(() => {
    if (!map) return;
    map.setCenter(location);
  }, [map, location]);

  // Disable double click zoom if a marker selected
  useEffect(() => {
    if (!api || !map) return;

    const states = [
      // If marker selected
      markerStates.selected.index && true,
      // If cluster selected and cluster passed maximum threshold
      clusterStates.id && clusterStates.isPassedThreshold,
    ];

    const isDisabled = states.includes(true);
    map.setOptions({ disableDoubleClickZoom: isDisabled });
  }, [markerStates, api, map, clusterStates]);

  // Reset to initial location, zoom and markers on mobile map close
  useEffect(() => {
    if (!map) return;
    if (isMapOpen) return;
    map.setCenter(location);
    map.setZoom(MAP_OPTIONS.zoom);
    setMarkerStates(DEFAULT_MARKER_STATES.LISTING);
  }, [isMapOpen, location, map, setMarkerStates]);

  // Render
  if (error) return <ErrorMaps />;
  if (!api || loading) return <LoadingMaps />;

  const uiForceShowClass = !isTouch ? "force-show" : "";

  return (
    <div className="google_maps__container">
      <Map
        api={api}
        ref={setMap}
        options={{
          ...MAP_OPTIONS,
          zoom: defaultZoom ? defaultZoom : MAP_OPTIONS.zoom,
        }}
      >
        <Overlay>
          {clusters &&
            clusters.map((cluster, index) => {
              const [lng, lat] = cluster.geometry.coordinates;
              const isCluster = cluster.properties.cluster;
              const marker = !isCluster ? cluster.marker : null;

              let leaves = [];
              if (cluster?.id && isCluster) {
                  try {
                      leaves = supercluster.getLeaves(cluster?.id, "Infinity");
                  }
                  // prevent random `no cluster with this ID is found` errors when switching cities from crashing the page
                  // the error doesn't affect the functionality of the map, this component gets recreated on city selection, through the `key` attribute
                  catch (err) {}
              }

              const isClusterHovered = detectClusterHover(markers, leaves);

              const isSameCluster =
                clusterStates.coordinates[0] === lng &&
                clusterStates.coordinates[1] === lat;

              const onClickCluster = () => {
                if (isSameCluster) {
                  setClusterStates(DEFAULT_MARKER_STATES.CLUSTER);
                  return;
                }

                const zoomTo = handleClusterOnClick(
                  map,
                  supercluster,
                  cluster,
                  lng,
                  lat
                );

                setMarkerStates(DEFAULT_MARKER_STATES.LISTING);

                setClusterStates({
                  id: cluster.id,
                  coordinates: cluster.geometry.coordinates,
                  leaves: leaves,
                  isPassedThreshold: zoomTo >= CLUSTER_ZOOM_THRESHOLD,
                });
              };

              const onClickMarker = () => {
                setClusterStates(DEFAULT_MARKER_STATES.CLUSTER);
                handleMarkerOnClick(marker, index);
              };

              return (
                <Marker key={`${lng}-${lat}__${index}`} lng={lng} lat={lat}>
                  {isCluster ? (
                    <ClusterMarker
                      onClick={onClickCluster}
                      cluster={cluster}
                      leaves={leaves}
                      hovered={isClusterHovered}
                      isMobile={isMobile}
                      intactListingData={intactListingData}
                      active={isSameCluster}
                      clusterStates={clusterStates}
                      setClusterStates={setClusterStates}
                      map={map}
                    />
                  ) : (
                    <MapMarker
                      key={index}
                      isMobile={isMobile}
                      id={marker.id}
                      label={marker.label}
                      intactListingData={intactListingData}
                      data={marker.data}
                      onClick={onClickMarker}
                      setMarkerStates={setMarkerStates}
                      map={map}
                      {...marker}
                    />
                  )}
                </Marker>
              );
            })}
        </Overlay>
      </Map>

      <div className={`google_maps__search-ui ${uiForceShowClass}`}>
        <div className="search-ui__corner">
          <CloseButton toggleMap={toggleMap} />
          <ZoomControl map={map} defaultZoom={defaultZoom} />
        </div>

        <AutoPan
          map={map}
          api={api}
          markerStates={markerStates}
          markers={markers}
          clusters={{ clusters: clusters, supercluster: supercluster }}
        />
      </div>

      {/* Mobile listing card */}
      {isMobile && (
        <MobileListingCard
          data={markers}
          markerStates={markerStates}
          intactListingData={intactListingData}
          setMarkerStates={setMarkerStates}
          clusterStates={clusterStates}
          setClusterStates={setClusterStates}
          isMapOpen={isMapOpen}
        />
      )}
    </div>
  );
};

export default GoogleMap;
