//JSDOC

/**
 * Returns the zoom level of the map based on the city
 * @param {string} city - The city to get the zoom level for
 * @returns {number} The zoom level of the map
 * @example
 * getMapZoomLevelByCity("dubai")
 * // returns 11
 **/

export const getMapZoomLevelByCity = (city) => {
  const cityName = city ? city.toLowerCase() : "";
  switch (cityName) {
    case "dubai":
      return 11;
    default:
      return 13;
  }
};
