export const DEFAULT_MARKER_STATES = {
  LISTING: {
    selected: { index: null, id: null },
    hovered: { index: null, id: null },
  },
  CLUSTER: {
    id: null,
    coordinates: [0, 0],
    leaves: null,
    isPassedThreshold: false,
  },
};

export const CLUSTER_ZOOM_THRESHOLD = 16;
