import React from "react";
import { MAP_OPTIONS } from "./../../../../Utils/GoogleMap/constants";

import { BiPlus, BiMinus } from "react-icons/bi";

const ZoomControl = (props) => {
  const { map, defaultZoom } = props;

  // Custom zoom
  const zoomMap = (type) => {
    if (!map) return;
    const currentZoom = map.zoom || defaultZoom || MAP_OPTIONS.zoom;
    const newZoom = type === "in" ? currentZoom + 1 : currentZoom - 1;
    map.setZoom(newZoom);
  };

  return (
    <div className="search-ui__item item-zoom">
      <button
        className="search-ui__button button_zoom-in p-0 m-0"
        onClick={() => zoomMap("in")}
      >
        <BiPlus className="ui_icon" />
      </button>
      <div className="search-ui__button-divider"></div>
      <button
        className="search-ui__button button_zoom-out p-0 m-0"
        onClick={() => zoomMap("out")}
      >
        <BiMinus className="ui_icon" />
      </button>
    </div>
  );
};

export default ZoomControl;
