import React from "react";

import { CiImportant } from "../../../../CustomIcons";

// Translation Package
import { Trans } from "react-i18next";

const getError = (data) => {
  if (data.isRangeAvailable === false) {
    return "error_dates";
  }
  if (data.violatesLOS === true) {
    return `los_notice`;
  }
  return false;
};

const QuotationError = ({ data }) => {
  if (!data) return <></>;

  const error = getError(data);
  if (error === false) return <></>;

  return (
    <div className="quotation-error">
      <div className="quotation-error__icon">
        <CiImportant color="#c50000" />
      </div>
      <div className="quotation-error__message">
        <Trans i18nKey={error} values={{ dataMinLOS: data?.minLOS }} />
      </div>
    </div>
  );
};

export default QuotationError;
