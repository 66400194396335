import { useState, useEffect } from "react";
// Translation Package
import { useTranslation } from "react-i18next";

import useFilters from "../hooks/useFilters";

import {
  SEARCH_TYPES,
  FILTERS,
  FLEXIBLE_TYPES,
} from "../../Booking/Search/search_constants";

import isDateValid from "../../Utils/functions/isDateValid";
import createDateRangeString from "../../Utils/functions/createDateRangeString";
import dayjs from "dayjs";

const useButtonLabel = (
  searchType,
  start,
  end,
  flexibleType,
  flexibleMonths,
  forceEmpty
) => {
  // Translation hook
  const { t } = useTranslation();

  const filters = useFilters();
  const [buttonLabel, setButtonLabel] = useState("");

  useEffect(() => {
    if (searchType === SEARCH_TYPES.calendar) {
      const dates = {
        start:
          start ||
          (!forceEmpty && isDateValid(filters[FILTERS.startDate.paramKey])),
        end:
          end ||
          (!forceEmpty && isDateValid(filters[FILTERS.endDate.paramKey])),
      };

      const label = createDateRangeString(dates.start, dates.end);
      setButtonLabel(t(label));
    }

    if (searchType === SEARCH_TYPES.flexible) {
      const flexibleOptions = {
        type: flexibleType || filters[FILTERS.flexibility_type.paramKey],
        months:
          flexibleMonths ||
          filters[FILTERS.flexibility_months.paramKey] ||
          FILTERS.flexibility_months.defaultValue,
      };

      const typeLabel =
        t(FLEXIBLE_TYPES[flexibleOptions.type]?.label) || t("week");
      const months = flexibleOptions.months.split(",");
      const monthNames = months
        ? months.map((month) => t(dayjs(month).format("MMMM").toLowerCase()))
        : t(dayjs().format("MMMM").toLowerCase());
      const monthLabel = monthNames.join(", ");

      setButtonLabel(`${typeLabel} ${t("in")} ${monthLabel}`);
    }
  }, [
    filters,
    searchType,
    start,
    end,
    flexibleType,
    flexibleMonths,
    forceEmpty,
    t,
  ]);

  return buttonLabel;
};
export default useButtonLabel;
