import React from "react";
import styles from "./index.module.scss";
import articles from "Components/Blog/articles";

// Translation Package
import { useTranslation } from "react-i18next";

// Logo Imports
import EsquireLogo from "./assets/esquire.svg";
import AsharqLogo from "./assets/bloomberg.svg";

const FeaturedArticle = ({ article }) => {
  return (
    <a
      href={article.originalUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.article} my-2 mx-2`}
    >
      <div className="article__logo">
        <img src={article.logo} alt={article.title} />
      </div>
    </a>
  );
};

// Featured component
const Featured = ({ style, title }) => {
  // Translation hook
  const { t } = useTranslation();
  let SectionTitle = t("title_featured_in");
  if (title) {
    SectionTitle = title;
  }

  // Adding direct homepage articles
  const featuredArticles = [];

  featuredArticles.push(
    {
      logo: AsharqLogo,
      title: "Bloomberg",
      originalUrl: "https://youtu.be/RrYU3PPj20w",
    },
    {
      logo: EsquireLogo,
      title: "Esquire",
      originalUrl:
        "https://ar.esquireme.com/%D8%A2%D8%AE%D8%B1-%D8%A7%D9%84%D8%A3%D8%AE%D8%A8%D8%A7%D8%B1/%D8%B3%D8%AA%D9%8A%D9%84%D8%A7-%D8%B3%D8%AA%D9%8A%D8%B3-%D8%AA%D8%AF%D8%AE%D9%84-%D8%A7%D9%84%D9%85%D9%85%D9%84%D9%83%D8%A9-%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%D8%A9-%D8%A7%D9%84%D8%B3%D8%B9%D9%88",
    }
  );

  featuredArticles.push(
    ...articles
      .filter((article) => article.homepage)
      .sort(function (a, b) {
        // Sort by homepage index
        let keyA = a.homepageIndex;
        let keyB = b.homepageIndex;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
  );

  return (
    <div className={`container-fluid px-0 ${styles.featured}`} style={style}>
      <div className="container py-5">
        <div className={`row`}>
          <div className="col-12">
            <div className="header text-center">
              <h2 className="stella_h2">{SectionTitle}</h2>
            </div>
          </div>
        </div>

        <div className={`row justify-content-center`}>
          {/* Only Homepage articles */}
          <div
            className={`col-12 d-flex flex-row align-items-center justify-content-center py-4 gap-4 flex-wrap`}
          >
            {featuredArticles.map((article, index) => (
              <FeaturedArticle article={article} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
