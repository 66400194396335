import React from "react";

import { BiX } from "react-icons/bi";

const CloseButton = (props) => {
  const { toggleMap } = props;

  const onClick = () => toggleMap(false);
  return (
    <div className="search-ui__item ">
      <button
        className="search-ui__button button_close p-0 m-0 w-100"
        onClick={onClick}
      >
        <BiX className="ui_icon" />
      </button>
    </div>
  );
};

export default CloseButton;
