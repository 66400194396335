import React, { useCallback, useEffect, useRef, useState } from "react";
import "./dropdown.css";
import { Dropdown } from "semantic-ui-react";
import { CRYPTO_COINS } from "./coins";
import { CRYPTO_NETWORKS } from "./networks";
import CountDown from "./CountDown";
import CopyIcon from "./icons/copy.svg";
import "./style.scss";
import BookingAgreementConfirmation from "../../Components/TermsAgreement";
import { useMutation } from "@apollo/client";
import QUERIES from "../../../../../Constants/Queries";
import { Link, useParams } from "react-router-dom";
import Shimmer from "../../../../Utils/Shimmer";
import * as ROUTES from "../../../../../Constants/Routes";
import { validateForm as validateContactForm } from "../ContactReview/validateForm";
import { paymentErrorCodes } from "../errorCodes";
import CryptoPaymentSuccessful from "./CryptoPaymentSuccessful";
import mobileAppCallback from "Components/Booking/Payment/Forms/functions/mobileAppCallback";
import { PAYMENT_METHOD, PAYMENT_CONTEXT } from "Constants/globals";
import { usePaymentContext } from "Components/Booking/Payment/index";
import PriceNoticeBox from "../../Components/PriceNoticeBox";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

const CryptoPaymentContainer = () => {
  // Translation hook
  const { t } = useTranslation();

  const { context, invCode, propertyData } = usePaymentContext();

  const { id: propertyID, startDate, endDate, numberOfGuests } = useParams();
  const selectedCoin = "USDT";
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [confirmCode, setConfirmCode] = useState(null);

  const [stellaStaysFetchCryptoExchangeRate] = useMutation(
    QUERIES.STELLA_STAYS_FETCH_CRYPTO_EXCHANGE_RATE
  );
  const [stellaStaysCreateCryptoReservation] = useMutation(
    QUERIES.STELLA_STAYS_CREATE_RESERVATION
  );
  const [isFetchingRate, setIsFetchingRate] = useState(false);
  const [isFetchError, setIsFetchError] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);
  const [totalCryptoAmount, setTotalCryptoAmount] = useState(null);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const lastFetchedCoin = useRef(null);
  const networkInfo =
    selectedCoin === "" || selectedNetwork === ""
      ? {}
      : CRYPTO_NETWORKS[selectedCoin].find((n) => n.value === selectedNetwork);
  const [isRateExpired, setIsRateExpired] = useState(false);
  const exchangeLookupID = useRef(null);
  //on successful submission
  const [isComplete, setIsComplete] = useState(false);

  /**
   * Copy to clipboard
   */
  const copyToClipboard = (value) => {
    const elem = document.createElement("textarea");
    elem.value = value;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  /**
   * Fetches rate
   */
  const fetchExchangeRate = useCallback(async () => {
    if (selectedNetwork === "" || lastFetchedCoin.current === selectedCoin) {
      return;
    }
    lastFetchedCoin.current = selectedCoin;
    const payload = {
      coin: selectedCoin,
      network: selectedNetwork,
      propertyID,
      startDate,
      endDate,
      reservationCode: invCode,
    };
    setIsFetchError(null);
    setIsFetchingRate(true);
    try {
      const response = await stellaStaysFetchCryptoExchangeRate({
        variables: payload,
      });
      exchangeLookupID.current = response.data.result.lookupID;
      setTotalCryptoAmount(
        response.data.result.amount + response.data.result.securityDepositAmount
      );
    } catch (err) {
      setIsFetchError(true);
    } finally {
      setIsFetchingRate(false);
    }
  }, [
    selectedNetwork,
    selectedCoin,
    propertyID,
    startDate,
    endDate,
    stellaStaysFetchCryptoExchangeRate,
  ]);

  /**
   * On submission
   */
  const onSubmission = async () => {
    const { isFormValid, ...userProfile } = validateContactForm();
    if (!isFormValid) {
      return;
    }
    setSubmissionError(null);
    setIsPaymentInProgress(true);
    const mutationVariables = {
      payload: {
        propertyID,
        startDate,
        endDate,
        guests: parseInt(numberOfGuests),
        userProfile: {...userProfile, birthdate: userProfile.birthdate ?? null},
        exchangeLookupID: exchangeLookupID.current,
        reservationCode: invCode,
      },
    };
    try {
      const response = await stellaStaysCreateCryptoReservation({
        variables: mutationVariables,
      });
      const reservation = response?.data?.result;
      setConfirmCode(reservation?.confirmationCode);

      if (context === PAYMENT_CONTEXT.MOBILE_APP) {
        const message = {
          reservation_id: reservation.reservationID,
          confirmationCode: reservation?.confirmationCode,
          method: PAYMENT_METHOD.CRYPTO,
          status: "success",
        };

        mobileAppCallback(message);
      }

      setIsComplete(true);
    } catch (err) {
      setSubmissionError(
        paymentErrorCodes[err.message]
          ? t(paymentErrorCodes[err.message])
          : t(paymentErrorCodes.DEFAULT)
      );
      setIsPaymentInProgress(false);
    }
  };

  useEffect(() => {
    if (selectedCoin === "") {
      return;
    }
    fetchExchangeRate();
  }, [selectedCoin, fetchExchangeRate]);

  const showTransfer =
    selectedCoin !== "" &&
    selectedNetwork !== "" &&
    !isFetchingRate &&
    isFetchError === null;

  return (
    <>
      <div
        style={{ padding: "10px", paddingTop: "15px", paddingBottom: "30px" }}
      >
        {/* Process Info */}
        <div
          className="container booking-confirmation p-0"
          id="confirmation-page"
        >
          <div className="row confirmation-content">
            <div className="col-12">
              <div className="shadow-card journey-area border">
                <div className="before-check_in mt-0">
                  {isFetchError !== null && (
                    <div className="alert alert-warning" role="alert">
                      {t("error_payment")}{" "}
                      <Link target="_blank" to={ROUTES.CONTACT_US}>
                        {t("contact_us_click")}
                      </Link>
                    </div>
                  )}
                  {submissionError !== null && (
                    <div className="alert alert-warning" role="alert">
                      {submissionError}
                    </div>
                  )}

                  {/* Notice */}
                  <div className="col-12">
                    <div
                      className="alert alert-warning d-flex align-items-center title"
                      role="alert"
                    >
                      <div className="mx-2">
                        <small>{t("payment_approval_time_crypto")}</small>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Step 1 */}
                <div className="step step_one" id="crypto-network-selector">
                  <div className="step__indicator">
                    <div className="indicator__text">1</div>
                    <div className="indicator__line"></div>
                  </div>
                  <div className="step__content">
                    <h6 className="step__title">{t("select_network")}</h6>

                    <p className="step__description">{t("usdt_only")}</p>

                    <Dropdown
                      disabled={selectedCoin === "" || isFetchingRate}
                      placeholder={t("select_network")}
                      search
                      fluid
                      selection
                      value={selectedNetwork}
                      onChange={(event, data) => setSelectedNetwork(data.value)}
                      options={CRYPTO_NETWORKS[selectedCoin] ?? []}
                    />
                    <br />
                  </div>
                </div>

                {/* Step 2 */}
                <div className="step step_two">
                  <div className="step__indicator">
                    <div className="indicator__text">2</div>
                  </div>
                  <div className="step__content w-100">
                    <h6 className="step__title mb-2">
                      {showTransfer ? (
                        <Trans
                          i18nKey="crypto_instruction_notice"
                          values={{
                            memoTagVar:
                              networkInfo.memoTag !== null
                                ? t("crypto_instruction_memo")
                                : " ",
                          }}
                        >
                          Send only {{ selectedCoinVar: selectedCoin }} to this
                          deposit address
                          <span>[memoTagVar]</span>& ensure the network is{" "}
                          {{ networkInfoVar: networkInfo.text }}.
                        </Trans>
                      ) : (
                        "..."
                      )}
                    </h6>

                    {isFetchingRate && (
                      <div className="col-12 col-md-6 p-4">
                        <Shimmer />
                      </div>
                    )}

                    {showTransfer && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <PriceNoticeBox
                              title={t("total")}
                              price={`${totalCryptoAmount} ${selectedCoin}`}
                            />
                          </div>

                          <div className="col-12 my-3 d-flex flex-row justify-content-end">
                            <CountDown
                              coin={selectedCoin}
                              setIsRateExpired={setIsRateExpired}
                            />
                          </div>

                          <div className="col-12">
                            <div className="card">
                              <div className="card-header py-4">
                                <div className="row">
                                  <div className="col-12 col-md-8 order-2 order-md-1">
                                    <div className="row">
                                      <div className="col-12 d-flex flex-row align-items-center gap-2 mb-2">
                                        <img
                                          src={CRYPTO_COINS[0].image.src}
                                          alt={CRYPTO_COINS[0]?.text}
                                          style={{ width: "64px" }}
                                        />
                                        <h4>{CRYPTO_COINS[0]?.text}</h4>
                                      </div>

                                      {/* Network */}
                                      <div className="col-12 mb-2">
                                        <p className="block text-gray-700 text-sm font-bold mb-0 capitalize">
                                          {t("crypto_network")}
                                        </p>
                                        <div
                                          className={
                                            "col-12 w-full border rounded-2 px-2 py-2 d-flex flex-row justify-content-between"
                                          }
                                          style={{
                                            transition: "all 400ms ease-out",
                                            background: "#f6f8f9",
                                          }}
                                        >
                                          <h6 className="font-bold mb-0 capitalize">
                                            {networkInfo.text}
                                          </h6>
                                        </div>
                                      </div>

                                      {/* Address */}
                                      <div className="col-12">
                                        <p className="block text-sm font-bold mb-0 capitalize">
                                          {t("crypto_address")}
                                        </p>
                                        <div
                                          className={
                                            "col-12 w-full border rounded-2 px-2 py-2 d-flex flex-row justify-content-between"
                                          }
                                          style={{
                                            transition: "all 400ms ease-out",
                                            background: "#f6f8f9",
                                          }}
                                        >
                                          <h6
                                            className="font-bold mb-0 capitalize"
                                            onClick={() =>
                                              copyToClipboard(
                                                networkInfo.address
                                              )
                                            }
                                            role="button"
                                            style={{ wordBreak: "break-all" }}
                                          >
                                            {networkInfo.address}{" "}
                                            <span>
                                              <img
                                                src={CopyIcon}
                                                alt={t("copy")}
                                                style={{
                                                  width: "20px",
                                                  cursor: "pointer",
                                                }}
                                                title={t("copy_address")}
                                              />
                                            </span>
                                          </h6>
                                        </div>
                                      </div>

                                      {networkInfo.memoTag !== null && (
                                        <div className="col-12 mt-2">
                                          <h6>
                                            {t("crypto_memo")}:
                                            <span
                                              style={{ paddingLeft: "5px" }}
                                            >
                                              {networkInfo.memoTag}
                                            </span>
                                            <span
                                              style={{ paddingLeft: "10px" }}
                                            >
                                              <img
                                                src={CopyIcon}
                                                alt={t("copy")}
                                                style={{
                                                  width: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  copyToClipboard(
                                                    networkInfo.memoTag
                                                  )
                                                }
                                                title={t("copy_memo")}
                                              />
                                            </span>
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 order-1 order-md-2 d-flex flex-row justify-content-center justify-content-md-end align-items-start">
                                    <img
                                      id="crypto-qr-image"
                                      src={networkInfo.qr}
                                      style={{ maxWidth: "100px" }}
                                      alt={"qr"}
                                      className="my-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showTransfer && (
          <BookingAgreementConfirmation
            buttonTitle={t("crypto_confirmation_button")}
            isPaymentInProgress={isPaymentInProgress}
            disabled={isRateExpired || exchangeLookupID.current === null}
            onClick={onSubmission}
          />
        )}
      </div>
      {isComplete && (
        <CryptoPaymentSuccessful
          propertyData={propertyData}
          startDate={startDate}
          endDate={endDate}
          reservationID={confirmCode}
        />
      )}
    </>
  );
};
export default CryptoPaymentContainer;
