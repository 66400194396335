import omitRedirectToParam from "Components/Auth/functions/omitRedirectToParam.js";

/** @function redirectAfterLoginSuccess
 * redirects and reloads page
 *
 * @param redirectUrl: string
 * This url must contain the url and search params
 *
 */
const redirectAfterLoginSuccess = (redirectUrl, urlParams) => {
  const cleanUrlParams = omitRedirectToParam(urlParams);
  const makeURL = `${redirectUrl}${cleanUrlParams ? `?${cleanUrlParams}` : ``}`;

  if (redirectUrl) {
    window.location.href = makeURL;
  } else {
    window.location.reload(true);
  }
};

export default redirectAfterLoginSuccess;
