import moment from "moment";
import { DATE_FORMAT } from "Constants/url_params";

const filterHardBlock = (day, sortedUnavailableRange) => {
  let isFound = false;

  /* Check if date is part of HardBlock
      HardBlock is
        Checkin is not possible
        Checkout is not possible
        and also cannot be part of bookable range
  */
  isFound = sortedUnavailableRange.some((unAvailDay) => {
    const matchCondition = unAvailDay.canCheckOutOnStartDate ? "(]" : "[]";

    return day.isBetween(
      unAvailDay.startDate,
      unAvailDay.endDate,
      undefined,
      matchCondition
    );
  });

  return isFound;
};

const calcConfig = (losDay, day, sortedUnavailableRange, losDates) => {
  let isFound = filterHardBlock(day, sortedUnavailableRange);
  if (isFound) {
    // if it is Hard Block return empty, which equates to skip this day in available for either CheckIn or CheckOut
    return [];
  }

  // Check if date has Checkin date else Block it
  let closestStartIndex;
  let closestEndIndex;

  sortedUnavailableRange.forEach((unavailDay, index) => {
    if (day.isSameOrAfter(moment(unavailDay.endDate))) {
      closestStartIndex = index;
    }

    if (
      day.isSameOrBefore(moment(unavailDay.startDate)) &&
      typeof closestEndIndex !== "number"
    ) {
      closestEndIndex = index;
    }
  });

  if (
    typeof closestStartIndex !== "number" ||
    typeof closestEndIndex !== "number"
  ) {
    return [];
  }

  const firstCheckin = moment(sortedUnavailableRange[closestStartIndex].endDate)
    .add(1, "days")
    .format(DATE_FORMAT.apiFormat);

  const closestCheckout = moment(
    sortedUnavailableRange[closestEndIndex].startDate
  )
    .subtract(
      sortedUnavailableRange[closestEndIndex].canCheckOutOnStartDate ? 0 : 1,
      "days"
    )
    .format(DATE_FORMAT.apiFormat);

  const checkOutOnly = moment(losDay.day)
    .add(losDay.los, "days")
    .isAfter(closestCheckout);

  //Calculate closest checkin for this specific date
  const dateToCheckTillFirstAvailableCheckinDiff = Math.abs(
    day.clone().diff(firstCheckin, "days")
  );
  let closestCheckin;
  let thisDateIsBetweenBookableRange = false;

  // if checkin is possible set thisDateIsBetweenBookableRange to true;
  thisDateIsBetweenBookableRange = moment(losDay.day)
    .add(losDay.los, "days")
    .isSameOrBefore(closestCheckout);

  // calc closest checkin and thisDateIsBetweenBookableRange
  if (dateToCheckTillFirstAvailableCheckinDiff > 0) {
    for (let i = dateToCheckTillFirstAvailableCheckinDiff; i >= 0; i--) {
      const dateToPlay = day.clone().subtract(i, "days");
      const findDateInLos = losDates.find((losDate) =>
        dateToPlay.isSame(losDate.day, "days")
      );

      // Check for isBookable
      if (
        (findDateInLos &&
          moment(findDateInLos.day)
            .add(findDateInLos.los, "days")
            .isSameOrBefore(day.format(DATE_FORMAT.apiFormat), "day")) ||
        closestCheckin !== undefined
      ) {
        closestCheckin = findDateInLos.day;
      }

      // Check for isBookable
      if (
        findDateInLos &&
        moment(findDateInLos.day)
          .add(findDateInLos.los, "days")
          .isSameOrBefore(closestCheckout) &&
        day.isBetween(findDateInLos.day, closestCheckout, undefined, "[]")
      ) {
        thisDateIsBetweenBookableRange = true;
      }

      if (closestCheckin !== undefined && thisDateIsBetweenBookableRange) {
        // Break if both found else keep search till first possible checkin
        break;
      }
    }
  }

  /* Check if date is part of bookable range
    ------firstcheckin + los is less and equal than closestCheckout
    ------this date is between firstCheckin and closestCheckout
    */

  // check for all thisDateIsBetweenBookableRange between firstCheckin and closestCheckout

  if (!thisDateIsBetweenBookableRange && checkOutOnly) {
    return [];
  }

  // build Date config object
  const dayConfig = {
    day: day.format(DATE_FORMAT.apiFormat),
    checkOutOnly: checkOutOnly,
    firstPossibleCheckin: firstCheckin,
    closestCheckin: closestCheckin,
    lastPossibleCheckout: closestCheckout,
    thisDateIsBetweenBookableRange: thisDateIsBetweenBookableRange,
    losDay,
  };

  return dayConfig;
};

const getLosAvailabilityDatesConfig = (losDates, sortedUnavailableRange) => {
  const datesConfig = losDates.flatMap((losDay) =>
    calcConfig(losDay, moment(losDay.day), sortedUnavailableRange, losDates)
  );

  return datesConfig;
};

export default getLosAvailabilityDatesConfig;
