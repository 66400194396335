import PrimaryButton from "Components/Buttons/Primary";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrivalTimeSelect from "./ArrivalTimeSelect";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "Components/Form/FormInput";
import SignUpWithEmailForm from "./WithEmail";
import SocialMethods from "Components/Auth/Helpers/SocialMethods";
import ContactReviewForm from "./ContactForm";
import { useMutation } from "@apollo/client";
import useMobileDetect from "Components/Utils/hooks/useMobileDetect";
import { BiCheck } from "react-icons/bi";
import Loading from "Components/Utils/Loading";

import { convertTo24HourFormat } from "./ArrivalTimeSelect/generateTimeSlots";
import { useUser } from "Providers/UserProvider";
import { isEmptyObject } from "../helpers/isEmptyObject";
import { parsePhoneNumber } from "libphonenumber-js";
import { BsExclamationCircleFill } from "react-icons/bs";
import QUERIES from "Constants/Queries";
import { useHistory, useParams } from "react-router-dom";
import { areFieldsDirty } from "../helpers/areFieldsDirty";
import { contactReviewFormState } from "Components/Booking/Payment/Forms/ContactReview/formState";
import { makeVar } from "@apollo/client";

const formValuesVar = makeVar({});

function processData({ data, parsedMobile, email }) {
  let countryCode = "";

  let mobile = data?.mobile ?? parsedMobile;

  if (mobile) countryCode = parsePhoneNumber(mobile)?.country;

  return {
    countryCode,
    mobile,
    arrivalTime: convertTo24HourFormat(data?.arrivalTime),
    specialRequests: data?.specialRequests?.trim(),
    firstName: data?.firstName?.trim(),
    lastName: data?.lastName?.trim(),
    email,
  };
}
function getParsedMobileNumber(mobile) {
  if (!mobile || mobile?.length === 0) return "+";

  let val = mobile?.trim();
  try {
    val = parsePhoneNumber(mobile?.replace(/\s+/g, ""))?.formatInternational();
  } catch (err) {
    // Invalid phone number, keep as is
  }
  return val;
}
export default function PersonalInfo({
  goToNextStep,
  propertyCheckInTime = 16,
  formDefaultValues,
  isLastStep,
  refetch,
}) {
  // Browser history
  const history = useHistory();

  // get user object
  const { user } = useUser();

  // get reservation code
  const { id } = useParams();

  // is signing up state
  const [isSigningUp, setIsSigningUp] = useState(false);

  // is subscribed to news letter state
  const [isSubscribedToNewsLetter, setIsSubscribedToNewsLetter] =
    useState(true);

  // a ref to hold the sign-up button to be clicked on submit.
  const signUpButtonRef = useRef(null);

  // for translations
  const { t } = useTranslation();

  // check if the current screen is mobile.
  const isMobile = useMobileDetect();

  // submit online-check-in mutation if the current step is the final step.
  const [submitOnlineCheckIn, { error: onlineCheckInErrors }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SUBMIT_ONLINE_CHECK_IN
  );

  // submit personal info mutation.
  const [submitPersonalInfo, { error: personalInfoError }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SUBMIT_PERSONAL_INFO
  );

  // update signed-in user profile mutation
  const [updateUserProfile, { error: userProfileError }] = useMutation(
    QUERIES.USER.UPDATE_PROFILE
  );

  // parse mobile number.
  const parsedMobile = useMemo(
    () => getParsedMobileNumber(formDefaultValues?.mobile),
    [formDefaultValues]
  );

  // react-hooks-form initialization
  const methods = useForm({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      arrivalTime: formDefaultValues?.arrivalTime,
      specialRequests: formDefaultValues?.specialRequests ?? "",
      firstName: formDefaultValues?.firstName ?? "",
      lastName: formDefaultValues?.lastName ?? "",
      mobile: parsedMobile,
      ...contactReviewFormState(),
      ...formValuesVar(),
    },
  });

  const {
    formState: { dirtyFields, isSubmitting },
  } = methods;

  const shouldUpdatePersonalInfo = areFieldsDirty(
    ["arrivalTime", "specialRequests"],
    dirtyFields
  );

  const shouldUpdateUserProfile = areFieldsDirty(
    ["firstName", "lastName", "mobile"],
    dirtyFields
  );

  useEffect(() => {
    if (user && signUpButtonRef?.current?.signup) {
      methods.reset({
        ...contactReviewFormState(),
        ...formValuesVar(),
        mobile: getParsedMobileNumber(user?.mobile),
      });
    }
  }, [user]);

  if (contactReviewFormState() === null) {
    contactReviewFormState({
      ...user,
      mobile: getParsedMobileNumber(user?.mobile ?? parsedMobile),
    });
  }

  const onSubmit = async (data) => {
    try {
      // get processed data
      const processedData = processData({
        data,
        parsedMobile,
        email: user?.email ?? formDefaultValues?.email,
      });

      formValuesVar({ ...processedData, arrivalTime: data?.arrivalTime });
      if (signUpButtonRef?.current?.signup) {
        // sign user up.
        await signUpButtonRef.current.signup();
      } else if (!processedData?.mobile) {
        // if no mobile number provided
        methods.setError("mobile", {
          type: "required",
          message: t("error_phone_number_valid"),
        });
      } else {
        // submit the personal info data
        if (!(await submitPersonalInfoStep(processedData))) return;

        if (
          shouldUpdateUserProfile &&
          !(await updateUserProfileStep(processedData))
        )
          return;
        if (shouldUpdatePersonalInfo || shouldUpdateUserProfile) {
          await refetch();
        }

        // if is last step we submit the final step mutation (submit online-check-in)
        if (isLastStep) {
          if (!(await submitFinalStep())) return;
          history.replace(`/online-check-in/${id}/details`);
        } else {
          goToNextStep();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const submitPersonalInfoStep = async (processedData) => {
    const { data: personalInfoResponse, error: personalInfoError } =
      await submitPersonalInfo({
        variables: {
          code: id,
          payload: {
            mobile: processedData?.mobile,
            countryCode: processedData?.countryCode,
            arrivalTime: processedData?.arrivalTime,
            specialRequests: processedData?.specialRequests,
          },
        },
      });

    if (personalInfoError) return false;

    return personalInfoResponse?.result || false;
  };

  const updateUserProfileStep = async (processedData) => {
    const { data: updateProfileResponse, error: userProfileError } =
      await updateUserProfile({
        variables: {
          payload: {
            firstName: processedData.firstName,
            lastName: processedData.lastName,
            mobile: processedData.mobile,
            email: processedData?.email,
          },
        },
      });

    if (userProfileError) return false;

    return updateProfileResponse?.stellaStaysSetUserProfile || false;
  };

  const submitFinalStep = async () => {
    const { data: submitOnlineCheckInResponse, error: onlineCheckInError } =
      await submitOnlineCheckIn({
        variables: {
          code: id,
        },
      });

    if (onlineCheckInError) return false;

    return submitOnlineCheckInResponse?.result || false;
  };

  return (
    <section>
      <section>
        {/* if user is null show the sign-up form and social Media sign up steps. */}
        {!user && (
          <section className="position-relative">
            <SignUpWithEmailForm
              isInlineSignup
              ref={signUpButtonRef}
              setIsSigningUp={(newValue) => setIsSigningUp(newValue)}
              isSubscribedToNewsLetter={isSubscribedToNewsLetter}
              defaultFormValues={{
                firstName: formDefaultValues?.firstName,
                lastName: formDefaultValues?.lastName,
                email: formDefaultValues?.email,
                mobile: parsedMobile,
              }}
            />
            <SocialMethods
              type="signup"
              socialMethodColSpan={isMobile ? 12 : 6}
              isSubscribedToNewsLetter={isSubscribedToNewsLetter}
            />

            <div className="row">
              <div className="mt-2 mb-2 confirm-and-pay__agreement pb-3">
                <div className="agreement d-flex gap-2">
                  <div className="agreement__button">
                    <button
                      className={`check-button my-1 ${
                        isSubscribedToNewsLetter ? "check-button--checked" : ""
                      }`}
                      onClick={() =>
                        setIsSubscribedToNewsLetter(!isSubscribedToNewsLetter)
                      }
                      style={{ border: "0px" }}
                    >
                      {isSubscribedToNewsLetter && <BiCheck className="icon" />}
                    </button>
                  </div>
                  <div className="agreement__text d-flex flex-row align-items-center">
                    <p className="text mb-0">
                      {t("newsLetter_subscription_notice")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {isSigningUp && (
              <div
                className="position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-start px-5 pt-2 rounded-3"
                style={{ background: "rgba(255,255,255,0.8)", zIndex: 1 }}
              >
                <Loading />
              </div>
            )}
          </section>
        )}
      </section>

      {/* Form Section */}
      <section>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {/* contact/profile Info inputs */}
            {!isEmptyObject(user) && user && <ContactReviewForm user={user} />}

            {/* show error if mobile number is missing */}
            {methods?.errors?.mobile && (
              <div className="d-flex flex-row align-items-center gap-2 online-check-in__info--error--text">
                <BsExclamationCircleFill className="icon" />
                {methods?.errors?.mobile?.message}
              </div>
            )}

            {/* Section separator */}
            <hr className="online-check-in__section-separator " />

            {/* Arrival Time section and input */}
            <ArrivalTimeSelect standardCheckInTime={propertyCheckInTime} />

            {/* Section separator */}
            <hr className="online-check-in__section-separator " />

            {/* special request section */}
            <section className="row mb-5">
              <h1 className="online-check-in__subtitle  mb-3">
                {t("online_check_in_special_request")}
              </h1>
              <p className="online-check-in__info--dark-gray online-check-in__info--md pb-3">
                {t("online_check_in_accommodate_requests")}
              </p>

              <FormInput
                register={methods.register()}
                name="specialRequests"
                label={t("special_request_placeholder")}
                type="textarea"
                rows={5}
                error={methods?.errors?.specialRequests}
                defaultValue={formValuesVar()?.specialRequests}
              />
            </section>

            {/* handle graphQL Error */}
            {(onlineCheckInErrors || personalInfoError || userProfileError) && (
              <section className="p-4 online-check-in__info--error--text online-check-in__info--error--bg text-center mt-5">
                {t("error_general")}
              </section>
            )}

            {
              <PrimaryButton
                label={
                  isSigningUp || isSubmitting ? (
                    <Loading light />
                  ) : isLastStep ? (
                    t("submit")
                  ) : (
                    t("next")
                  )
                }
                disabled={isSigningUp || isSubmitting}
                className="online-check-in__button my-5"
                type="submit"
              />
            }
          </form>
        </FormProvider>
      </section>
    </section>
  );
}
