import React from "react";
import { STELLA_BANK_ACCOUNT_LIST } from "Constants/globals";
/**
 * Takes property city as prop and returns bank acc info of Stella Stays where Guest has to deposit.
 */

const StellaBankAccount = ({ city = "Dubai" }) => {
  const cityToCheck = city.toLowerCase();
  let account = null;

  switch (cityToCheck) {
    case "dubai": {
      account = STELLA_BANK_ACCOUNT_LIST.dubai;
      break;
    }
    case "manama": {
      account = STELLA_BANK_ACCOUNT_LIST.manama;
      break;
    }
    case "riyadh": {
      account = STELLA_BANK_ACCOUNT_LIST.riyadh;
      break;
    }
    case "istanbul": {
      account = STELLA_BANK_ACCOUNT_LIST.istanbul;
      break;
    }

    default: {
      account = null;
      break;
    }
  }

  if (!account) {
    return <></>;
  }

  return (
    <ul className="mb-0" dir="ltr">
      <li>Bank Name: {account?.name}</li>
      <li>Branch: {account?.branch}</li>
      <li>Account Name: {account?.accountName}</li>
      <li>Account No.: {account?.accountNo}</li>
      <li>IBAN: {account?.iban}</li>
      <li>Swift: {account?.swift}</li>
    </ul>
  );
};

export default StellaBankAccount;
