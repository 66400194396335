/**
 The payment-gateway redirects the user to this page, for a few seconds before redirecting back to the booking-confirmation page.
 This gives enough time for the payment-gateway to call our webhook and set the order as paid
 */
import React, { useEffect, useState } from "react";
import * as ROUTES from "./../../../Constants/Routes";
import { useParams, useHistory, useLocation } from "react-router-dom";

import Topbar from "../../Layout/Topbar";
import Footer from "../../Layout/Footer";
import Loading from "../../Utils/Loading";
import { PAYMENT_CONTEXT, PAYMENT_METHOD, URL_PARAMS } from "Constants/globals";
import mobileAppCallback from "Components/Booking/Payment/Forms/functions/mobileAppCallback";

// Translation Package
import { useTranslation } from "react-i18next";

const Redirect = () => {
  // Translation hook
  const { t } = useTranslation();
  // For Debug purpose on staging
  const [messageObj, setMessageObj] = useState("");

  const { id: reservationID } = useParams();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectStatus = searchParams.get(URL_PARAMS.REDIRECT_STATUS);
  const context = searchParams.get(URL_PARAMS.CONTEXT);
  const confirmationCodeFromParam = searchParams.get(
    URL_PARAMS.CONFIRMATION_CODE
  );

  const nextPage = `${ROUTES.BOOKING_CONFIRMATION}/${reservationID}`;
  const seconds = 10;

  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((t) => {
        if (t <= 0) {
          return 0;
        }
        return t - 1;
      });

      if (timeLeft === 0) {
        if (context === PAYMENT_CONTEXT.MOBILE_APP) {
          const message = {
            reservation_id: reservationID,
            method: PAYMENT_METHOD.CREDIT_CARD,
            status: redirectStatus,
            confirmationCode: confirmationCodeFromParam,
          };

          setMessageObj(message);
          mobileAppCallback(message);
        } else {
          history.push(nextPage);
        }

        return;
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <>
      {context !== PAYMENT_CONTEXT.MOBILE_APP && <Topbar />}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh", flexDirection: "column" }}
      >
        {timeLeft > 0 ? (
          <div style={{ textAlign: "center" }}>
            <div style={{ fontWeight: 500 }}>{t("redirection_title")}</div>
            <small>{t("redirection_notice", { timeLeft: timeLeft })}</small>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div style={{ fontWeight: 500 }}>{t("redirection_subtitle")}</div>
            <div style={{ marginTop: "20px" }}>
              {context !== PAYMENT_CONTEXT.MOBILE_APP && (
                <small>
                  {t("redirection_trouble")}
                  <br />
                  <a href={nextPage} className="text-button">
                    {t("redirection_manual_message")}
                  </a>
                </small>
              )}
              {process.env.REACT_APP_ENV === "staging" && (
                <span style={{ wordBreak: "break-all" }}>
                  {JSON.stringify(messageObj)}
                </span>
              )}
            </div>
          </div>
        )}

        <div
          style={{ marginTop: "30px", opacity: 0.6 }}
          hidden={timeLeft === 0}
        >
          <Loading />
        </div>
      </div>
      {context !== PAYMENT_CONTEXT.MOBILE_APP && <Footer />}
    </>
  );
};
export default Redirect;
