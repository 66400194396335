import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "Components/Form/Select";
import { generateTimeSlots } from "./generateTimeSlots";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

/**
 * ArrivalTimeSelect component allows users to select their estimated arrival time.
 * It generates time slots based on the property's check-in time.
 *
 * @param {Object} props - Component props.
 * @param {number} props.propertyCheckInTime - The default check-in time for the property, in hours (24-hour format).
 * @returns {JSX.Element} The rendered component.
 */
function ArrivalTimeSelect({ propertyCheckInTime }) {
  // State to store the generated time slots for selection
  const [timeSlots, setTimeSlots] = useState([]);

  // Access the 'register' function from react-hook-form context for form validation
  const { control, errors, setValue } = useFormContext();

  const arrivalTime = useWatch({
    control,
    name: "arrivalTime",
  });

  // For Translations
  const { t } = useTranslation();

  useEffect(() => {
    // Set the default selected time and generate time slots when propertyCheckInTime changes
    setTimeSlots(generateTimeSlots(propertyCheckInTime, arrivalTime));
  }, [propertyCheckInTime]);

  /**
   * Handles the change event when the user selects a different arrival time.
   *
   * @param {Event} e - The change event from the select input.
   */
  function handleChange(e) {
    setValue("arrivalTime", e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }
  // Render the arrival time selection component
  return (
    <div>
      <h1 className="online-check-in__subtitle mb-4">
        {t("online_check_in_estimated_time_of_arrival")}
      </h1>
      <div className="row col-lg-12 col-xl-8">
        <Controller
          name="arrivalTime"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("error_required"),
            },
          }}
          defaultValue={arrivalTime}
          render={(field) => (
            <Select
              label={t("select_your_estimated_arrival_time")}
              reasons={false}
              options={timeSlots}
              classSuffix="white-bg mt-1"
              error={errors?.arrivalTime}
              onSelectChange={handleChange}
              {...field}
            />
          )}
        />
        <p className="online-check-in__info--dark-gray online-check-in__info--md d-flex align-items-start align-items-md-center gap-2 mb-0">
          <FaRegCircleCheck size="20px" color="#49B338" />
          {t("online_check_in_arrival_time_note", { time: arrivalTime })}
        </p>
      </div>
    </div>
  );
}

// Define prop types for the component
ArrivalTimeSelect.propTypes = {
  /** The default check-in time for the property, in hours (24-hour format). */
  propertyCheckInTime: PropTypes.number,
};

// Define default props for the component
ArrivalTimeSelect.defaultProps = {
  propertyCheckInTime: 16,
};

export default ArrivalTimeSelect;
