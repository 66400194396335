/**
 *
 * @param {required: Boolean} required
 * @returns {Function <value: string>}
 * @description Function to validate the form input and make sure it doesn't contains white spaces and it returns function because the react hooks plugin expect function on load to invoke with while validating the input and the outer function just to closein the required var to check it the field is required or not
 */
const trimWhiteSpace =
  (required = true) =>
  (value) =>
    required
      ? !!("" + value).trim()
      : value === ""
      ? true
      : !!("" + value).trim();

export default trimWhiteSpace;
