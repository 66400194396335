import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import QUERIES from "../Constants/Queries";

import firebase from "firebase/app";

import { ApolloProvider, useLazyQuery } from "@apollo/client";
import client from "../lib/apollo";
import * as Sentry from "@sentry/react";
import useSearchQuery from "hooks/useSearchQuery";

const UserContext = createContext();
const UserUpdateContext = createContext();

export const useUser = () => useContext(UserContext);
export const useSignOutUser = () => useContext(UserUpdateContext);

const getFirebaseTokenFromLocalStorage = () => localStorage.getItem("token");
const removeFirebaseTokenFromLocalStorage = () =>
  localStorage.removeItem("token");

const DEFAULT_STATE = {
  firebase: {
    loading: true,
    data: null,
  },
  database: {
    loading: false,
    data: null,
  },
  verify: {
    loading: false,
  },
  preferLang: {
    loading: false,
    data: null,
  },
};

const NO_LOADING_STATE = {
  firebase: {
    loading: false,
    data: null,
  },
  database: {
    loading: false,
    data: null,
  },
  verify: {
    loading: false,
  },
  preferLang: {
    loading: false,
    data: null,
  },
};

const firebaseLocalStorageToken = getFirebaseTokenFromLocalStorage();

const UserProvider = (props) => {
  const { children } = props;

  const [state, setState] = useState(DEFAULT_STATE);
  const [lngParam] = useSearchQuery("lng");

  // Backend data of user
  const [getUserProfile, userProfile] = useLazyQuery(QUERIES.USER.GET_PROFILE, {
    client: client,
    fetchPolicy: "no-cache",
    onCompleted: () => {
      userProfileCallback();
    },
    onError: () => {
      setState(NO_LOADING_STATE);
    },
  });

  // Verify token
  const [verifyUserToken] = useLazyQuery(QUERIES.USER.VERIFY_TOKEN, {
    client: client,
    fetchPolicy: "no-cache",
    variables: {
      token: firebaseLocalStorageToken,
    },
    onCompleted: () => {
      setState((state) => ({
        ...state,
        verify: {
          loading: false,
        },
        database: {
          loading: true,
          data: null,
        },
      }));
      getUserProfile();
      if(lngParam) {
        setState((state) => ({
          ...state,
          preferLang: {
            loading: false,
            data: {language: lngParam},
          },
        }));
      } else {
        getUserPreferredLanguage();
      }
    },
    onError: () => {
      setState(NO_LOADING_STATE);
    },
  });

  // Preferred Language of user
  const [getUserPreferredLanguage, preferredLang] = useLazyQuery(
    QUERIES.USER.PREFERRED_LANGUAGE,
    {
      client: client,
      fetchPolicy: "no-cache",
      onCompleted: () => {
        preferredLangCallback();
      },
      onError: () => {
        setState(NO_LOADING_STATE);
      },
    }
  );

  // Sign out user function
  const resetUserData = () => {
    setState(NO_LOADING_STATE);
    window.location.reload();
  };

  // Set Firebase user
  useEffect(() => {
    setState({
      ...DEFAULT_STATE,
      firebase: {
        loading: true,
        data: null,
      },
    });

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setState({
          ...DEFAULT_STATE,
          firebase: {
            loading: false,
            data: user,
          },
          verify: {
            loading: true,
          },
        });
      } else {
        // Sign out user
        removeFirebaseTokenFromLocalStorage();
        setState(NO_LOADING_STATE);
      }
    });
  }, []);

  // Call verify user token
  useEffect(() => {
    if (state.firebase.loading) return;
    if (!state.firebase.data) return;
    if (!firebaseLocalStorageToken) {
      setState(NO_LOADING_STATE);
      return;
    }
    verifyUserToken();
  }, [state.firebase, verifyUserToken]);

  // Sentry User set
  const setSentryIdentifyUser = (data) => {
    Sentry.setUser({
      email: data?.email,
      name: data?.firstName,
    });
  };

  //Set Google Tag Manager DataLayer
  const sendUserToDataLayer = (data) => {
    let userData = { ...data };
    delete userData["__typename"];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "userLoggedIn", user: userData });
  };

  // Set database user
  const userProfileCallback = useCallback(() => {
    if (userProfile.error) return;
    if (userProfile.data) {
      // Set Provider Data
      setState((state) => ({
        ...state,
        database: {
          loading: false,
          data: userProfile.data.result,
        },
      }));

      // Set Sentry user
      setSentryIdentifyUser(userProfile.data?.result);

      // Send user data to dataLayer
      sendUserToDataLayer(userProfile.data?.result);
    }
  }, [userProfile]);

  // Set database user
  const preferredLangCallback = useCallback(() => {
    if (preferredLang.error) return;
    if (preferredLang.data) {
      setState((state) => ({
        ...state,
        preferLang: {
          loading: false,
          data: preferredLang.data.result,
        },
      }));
    }
  }, [preferredLang]);

  return (
    <UserContext.Provider
      value={{
        user: state.database.data,
        setUser: (newUser) => setState((state) => ({
          ...state,
          database: {
            loading: false,
            data: newUser,
          },
        })),
        firebaseUser: state.firebase.data,
        loading:
          state.firebase.loading ||
          state.database.loading ||
          state.verify.loading ||
          state.preferLang.loading,
        location: null,
        preferLang: state.preferLang.data,
      }}
    >
      <UserUpdateContext.Provider value={resetUserData}>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};

export default UserProvider;
