import React, { useState, useEffect, useRef } from "react";
import LogoutModal from "../../../Modals/LogoutModal";
import { Link, useLocation } from "react-router-dom";

import useOnClickOutside from "../../../Utils/hooks/useOnClickOutside";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import composeJsxClass from "../../../Utils/functions/composeJsxClass";
import { toggleFilterVisibility } from "../../../Booking/Search/FilterVisibility";

// Translation Package
import { useTranslation } from "react-i18next";

const ItemLabel = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { item } = props;

  if (item.icon) {
    return (
      <>
        {t(item.title)}
        <span>{<item.icon />}</span>
      </>
    );
  }
  return <>{t(item.title)}</>;
};

const Dropdown = (props) => {
  const {
    label,
    items,
    loading,
    noLabelBorder,
    primary,
    condition = true,
    btnCallback,
    btnPrefixChild,
    btnTestId,
  } = props;

  const ref = useRef();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const toggleDropdown = () => {
    toggleFilterVisibility(!show);
    setShow(!show);
  };

  const [logoutModalShown, setLogoutModal] = useState(false);
  const toggleLogoutModal = () => !setLogoutModal(!logoutModalShown);

  // Auto hide on location change
  useEffect(() => setShow(false), [location]);

  // Hide on clicking somewhere else
  useOnClickOutside(ref, show, () => {
    setShow(false);
    toggleFilterVisibility(false);
  });

  const mainClass = "topbar__dropdown";
  const dropdownClass = composeJsxClass(mainClass, {
    active: show,
    loading: loading,
    noLabelBorder: noLabelBorder,
    primary: primary,
  });

  if (loading) return <div className={dropdownClass} ref={ref} />;
  if (!condition) return <></>;

  return (
    <div className={dropdownClass} ref={ref}>
      <button
        className="dropdown__button d-flex flex-row gap-1 align-items-center"
        onClick={toggleDropdown}
        data-test-id={btnTestId}
      >
        {btnPrefixChild}
        {label}
        <span className="dropdown__chevron">
          {show ? (
            <FiChevronUp className="icon" />
          ) : (
            <FiChevronDown className="icon" />
          )}
        </span>
      </button>
      <div className="dropdown__content">
        {items.map((item) => {
          const buttonFunction = () => {
            if (item.link) return;

            switch (item.functionKey) {
              case "logout":
                toggleLogoutModal(true);
                break;

              default:
                if (typeof btnCallback === "function") {
                  btnCallback(item);
                }
                break;
            }
          };

          const itemLabelProps = {
            item: item,
          };

          const itemClass = composeJsxClass("dropdown__item", {
            hasIcon: item.icon,
          });

          return (
            <div className={itemClass} key={item.title}>
              {item.link ? (
                item.external ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <ItemLabel {...itemLabelProps} />
                  </a>
                ) : (
                  <Link to={item.link}>
                    <ItemLabel {...itemLabelProps} />
                  </Link>
                )
              ) : (
                <button onClick={buttonFunction}>
                  <ItemLabel {...itemLabelProps} />
                </button>
              )}
            </div>
          );
        })}
      </div>

      {logoutModalShown && <LogoutModal toggle={toggleLogoutModal} />}
    </div>
  );
};

export default Dropdown;
