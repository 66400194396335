//TODO: test that the calender uses English formatting for internal validation, and it renders exact available days in all languages
import React, { useState, useEffect, useRef, useCallback } from "react";

// Modules
import { DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import { RemoveScroll } from "react-remove-scroll";
import { Portal } from "react-portal";
import moment from "moment";
import _ from "lodash";

// Hooks
import useFilters from "../../Utils/hooks/useFilters";
import useButtonLabel from "../../Utils/hooks/useButtonLabel";
import useWindowSize from "../../Utils/hooks/useWindowSize";
import useOnClickOutside from "../../Utils/hooks/useOnClickOutside";

// Functions
import composeJsxClass from "../../Utils/functions/composeJsxClass";
import getParamMonths from "./functions/getParamMonths";
import getDaySize from "./functions/getDaySize";
import getControllerDates from "./functions/getControllerDates";
import onDatesChange from "./functions/onDatesChange";
import getOutsideRange from "./functions/getOutsideRange";
import isDayBlocked from "./functions/isDayBlocked";
// import isPastCheckInTime from "./functions/isPastCheckInTime";

// Components
import SearchFilterButton from "../../Buttons/SearchFilter";

import Tracker from "./Tracker";
import CalendarDay from "./CalendarDay";
import CalendarMonth from "./CalendarMonth";
import NavPrev from "./Nav/Prev";
import NavNext from "./Nav/Next";
import Header from "./Header";
import Footer from "./Footer";
import FlexibleArea from "./FlexibleArea";

// Constants
import {
  DEFAULT_PROPS,
  DEFAULT_DATES,
  DEFAULT_FLEXIBLE,
  FOCUS_KEYS,
  FLEXIBLE_MONTH_LIST,
} from "./constants";
import {
  SEARCH_TYPES,
  FLEXIBLE_TYPES,
  FILTERS,
} from "../../Booking/Search/search_constants";

// Translation Package
import { useTranslation } from "react-i18next";

const debounceAmount = 300;
const debouncedUpdate = _.debounce((callback) => callback(), debounceAmount);

const DateInput = (props) => {
  const {
    header,
    updateDates,
    updateFlexible,
    initialDateValues,
    minimumNights,
    allowFlexible,
    autoClose,
    forceClose,
    className,
    nodeId,
    loading,
    disabled,
    compact,
    autoScrollIntoView,
    forceShow,
    tabIndex,
    suite,
    sortedLosDates,
    sortedUnavailableRanges,
    availableDatesConfig,
    ...otherProps
  } = props;
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 992;
  const filters = useFilters();
  const datePickerHolderRef = useRef();

  //Translation hook
  const { i18n, t } = useTranslation();

  // Initials
  const initialDates = initialDateValues || {
    start: filters[FILTERS.startDate.paramKey],
    end: filters[FILTERS.endDate.paramKey],
  };

  const flexibilityMonthsParams =
    filters[FILTERS.flexibility_months.paramKey]?.split(",") ||
    FILTERS.flexibility_months.defaultValue;
  const initialFlexible = {
    type:
      FLEXIBLE_TYPES[filters[FILTERS.flexibility_type.paramKey]] ||
      FLEXIBLE_TYPES.weekend,
    months: FLEXIBLE_MONTH_LIST.filter((month) =>
      flexibilityMonthsParams.includes(month.value)
    ),
  };

  const initialSearchType =
    filters[FILTERS.searchType.paramKey] || SEARCH_TYPES.calendar;

  // Dates that affects listing results
  const [dates, setDates] = useState(initialDates);
  const [tempDates, setTempDates] = useState(initialDates); // Dates that shown on the picker
  const [flexible, setFlexible] = useState(initialFlexible);
  const [tempFlexible, setTempFlexible] = useState(initialFlexible);
  const [los, setLos] = useState(null);
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(null);

  // Datepicker states
  const [selectType, setSelectType] = useState(initialSearchType);
  const [searchType, setSearchType] = useState(initialSearchType);
  const [monthsAnimationRunning, setMonthsAnimationRunning] = useState(false);

  const handleOnDatesChange = ({ startDate, endDate }) => {
    const payload = {
      // States
      startDate,
      endDate,
      tempDates,
      focus,
      // State handlers
      setLos,
      setTempDates,
      setFocus,
      setShow,
      setDates,
      // Functions
      updateDates,
      availableDatesConfig,
      // Misc
      autoClose,
      allowFlexible,
      isMobile,
      FOCUS_KEYS,
    };
    // return;
    onDatesChange(payload);
  };

  useEffect(() => {
    debouncedUpdate(() => {
      setMonthsAnimationRunning(false);
    });
  }, [monthsAnimationRunning]);

  const desktopNavs = {
    navPrev: <NavPrev setMonthsAnimationRunning={setMonthsAnimationRunning} />,
    navNext: <NavNext setMonthsAnimationRunning={setMonthsAnimationRunning} />,
  };
  const responsiveProps = {
    orientation: isMobile ? "verticalScrollable" : "horizontal",
    numberOfMonths: isMobile ? 6 : 2,
    keepOpenOnDateSelect: isMobile,
    daySize: isMobile ? getDaySize(windowWidth) : 48,
    ...(!isMobile && desktopNavs),
    minDate: moment(),
  };

  // Button handlers
  const [trackInView, setTrackInView] = useState(false);
  const trackerHolderRef = useRef();

  const onShow = useCallback(() => {
    setFocus(FOCUS_KEYS.start);
    setShow(true);
    setTempDates(dates);

    // Auto scroll into view
    if (!autoScrollIntoView) return;
    if (isMobile) return;
    if (trackInView === false) {
      trackerHolderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }
  }, [autoScrollIntoView, dates, isMobile, trackInView]);

  const [oldForceShow, setOldForceShow] = useState(forceShow);
  useEffect(() => {
    if (!forceShow) return;
    if (oldForceShow === forceShow) return;
    setOldForceShow(forceShow);
    onShow();
  }, [forceShow, oldForceShow, onShow]);

  const onApply = () => {
    setFocus(null);
    setShow(false);
    setDates(tempDates);
    setFlexible(tempFlexible);
    setSearchType(selectType);

    if (updateDates && selectType === SEARCH_TYPES.calendar) {
      updateDates(tempDates);
    }
    if (updateFlexible && selectType === SEARCH_TYPES.flexible) {
      const props = {
        type: tempFlexible.type.value,
        months: getParamMonths(tempFlexible.months),
      };
      updateFlexible(props);
    }
  };

  const onClear = () => {
    if (selectType === SEARCH_TYPES.calendar) {
      setFocus(FOCUS_KEYS.start);
      setTempDates(DEFAULT_DATES);

      if (!isMobile && updateDates) {
        setDates(DEFAULT_DATES);
        updateDates(DEFAULT_DATES);
      }

      if (!isMobile && tempDates === DEFAULT_DATES) {
        onClose();
      }
    }

    if (selectType === SEARCH_TYPES.flexible) {
      setTempFlexible(DEFAULT_FLEXIBLE);
    }
  };

  const onClose = () => {
    setFocus(null);
    setShow(false);
    setTempFlexible(flexible);
    if (!tempDates.start && !tempDates.end) {
      setDates(DEFAULT_DATES);
    }
  };

  useEffect(() => {
    if (forceClose && show) {
      setShow(false);
    }
  }, [forceClose, show]);

  // Fix calendar height issue
  useEffect(() => {
    if (isMobile) return;

    let months = document.querySelectorAll(
      ".CalendarMonthGrid_month__horizontal:not(.CalendarMonthGrid_month__hidden)"
    );
    if (!months) return;

    let maxHeight = 0;
    for (let month of months) {
      if (month.clientHeight > maxHeight) {
        maxHeight = month.clientHeight;
      }
    }

    const container = document.querySelectorAll(
      ".DayPicker_transitionContainer"
    );
    if (!container || !container[0]) return;
    let containerHeight = parseInt(container[0].style.height.replace("px", ""));

    if (containerHeight < maxHeight) {
      container[0].style.height = `${maxHeight + 10}px`;
    }
  }, [focus, isMobile]);

  // On outside click
  useOnClickOutside(datePickerHolderRef, show && !isMobile, onClose);

  // JSX
  const buttonText = useButtonLabel(
    searchType,
    dates.start,
    dates.end,
    flexible.type.value,
    getParamMonths(flexible.months),
    true
  );
  const buttonLabel =
    searchType === SEARCH_TYPES.calendar
      ? t("dates")
      : `${t("flexible")} ${t("dates")}`;

  const datepickerClass = composeJsxClass("custom_datepicker", {
    monthsAnimationRunning: monthsAnimationRunning,
    compact: compact,
    focused: show,
  });

  const startDate = getControllerDates(show ? tempDates.start : dates.start);
  const endDate = getControllerDates(show ? tempDates.end : dates.end);

  const portalNode =
    !isMobile &&
    document &&
    document.getElementById(nodeId || "date_input-holder");

  return (
    <div className="datepicker__holder" ref={datePickerHolderRef}>
      <SearchFilterButton
        onClick={onShow}
        id="date_input"
        divId={nodeId || "date_input-holder"}
        className={className}
        label={buttonLabel}
        buttonText={buttonText}
        hasValue={t(buttonText) !== t("select_dates")}
        disabled={disabled}
        loading={loading}
        tabIndex={tabIndex || 1}
        {...otherProps}
      />

      <RemoveScroll enabled={show && isMobile}>
        <Portal node={portalNode}>
          <div className={datepickerClass}>
            <div className="custom_datepicker__container">
              <Header
                header={header}
                allowFlexible={allowFlexible}
                onClose={onClose}
                compact={compact}
                isMobile={isMobile}
                selectType={selectType}
                setSelectType={setSelectType}
              />
              {selectType === SEARCH_TYPES.flexible && (
                <FlexibleArea
                  allowFlexible={allowFlexible}
                  isMobile={isMobile}
                  tempFlexible={tempFlexible}
                  setTempFlexible={setTempFlexible}
                  focus={focus}
                />
              )}

              {selectType === SEARCH_TYPES.calendar && (
                <div className="custom_datepicker__calendar">
                  <div className="custom_datepicker__picker">
                    <DayPickerRangeController
                      {...DEFAULT_PROPS}
                      isRTL={i18n.dir() === "rtl"}
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={handleOnDatesChange}
                      focusedInput={focus}
                      renderMonthElement={({ month }) => {
                        return <CalendarMonth month={month} />;
                      }}
                      renderDayContents={(day) => {
                        //Change week day with translated day array
                        day._locale._weekdaysMin = [
                          t("sun"),
                          t("mon"),
                          t("tue"),
                          t("wed"),
                          t("thu"),
                          t("fri"),
                          t("sat"),
                        ];
                        const findThisDayConfig = availableDatesConfig?.find(
                          (config) =>
                            day.format("YYYY-MM-DD") === config.losDay.day
                        );

                        return (
                          <CalendarDay
                            day={day}
                            startDate={startDate}
                            endDate={endDate}
                            availableDatesConfig={availableDatesConfig}
                            thisDayConfig={findThisDayConfig}
                          />
                        );
                      }}
                      minimumNights={los || minimumNights}
                      isDayBlocked={(day) =>
                        isDayBlocked(day, availableDatesConfig)
                      }
                      isOutsideRange={(day) =>
                        getOutsideRange(day, tempDates, availableDatesConfig)
                      }
                      {...responsiveProps}
                    />
                  </div>
                </div>
              )}
              <Footer
                onClear={onClear}
                onClose={onClose}
                onApply={onApply}
                isMobile={isMobile}
                compact={compact}
              />
              <div
                ref={trackerHolderRef}
                style={{ height: "30px", position: "absolute" }}
              >
                <Tracker tracker={setTrackInView} />
              </div>
            </div>
          </div>
        </Portal>
      </RemoveScroll>
    </div>
  );
};

export default DateInput;
