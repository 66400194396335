import React from "react";

import { AiOutlineLoading } from "react-icons/ai";

const LoadingMaps = () => {
  return (
    <div
      className="google_maps__placeholder loading"
      style={{ height: "100%", width: "100%" }}
    >
      <div className="content">
        <AiOutlineLoading className="icon" />
      </div>
    </div>
  );
};
export default LoadingMaps;
