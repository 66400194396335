import React from "react";
import PropTypes from "prop-types";

/**
 * Step Component
 *
 * Represents a single step in the StepForm component.
 * Displays the step number and name with appropriate styling based on the status.
 *
 * Props:
 * - stepNumber: The number representing the step's position in the sequence.
 * - stepName: The name or title of the step.
 * - status: The current status of the step ('active', 'inactive', 'complete').
 */
const Step = React.memo(function Step({ stepNumber, stepName, status }) {
  // Construct class names based on the status prop
  const stepCircleClassName = `step-form__step-circle step-form__step-circle--${status}`;
  const stepNameClassName = "step-form__step-name";

  return (
    <div className="step-form__step">
      {/* Step Circle */}
      <div className={stepCircleClassName}>{stepNumber}</div>
      {/* Step Name */}
      <div className={stepNameClassName}>{stepName}</div>
    </div>
  );
});

// PropTypes for type checking and documentation
Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepName: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["active", "inactive", "complete"]).isRequired,
};

export default Step;
