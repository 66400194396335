import React, { useState, useEffect, useRef } from "react";

import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { Portal } from "react-portal";
import { RemoveScroll } from "react-remove-scroll";

import ConditionalWrapper from "../../Utils/ConditionalWrapper";
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import { CiX } from "../../../CustomIcons";

const swipeMaxThreshold = 10;
const SwipeableCard = ({
  children,
  isPortal,
  shadowBackground,
  toggle,
  state,
  disableScrolling,
  height = 100,
  heightType = "vh",
  closeIcon = true,
  className,
  topClassName,
  hasContainer,
  mobileWidth,
}) => {
  const isMobile = useMobileDetect(mobileWidth || null);
  // Swiper
  const cardRef = useRef();
  const [cardHeight, setCardHeight] = useState(5000);
  useEffect(() => {
    setCardHeight(cardRef.current.clientHeight);
  }, [cardRef, height, heightType]);

  const [{ y }, springApi] = useSpring(() => ({ y: cardHeight }));
  const open = () => {
    console.log("called open directly");
    springApi.start({
      y: 0,
      immediate: false,
      config: config.default,
    });
  };
  const close = (velocity = 0) => {
    springApi.start({
      y: cardHeight,
      immediate: false,
      config: { ...config.default, velocity },
      onResolve: () => toggle(),
    });
  };
  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position

      if (my < -swipeMaxThreshold) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        my > cardHeight * 0.5 || vy > 0.5 ? close(vy) : open();
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else {
        springApi.start({ y: my, immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  // Open on click
  useEffect(() => {
    if (state) {
      springApi.start({
        y: 0,
        immediate: false,
        config: config.default,
      });
    }
  }, [springApi, state]);

  // Force close if card closed
  useEffect(() => {
    if (!state) {
      springApi.start({
        y: cardHeight,
        immediate: false,
        config: { ...config.default },
      });
    }
  }, [springApi, y, cardHeight, state, isMobile]);

  const bgStyle = {
    opacity: y.to([0, cardHeight], [0.4, 0], "clamp"),
  };

  const cardStyle = isMobile ? { y } : {};

  const handleOnCloseClick = () => {
    toggle();
  };
  return (
    <ConditionalWrapper
      condition={disableScrolling}
      wrapper={(children) => (
        <RemoveScroll enabled={state} allowPinchZoom={true}>
          {children}
        </RemoveScroll>
      )}
    >
      <ConditionalWrapper
        condition={isPortal}
        wrapper={(children) => <Portal>{children}</Portal>}
      >
        {shadowBackground && state && (
          <animated.div
            className="swipeable-card__background"
            style={bgStyle}
          />
        )}

        <animated.div
          className={`swipeable-card__content ${className ? className : ""}`}
          style={{
            ...cardStyle,
            marginBottom: -swipeMaxThreshold,
            paddingBottom: swipeMaxThreshold,
            height: `${height}${heightType}`,
          }}
          ref={cardRef}
          {...bind()}
        >
          <ConditionalWrapper
            condition={hasContainer}
            wrapper={(children) => (
              <div
                className="container px-0"
                style={{ position: "relative", height: "100%" }}
              >
                {children}
              </div>
            )}
          >
            <div
              className={`swipeable-card__top 
						${topClassName ? topClassName : ""}`}
              style={{ cursor: "grab", touchAction: "pan-x" }}
            >
              <div className="top__handle">
                <div className="top__handle__line"></div>
              </div>
              <button
                className={`top__close-button ${
                  closeIcon
                    ? "top__close-button--icon"
                    : "top__close-button--text"
                }`}
                onClick={handleOnCloseClick}
                aria-label="Close"
              >
                {closeIcon ? <CiX className="icon" size={24} /> : `Close`}
              </button>
            </div>
            <div className="swipeable-card__children">{children}</div>
          </ConditionalWrapper>
        </animated.div>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};

export default SwipeableCard;
