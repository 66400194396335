/**
 * Function to send message event for Mobile app
 * @message {
      reservation_id: "5889", Internal Reservation ID
      status: "success" | "succeeded" | "fail",
      confirmationCode: "61700092", Public reference ID for Stays.ai for Crypto & Bank
      method: "creditCard" | "bankTransfer" | "crypto",
    }
 */
const mobileAppCallback = (message) => {
  if (window.paymentMessageHandler) {
    const stringifyMessage = JSON.stringify(message);
    window.paymentMessageHandler.postMessage(stringifyMessage);
  }
};

export default mobileAppCallback;
