import React from "react";
import PlayStoreBadge from "./play_store.svg";
import AppStoreBadge from "./app_store.svg";

import { GUEST_APP_LINKS } from "../../Constants/globals";
import logGaEvent from "../../lib/ga/logGaEvent";
const StoreBadges = () => {
  const handleGaEvent = (store) => {
    logGaEvent(`app_click`, {
      store: store,
    });
  };
  return (
    <>
      <a
        href={GUEST_APP_LINKS.APP_STORE}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleGaEvent("appStore")}
      >
        <img
          alt="Download on the App Store"
          src={AppStoreBadge}
          className="store-badge app-store"
        />
      </a>

      <a
        href={GUEST_APP_LINKS.PLAY_STORE}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleGaEvent("playStore")}
      >
        <img
          alt="Get it on Google Play"
          src={PlayStoreBadge}
          className="store-badge google-play"
        />
      </a>
    </>
  );
};
export default StoreBadges;
