import React, { useState, createContext, useContext } from "react";
import Cookies from "universal-cookie/es6";
import stringToBoolean from "../Components/Utils/functions/stringToBoolean";

// Translation Package
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

const CookieConsentContext = createContext();
const CookieConsentResetContext = createContext();

export const useCookieConsent = () => useContext(CookieConsentContext);
export const useResetCookieConsent = () =>
  useContext(CookieConsentResetContext);

export const consentCookieName = "cookieConsent";

export const setCookie = (val) => {
  const expire = new Date();
  const year = 365 * 24 * 60 * 60 * 1000;
  expire.setTime(expire.getTime() + 1 * year);

  cookies.set(consentCookieName, val, { expires: expire, path: "/" });
};

const CookieConsent = (props) => {
  const { children } = props;
  const cookie = cookies.get(consentCookieName);
  // Translation hook
  const { t } = useTranslation();

  const [value, setValue] = useState(cookie ? stringToBoolean(cookie) : null);

  const saveConsent = (val) => {
    setCookie(val);
    setValue(val);
  };

  const resetConsent = () => {
    setValue(null);
    cookies.remove(consentCookieName);
  };
  return (
    <CookieConsentContext.Provider value={value}>
      <CookieConsentResetContext.Provider value={resetConsent}>
        {value === null && (
          <div className="cookie-consent">
            <div className="cookie-consent__content">
              <div className="cookie-consent__title">{t("cookies_title")}</div>
              <div className="cookie-consent__legal-text">
                {t("cookies_text")}
              </div>

              <div className="cookie-consent__buttons">
                <button
                  className="accept"
                  data-test-id="accept-cookies"
                  onClick={() => saveConsent(true)}
                >
                  {t("accept")}
                </button>
                <button
                  className="decline"
                  data-test-id="decline-cookies"
                  onClick={() => saveConsent(false)}
                >
                  {t("decline")}
                </button>
              </div>
            </div>
          </div>
        )}

        {children}
      </CookieConsentResetContext.Provider>
    </CookieConsentContext.Provider>
  );
};

export default CookieConsent;
