import React from "react";

const Loading = ({ light }) => {
  const colorClass = light ? "light" : "";
  return (
    <div className={`loading ${colorClass}`}>
      <div className="loading__dots" />
    </div>
  );
};

export default Loading;
