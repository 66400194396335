import dayjs from "dayjs";
import { i18n_TYPES } from "../../../Constants/i18n";
require("dayjs/locale/ar");

const createDateRangeString = (start, end) => {
  const isValidStart = dayjs(start || null).isValid();
  const isValidEnd = dayjs(end || null).isValid();

  if (!isValidStart || !isValidEnd) return "select_dates";

  let locale = "en";
  if (window.localStorage.getItem(i18n_TYPES.lookupLocalStorage) === "ar") {
    locale = "ar";
  }

  const today = dayjs();
  const days = {
    start: dayjs(start).locale(locale),
    end: dayjs(end).locale(locale),
  };

  const isSame = {
    month: {
      start: {
        withEnd: days.start.isSame(days.end, "month"),
        withToday: days.start.isSame(today, "month"),
      },
      end: {
        withToday: days.end.isSame(today, "month"),
      },
      all:
        days.start.isSame(days.end, "month") &&
        days.start.isSame(today, "month"),
    },
    year: {
      start: {
        withEnd: days.start.isSame(days.end, "year"),
        withToday: days.start.isSame(today, "year"),
      },
      end: {
        withToday: days.end.isSame(today, "year"),
      },
      all:
        days.start.isSame(days.end, "year") && days.start.isSame(today, "year"),
    },
  };

  const formats = {
    daily: {
      start: days.start.format("DD"),
      end: days.end.format("DD"),
    },
    monthly: {
      start: days.start.format("DD MMM"),
      end: days.end.format("DD MMM"),
    },
    yearly: {
      short: {
        start: days.start.format("DD MMM YY"),
        end: days.end.format("DD MMM YY"),
      },
      long: {
        start: days.start.format("DD MMM YYYY"),
        end: days.end.format("DD MMM YYYY"),
      },
    },
  };

  // Outcomes
  if (isSame.year.all) {
    if (isSame.month.start.withEnd) {
      // Today: 02 May 2021, Start: 07 May 2021, End: 14 May 2021
      // Result: 05 - 12 May
      return `${formats.daily.start} - ${formats.monthly.end}`;
    } else {
      // Today: 02 May 2021, Start: 27 May 2021, End: 14 June 2021
      // Result: 05 May - 12 Jun
      return `${formats.monthly.start} - ${formats.monthly.end}`;
    }
  }

  if (isSame.year.start.withEnd) {
    if (isSame.month.start.withEnd) {
      // Today: 02 May 2021, Start: 12 January 2022, End: 24 January 2022
      // Result: 05 - 12 Jan 2022
      return `${formats.daily.start} - ${formats.yearly.long.end}`;
    } else {
      // Today: 02 May 2021, Start: 26 January 2022, End: 07 February 2022
      // Result: 05 Jan - 12 Feb 2022
      return `${formats.monthly.start} - ${formats.yearly.long.end}`;
    }
  }

  // If start and end is not in the same year between them
  // Today: 02 May 2021, Start: 05 December 2021, End: 16 January 2022
  // Result: 05 Dec 21 - 16 Jan 22

  // or

  // Today: 02 May 2021, Start: 18 November 2022, End: 24 May 2023
  // Result: 05 Nov 22 - 24 May 23
  return `${formats.yearly.short.start} - ${formats.yearly.short.end}`;
  //
};

export default createDateRangeString;
