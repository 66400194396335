import { useState, useEffect } from "react";
import useWindowSize from "./useWindowSize";

import DEFAULT_DEBOUNCE_AMOUNT from "./useWindowSize";

export const DEFAULT_WIDTH_THRESHOLD = 992; //px

const useMobileDetect = (
  widthThreshold = DEFAULT_WIDTH_THRESHOLD,
  debounceAmount = DEFAULT_DEBOUNCE_AMOUNT
) => {
  const [mobile, setMobile] = useState(false);
  const currentWidth = useWindowSize(debounceAmount).width;

  useEffect(() => {
    if (currentWidth >= widthThreshold) {
      setMobile(false);
      return;
    }

    setMobile(true);
  }, [currentWidth, widthThreshold]);

  return mobile;
};

export default useMobileDetect;
