import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SlickWrapper from "../../../Utils/SlickWrapper";

import { BiCalendarAlt } from "react-icons/bi";

import toMoney from "../../../Utils/functions/toMoney";

import * as ROUTES from "../../../../Constants/Routes";
import "react-lazy-load-image-component/src/effects/opacity.css";

import useFilters from "../../../Utils/hooks/useFilters";
import createDateRangeString from "../../../Utils/functions/createDateRangeString";
import { FILTERS } from "../../../../Constants/url_params";
import { useInView } from "react-intersection-observer";
import logListingClickEvent from "../functions/logListingClickEvent";

// Translation Package
import { useTranslation } from "react-i18next";

const composeSearchQuery = (filters) => {
  const queries = [
    filters.start_date
      ? `${FILTERS.startDate.paramKey}=${filters.start_date}`
      : null,
    filters.end_date ? `${FILTERS.endDate.paramKey}=${filters.end_date}` : null,
    filters.guests ? `${FILTERS.guests.paramKey}=${filters.guests}` : null,
  ];
  return `?${queries.filter((q) => q).join("&")}`;
};

const ListingCard = (props) => {
  const {
    data,
    setMarkerStates,
    fullList,
    index,
    selectedClass,
    map,
    isMobile,
    alternative,
    forceShowCarousel,
    clickOrigin = "listingCard",
  } = props;

  // Translation hook
  const { t } = useTranslation();

  const [showCarousel, setShowCarousel] = useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    rootMargin: isMobile ? "250px" : "0px",
    triggerOnce: !isMobile,
  });

  useEffect(() => {
    if (!isMobile && forceShowCarousel) {
      return setShowCarousel(true);
    }
    if (!data?.images) {
      return setShowCarousel(false);
    }
    if (!inView) {
      return setShowCarousel(false);
    }
    if (!isMobile) {
      return setShowCarousel(true);
    }
    if (map && !inView) {
      return setShowCarousel(false);
    }
    return setShowCarousel(true);
  }, [data.images, inView, isMobile, map, showCarousel, forceShowCarousel]);

  const filters = useFilters();

  const properties = [
    {
      label: "Bedroom",
      data: data.bedrooms,
    },
    {
      label: "Bathroom",
      data: data.baths,
    },
    {
      label: "Guest",
      data: data.maxGuests,
    },
  ];

  const price = toMoney(
    data.avgPrice || data.basePrice,
    data.displayCurrency,
    0
  );

  const [showSliderNav, setSliderNav] = useState(false);
  const toggleSliderNav = (bool) => setSliderNav(bool);

  const handleOnFocus = () => {
    toggleSliderNav(true);
  };

  const handleOnBlur = () => {
    toggleSliderNav(false);
  };

  const handleOnMouseEnter = () => {
    toggleSliderNav(true);
    setMarkerStates((states) => {
      return {
        hovered: { index: index, id: data.id },
        selected: states.selected,
      };
    });
  };

  const handleOnMouseLeave = () => {
    toggleSliderNav(false);
    setMarkerStates((states) => {
      return { hovered: { index: null, id: null }, selected: states.selected };
    });
  };

  const disableLink = (e) => e.preventDefault();

  const searchQuery = composeSearchQuery({
    guests: filters.guests,
    start_date: data?.quotationStartDate || filters.start_date,
    end_date: data?.quotationEndDate || filters.end_date,
  });

  const mainClass = alternative
    ? "col-12 listings-col alternative-col"
    : "col-12 col-md-6 listings-col";

  const handleGaEvent = () => {
    logListingClickEvent(fullList, filters, data, clickOrigin, props.index);
  };

  return (
    <div className={mainClass} ref={ref}>
      <div
        className={`search_page__listing-card ${selectedClass}`}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <Link
          className="listing_anchor"
          target="_blank"
          onClick={handleGaEvent}
          to={{
            pathname: `${ROUTES.SUITE}/${data.id}/${data.urlFriendlyID}`,
            search: searchQuery,
          }}
        >
          <div className="listing">
            <div className="box-shadow" />

            <div className="search_page__slider listing-card__slider">
              {showCarousel ? (
                <SlickWrapper
                  items={[...data?.images].splice(0, 10).map((image, index) => {
                    return (
                      <img
                        loading="lazy"
                        key={index}
                        src={image.url}
                        alt={image.title}
                        width="402px"
                        height="245px"
                      />
                    );
                  })}
                  forceShowNav={showSliderNav}
                />
              ) : (
                <div className="search_page__slider-placeholder" />
              )}
            </div>

            <div className="listing__price" onClick={disableLink}>
              {!data.quotationStartDate && !data.quotationEndDate
                ? t("from") + " "
                : ""}
              <span>{price}</span>/{t("night")}
            </div>

            <div className="listing__content">
              <div className="listing__availability">
                {data.quotationStartDate && (
                  <>
                    <span className="icon-holder">
                      <BiCalendarAlt className="icon" />
                    </span>

                    <div className="label">
                      {t("available")}{" "}
                      <span className="data">
                        {createDateRangeString(
                          data.quotationStartDate,
                          data.quotationEndDate
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="listing__title">
                <h6 className="title">
                  {/* TODO replace name? */}
                  {data?.listingName?.replace("Stella Stays | ", "")}
                </h6>
              </div>

              <div className="listing__bottom">
                <div className="properties" onClick={disableLink}>
                  {properties.map((property, index) => {
                    let propertyLabel = property?.label?.toLowerCase();

                    switch (propertyLabel) {
                      case "bedroom": {
                        // Check for bedroom count and return bedroom or studio
                        switch (property.data) {
                          case 0: {
                            propertyLabel = t("studio");
                            break;
                          }
                          case 1: {
                            propertyLabel = `${property.data} ${t(
                              propertyLabel
                            )}`;
                            break;
                          }
                          default: {
                            propertyLabel = `${property.data} ${t(
                              propertyLabel + "s"
                            )}`;
                            break;
                          }
                        }
                        break;
                      }

                      case "guest": {
                        // Check for guest count and return guest or guest_counter
                        propertyLabel = `${property.data} ${
                          property.data > 1 ? t(`guests_counter`) : t("guest")
                        }`;
                        break;
                      }

                      default: {
                        // handle all cases other than bedroom
                        if (property.data > 1) {
                          propertyLabel = `${property.data} ${t(
                            propertyLabel + "s"
                          )}`;
                        } else {
                          propertyLabel = `${property.data} ${t(
                            propertyLabel
                          )}`;
                        }
                      }
                    }

                    return (
                      <div className="property" key={index}>
                        <span className="data">{propertyLabel} </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ListingCard;
