import React from "react";

import {
  CONTACT_NUMBER,
  CONTACT_EMAIL,
} from "../../../../../Constants/globals";
import {
  CiMail,
  CiQuestionBubble,
  CiWhatsapp,
} from "../../../../../CustomIcons";
import logGaEvent from "../../../../../lib/ga/logGaEvent";
import styles from "./index.module.scss";
// Translation Package
import { useTranslation } from "react-i18next";

const QuestionBox = ({ city }) => {
  //Translation hook
  const { t } = useTranslation();

  const mail = {
    address: CONTACT_EMAIL,
    subject: `Inquiry`,
    body: encodeURI(
      `Hello, I have a question about ${city ? city : ""} properties.`
    ),
  };
  const whatsapp = {
    number: CONTACT_NUMBER.EMBED.replace("+", ""),
    numberHuman: CONTACT_NUMBER.HUMAN,
    text: encodeURI(
      `Hello! I have a question about ${city ? city : ""} properties.`
    ),
  };

  const handleGaEvent = (method) => {
    logGaEvent(`contact_us`, {
      method: method,
    });
  };

  return (
    <div
      className={[
        styles.question,
        "d-flex flex-column justify-content-center align-items-center",
      ].join(" ")}
    >
      <div className={styles.section}>
        <div className={[styles.title, "d-flex flex-row gap-2"].join(" ")}>
          <CiQuestionBubble size={32} className={styles.icon} />
          <h3>{t("title_question_box_results")}</h3>
        </div>

        <div className={styles.content}>
          <div
            className={[
              styles.buttons,
              "d-flex flex-column align-items-start",
            ].join(" ")}
          >
            <div className={styles.button}>
              <a
                href={`https://wa.me/${whatsapp.number}?text=${whatsapp.text}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleGaEvent("whatsapp")}
              >
                <CiWhatsapp className={styles.icon} />{" "}
                <span dir="ltr">{CONTACT_NUMBER.HUMAN}</span>
              </a>
            </div>
            <div className={styles.button}>
              <a
                href={`mailto:${mail.address}?subject=${mail.subject}&body=${mail.body}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleGaEvent("email")}
              >
                <CiMail className={styles.icon} />
                <span dir="ltr">{mail.address}</span>
              </a>
            </div>
          </div>
          <div className={styles.background}>
            <CiQuestionBubble size={180} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionBox;
