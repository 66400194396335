import { useMutation } from "@apollo/client";
import StripeNewCardSetup from "./StripeCardSetup";
import Loading from "Components/Utils/Loading";
import QUERIES from "Constants/Queries";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Error from "Components/Utils/Error";

export default function PaymentDetails({
  goToPreviousStep,
  goToNextStep,
  total,
  currency,
  refetch,
  isLastStep,
}) {
  const { id } = useParams();

  const [setUpPaymentIntent, { data, loading, error }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SETUP_PAYMENT_INTENT
  );

  useEffect(() => {
    async function triggerPaymentIntentMutation() {
      try {
        await setUpPaymentIntent({
          variables: {
            code: id,
          },
          skip: !id,
        });
      } catch (error) {
        console.log({ error });
      }
    }
    triggerPaymentIntentMutation();
  }, [id, setUpPaymentIntent]);

  if (loading) return <Loading />;

  if (error || !data?.result?.intentReferenceKey) return <Error />;

  return (
    <section>
      <StripeNewCardSetup
        intentReferenceKey={data?.result?.intentReferenceKey}
        publishableKey={data?.result?.publishableKey}
        clientSecret={data?.result?.clientSecret}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        total={total}
        currency={currency}
        refetch={refetch}
        isLastStep={isLastStep}
      />
    </section>
  );
}
