import * as ROUTES from "./Routes";

export const URL_PARAMS = {
  REDIRECT: "redirect_to",
  REDIRECT_STATUS: "redirect_status",
  RESERVATION_CODE: "reservation_code",
  CONFIRMATION_CODE: "confirmation_code",
  CONTEXT: "context",
};
export const ONE_DAY_IN_HOURS = 24
export const TWO_DAYS_IN_HOURS = 48;

export const CONTACT_NUMBER = {
  HUMAN: `+18777703934`,
  EMBED: `+18777703934`,
};
export const CONTACT_NUMBER_HQ = {
  HUMAN: `+971 4 242 5595`,
  EMBED: `97142425595`,
};

export const STELLA_HQ_GOOGLE_MAP_LINK =
  "https://maps.app.goo.gl/4zcZ6BbgbuwKjm9v5";
export const PO_BOX_NUMBER = "11132";

export const CONTACT_EMAIL = `hello@stellastays.com`;
export const PRESS = {
  EMAIL: "press@stellastays.com",
  SUBJECT: `Stella Stays Media Enquiry`,
  BODY: `My name is [your name], and I am the [position, company].\n I’m reaching out about`,
};

export const CORPORATE_NUMBER = {
  HUMAN: `+18777703934`,
  EMBED: `+18777703934`,
};
export const CORPORATE_EMAIL = `hello@stellastays.com`;

export const MENU_TITLES = {
  ABOUT: "nav_menu_about",
  FIND_A_STELLA: "find_a_stella",
  WHO_WE_ARE: "who_we_are",
  HELP_CENTER: "help_center",
  FOR_REAL_ESTATE_PARTNERS: "for_real_estate_partners",
  CONTACT_US: "contact_us",
  PRESS: "stella_press",
  CORPORATE: "for_business",
  CAREERS: "careers",
};

export const LEGAL_TITLES = {
  TERMS_AND_CONDITIONS: "terms_and_conditions",
  PRIVACY_POLICY: "privacy_policy",
};

export const AUTH_TITLES = {
  LOG_OUT: "log_out",
  LOG_IN: "login",
  SIGN_UP: "sign_up",
  MY_ACCOUNT: "my_account",
};

export const GUEST_APP_LINKS = {
  PLAY_STORE:
    "https://play.google.com/store/apps/details?id=ai.stays.stella.guestapp.release&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  APP_STORE: "https://apps.apple.com/ca/app/stella-stays/id1568217403",
};

export const SOCIAL_MEDIA_LINKS = [
  {
    title: "facebook",
    icon: "",
    link: ROUTES.FACEBOOK,
  },
  // {
  //   title: "Twitter",
  //   icon: "",
  //   link: "https://twitter.com/stellastays/",
  // },
  {
    title: "linkedin",
    icon: "",
    link: ROUTES.LINKEDIN,
  },
  {
    title: "instagram",
    icon: "",
    link: ROUTES.INSTAGRAM,
  },
];

// enum QuotationPaymentMethod
export const PAYMENT_METHOD = {
  CREDIT_CARD: "creditCard",
  BANK: "bankTransfer",
  CRYPTO: "crypto",
  INQUIRY: "inquiry",
};

export const PAYMENT_STEP = {
  SELECT_METHOD: "select_method",
  PAY: "pay",
};

export const PAYMENT_CONTEXT = {
  WEBSITE: "website",
  MOBILE_APP: "mobile_app",
};

export const DOCUMENT_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REVIEW: "review",
};

export const STELLA_BANK_ACCOUNT_LIST = {
  dubai: {
    name: "ADCB Bank",
    branch: "Main Br Al Ain, UAE",
    accountName: "Stella Stays Vacation Homes Rental LLC",
    accountNo: "11504750920001",
    iban: "AE600030011504750920001",
    swift: "ADCBAEAA",
  },
  manama: {
    name: "National Bank of Bahrain",
    branch: "NBB Building, Government Avenue, Manama",
    accountName: "Stella Stays Bahrain W.L.L",
    accountNo: "0084117842",
    iban: "BH45NBOB00000084117842",
    swift: "NBOBBHBM or NBOBBHBMXXX",
  },
  riyadh: {
    name: "Riyad Bank",
    branch: "Global Services Central",
    accountName: "Marhab Residentials Units Co. KSA",
    accountNo: "2000056869940",
    iban: "SA6720000002000056869940",
    swift: "RIBLSARI",
  },
  istanbul: {
    name: "Ziraat bankası",
    branch: "Vadesiz Hesap",
    accountName: "Stella Stays Konaklama Hizmetleri Anonim Sirketi",
    accountNo: "97560409-5002",
    iban: "TR82 0001 0020 5797 5604 0950 02",
    swift: "TCZBTR2A",
  },
  london: {
    name: "ADCB Bank",
    branch: "Main Br Al Ain, UAE",
    accountName: "Stella Stays Vacation Homes Rental LLC",
    accountNo: "11504750920001",
    iban: "AE600030011504750920001",
    swift: "ADCBAEAA",
  },
};
