import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Tracker = ({ tracker }) => {
  const { ref, inView } = useInView({
    rootMargin: "-20px",
  });
  useEffect(() => {
    tracker(inView);
  }, [inView, tracker]);
  return <div className="custom_datepicker--in-view-tracker" ref={ref}></div>;
};

export default Tracker;
