import React, { useState } from "react";

// Translation Package
import { useTranslation } from "react-i18next";

import { CiCheck } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";
import SvgItem from "../../../../CustomIcons/SvgItem";
import decodeSVG from "../../../../CustomIcons/decodeSVG";

const OurPlaceOffers = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  const unitOffers = suite.unitOffers;
  const isMobile = useMobileDetect();

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(!showAll);

  if (!unitOffers) return <></>;

  return (
    <div className="suite__our-place-offers" id="our-place-offers">
      <div className="suite__section">
        <SectionDivider className="my-4" />
        <SectionTitle
          icon={<CiCheck size={22} />}
          title={t("our_place_offers")}
        />
        <div className="section__content our-place-offers__content">
          <div className="content__title">{t("unit_features")}</div>
          {/* <div className="content__subtitle">
            Duis aute irure dolor in reprehenderit in voluptate.
          </div> */}
          <div className="content__items">
            {unitOffers
              .slice(0, showAll ? unitOffers.length : isMobile ? 10 : 12)
              .map((item, index) => {
                return <UnitOffersItem item={item} key={index} />;
              })}
          </div>
          <div className="content__show-all mt-2" hidden={showAll}>
            <button className="text-button" onClick={toggleShowAll}>
              {t("show_all_amenities", { n: unitOffers.length })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UnitOffersItem = ({ item }) => {
  const svg = decodeSVG(item.icon);
  return (
    <div className="unit-offers-item">
      <div className="unit-offers-item__content">
        <div className="unit-offers-item__icon">
          <SvgItem className="icon" i={svg} size={22} />
        </div>
        <div className="unit-offers-item__label">{item.title}</div>
      </div>
    </div>
  );
};
export default OurPlaceOffers;
