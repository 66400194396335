import FloatingModal from "Components/Modals/FloatingModal";
import { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";

const ChangePaymentMethod = ({ disabled = false, onConfirm = () => {} }) => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="my-2 pb-3">
        <div className="d-flex gap-2">
          <button
            type="button"
            onClick={() => setShowModal(!showModal)}
            className="btn btn-outline-secondary d-inline-flex align-self-center w-auto rounded-pill align-items-center px-4"
          >
            {i18n.dir() === "rtl" ? <BsArrowRight /> : <BiArrowBack />}{" "}
            <span className="px-2">{t("change_payment_method")}</span>
          </button>
        </div>
      </div>

      {/* Confirm Modal */}
      <FloatingModal state={showModal} toggle={() => setShowModal(!showModal)}>
        <div
          className="d-flex flex-column my-4 justify-content-center"
          style={{ maxHeight: "30vh", height: "100%", overflowY: "scroll" }}
        >
          <div className="logout-modal w-auto h-auto position-relative">
            <div className="content_holder my-4">
              <div className="modal_content">
                <h4 className="title mb-4">{t("change_payment_method")}</h4>

                <div className="buttons">
                  <button
                    className="btn cancel"
                    onClick={() => setShowModal(!showModal)}
                  >
                    {t("no")}
                  </button>
                  <button className="btn log-out" onClick={onConfirm}>
                    {t("yes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FloatingModal>
    </>
  );
};

export default ChangePaymentMethod;
