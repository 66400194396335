import React from "react";
import logGaEvent from "../../../lib/ga/logGaEvent";
import { CONTACT_NUMBER, CONTACT_EMAIL } from "../../../Constants/globals";

// Translation Package
import { useTranslation } from "react-i18next";

const Help = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { data } = props;

  const confirmationCode = data.reservation.confirmationCode;
  const mail = {
    address: CONTACT_EMAIL,
    subject: `Help: My stay - ${confirmationCode}`,
    body: "Hello, I'd like to know more about my stay.",
  };
  const whatsapp = {
    number: CONTACT_NUMBER.EMBED,
    text: `Hello, I'd like to know more about my stay. My confirmation code is ${confirmationCode}.`,
  };

  const handleGaEvent = (method) => {
    logGaEvent(`contact_us`, {
      method: method,
    });
  };

  return (
    <div className="shadow-card help" id="confirmation-help">
      <h6 className="question">{t("success_page_contact_us_title")}</h6>
      <p className="contact-info">
        {t("success_page_contact_us_whatsapp")}{" "}
        <a
          href={`https://wa.me/${whatsapp.number
            .replace("+", "")
            .replace(/\s/g, "")}?text=${encodeURI(whatsapp.text)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleGaEvent("whatsapp")}
        >
          {whatsapp.number}
        </a>{" "}
        {t("success_page_contact_us_email")}{" "}
        <a
          href={`mailto:${mail.address}?subject=${mail.subject}&body=${mail.body}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleGaEvent("email")}
        >
          {mail.address}
        </a>
      </p>
    </div>
  );
};

export default Help;
