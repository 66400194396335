/**
 *
 * @param paramKey the param key you want to access
 * @returns array of two elements, the first element is the value for the `paramKey`
 * the second item is a setter function to change the `paramKey` value
 * 
 * @example
 * const myComponent = () => {
 *  const [langParam, setLangParam] = useSearchQuery("lng");
 *  console.log(langParam);  // 'en'
 *  
 *  const onChangeLang = (newLangVal) => setLangParam(newLangVal);   
 * }
 */
const useSearchQuery = (paramKey = "") => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(paramKey);

  const setParam = (newVal) => {
    urlParams.set(paramKey, newVal);
    window.location.search = urlParams.toString();
  };

  return [param, setParam];
};

export default useSearchQuery;