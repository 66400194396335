import React, { useEffect } from "react";
import { BsX } from "react-icons/bs";
import { RemoveScroll } from "react-remove-scroll";
import { Portal } from "react-portal";

import {
  useModal,
  useUpdateModal,
  MODALS,
} from "../../Providers/FloatingModalProvider";
import useMobileDetect from "../Utils/hooks/useMobileDetect";

// Forms
import Login from "./Login";

import SignupOptions from "./SignUp/Options";
import SignupWithEmail from "./SignUp/WithEmail";

import ForgotPassword from "./Password/Forgot";
import ResetPassword from "./Password/Reset";

import VerifyEmail from "./Verify/Email";
import VerifyBirthdate from "./Verify/Birthdate";

export const DEFAULT_MODAL = { modal: null, props: null };

const AuthModal = () => {
  const isMobile = useMobileDetect();
  const { modal, props } = useModal();
  const updateModal = useUpdateModal();

  const showClass = !modal ? "" : "show";
  const closeAuthModal = () => {
    if (modal === MODALS.VERIFY_BIRTHDATE) return;
    updateModal(DEFAULT_MODAL);
  };

  // Close with escape key
  useEffect(() => {
    if (!modal) return;
    if (modal === MODALS.VERIFY_BIRTHDATE) return;

    const escFunction = (e) => {
      e.keyCode === 27 && updateModal(DEFAULT_MODAL);
    };

    document.addEventListener("keydown", escFunction);
    return () => document.removeEventListener("keydown", escFunction);
  }, [modal, updateModal]);

  return (
    <RemoveScroll enabled={isMobile ? false : modal ? true : false}>
      <Portal>
        <div className={`floating-modal ${showClass}`}>
          <div className="bg" onClick={closeAuthModal} />
          <div className="container auth-floating-container">
            <button
              className="close-button"
              onClick={closeAuthModal}
              hidden={!modal || modal === MODALS.VERIFY_BIRTHDATE}
            >
              <BsX className="close-icon" />
            </button>

            {modal === MODALS.LOGIN && <Login {...props} />}
            {modal === MODALS.SIGN_UP && <SignupOptions {...props} />}
            {modal === MODALS.SIGN_UP_WITH_EMAIL && (
              <SignupWithEmail {...props} />
            )}
            {modal === MODALS.VERIFY_EMAIL && <VerifyEmail {...props} />}
            {modal === MODALS.VERIFY_BIRTHDATE && (
              <VerifyBirthdate {...props} />
            )}
            {modal === MODALS.FORGOT_PASSWORD && <ForgotPassword {...props} />}
            {modal === MODALS.RESET_PASSWORD && <ResetPassword {...props} />}
          </div>
        </div>
      </Portal>
    </RemoveScroll>
  );
};

export default AuthModal;
