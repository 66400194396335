/**
 * Converts an hour in 24-hour format to a time string in "h:mm AM/PM" format.
 * @param {number} hour - Hour in 24-hour format (e.g., 16 for 4:00 PM).
 * @returns {string} Time string in "h:mm AM/PM" format.
 */
export function hoursToTimeString(hour = 16) {
  let hours = Math.floor(hour);
  let mins = Math.round((hour - hours) * 60);
  const meridiem = hours >= 12 ? "PM" : "AM";

  if (hours > 12) hours -= 12;
  else if (hours === 0) hours = 12;

  return `${hours}:${mins.toString().padStart(2, "0")} ${meridiem}`;
}
/**
 * Converts minutes since midnight to a time string in "h:mm A" format.
 * @param {number} minutes - Minutes since midnight.
 * @returns {string} Time string in "h:mm A" format.
 */
export function minutesToTimeString(minutes) {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  const meridiem = hours >= 12 ? "PM" : "AM";

  if (hours > 12) hours -= 12;
  else if (hours === 0) hours = 12;

  return `${hours}:${mins.toString().padStart(2, "0")} ${meridiem}`;
}

const INTERVAL = 30;

/**
 * Generates a list of time slots for arrival time selection.
 * @param {string} propertyCheckInTime - The property's standard check-in time in "h:mm A" format.
 * @returns {string[]} Array of time slot strings in "h:mm A" format.
 */
export function generateTimeSlots(
  propertyCheckInTime,
  previouslyChosenArrivalTime = undefined
) {
  const standardCheckInMinutes = propertyCheckInTime * 60;
  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();

  // Round down currentMinutes to the nearest 30-minute interval
  const roundedCurrentMinutes = currentMinutes - (currentMinutes % INTERVAL);

  // Determine the starting time in minutes
  let startMinutes = Math.max(standardCheckInMinutes, roundedCurrentMinutes);

  const endMinutes = 23 * 60 + 30; // 11:30 PM in minutes

  const timeSlots = [];

  for (let minutes = startMinutes; minutes <= endMinutes; minutes += INTERVAL) {
    timeSlots.push(minutesToTimeString(minutes));
  }

  if (
    previouslyChosenArrivalTime &&
    !timeSlots?.includes(previouslyChosenArrivalTime)
  ) {
    timeSlots.unshift(previouslyChosenArrivalTime);
  }
  return timeSlots;
}

/**
 * Converts a time string from 12-hour format with AM/PM to 24-hour format.
 *
 * @param {string} timeString - The time string in 12-hour format with AM/PM (e.g., "3:00 PM").
 * @returns {string} The time string in 24-hour format (e.g., "15:00").
 */
export function convertTo24HourFormat(timeString) {
  if (!timeString) return "";

  const [time, modifier] = timeString?.split(" ");

  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

/**
 * Converts a time string from 24-hour format to 12-hour format.
 *
 * @param {string} time24 - The time string in 24-hour format (e.g., "16:00").
 * @returns {string} The time string in 12-hour format with AM/PM (e.g., "04:00 PM").
 */
export function convertTo12HourFormat(time24) {
  // Split the time string into hours and minutes
  let [hours, minutes] = time24.split(":");

  // Convert hours from string to number
  hours = parseInt(hours, 10);

  // Determine AM or PM suffix
  const suffix = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format hours as two digits
  const formattedHours = hours.toString();

  // Return the formatted time string
  return `${formattedHours}:${minutes} ${suffix}`;
}
