import React from "react";

import { CiLocation } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";
import SvgItem from "../../../../CustomIcons/SvgItem";
import decodeSVG from "../../../../CustomIcons/decodeSVG";

import HouseSvg from "./HouseSvg";
import MarkerSvg from "./MarkerSvg";

// Utils
import GoogleMap from "../../../Utils/GoogleMap";

// Translation Package
import { useTranslation } from "react-i18next";

const Neighbourhood = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  if (!suite) return <></>;

  const location = {
    lat: parseFloat(suite.location.lat),
    lng: parseFloat(suite.location.lng),
  };

  const marker = <Marker lat={location.lat} lng={location.lng} />;
  const buildingAmenities = suite.buildingAmenities;
  return (
    <div className="suite__neighbourhood" id="neighbourhood">
      <div className="suite__section">
        <SectionDivider className="my-4" />
        <SectionTitle
          icon={<CiLocation size={22} />}
          title={t("the_neighbourhood_and_building")}
        />
        <div className="section__content neighbourhood__content">
          <div className="content__text">
            {/* <div className="content__title">Old Montreal</div>
            <div className="content__subtitle">
              Old Montreal is one of the city's most desirable neighborhoods to
              stay in. With a history stretching back to the 17th century, it's
              well-known for its classic architectural charms, vibrant
              nightlife, and world-class dining.
            </div> */}
            <div className="content__building-amenities">
              <div className="content__title">{t("building_amenities")}</div>
              <div className="content__subtitle content__location">
                {suite.location.address}
              </div>
              {buildingAmenities && (
                <div className="amenities">
                  {buildingAmenities.map((amenity, index) => {
                    return <BuildingAmenity amenity={amenity} key={index} />;
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="content__map" id="map-canvas">
            <div className="content__open-in-google-maps">
              <a
                className="google-maps-button"
                href={`https://www.google.com/maps/search/${location.lat},${location.lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("open_in_google_maps")}
              </a>
            </div>
            <GoogleMap location={location} marker={marker} />
          </div>
        </div>
      </div>
    </div>
  );
};

const BuildingAmenity = ({ amenity }) => {
  const svg = decodeSVG(amenity.icon);

  return (
    <div className="amenity-item">
      <div className="amenity-item__content">
        <div className="amenity-item__icon">
          <SvgItem className="icon" i={svg} size={22} />
        </div>
        <div className="amenity-item__label">{amenity.title}</div>
      </div>
    </div>
  );
};

const Marker = () => {
  return (
    <div className="neighbourhood__marker">
      <div className="marker__marker">
        <MarkerSvg />
      </div>

      <div className="marker__house">
        <HouseSvg />
      </div>
      <div className="arrow-down" />
    </div>
  );
};

export default Neighbourhood;
