import React from "react";

function RadioBox({
  className,
  name,
  label,
  register,
  onClick,
  value,
  selected,
  icon,
}) {
  const checked = value === selected;
  return (
    <div
      className={`radiobox d-flex flex-row gap-2 align-items-center ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <input
        type="radio"
        className="input"
        name={name}
        ref={register}
        value={value}
        selected={value === selected}
        readOnly
      />
      <div
        className={`icon-holder d-flex justify-content-center align-items-center`}
      >
        {checked && <div className="checked"></div>}
      </div>
      {icon}
      <label className={`label ${checked && "checked"} text-dark`}>
        {label}
      </label>
    </div>
  );
}

export default RadioBox;
