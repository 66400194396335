// Lib
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import "dayjs/locale/ar";

// Components and hooks
import Topbar from "Components/Layout/Topbar";
import Footer from "Components/Layout/Footer";
import Loading from "Components/Utils/Loading";
import Error from "Components/Utils/Error";
import SummaryCard from "../Components/SummaryCard";
import useMobileDetect from "Components/Utils/hooks/useMobileDetect";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import QUERIES from "Constants/Queries";
import CheckInImage from "../Components/Icons/check-in.svg";
import CheckOutImage from "../Components/Icons/check-out.svg";

// Images
import DigitalUnlock from "../Components/Icons/digital-unlock.png";
import DigitalUnlock2 from "../Components/Icons/digital-unlock2.png";
import AppStore from "../Components/Icons/app-store.png";
import GooglePlay from "../Components/Icons/google-play.png";
import { CgProfile } from "react-icons/cg";

// Styles
import "../onlineCheckIn.scss";
import "./index.scss";

const BookingDetailsPage = () => {
  // for translation
  const { t, i18n } = useTranslation();

  // getting the reservation code
  const { id = undefined } = useParams();

  // detect small screens at 992px
  const isMobile = useMobileDetect();

  // fetch reservation data
  const { loading, error, data } = useQuery(
    QUERIES.ONLINE_CHECK_IN.GET_RESERVATION_BY_CODE,
    {
      variables: { code: id, language: i18n.language.replace("-GQL", "") },
      skip: !id || !i18n.language,
    }
  );

  // extract data from result
  const { reservation, documents } = useMemo(
    () => ({
      reservation: data?.result?.reservation,
      documents: data?.result?.documents,
    }),
    [data?.result]
  );

  // Loader
  if (loading) return <Loading />;

  // error Page
  if (error || !data?.result)
    return (
      <>
        <Topbar hideFindAStella />
        <Helmet>
          <title>
            {t("quick_online_check_in")} - {t("stella_stays")}
          </title>
        </Helmet>
        <Error />
      </>
    );

  return (
    <>
      <Topbar hideFindAStella />
      <Helmet>
        <title>
          {t("quick_online_check_in")} - {t("stella_stays")}
        </title>
      </Helmet>

      {/* Page content */}
      <MainContent
        isMobile={isMobile}
        reservation={reservation}
        documents={documents}
      />

      <Footer />
    </>
  );
};

const MainContent = ({ isMobile, reservation, documents }) => (
  <section className={`online-check-in booking-details mb-5`}>
    <div className="container-xl">
      <div className="row content align-content-start justify-content-start justify-content-lg-between mt-4 mt-xxl-5">
        {/* reservation data mapped */}
        <ContentLeftColumn reservation={reservation} documents={documents} />

        {/* Summary Card */}
        <ContentRightColumn reservation={reservation} />
      </div>
    </div>

    {/* The Gray section where we show the benefits and stores links */}
    <GraySection isMobile={isMobile} />
  </section>
);

const ContentLeftColumn = ({ reservation, documents }) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="col-12 col-lg-7 col-xxl-7 order-1 order-lg-0">
      <h1
        className="online-check-in__title d-flex align-items-center gap-2"
        style={{ color: "#49B338" }}
      >
        <IoMdCheckmarkCircleOutline size="2rem" />{" "}
        <span>
          {t("your_booking_details_title", {
            city: t(reservation?.city?.toLowerCase() ?? reservation?.city),
          })}
        </span>
      </h1>
      <p className="online-check-in__description mt-4">
        {t("your_booking_details_description")}
      </p>

      {/* check-in details date and time */}
      <CheckInOutDetails
        checkIn={dayjs(reservation?.checkIn)
          .locale(i18n.languages[0])
          .format("dddd, D MMMM YYYY")}
        checkOut={dayjs(reservation?.checkOut)
          .locale(i18n.languages[0])
          .format("dddd, D MMMM YYYY")}
        title={t("your_trip_details")}
      />

      {/* listing all guests's info */}
      <ListInfoComponent
        items={documents?.filter((item) => item?.isGuest)}
        title={t("guests_information")}
      />

      {/* listing all visitors's info */}
      <ListInfoComponent
        items={documents?.filter((item) => !item?.isGuest)}
        title={t("visitors_information")}
      />
    </section>
  );
};

const ContentRightColumn = ({ reservation }) => (
  <section
    className={`col-12 col-lg-5 col-xxl-4 order-0 order-lg-1 mb-4 mb-lg-5`}
  >
    <SummaryCard
      property={{
        mainImageUrl: reservation?.propertyImageUrl,
        listingName: reservation?.propertyListingName,
        address: reservation?.propertyAddress,
      }}
      startDate={reservation?.checkIn}
      endDate={reservation?.checkOut}
      guests={reservation?.numberOfGuests}
      confirmationCode={reservation?.confirmationCode}
      guestName={`${reservation?.firstName} ${reservation?.lastName}`}
      channel={reservation?.channel}
    />
  </section>
);

const GraySection = ({ isMobile }) => {
  const { t } = useTranslation();
  return (
    <section className="booking-details__gray-section-container">
      <div className="booking-details__gray-section">
        <div className="container-xl">
          <div className="">
            <section>
              <h1 className="online-check-in__title booking-details__gray-section__title">
                {t("smart_digital_key")}
              </h1>
              <p className="online-check-in__description mt-3 online-check-in__info--lg">
                {t("smart_digital_key_description")}
              </p>
            </section>

            <section className="row ">
              <div className="col-12 col-lg-5">
                <BenefitList
                  list={[
                    t("hassle_free_entry"),
                    t("secure_access"),
                    t("no_keys_required"),
                  ]}
                />
                {isMobile && (
                  <div className="d-flex gap-3 align-items-center justify-content-center">
                    <img
                      src={DigitalUnlock2}
                      alt="Digital Unlock2"
                      className="booking-details__digital-unlock-section__image-sm"
                    />
                    <img
                      src={DigitalUnlock}
                      alt="Digital Unlock"
                      className="booking-details__digital-unlock-section__image-sm"
                    />
                  </div>
                )}
                <DownLoadFromStores />
              </div>

              {!isMobile && (
                <div className="col-12 col-lg-7 booking-details__digital-unlock-section d-flex align-items-center">
                  <img
                    src={DigitalUnlock2}
                    alt="Digital Unlock2"
                    className="booking-details__digital-unlock-section__image"
                  />
                  <img
                    src={DigitalUnlock}
                    alt="Digital Unlock"
                    className="booking-details__digital-unlock-section__image"
                  />
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

const CheckInOutDetails = ({ checkIn, checkOut, title }) => {
  const { t } = useTranslation();

  function CheckIn() {
    return (
      <h3 className="online-check-in__info--dark-gray online-check-in__info--lg mt-3 d-flex align-items-center gap-3">
        <span
          className="d-flex gap-2 align-items-center "
          style={{ flexBasis: "145px" }}
        >
          <span>
            <img src={CheckInImage} alt={t("check_in")} />
          </span>
          <span className="online-check-in__info--dark-gray online-check-in__info--lg font-semibold primary-font ">
            {t("check_in")}
          </span>
        </span>
        <span className="online-check-in__info--gray online-check-in__info--lg primary-font">
          {checkIn}
        </span>
      </h3>
    );
  }
  function CheckOut() {
    return (
      <h3 className="online-check-in__info--dark-gray online-check-in__info--lg mt-3 d-flex align-items-center gap-3">
        <span
          className="d-flex gap-2 align-items-center "
          style={{ flexBasis: "145px" }}
        >
          <span>
            <img src={CheckOutImage} alt={t("check_out")} />
          </span>
          <span className="online-check-in__info--dark-gray online-check-in__info--lg font-semibold  primary-font ">
            {t("check_out")}
          </span>
        </span>
        <span className="online-check-in__info--gray online-check-in__info--lg primary-font">
          {checkOut}
        </span>
      </h3>
    );
  }

  return (
    <div className="my-4 my-xl-5">
      <h2 className="online-check-in__subtitle ">{title}</h2>
      <CheckIn />
      <CheckOut />
    </div>
  );
};

const ListInfoComponent = ({ items, title }) => {
  if (items?.length === 0) return null;
  return (
    <>
      <hr className="online-check-in__separator" />
      <div className="my-4 my-xl-5">
        <h2 className="online-check-in__subtitle">{title}</h2>
        {items?.map((item) => (
          <div
            className="d-flex align-items-center gap-2 my-3"
            key={item?.firstName}
          >
            <div className="online-check-in__info--gray online-check-in__info--lg">
              <CgProfile size="1.5rem" />
            </div>
            <div className="online-check-in__info--dark-gray online-check-in__info--lg">
              {item?.firstName} {item?.lastName}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const BenefitList = ({ list }) => (
  <div className="booking-details__list-container">
    <ol className="booking-details__list">
      {list?.map((item, index) => (
        <li
          className="d-flex align-items-center gap-2 booking-details__list-item"
          key={index}
        >
          <IoMdCheckmarkCircleOutline size="1.25rem" color="#49B338" />
          <span className="online-check-in__info--dark-gray online-check-in__info--lg">
            {item}
          </span>
        </li>
      ))}
    </ol>
  </div>
);

const DownLoadFromStores = () => (
  <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-2 booking-details__stores-section">
    <a
      href="https://apps.apple.com/ca/app/stella-stays/id1568217403"
      target="_blank"
      rel="noreferrer"
    >
      <img src={AppStore} alt="app-store" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=ai.stays.stella.guestapp.release&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      target="_blank"
      rel="noreferrer"
    >
      <img src={GooglePlay} alt="google-play" />
    </a>
  </div>
);

export default BookingDetailsPage;
