import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Topbar from "../Layout/Topbar";
import StepForm from "./Components/StepsForm";
import PersonalInfo from "./Components/PersonalInfo";
import PaymentDetails from "./Components/PaymentDetails";
import IDVerification from "./Components/IDVerification";
import Loading from "Components/Utils/Loading";
import { useEffect, useMemo, useState } from "react";
import SummaryCard from "./Components/SummaryCard";
import { useParams } from "react-router-dom";
import QUERIES from "Constants/Queries";
import { useQuery } from "@apollo/client";
import StickyBox from "react-sticky-box";
import Footer from "Components/Layout/Footer";
import { isNonEmptyObj } from "./Components/helpers/isNoneEmptyObject";
import {
  convertTo12HourFormat,
  hoursToTimeString,
} from "./Components/PersonalInfo/ArrivalTimeSelect/generateTimeSlots";
import Error from "Components/Utils/Error";
import "./onlineCheckIn.scss";

export default function OnlineCheckIn() {
  const { t, i18n } = useTranslation();
  const { id = undefined } = useParams();
  const [showPaymentStep, setShowPaymentStep] = useState(false);
  const [showIDVerification, setShowIDVerification] = useState(false);
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const {
    loading,
    error,
    data: fetchData,
    refetch,
  } = useQuery(QUERIES.ONLINE_CHECK_IN.GET_RESERVATION_BY_CODE, {
    variables: { code: id, language: i18n.language?.replace("-GQL", "") },
    skip: !id || !i18n.language,
  });

  const { reservation, payment, documents, isDocumentSubmissionRequired } =
    useMemo(() => {
      return {
        reservation: fetchData?.result?.reservation,
        payment: fetchData?.result?.payment,
        documents: fetchData?.result?.documents,
        isDocumentSubmissionRequired:
          fetchData?.result?.isDocumentSubmissionRequired,
      };
    }, [fetchData?.result]);

  useEffect(() => {
    // show the payment step if the payment Object does not equals null.
    setShowPaymentStep(isNonEmptyObj(payment));
    setShowIDVerification(isDocumentSubmissionRequired);
  }, [isDocumentSubmissionRequired, payment]);

  const steps = useMemo(
    () => [
      t("personal_info"),
      ...(showPaymentStep ? [t("payment_details")] : []),
      ...(showIDVerification ? [t("id_verification")] : []),
    ],
    [showPaymentStep, t, showIDVerification]
  );

  function shouldRenderStep(currentStep, name) {
    return steps[currentStep] === name;
  }
  function shouldSubmitCheckIn(name) {
    return steps[steps?.length - 1] === name;
  }
  if (loading) return <Loading />;

  if (error || !fetchData?.result)
    return (
      <>
        <Topbar hideFindAStella />
        <Helmet>
          <title>
            {t("quick_online_check_in")} - {t("stella_stays")}
          </title>
        </Helmet>
        <Error />
      </>
    );

  return (
    <>
      <Topbar hideFindAStella />
      <Helmet>
        <title>
          {t("quick_online_check_in")} - {t("stella_stays")}
        </title>
      </Helmet>

      <section className="container-xl online-check-in mb-5 ">
        <div className="row content align-content-start justify-content-start justify-content-lg-between mt-4 mt-xxl-5">
          <section className="col-12 col-lg-7 col-xxl-7 order-1 order-lg-0 ">
            <div>
              <h1 className="online-check-in__title">
                {t("quick_online_check_in")}
              </h1>
              <p className="online-check-in__description mt-4">
                {t("online_check_in_desc")}
              </p>
            </div>
            <div>
              <StepForm
                steps={steps}
                initialStep={0}
                getCurrentStep={(currentStep) => {
                  setIsPaymentStep(
                    shouldRenderStep(currentStep, t("payment_details"))
                  );
                }}
              >
                {({ currentStep, goToNextStep, goToPreviousStep }) => (
                  <>
                    {shouldRenderStep(currentStep, t("personal_info")) && (
                      <PersonalInfo
                        goToNextStep={goToNextStep}
                        propertyCheckInTime={reservation?.standardCheckInHour}
                        formDefaultValues={{
                          arrivalTime: reservation?.arrivalTime
                            ? convertTo12HourFormat(reservation?.arrivalTime)
                            : hoursToTimeString(
                                reservation?.standardCheckInHour ?? 16
                              ),
                          specialRequests: reservation?.specialRequests || "",
                          firstName: reservation?.firstName || "",
                          lastName: reservation?.lastName || "",
                          email: reservation?.email || "",
                          mobile: reservation?.mobile || "",
                        }}
                        isLastStep={shouldSubmitCheckIn(t("personal_info"))}
                        refetch={refetch}
                      />
                    )}

                    {shouldRenderStep(currentStep, t("payment_details")) && (
                      <PaymentDetails
                        goToPreviousStep={goToPreviousStep}
                        goToNextStep={goToNextStep}
                        isLastStep={shouldSubmitCheckIn(t("payment_details"))}
                        total={payment?.grandTotal ?? 0}
                        currency={payment?.currency}
                        refetch={refetch}
                      />
                    )}

                    {shouldRenderStep(currentStep, t("id_verification")) && (
                      <IDVerification
                        documents={documents ?? []}
                        initialGuests={reservation?.numberOfGuests || 1}
                        city={reservation?.city?.toLowerCase()}
                        country={reservation?.country?.toLowerCase()}
                        isLastStep={shouldSubmitCheckIn(t("id_verification"))}
                        maxGuests={reservation?.propertyMaxGuests}
                        refetch={refetch}
                      />
                    )}
                  </>
                )}
              </StepForm>
            </div>
          </section>
          <section className="col-12 col-lg-5 col-xxl-4  order-0 order-lg-1 mb-4 mb-lg-5 ">
            <StickyBox offsetTop={90} offsetBottom={20} className="sticky-box">
              <SummaryCard
                initialOpenForMobile={false}
                property={{
                  mainImageUrl: reservation?.propertyImageUrl,
                  listingName: reservation?.propertyListingName,
                  address: reservation?.propertyAddress,
                }}
                payment={{
                  grandTotal: payment?.grandTotal,
                  reservationTotal: payment?.reservationTotal || 0,
                  securityDepositTotal: payment?.securityDepositTotal || 0,
                  currency: payment?.currency,
                }}
                reservationInvoiceDescription={
                  payment?.reservationInvoiceDescription ?? ""
                }
                startDate={reservation?.checkIn}
                endDate={reservation?.checkOut}
                guests={reservation?.numberOfGuests}
                securityDepositDueDate={
                  reservation?.reservationInvoiceDescription
                }
                confirmationCode={reservation?.confirmationCode}
                guestName={reservation?.firstName + " " + reservation?.lastName}
                channel={reservation?.channel}
                isCurrentStepPayment={isPaymentStep}
              />
            </StickyBox>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
}
