/**
 * Polls an API at regular intervals until a specified condition is met or a timeout occurs.
 *
 * @param {Function} requestFn - An asynchronous function to perform the API request.
 * @param {Function} checkCondition - A function that takes the result of `requestFn` and returns a boolean, indicating whether the condition to stop polling is met.
 * @param {number} [interval=2000] - The time in milliseconds between each polling call.
 * @param {number} [timeout=30000] - The maximum time in milliseconds to continue polling before timing out.
 * @returns {Promise<*>} - A promise that resolves with the result of `requestFn` if `checkCondition` is met before the timeout. Rejects with an error if the timeout is reached or `requestFn` throws an error.
 *
 * @example
 * async function requestFn() {
 *   // API request logic here
 *   return fetchDataFromApi();
 * }
 *
 * function checkCondition(result) {
 *   // Check if the desired condition is met
 *   return result.status === 'complete';
 * }
 *
 * pollApi(requestFn, checkCondition, 1000, 20000)
 *   .then((result) => {
 *     console.log('Condition met:', result);
 *   })
 *   .catch((error) => {
 *     console.log('Error:', error.message);
 *   });
 */
export async function pollApi(
  requestFn,
  checkCondition,
  interval = 2000,
  timeout = 30000
) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    const timerId = setInterval(async () => {
      try {
        const result = await requestFn();
        // Check if the condition to stop polling is met
        // If the condition is met, stop polling and resolve with the result
        // If not, continue polling until the timeout is reached
        if (checkCondition(result)) {
          clearInterval(timerId);
          resolve(result);
        } else if (Date.now() - startTime >= timeout) {
          clearInterval(timerId);
          reject(new Error("polling_timed_out"));
        }
      } catch (error) {
        clearInterval(timerId);
        reject(error);
      }
    }, interval);
  });
}
