import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";

import ListingCard from "../../Listings/ListingCard";
import { DEFAULT_MARKER_STATES } from "../map_constants";
import useClickOutside from "./useClickOutside";
import useWindowSize from "../../../../Utils/hooks/useWindowSize";
import useCardPosition from "./useCardPosition";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

const ClusterMarker = (props) => {
  const {
    cluster,
    onClick,
    active,
    intactListingData,
    clusterStates,
    setClusterStates,
    map,
    hovered,
  } = props;
  // Translation hook
  const { t } = useTranslation();

  const points = cluster.properties.point_count_abbreviated;
  const allowNodeToSpawn = useWindowSize().width >= 768;

  const activeClass = active ? "active" : "";
  const hoveredClass = hovered ? "hovered" : "";

  const showMultipleListings = active && clusterStates.isPassedThreshold;
  const leaves = active && clusterStates.leaves;

  return (
    <>
      <div
        onClick={onClick}
        className={`search-map-markers__cluster-marker ${activeClass} ${hoveredClass}`}
      >
        <span className="item-count">{points}</span>
        <span className="cluster-label">{t("units")}</span>
      </div>
      {allowNodeToSpawn && showMultipleListings && (
        <FloatingClusterCard
          leaves={leaves}
          intactListingData={intactListingData}
          cluster={cluster}
          setClusterStates={setClusterStates}
          map={map}
        />
      )}
    </>
  );
};

const FloatingClusterSliderNextArrow = (props) => {
  return (
    <button
      className="floating-cluster-slider__button button-next"
      onClick={props.onClick}
    >
      <BsChevronRight className="icon" />
    </button>
  );
};

const FloatingClusterSliderPrevArrow = (props) => {
  return (
    <button
      className="floating-cluster-slider__button button-prev"
      onClick={props.onClick}
    >
      <BsChevronLeft className="icon" />
    </button>
  );
};

const FloatingClusterCard = (props) => {
  // Translation hook
  const { i18n } = useTranslation();

  const SLIDER_PROPS = {
    dots: false,
    infinite: false,
    speed: 500,
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 150,
    swipeToSlide: true,
    waitForAnimate: false,
    draggable: false,
    className: "floating-cluster-slider",
    nextArrow: <FloatingClusterSliderNextArrow />,
    prevArrow: <FloatingClusterSliderPrevArrow />,
    rtl: i18n.dir() === "rtl",
    currentSlide: 0,
  };

  const { leaves, setClusterStates, map, cluster, intactListingData } = props;
  const cardRef = useRef();

  useClickOutside(cardRef, () =>
    setClusterStates(DEFAULT_MARKER_STATES.CLUSTER)
  );

  const coordinates = {
    lat: cluster.geometry.coordinates[1],
    lng: cluster.geometry.coordinates[0],
  };

  const cardPosition = useCardPosition(map, coordinates);

  const [shownCarousels, setShownCarousels] = useState([0]);
  const beforeChange = (current, next) => {
    setShownCarousels((c) => {
      const newArray = [...c, next];
      return [...new Set(newArray)];
    });
  };
  useEffect(() => {
    setShownCarousels([0]);
  }, [cluster]);

  return (
    <div
      className={`search-map-markers__floating-cluster ${cardPosition}`}
      ref={cardRef}
    >
      <div className="cluster-title">
        <Trans
          i18nKey={"listing_results_found"}
          values={{ listingCount: leaves.length }}
        />
      </div>

      <Slider {...SLIDER_PROPS} beforeChange={beforeChange}>
        {leaves.map((leave, index) => {
          return (
            <div
              className="search-map-markers__floating-item"
              key={leave.marker.data.id}
            >
              <ListingCard
                data={leave.marker.data}
                index={index}
                markerStates={null}
                setMarkerStates={() => {}}
                mapCard={true}
                fullList={intactListingData}
                clickOrigin="mapPin"
                forceShowCarousel={shownCarousels.includes(index)}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ClusterMarker;
