import React, { useState } from "react";
import * as ROUTES from "./../../../Constants/Routes";
// Translation Package
import { useTranslation } from "react-i18next";

import QUERIES from "../../../Constants/Queries";
import { EMAIL_PATTERN } from "../constants";
import redirectAfterLoginSuccess from "Components/Auth/functions/redirectAfterLoginSuccess.js";

// Packages
import { useForm } from "react-hook-form";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

// Components
import FormInput from "./../../Form/FormInput";
import PrimaryButton from "../../Buttons/Primary";
import SocialMethods from "../Helpers/SocialMethods";
import { DEFAULT_ERROR, handleLoginError } from "./errorHandler";
import Loading from "../../Utils/Loading";

// Providers
import {
  useModal,
  useUpdateModal,
  MODALS,
} from "../../../Providers/FloatingModalProvider";
import logGaEvent from "../../../lib/ga/logGaEvent";

// Functions
import trimWhiteSpace from "../../Utils/validate";

const LoginForm = (formProps) => {
  const { redirectTo, urlParams, isRoute, routeStateEmail } = formProps;

  //Translation hook
  const { t } = useTranslation();

  const history = useHistory();

  const [email, setEmail] = useState(routeStateEmail);
  const updateEmailValue = (e) => setEmail(e.target.value);

  const { props } = useModal();
  const updateModal = useUpdateModal();

  // Form handling
  const [error, setError] = useState(DEFAULT_ERROR);
  const [disabled, setDisabled] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: isRoute ? routeStateEmail : props?.email,
    },
  });

  // Submit form
  const [login] = useMutation(QUERIES.USER.SET_LOGIN);
  const tryLogin = async (data) => {
    setError(DEFAULT_ERROR);
    setDisabled(true);

    await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async (res) => await res.user.getIdToken())
      .then(async (token) => {
        return await login({ variables: { firebaseToken: token } });
      })
      .then((res) => localStorage.setItem("token", res.data.result.jwtToken))
      .then(() => {
        logGaEvent("login", "email");

        // Wait for GA event
        setTimeout(() => {
          if (redirectTo) {
            redirectAfterLoginSuccess(redirectTo, urlParams);
          } else {
            history.go(0);
          }
        }, 300);
      })
      .catch((error) => handleLoginError(error, setError));

    setDisabled(false);
  };

  // Modal / page handler
  const onSignupClick = () => {
    if (isRoute) {
      history.push({
        pathname: ROUTES.SIGN_UP,
        search: urlParams,
        state: { email: email },
      });
      return;
    }

    updateModal({
      modal: MODALS.SIGN_UP,
      props: { email: email || null },
    });
    return;
  };

  const onForgotPasswordClick = () => {
    if (isRoute) {
      history.push({
        pathname: ROUTES.FORGOT_PASSWORD,
        search: urlParams,
        state: { email: email },
      });
      return;
    }

    updateModal({
      modal: MODALS.FORGOT_PASSWORD,
      props: { email: email || null },
    });
    return;
  };

  return (
    <div className="auth-form">
      <form onSubmit={handleSubmit(tryLogin)} className="auth-form__login">
        <div className="row">
          <div className="col-12 ">
            <h6 className="auth-form__form-title">{t("login")}</h6>
          </div>
        </div>

        {/* EMAIL AND PASSWORD */}
        <div className="row">
          {/* Email address */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
                pattern: {
                  value: EMAIL_PATTERN,
                },
                validate: trimWhiteSpace(),
              })}
              type="email"
              name="email"
              label={t("email")}
              onChange={updateEmailValue}
              onFocus={() => setDisabled(false)}
              autoFocus={true}
            />
          </div>

          {/* Password */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
              })}
              type="password"
              name="password"
              label={t("password")}
              onFocus={() => setDisabled(false)}
            />
          </div>

          {error.error && (
            <div className="col-12 auth-form__error">
              {error.error && t(error.message)}
            </div>
          )}

          {/* Forgotten password */}
          <div className="col-12 forgot-password">
            <button
              type="button"
              className="auth-form__link"
              onClick={onForgotPasswordClick}
            >
              {t("forgot_password")}
            </button>
          </div>

          <div className="col-12">
            <div className="login-button mt-1">
              <PrimaryButton
                label={disabled ? <Loading light /> : t("login")}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        <SocialMethods
          type="login"
          redirectTo={redirectTo}
          urlParams={urlParams}
        />

        {/* OR SIGN UP */}
        <div className="row sign-up">
          <div className="col-12 mt-3">
            {t("sign_up_question")}
            <button
              className="auth-form__link"
              onClick={onSignupClick}
              data-test-id="sign-up-btn"
              type="button"
            >
              {t("sign_up")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
