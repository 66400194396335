import React, { useState, useEffect } from "react";
import isTouchDevice from "is-touch-device";
import { isPointInPolygon } from "geolib";
import Cookies from "universal-cookie";

// Map
import { Map, Overlay } from "rgm";
import { useGoogleApiLoader } from "./hooks";
import mapStyles from "./mapStyles";

// Misc
import { AiOutlineLoading } from "react-icons/ai";
import { AiOutlineMeh } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { useCookieConsent } from "../../../Providers/CookieConsentProvider";

const DUBAI_LOCATION = {
  lat: 25.204849,
  lng: 55.270782,
};
const options = {
  styles: mapStyles,
  disableDefaultUI: isTouchDevice(),
  gestureHandling: isTouchDevice() ? "greedy" : "",
  zoom: 13,
  center: DUBAI_LOCATION,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  fullscreenControl: false,
  clickableIcons: false,
};

const cookies = new Cookies();

const GoogleMap = (props) => {
  const {
    error,
    loading,
    markers,
    defaultZoom,
    location,
    mobileMap,
    autoPanControl,
    marker,
  } = props;

  const api = useGoogleApiLoader();
  const [map, setMap] = useState(null);

  const cookieConsent = useCookieConsent();

  // Custom zoom
  const zoomMap = (type) => {
    if (!map) return;
    const currentZoom = map.zoom || defaultZoom || options.zoom;
    const newZoom = type === "in" ? currentZoom + 1 : currentZoom - 1;
    map.setZoom(newZoom);
  };

  // Change location
  useEffect(() => {
    if (!map) return;
    map.setCenter(location);
  }, [map, location]);

  // Autopan
  const autoPanCookie = cookies.get("autoPanGoogleMaps");
  const [autoPan, setAutoPan] = useState(
    autoPanCookie ? (autoPanCookie === `true` ? true : false) : true
  );

  const toggleAutoPan = () => {
    setAutoPan(!autoPan);

    if (!cookieConsent) return;

    let d = new Date();
    d.setTime(d.getTime() + 3600 * 1000 * 87660); // Ten years
    cookies.set("autoPanGoogleMaps", !autoPan, { path: "/search", expires: d });
  };

  // Autopan if hovered marker not in scene
  useEffect(() => {
    if (!autoPan) return;
    if (!map) return;
    if (!markers) return;

    // Do not recenter if there is a selected item
    const selected = markers.find((marker) => marker.props.selected);
    if (selected !== undefined) return;

    // Get currently hovered marker
    const hovered = markers.find((marker) => marker.props.hovered);
    if (hovered === undefined) return;

    const point = { latitude: hovered.props.lat, longitude: hovered.props.lng };

    // Force move on every hover
    if (mobileMap) {
      map.panTo({ lat: point.latitude, lng: point.longitude });
      return;
    }

    const mapBounds = map.getBounds();
    if (!mapBounds) return;
    const areaBounds = {
      north: mapBounds.getNorthEast().lat(),
      south: mapBounds.getSouthWest().lat(),
      east: mapBounds.getNorthEast().lng(),
      west: mapBounds.getSouthWest().lng(),
    };

    const bounds = [
      // North west - top left
      { latitude: areaBounds.north, longitude: areaBounds.west },
      // North east - top right
      { latitude: areaBounds.north, longitude: areaBounds.east },
      // South east - bottom right
      { latitude: areaBounds.south, longitude: areaBounds.east },
      // South west - bottom left
      { latitude: areaBounds.south, longitude: areaBounds.west },
    ];

    const isInScene = isPointInPolygon(point, bounds);
    if (isInScene) return;

    map.panTo({ lat: point.latitude, lng: point.longitude });
    //
  }, [markers, map, api, mobileMap, autoPan]);

  // Disable double click zoom if a marker selected
  useEffect(() => {
    if (!markers || !api || !map) return;

    // Return if nothing selected
    const selected = markers.find((marker) => marker.props.selected);
    if (selected !== undefined) {
      map.setOptions({ disableDoubleClickZoom: true });
    } else {
      map.setOptions({ disableDoubleClickZoom: false });
    }
  }, [markers, api, map]);

  // Render
  if (error) return <ErrorMaps />;
  if (!api || loading) return <LoadingMaps />;

  return (
    <div className="google_maps__container">
      <Map api={api} ref={setMap} options={options}>
        <Overlay>{marker || markers}</Overlay>
      </Map>

      {autoPanControl && (
        <AutoPan toggleAutoPan={toggleAutoPan} autoPan={autoPan} />
      )}

      <ZoomControl zoom={zoomMap} show={isTouchDevice()} />
    </div>
  );
};

const AutoPan = (props) => {
  const { toggleAutoPan, autoPan } = props;
  return (
    <div className="google_maps__auto-pan">
      <button className="auto-pan-button" onClick={toggleAutoPan}>
        <span className={`icon_holder ${autoPan ? "active" : ""}`}>
          {autoPan && <BsCheck className="icon" />}
        </span>
        Auto move map
      </button>
    </div>
  );
};

const ZoomControl = (props) => {
  const { zoom, show } = props;
  const showClass = { display: show ? "none" : "" };
  return (
    <div className="google_maps__map-zoom" style={showClass}>
      <div className="zoom-in">
        <button onClick={() => zoom("in")}>+</button>
      </div>
      <div className="divider"></div>
      <div className="zoom-out">
        <button onClick={() => zoom("out")}>-</button>
      </div>
    </div>
  );
};

const ErrorMaps = () => {
  return (
    <div className="google_maps__placeholder error ">
      <div className="content">
        <AiOutlineMeh className="icon" />
        <p>
          Something happened wrong while we are loading the map.
          <br />
          You may need to reload the page.
        </p>
      </div>
    </div>
  );
};

const LoadingMaps = () => {
  return (
    <div className="google_maps__placeholder loading">
      <div className="content">
        <AiOutlineLoading className="icon" />
      </div>
    </div>
  );
};

export default GoogleMap;
