import BankTransfer from "./Bank";
import CryptoPaymentContainer from "./Crypto/Container";
import StripePaymentCard from "./Stripe";
import PaymentInquiry from "./Inquiry";
import ChangePaymentMethod from "../Components/ChangePaymentMethod";

import { PAYMENT_METHOD, PAYMENT_STEP } from "Constants/globals";
import { usePaymentContext } from "../index";

// Translation Package
import { useTranslation } from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {paymentMethodVar} from "../../../Auth/SignUp/trigger";

const PaymentCard = () => {
  // Translation hook
  const { t } = useTranslation();
  const { quotationData, setPaymentStep } = usePaymentContext();
  const paymentMethod = useReactiveVar(paymentMethodVar);

  const showInquiryForm =
    !quotationData?.isCreditCardEligible &&
    !quotationData?.isCryptoEligible &&
    !quotationData?.isBankEligible;

  return (
    <>
      <div className="confirm-and-pay__form mt-4">
        {/* Show payment form if there is a payment method available */}
        {!showInquiryForm && (
          <>
            <div className="form-title">
              <h5 className="form-title__title">{t("payment_form")}</h5>
            </div>
            <div className="row">
              {/* Change payment method button */}
              <div className="col-12 d-flex flex-row justify-content-start">
                <ChangePaymentMethod
                  onConfirm={() => setPaymentStep(PAYMENT_STEP.SELECT_METHOD)}
                />
              </div>
              <div className="col-12">
                <div className="tab-content" id="myTabContent">
                  {/* Credit Card */}
                  {paymentMethod === PAYMENT_METHOD.CREDIT_CARD && (
                    <div
                      className={`tab-pane fade show active`}
                      id="creditCard"
                      role="tabpanel"
                      aria-labelledby="creditCard-tab"
                    >
                      <StripePaymentCard />
                    </div>
                  )}

                  {/* Bank Info */}
                  {quotationData?.isBankEligible &&
                    paymentMethod === PAYMENT_METHOD.BANK && (
                      <div
                        className={`tab-pane fade show active`}
                        id="bank"
                        role="tabpanel"
                        aria-labelledby="bank-tab"
                      >
                        <BankTransfer />
                      </div>
                    )}

                  {/* Crypto */}
                  {quotationData?.isCryptoEligible &&
                    paymentMethod === PAYMENT_METHOD.CRYPTO && (
                      <div
                        className={`tab-pane fade show active`}
                        id="crypto"
                        role="tabpanel"
                        aria-labelledby="crypto-tab"
                      >
                        <CryptoPaymentContainer />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Show inquiry form if there is no payment method available */}
        {showInquiryForm && (
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                {/* Inquiry */}
                <div
                  className={`tab-pane fade show active`}
                  id="inquiry"
                  role="tabpanel"
                  aria-labelledby="inquiry-tab"
                >
                  <PaymentInquiry />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCard;
