import { useEffect } from "react";
const useEscapeButton = (callback = () => {}) => {
  useEffect(() => {
    if (typeof callback !== "function") return;

    const onEscPressed = (e) => e.keyCode === 27 && callback();

    document.addEventListener("keydown", onEscPressed);
    return () => document.removeEventListener("keydown", onEscPressed);
  }, [callback]);
};

export default useEscapeButton;
