import React, { useState } from "react";
import Slider from "react-slick";
import isTouchDevice from "is-touch-device";
import useWindowSize from "../hooks/useWindowSize";

import Dots from "./Dots";
import Dot from "./Dot";
import { NextArrow, PrevArrow } from "./Arrows";

// Translation Package
import { useTranslation } from "react-i18next";

const SlickWrapper = (props) => {
  // Translation hook
  const { i18n } = useTranslation();

  const { items, forceShowNav, dots } = props;

  const windowWidth = useWindowSize().width;

  const [indexes, setIndexes] = useState({ old: 0, new: 0 });
  const isTouch = isTouchDevice();

  const settings = {
    dots: dots || true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 150,
    rtl: i18n.dir() === "rtl",
    currentSlide: 0,
    /** swipeToSlide: isTouchDevice(), 
        noticed whacky behaviour on chrome mobile browser
        it was sliding multiple slides at once on RTL direction
    **/
    swipeToSlide: false,
    waitForAnimate: false,
    className: "slick__slider",
    appendDots: (dots) => (
      <Dots dots={dots} indexes={indexes} total={items.length - 1} />
    ),
    customPaging: (dot) => (
      <Dot dot={dot} index={indexes.new} total={items.length - 1} />
    ),
    nextArrow: <NextArrow forceShowNav={forceShowNav} />,
    prevArrow: <PrevArrow forceShowNav={forceShowNav} />,
    arrows: !isTouch || windowWidth > 768,
    beforeChange: (oldIndex, newIndex) =>
      setIndexes({ old: oldIndex, new: newIndex }),
  };

  if (!items || items.length === 0) return <></>;

  return (
    <Slider {...settings} {...props}>
      {items.map((item, index) => (
        <div className="slick__image" key={index}>
          {item}
        </div>
      ))}
    </Slider>
  );
};

export default SlickWrapper;
