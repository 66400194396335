import React, { useCallback, useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ShimmerLoading from "Components/Utils/Shimmer";
import { paymentErrorCodes } from "Components/Booking/Payment/Forms/errorCodes";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PriceNoticeBox from "Components/Booking/Payment/Components/PriceNoticeBox";
import toMoney from "Components/Utils/functions/toMoney";
import BookingAgreementConfirmation from "./TermsAgreement";
import { pollApi } from "../helpers/pollingAPI";
import { useMutation } from "@apollo/client";
import QUERIES from "Constants/Queries";
import { useHistory, useParams } from "react-router-dom";
require("dayjs/locale/ar");
let isToday = require("dayjs/plugin/isToday");
dayjs.extend(isToday);

let stripeInstance = null;
let stripeElements = null;

const StripeNewCardSetup = ({
  intentReferenceKey,
  publishableKey,
  clientSecret,
  goToPreviousStep,
  total,
  currency,
  refetch,
  isLastStep,
}) => {
  // Browser history
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [paymentError, setPaymentError] = useState(null);
  const paymentIntentReferenceKey = useRef(null);
  const isMounted = useRef(true);
  const paymentElementRef = useRef(null);
  const { id } = useParams();

  const [checkPaymentStatus] = useMutation(
    QUERIES.ONLINE_CHECK_IN.CHECK_PAYMENT_STATUS
  );

  // submit online-check-in mutation if the current step is the final step.
  const [submitOnlineCheckIn, { error: onlineCheckInErrors }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SUBMIT_ONLINE_CHECK_IN
  );

  /**
   * Initialization
   */
  const initializeStripe = useCallback(async () => {
    try {
      paymentIntentReferenceKey.current = intentReferenceKey;
      stripeInstance = await loadStripe(publishableKey);
      const appearance = {
        theme: "stripe",
        variables: {
          fontFamily:
            i18n?.dir() === "rtl"
              ? "Almarai, Helvetica Neue, sans-serif"
              : "Gilroy, Almarai, sans-serif",
        },
        labels: "floating",
        rules: {
          ".Input": {
            borderRadius: "6px",
            boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.08)",
            marginBottom: "12px",
            color: "#828282",
          },
          ".Input:focus": {
            borderColor: "transparent",
            borderRadius: "6px",
            boxShadow: "0 0 0 2px rgba(117, 150, 159, 0.9)",
          },
          ".Label": {
            color: "#828282",
            fontWeight: "400",
            fontSize: "12px",
          },
          ".TermsText": {
            fontFamily: "sans-serif",
            fontWeight: "400",
            color: "#4B5154",
            fontSize: "14px",
          },
        },
      };
      stripeElements = stripeInstance.elements({
        locale: i18n?.languages?.[0] ?? "en",
        fonts: [
          {
            family: "Almarai",
            src: "url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-agtnqWo4z1oXk.woff2)",
          },
        ],
        loader: "always",
        appearance,
        clientSecret: clientSecret,
      });
      paymentElementRef.current = stripeElements.create("payment");
      paymentElementRef.current.mount("#payment-element");
    } catch (err) {
      if (isMounted.current) {
        setPaymentError(
          paymentErrorCodes[err.message]
            ? t(paymentErrorCodes[err.message])
            : t(paymentErrorCodes.DEFAULT)
        );
      }
    } finally {
      if (isMounted.current) {
        setIsStripeLoading(false);
      }
    }
  }, [clientSecret, i18n, intentReferenceKey, publishableKey, t]);

  function getTitle() {
    if (intentReferenceKey?.startsWith("pi_")) return t("payment_details");
    else return t("card_authorization_for_damages");
  }
  function getSubmitButtonName() {
    if (intentReferenceKey?.startsWith("pi_")) return "pay_now";
    return "save";
  }

  function getSetupType() {
    if (intentReferenceKey?.startsWith("pi_")) return "confirmPayment";
    else return "confirmSetup";
  }
  const paymentMethodSetup = {
    SET_UP_TYPE: getSetupType(),
    SUBMIT_BUTTON_NAME: getSubmitButtonName(),
    title: getTitle(),
  };
  function checkCondition(res) {
    // if isSuccessful doesn't equal null, then we got a response from the backend with either true or false in case of failure.
    // the default value for isSuccessful is null.
    return res?.data?.result?.isSuccessful !== null;
  }

  const onFormSubmission = async (e) => {
    e?.preventDefault();
    if (stripeElements === null || stripeInstance === null) {
      return;
    }
    if (!isMounted.current) {
      return;
    }
    setPaymentError(null);
    setIsPaymentInProgress(true);
    try {
      const { error } = await stripeInstance[paymentMethodSetup.SET_UP_TYPE]({
        elements: stripeElements,
        redirect: "if_required",
      });

      if (error) {
        if (isMounted.current) {
          setPaymentError(`Error: ${error.message}`);
        }
      } else {
        // if stripe did not throw any error
        const result = await pollApi(checkStatus, checkCondition);

        if (result?.data?.result?.isSuccessful) {
          if (isLastStep) {
            if (!(await submitFinalStep())) return;
            history.replace(`/online-check-in/${id}/details`);
          } else {
            await refetch();
          }
        } else {
          setPaymentError(result?.data?.result?.failureMessage);
          console.log(result?.data?.result?.failureMessage);
        }
      }
    } catch (error) {
      console.log({ error });

      if (isMounted.current) {
        if (error?.message === "polling_timed_out") {
          setPaymentError(t("polling_timed_out"));
        } else setPaymentError(t("error_unknown"));
      }
    } finally {
      if (isMounted.current) {
        setIsPaymentInProgress(false);
      }
    }
  };

  const checkStatus = () => checkPaymentStatus({ variables: { code: id } });

  const submitFinalStep = async () => {
    const { data: submitOnlineCheckInResponse, error: onlineCheckInError } =
      await submitOnlineCheckIn({
        variables: {
          code: id,
        },
      });

    if (onlineCheckInError) return false;

    return submitOnlineCheckInResponse?.result || false;
  };

  /**
   * Initialize payment element
   */
  useEffect(() => {
    initializeStripe();

    return () => {
      isMounted.current = false;
      if (paymentElementRef.current) {
        paymentElementRef.current.unmount();
        paymentElementRef.current = null;
      }
    };
  }, [initializeStripe]);

  return (
    <>
      <h2 className="online-check-in__subtitle">{paymentMethodSetup?.title}</h2>

      <form
        id="payment-form"
        style={{ padding: "10px" }}
        onSubmit={onFormSubmission}
      >
        <div
          className="container booking-confirmation p-0"
          id="confirmation-page"
        >
          <div className="row confirmation-content">
            <div className="col-12">
              <div className="shadow-card journey-area border">
                {paymentError && (
                  <div className="col-12 d-flex flex-column gap-1 mb-4">
                    <div className="alert alert-warning" role="alert">
                      {t(paymentError)}
                    </div>
                  </div>
                )}

                {/* show grandTotal only if more it's not null and more than zero */}
                {total && total > 0 ? (
                  <div className="col-12 d-flex flex-column gap-1 mb-4">
                    <PriceNoticeBox
                      title={t("total")}
                      price={toMoney(total, currency)}
                    />
                  </div>
                ) : null}

                {isStripeLoading && <ShimmerLoading />}

                <h6 className="step__title pt-2">
                  {t("enter_credit_card_details")}
                </h6>

                <div id="payment-element">
                  {/* Stripe.js injects the Payment Element here */}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="complete-booking-button mt-3 mb-4">
          <BookingAgreementConfirmation
            isPaymentInProgress={isPaymentInProgress}
            onClick={onFormSubmission}
            goToPreviousStep={goToPreviousStep}
            btnLabel={paymentMethodSetup?.SUBMIT_BUTTON_NAME}
            error={onlineCheckInErrors}
          />
        </div>
        <div id="payment-message" className="hidden" />
      </form>
    </>
  );
};

export default React.memo(StripeNewCardSetup);
