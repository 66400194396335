import React, { useEffect, useState } from "react";

const Context = React.createContext({ data: null });

const Provider = ({ children }) => {
  const [state, setState] = useState(null);
  const [initMode, setInitMode] = useState(true);

  const setDetailData = (data) => {
    setState(data);
  };

  const setMode = (value) => {
    setInitMode(value);
  };

  useEffect(() => {}, [state, initMode]);

  return (
    <Context.Provider value={{ data: state, initMode, setDetailData, setMode }}>
      {children}
    </Context.Provider>
  );
};

export { Provider as DetailProvider };

export { Context as DetailContext };
