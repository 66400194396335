import React from "react";
import styles from "./index.module.scss";
import { BsArrowRight, BsCheckCircle } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";

// Translation Package
import { useTranslation } from "react-i18next";

// Packages
import dayjs from "dayjs";
require("dayjs/locale/ar");

const ReservationSuccess = (props) => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const {
    title,
    codeTitle = "reservation_code",
    code,
    message,
    propertyTitle,
    propertyImg,
    propertyLocation,
    startDate,
    endDate,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.codeContainer}>
        <BsCheckCircle size={36} color="#779D81" />
        {title && <p className={styles.title}>{title}</p>}

        <div className={styles.code}>
          <div className="d-flex flex-column align-items-center justify-content-start">
            <p className={styles.label}>{t(codeTitle)}</p>
            {code && <p className={styles.codeNumber}>{code}</p>}
          </div>
        </div>

        {message && <p className={styles.message}>{message}</p>}
      </div>

      <div className={styles.listingContainer}>
        <div className="d-flex gap-2 align-items-center justify-content-center w-100">
          <img className="w-50 h-full rounded" src={propertyImg} alt="" />
          <div className="d-flex flex-column align-items-start justify-content-center w-100 gap-2">
            <p className={styles.listingTitle}>{propertyTitle}</p>
            <p className={styles.listingLocation}>{propertyLocation}</p>
          </div>
        </div>
        <div className="d-flex my-2 w-100 border-bottom"></div>

        <div
          className={`d-flex flex-row align-items-center justify-content-between w-100 ${styles.datesContainer}`}
        >
          <div className={`d-flex flex-column w-auto ${styles.date}`}>
            <p>{t("check_in")}</p>
            {startDate && (
              <h5 className="d-inline-flex flex-column">
                <span>
                  {dayjs(startDate)
                    .locale(i18n.languages[0])
                    .format("DD MMM YYYY")}
                </span>{" "}
                <br />{" "}
                {dayjs(startDate).locale(i18n.languages[0]).format("dddd")}
              </h5>
            )}
          </div>

          <div className="d-flex flex-row justify-content-center">
            {i18n.dir() === "rtl" ? (
              <BiArrowBack size={24} opacity={"70%"} color={"#4b5154"} />
            ) : (
              <BsArrowRight size={24} opacity={"70%"} color={"#4b5154"} />
            )}
          </div>

          <div className={`d-flex flex-column w-auto ${styles.date}`}>
            <p>{t("check_out")}</p>
            {endDate && (
              <h5 className="d-inline-flex flex-column">
                <span>
                  {dayjs(endDate)
                    .locale(i18n.languages[0])
                    .format("DD MMM YYYY")}
                </span>{" "}
                <br /> {dayjs(endDate).locale(i18n.languages[0]).format("dddd")}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationSuccess;
