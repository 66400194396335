import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Portal } from "react-portal";

import CloseButton from "../../../Buttons/Close";
import composeJsxClass from "../../../Utils/functions/composeJsxClass";
import { RemoveScroll } from "react-remove-scroll";
import useEscapeButton from "../../../Utils/hooks/useEscapeButton";
import useKeyboardArrows from "../../../Utils/hooks/useKeyboardArrows";
import { CiChevronLeft, CiChevronRight } from "../../../../CustomIcons";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";

const debounceAmount = 10;
const debouncedUpdate = _.debounce((callback) => callback(), debounceAmount);

const FullScreenGallery = ({ carousel, setCarousel, images }) => {
  const isMobile = useMobileDetect();
  const galleryRef = useRef();
  const indexDetectorRef = useRef();

  const handleOnCloseClick = () => {
    if (carousel.fromDoubleTap) {
      setCarousel((carousel) => ({
        ...carousel,
        fullScreen: false,
        scrollGallery: false,
        fromDoubleTap: false,
      }));
    } else {
      setCarousel((carousel) => ({ ...carousel, fullScreen: false }));
    }
  };

  useEscapeButton(() => handleOnCloseClick());

  const galleryClass = composeJsxClass("suite__full-screen-gallery", {
    active: carousel.fullScreen,
  });

  const [touched, setTouched] = useState(false);
  useEffect(() => {
    if (carousel.fullScreen) {
      setTouched(true);
    } else {
      setTouched(false);
    }
  }, [carousel.fullScreen]);

  useEffect(() => {
    if (touched) return;
    if (!isMobile) return;

    const node = galleryRef.current;
    const scrollWidth = node.scrollWidth;
    const imageWidth = scrollWidth / images.length;
    const currentImageLeft = imageWidth * carousel.fullScreenIndex;

    node.scrollTo({
      left: currentImageLeft,
      top: 0,
      behavior: "auto",
    });
  }, [touched, images, carousel.fullScreenIndex, isMobile]);

  const handleOnScroll = () => {
    debouncedUpdate(() => {
      const indexDetectorBounding =
        indexDetectorRef.current.getBoundingClientRect();

      images.forEach((image, index) => {
        let imageNode = document.getElementById(
          `full-screen-gallery-image--${image.id}`
        );
        if (imageNode === null) return;

        let imageBounding = imageNode.getBoundingClientRect();
        if (
          imageBounding.left < indexDetectorBounding.left &&
          imageBounding.left + imageBounding.width > indexDetectorBounding.left
        ) {
          setCarousel((carousel) => ({
            ...carousel,
            fullScreenIndex: index,
          }));
        }
      });
    });
  };

  const handleNav = (direction) => {
    if (direction === "left") {
      if (carousel.fullScreenIndex === 0) return;
      setCarousel((carousel) => ({
        ...carousel,
        fullScreenIndex: carousel.fullScreenIndex - 1,
      }));
    }
    if (direction === "right") {
      if (carousel.fullScreenIndex === carousel.intact.length - 1) return;
      setCarousel((carousel) => ({
        ...carousel,
        fullScreenIndex: carousel.fullScreenIndex + 1,
      }));
    }
  };

  useKeyboardArrows({
    leftArrow: () => handleNav("left"),
    rightArrow: () => handleNav("right"),
  });

  return (
    <RemoveScroll allowPinchZoom={true} enabled={carousel.fullScreen}>
      <Portal>
        <div className={galleryClass}>
          <div className="full-screen-gallery__top">
            <CloseButton
              className="close-button"
              color={"#ffffff"}
              onClick={handleOnCloseClick}
            />
          </div>
          {/* Index detector */}
          <div
            className="full-screen-gallery__index-detector"
            id="full-screen-gallery--index-detector"
            ref={indexDetectorRef}
          />

          {/* Counter */}
          <div className="full-screen-gallery__counter">
            {carousel.fullScreenIndex + 1} / {carousel.total}
          </div>

          {/* Navigation */}
          {isMobile === false && (
            <div className="full-screen-gallery__nav-buttons hide-mobile">
              <div className="nav-buttons-holder">
                <button
                  className="nav-button nav-button__left"
                  onClick={() => handleNav("left")}
                  hidden={carousel.fullScreenIndex === 0}
                >
                  <CiChevronLeft size={18} color="#ffffff" />
                </button>
                <button
                  className="nav-button nav-button__right"
                  onClick={() => handleNav("right")}
                  hidden={
                    carousel.fullScreenIndex === carousel.intact.length - 1
                  }
                >
                  <CiChevronRight size={18} color="#ffffff" />
                </button>
              </div>
            </div>
          )}

          <div
            className="full-screen-gallery__gallery no-scrollbar"
            id="full-screen-gallery--gallery"
            ref={galleryRef}
            onScroll={handleOnScroll}
          >
            {images.map((image, index) => {
              return (
                <div
                  className="gallery-item"
                  key={image.id}
                  data-active={index === carousel.fullScreenIndex}
                >
                  <div className="gallery-image-holder">
                    <img
                      className="gallery-image"
                      id={`full-screen-gallery-image--${image.id}`}
                      src={image.url}
                      alt={image.id}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Portal>
    </RemoveScroll>
  );
};
export default FullScreenGallery;
