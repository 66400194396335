import TetherIcon from "./icons/tether.svg";


export const CRYPTO_COINS = [
  {
    key: "Tether",
    text: "Tether (USDT)",
    value: "USDT",
    image: { avatar: true, src: TetherIcon },
  },
];
