import { PAYMENT_METHOD } from "Constants/globals";

/** @function calcSetShowSDSeparate
 * Returns if SD should be shown separate
 *
 * @param quotation: quotation
 * @param paymentMethod: "creditCard" | "bankTransfer" | "crypto"
 *
 */

const calcSetShowSDSeparate = (quotation, paymentMethod) => {
  let checkForCCShortStay = false;
  if (paymentMethod === PAYMENT_METHOD.CREDIT_CARD) {
    checkForCCShortStay = quotation?.nights < 26;
  }

  const shouldShowSDSeparate = !!quotation?.isFutureSD || checkForCCShortStay;

  return shouldShowSDSeparate;
};

export default calcSetShowSDSeparate;
