import React from "react";

import { Paragraph, Title, Subtitle, Image, Anchor, Div } from "../Tags";

import PasticceriaAlatiCasetraImage from "./pasticerria-alati-casetra.jpg";
import CrewCollectiveImage from "./crew-collective.jpg";
import LePetitDepImage from "./le-petit-dep.jpg";
import AtwaterCocktailClubImage from "./atwater-cocktail-club.jpg";
import LAmoireAGlacesImage from "./l-amoire-a-glaces.jpg";

const FiveHiddenGems = () => {
  const imgAlt = "5 hidden gems in Montreal";
  const divStyle = { marginTop: "70px" };
  const imgStyle = {
    objectFit: "contain",
    width: "100%",
    margin: "20px 0",
    borderRadius: "4px",
  };

  const subtitleStyle = {
    fontWeight: "400",
    opacity: 0.7,
    color: "#222222",
  };
  const subtitleClass = "text-font";
  return (
    <Div>
      <Paragraph>
        Whether you are a local or just visiting Montreal, these hidden gems are
        a great way to experience the city. We have curated a list of some of
        our favourite spots that should definitely not be missed whenever you
        are in town.
      </Paragraph>

      <Div key="Pasticceria Alati-Casetra" style={divStyle}>
        <Title>Pasticceria Alati-Casetra</Title>
        <Subtitle
          className={subtitleClass}
          style={subtitleStyle}
          href="https://goo.gl/maps/fYNoRsnn4YfvuRjC7"
        >
          277 Dante, Little Italy
        </Subtitle>
        <Image
          src={PasticceriaAlatiCasetraImage}
          alt={imgAlt}
          style={imgStyle}
        />
        <Paragraph>
          Established in 1968, Alati-Caserta has become part of Montreal’s
          Italian community. This family-owned bakery ensures the best quality
          and the freshest ingredients when selling their large variety of
          pastries. From Italian cakes and cookies to holiday treats and
          pastries, Pasticceria Alati-Caserta will definitely convert you into a
          sweet-tooth. Whilst this pastry shop keeps growing and attracting more
          attention, the hidden gem behind this storefront is the colourful
          selection of the best cannolis in town. Passed from generation to
          generation, this recipe will have you craving cannolis every day of
          the week! Luckily it’s only a 10-minute drive from our{" "}
          <Anchor
            title="Saint-Jacques properties"
            href="https://stellastays.com/suite/59/stella-stays-stunning-modern-2-floor-penthouse-old-mtl?guests=1"
          />
          !
        </Paragraph>{" "}
      </Div>

      <Div key="Crew Collective" style={divStyle}>
        <Title>Crew Collective</Title>
        <Subtitle
          className={subtitleClass}
          style={subtitleStyle}
          href="https://goo.gl/maps/WANtZxPLQhBuDBUW6"
        >
          360 Rue Saint-Jacques
        </Subtitle>
        <Image src={CrewCollectiveImage} alt={imgAlt} style={imgStyle} />
        <Paragraph>
          Crew Collective is a cafe and co-working space located in the former
          building of the Royal Bank of Canada. Within this breathtaking
          architectural and historical setting, around the corner from our{" "}
          <Anchor
            title="Saint-Jacques properties"
            href="https://stellastays.com/suite/34/beautiful-trendy-2-floor-penthouse-in-old-mtl?guests=1"
          />
          , you can sip your signature morning coffee whilst redefining the
          future of work with your colleagues in a beautiful co-working
          environment. Originally built in the 1920s, the design boasts
          impressive marble mosaic floors, original glass, and sandstone walls
          topped with coffered ceilings. For this reason, Crew Collective now
          offers a private event space which would make your events
          unforgettable. Trust us, we’ve seen wedding pictures, it’s amazing!
        </Paragraph>
      </Div>

      <Div key="Le Petit Dep" style={divStyle}>
        <Title>Le Petit Dep</Title>
        <Subtitle
          className={subtitleClass}
          style={subtitleStyle}
          href="https://goo.gl/maps/7Kuu1hy52uQ5Z6C86"
        >
          179 Saint-Paul St W
        </Subtitle>
        <Image src={LePetitDepImage} alt={imgAlt} style={imgStyle} />
        <Paragraph>
          Imagine an instagrammable and gourmet dépanneur; that’s Le Petit Dep!
          The iconic aqua green facade opens its doors as a specialty grocery
          store that also serves some of the best third wave coffee supplied by
          local producers in Quebec. Similar to your regular corner store in
          Montreal, Le Petit Dep offers ready-to-eat meals, pastries and baked
          goods, beverages and ice cream. But - make it gourmet. In order to
          expand and diversify their menu, they also offer a wide range of 100%
          vegan dishes, from curry, chili, and poke bowls. Our mouths are
          already watering! What’s for dinner? Just take a 13-minute walk from
          our{" "}
          <Anchor
            title="Champ de Mars"
            href="https://stellastays.com/suite/96/stella-stays-lux-rustic-home-old-mtl?guests=1"
          />{" "}
          unit and find out. PS: you can also find souvenirs for the tourist in
          you.
        </Paragraph>
      </Div>

      <Div key="Atwater Cocktail Club" style={divStyle}>
        <Title>Atwater Cocktail Club</Title>
        <Subtitle
          className={subtitleClass}
          style={subtitleStyle}
          href="https://goo.gl/maps/JP9ssTxwTVwGmakn8"
        >
          512 Atwater Ave
        </Subtitle>
        <Image src={AtwaterCocktailClubImage} alt={imgAlt} style={imgStyle} />
        <Paragraph>
          The Atwater Cocktail Club, also known as ACC, is not your run-of-the
          mill neighbourhood bar. First of all, you might not even know that
          it’s in your neighbourhood! That’s right, this cocktail bar is a
          hidden speakeasy: find the door and find your paradise. As one of the
          best bars in all of Canada, the ACC guarantees to take you on a
          gastronomic experience through their well-thought out food and
          beverage menus. However, what makes the ACC the spot to be is the team
          that is always eager to share their knowledge and passion for
          cocktails in this inviting and unique setting. Cheers! We recommend
          you take a cab though, even just for the 7 minute ride from our{" "}
          <Anchor
            title="Docteur-Penfield properties"
            href="https://stellastays.com/suite/43/gorgeous-2-floor-home-in-downtown-mtl-parking?guests=1"
          />
          .
        </Paragraph>
      </Div>

      <Div key={`L'amoire a Glaces`} style={divStyle}>
        <Title>L'amoire a Glaces</Title>
        <Subtitle
          className={subtitleClass}
          style={subtitleStyle}
          href="https://goo.gl/maps/7gP5x8Tpy7J8uGZc7"
        >
          6220 Rue Saint-Hubert
        </Subtitle>
        <Image src={LAmoireAGlacesImage} alt={imgAlt} style={imgStyle} />
        <Paragraph>
          Is it really ever too cold for a good ice cream cone? We don’t think
          so and l’Armoire a Glaces agrees. From classic to bold flavours, this
          gorgeous shop is a Montreal must stop to indulge in delicious and
          creamy creations. Their magical recipes are all natural, made with
          local ingredients with half the menu being vegan friendly. That means
          you can bring all your friends and family with you, no one has to miss
          out on the fun. If good ice cream is not enough to persuade you, it is
          important to note that l’armoire a glaces has become a pillar in
          making the community better, collaborating with youth centers to offer
          happy memories. Delicious and ethical? Sign us up! And it’s just a
          10-minute drive from our{" "}
          <Anchor
            title="Champ de Mars property"
            href="https://stellastays.com/suite/96/stella-stays-lux-rustic-home-old-mtl?guests=1"
          />
          .
        </Paragraph>
      </Div>

      <Paragraph style={{ marginTop: "70px" }}>
        Whenever you visit Montreal next, don’t forget to stop by these amazing
        places to make your stay unforgettable. And of course, if you are
        staying with Stella Stays you can contact our Guest Experience team and
        they will be sure to plan the perfect itinerary for you!
      </Paragraph>
    </Div>
  );
};
export default FiveHiddenGems;
