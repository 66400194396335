import React from "react";
// Translation Package
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  CiBaggage,
  CiCalendarDate,
  CiCheckin,
  CiSupport,
} from "../../../CustomIcons";
import StoreBadges from "../../AppBadges";
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import AppShowcaseImageMobile from "./app-showcase-mobile.webp";
import ScreenshotHardware from "./screenshots/hardware.png";

import appVid from "./video/mobile_app.mp4";

const AppShowcase = () => {
  // Translation hook
  const { t } = useTranslation();

  const isMobile = useMobileDetect(768);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const amenityIconProps = {
    className: "icon",
    size: "24",
    viewBox: "0 0 28 28",
  };

  return (
    <div className="app-showcase py-5">
      <div className="container px-0 app-showcase__container">
        <div className="app-showcase__title">
          <h1 className="homepage-section-title">
            {t("title_digital_experience")}
          </h1>
          <p className="homepage-section-subtitle">
            {t("subtitile_digital_experience")}
          </p>
        </div>

        <div className="app-showcase__amenities">
          <div className="amenity">
            <div className="amenity__icon">
              <CiBaggage {...amenityIconProps} />
            </div>
            <div className="amenity__text">
              {t("digital_experience_feature_1")}
            </div>
          </div>
          <div className="amenity">
            <div className="amenity__icon">
              <CiCheckin {...amenityIconProps} />
            </div>
            <div className="amenity__text">
              {t("digital_experience_feature_2")}
            </div>
          </div>
          <div className="amenity">
            <div className="amenity__icon">
              <CiCalendarDate {...amenityIconProps} />
            </div>
            <div className="amenity__text">
              {t("digital_experience_feature_3")}
            </div>
          </div>
          <div className="amenity">
            <div className="amenity__icon">
              <CiSupport {...amenityIconProps} />
            </div>
            <div className="amenity__text">
              {t("digital_experience_feature_4")}
            </div>
          </div>
        </div>

        <div className="app-showcase__image-holder">
          <div
            className="showcase-image-wrapper"
            ref={ref}
            data-animate={inView}
          >
            {!isMobile ? (
              <>
                <LazyLoadImage
                  className="showcase-image showcase-image--desktop"
                  src={ScreenshotHardware}
                  alt="stella-stays-app-image-iphone"
                />
                <video
                  loop
                  autoPlay
                  muted
                  playsInline
                  style={{
                    position: "absolute",
                    zIndex: -1,
                    borderRadius: "42px",
                    width: "100%",
                    height: "100%",
                    padding: "8px",
                  }}
                >
                  <source src={appVid} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            ) : (
              <LazyLoadImage
                className="showcase-image showcase-image--mobile"
                src={AppShowcaseImageMobile}
                alt="stella-stays-app-image-iphone"
              />
            )}
          </div>
        </div>

        <div className="app-showcase__badges pb-4 gap-2 justify-content-center justify-content-md-start">
          <StoreBadges />
        </div>
      </div>
    </div>
  );
};
export default AppShowcase;
