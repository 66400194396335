import dayjs from "dayjs";
import { DATE_FORMAT } from "../../Booking/Search/search_constants";
const formatForParams = (key, date) => {
  const dateFormat = DATE_FORMAT.paramFormat;
  if (!date) {
    return `${key}=`;
  }
  const formattedDate = dayjs(date).format(dateFormat);
  return `${key}=${formattedDate}`;
};

export default formatForParams;
