import RadioBox from "Components/Form/RadioBox";
import { PAYMENT_METHOD, PAYMENT_STEP } from "Constants/globals";
import PrimaryButton from "Components/Buttons/Primary";
import { usePaymentContext } from "../index";
import { validateForm as validateContactForm } from "../Forms/ContactReview/validateForm";

import { ReactComponent as CCIcon } from "../assets/cc.svg";
import { ReactComponent as BankIcon } from "../assets/bank.svg";
import { ReactComponent as CryptoIcon } from "../assets/crypto.svg";

// Translation Package
import { useTranslation } from "react-i18next";
import {paymentMethodVar, triggerPaymentMethodSelectionVar} from "../../../Auth/SignUp/trigger";
import {useReactiveVar} from "@apollo/client";
import {useEffect} from "react";

function ChoosePaymentMethod({ quotationDataLoading, isMobile, isInlineSignup=false }) {
  // Translation hook
  const { t } = useTranslation();
  const triggerPaymentMethodSelection = useReactiveVar(triggerPaymentMethodSelectionVar);
  const paymentMethod = useReactiveVar(paymentMethodVar);

  const { quotationData, setPaymentStep, setPaymentMethod: setPaymentMethodInContext} = usePaymentContext();

    /**
     * See Auth/SignUp/trigger.js
     */
  useEffect(() => {
      if(triggerPaymentMethodSelection === null)
      {
          return;
      }
      setPaymentStep(PAYMENT_STEP.PAY);
      triggerPaymentMethodSelectionVar(null);
  }, [triggerPaymentMethodSelection]);

   /**
    * See Auth/SignUp/trigger.js
   */
  useEffect(() => {
      if(!!paymentMethod)
      {
          setPaymentMethodInContext(paymentMethod);
      }
  }, [paymentMethod]);

  const disableNextBtn =
    quotationData?.violatesLOS ||
    !quotationData?.isRangeAvailable ||
    quotationDataLoading;

  const handleNextBtn = () => {
    const { isFormValid } = validateContactForm();
    if (!isFormValid && !isMobile) {
      return;
    }
    setPaymentStep(PAYMENT_STEP.PAY);
  };

  const paymentMethods = [
    {
      title: t("credit_card"),
      method: PAYMENT_METHOD.CREDIT_CARD,
      icon: <CCIcon />,
      order: 1,
    },
  ];

  if (quotationData?.isCryptoEligible) {
    paymentMethods.push({
      title: t("crypto"),
      method: PAYMENT_METHOD.CRYPTO,
      icon: <CryptoIcon />,
      order: 3,
    });
  }
  if (quotationData?.isBankEligible) {
    paymentMethods.push({
      title: t("bank_transfer"),
      method: PAYMENT_METHOD.BANK,
      icon: <BankIcon />,
      order: 2,
    });
  }

  return (
    <div className="confirm-and-pay__form my-4">
      <div className="form-title">
        <h5 className="form-title__title">{t("choose_payment_method")}</h5>
      </div>

      {paymentMethods
        .sort((a, b) => a.order - b.order)
        .map((method) => {
          return (
            <div
              key={method.method}
              className="form-group floating-label"
              style={{
                boxShadow: "0 0 0 1px rgb(0 0 0 / 10%)",
                padding: "10px",
                borderRadius: "6px",
              }}
              onClick={() => paymentMethodVar(method.method)}
            >
              <div className="floating-label__input-area">
                <RadioBox
                  name={"PaymentMethod"}
                  label={method.title}
                  value={method.method}
                  selected={paymentMethod}
                  icon={method.icon}
                />
              </div>
            </div>
          );
        })}
      {/* Hide this button for inline signup as it is shown in the payment page itself */}
      {!isInlineSignup && <PrimaryButton
        label={t("next")}
        disabled={!paymentMethod || disableNextBtn}
        onClick={handleNextBtn}
        className="my-5"
      />}
    </div>
  );
}

export default ChoosePaymentMethod;
