import React from "react";
import ConditionalWrapper from "../../Utils/ConditionalWrapper";

export const Paragraph = ({ children, ...props }) => (
  <p {...props}>{children}</p>
);
export const Title = ({ italic, bold, children, ...props }) => (
  <h4 style={{ fontStyle: italic || bold || "" }} {...props}>
    {children}
  </h4>
);
export const Subtitle = ({ italic, bold, href, children, ...props }) => (
  <h6
    style={{ fontStyle: (italic && "italic") || (bold && "bold") || "" }}
    {...props}
  >
    <ConditionalWrapper
      condition={href}
      wrapper={(children) => (
        <Anchor href={href} style={{ color: "#222" }}>
          {children}
        </Anchor>
      )}
    >
      {children}
    </ConditionalWrapper>
  </h6>
);
export const Image = ({ src, alt, ...props }) => (
  <img src={src} alt={alt || ""} {...props} />
);
export const Div = ({ children, ...props }) => <div {...props}>{children}</div>;
export const Anchor = ({ href, title, children, ...props }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
    {children || title}
  </a>
);
