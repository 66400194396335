import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import ScrollGallery from "./ScrollGallery";
import FullScreenGallery from "./FullScreenGallery";
import { CiChevronRight, CiChevronLeft } from "../../../../CustomIcons";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";
import useWindowSize from "../../../Utils/hooks/useWindowSize";
import useKeyboardArrows from "../../../Utils/hooks/useKeyboardArrows";

// Translation Package
import { useTranslation } from "react-i18next";

const debounceAmount = 10;
const debouncedUpdate = _.debounce((callback) => callback(), debounceAmount);

const defaultCarouselStyle = {
  x: 0,
  marginLeft: 0,
  transition: "",
};

const SuiteHeader = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  const images = suite.images;
  const isMobile = useMobileDetect();
  const windowWidth = useWindowSize().width;

  const carouselRef = useRef();

  const [carousel, setCarousel] = useState({
    mounted: false,
    intact: images,
    images: isMobile ? images : [...images, ...images],
    infinite: !isMobile,
    current: 0,
    currentImageId: images[0].id,
    total: images.length,
    scrollGallery: false,
    scrollGalleryScrollInto: 0,
    fullScreen: false,
    fullScreenIndex: 0,
    fromDoubleTap: false,
    focused: false,
  });

  useEffect(() => {
    if (isMobile) {
      setCarousel((carousel) => ({
        ...carousel,
        images: carousel.intact,
        infinite: false,
      }));
      return;
    }
    setCarousel((carousel) => ({
      ...carousel,
      images: [...carousel.intact, ...carousel.intact],
      infinite: true,
    }));
  }, [isMobile]);

  const handleOnShowAllClick = () => {
    setCarousel((carousel) => ({
      ...carousel,
      scrollGallery: true,
      scrollGalleryScrollInto: images[0].id,
    }));
  };

  const [carouselStyle, setCarouselStyle] = useState(defaultCarouselStyle);

  const handleOnScroll = () => {
    debouncedUpdate(() => {
      //  Get index
      let indexDetectorId = `suite-images--index-detector`;
      let indexDetectorNode = document.getElementById(indexDetectorId);
      let indexDetectorBounding = indexDetectorNode.getBoundingClientRect();

      carousel.images.forEach((image, index) => {
        let imageNode = document.getElementById(
          `suite-image--${image.id}-${index}`
        );
        if (imageNode === null) return;

        let imageBounding = imageNode.getBoundingClientRect();

        if (
          imageBounding.left < indexDetectorBounding.left &&
          imageBounding.left + imageBounding.width > indexDetectorBounding.left
        ) {
          setCarousel((carousel) => ({
            ...carousel,
            current: index,
            currentImageId: image.id,
          }));
        }
      });
    });
  };

  useEffect(() => {
    let isMobile = window.innerWidth <= 768;
    if (isMobile) return;
    if (carousel.infinite === false) return;

    let carouselNode = document.getElementById(`suite-images-carousel`);
    let carouselScrollWidth = carouselNode.scrollWidth;
    let carouselWidth = carouselNode.offsetWidth;
    let imageSize = carouselScrollWidth / carousel.images.length;

    setCarouselStyle((style) => ({
      ...style,
      x:
        -(imageSize * carousel.intact.length) + (carouselWidth - imageSize) / 2,
      marginLeft: 0,
    }));

    setTimeout(() => {
      setCarouselStyle((style) => ({
        ...style,
        transition: `transform 600ms cubic-bezier(0.83, 0, 0.17, 1)`,
      }));

      setCarousel((carousel) => ({
        ...carousel,
        carousel: 0,
        mounted: true,
      }));
    }, 10);

    // eslint-disable-next-line
  }, [windowWidth]);

  const handleNavClick = (direction) => {
    const carouselNode = document.getElementById("suite-images-carousel");
    const scrollWidth = carouselNode.scrollWidth;
    const imageWidth = scrollWidth / carousel.images.length;

    let newCurrent;
    let transformAmount;
    let marginLeft;

    // Calculate transform
    if (direction === "right") {
      newCurrent = carousel.current + 1;
      transformAmount = carouselStyle.x - imageWidth;
    }
    if (direction === "left") {
      newCurrent = carousel.current - 1;
      transformAmount = carouselStyle.x + imageWidth;
    }

    // Swipe
    let swipe = false;
    const oneArrayWidth = imageWidth * carousel.intact.length;
    const currentMarginLeft = carouselStyle.marginLeft;

    if (newCurrent === carousel.intact.length - 1 && direction === "right") {
      swipe = true;
      marginLeft = oneArrayWidth + currentMarginLeft;
      newCurrent = -1;
    }

    if (newCurrent === -carousel.intact.length && direction === "left") {
      swipe = true;
      marginLeft = -oneArrayWidth + currentMarginLeft;
      newCurrent = 0;
    }

    // Transform carousel
    setCarouselStyle((style) => ({
      ...style,
      x: transformAmount,
      marginLeft: swipe ? marginLeft : style.marginLeft,
    }));

    // Set new current
    setCarousel((carousel) => ({
      ...carousel,
      current: newCurrent,
    }));
  };

  const handleOnCarouselFocus = () => {
    if (carousel.focused === true) return;
    setCarousel((c) => ({ ...c, focused: true }));
  };
  const handleOnCarouselBlur = () => {
    if (carousel.focused === false) return;
    setCarousel((c) => ({ ...c, focused: false }));
  };

  useKeyboardArrows({
    leftArrow: () => carousel.focused && handleNavClick("left"),
    rightArrow: () => carousel.focused && handleNavClick("right"),
  });

  useEffect(() => {
    if (isMobile) {
      setCarouselStyle(defaultCarouselStyle);
      return;
    }
  }, [isMobile]);

  return (
    <div
      dir="ltr"
      className="container-fluid suite__images"
      onFocus={handleOnCarouselFocus}
      onBlur={handleOnCarouselBlur}
      onMouseOver={handleOnCarouselFocus}
      onMouseLeave={handleOnCarouselBlur}
    >
      <div
        className="images-carousel__index-detector"
        id="suite-images--index-detector"
      />
      <div className="images-carousel__counter hide-desktop">
        {carousel.current + 1} / {carousel.total}
      </div>

      <div className="images-carousel-holder">
        <div
          className="images-carousel no-scrollbar"
          onScroll={handleOnScroll}
          ref={carouselRef}
          id="suite-images-carousel"
          style={{
            transform: `translate3d(${carouselStyle.x}px, 0, 0)`,
            marginLeft: carouselStyle.marginLeft,
            transition: carouselStyle.transition,
            opacity: !isMobile ? (carousel.mounted ? "" : "0") : "",
          }}
        >
          {carousel.images.map((image, index) => {
            const handleOnClick = () => {
              setTimeout(() => {
                setCarousel((carousel) => ({
                  ...carousel,
                  scrollGallery: true,
                  scrollGalleryScrollInto: image.id,
                }));
              }, [100]);
            };

            const handleOnDoubleClick = () => {
              setCarousel((carousel) => ({
                ...carousel,
                fullScreen: true,
                fullScreenIndex: index,
                fromDoubleTap: true,
              }));
            };

            const ariaPressed =
              carousel.scrollGallery && carousel.current === index;

            return (
              <div
                className="image-holder"
                key={`${suite.listingName}-${image.id}-${index}`}
                onClick={handleOnClick}
                onDoubleClick={handleOnDoubleClick}
                tabIndex={0}
                role="button"
                aria-pressed={ariaPressed}
              >
                <img
                  className="suite-image"
                  src={image.url}
                  alt={`${suite.listingName}-${image.id}`}
                  id={`suite-image--${image.id}-${index}`}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="container container-max-xl ">
        <div className="images-carousel__show-all hide-mobile">
          <button onClick={handleOnShowAllClick} className="show-all">
            {t("show_all_photos")}
          </button>
          <div className="divider"></div>
          <div className="nav-buttons d-flex">
            <button
              onClick={() => handleNavClick("left")}
              className="nav nav__left"
            >
              <CiChevronLeft size={16} />
            </button>
            <button
              onClick={() => handleNavClick("right")}
              className="nav nav__right"
            >
              <CiChevronRight size={16} />
            </button>
          </div>
        </div>
      </div>

      <ScrollGallery
        carousel={carousel}
        setCarousel={setCarousel}
        images={images}
      />

      <FullScreenGallery
        carousel={carousel}
        setCarousel={setCarousel}
        images={images}
      />
    </div>
  );
};

export default SuiteHeader;
