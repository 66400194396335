import { useState, useEffect } from "react";

import useFilters from "../hooks/useFilters";

import { SEARCH_TYPES, FILTERS, FLEXIBLE_TYPES } from "../search_constants";

import isDateValid from "../../../Utils/functions/isDateValid";
import createDateRangeString from "../../../Utils/functions/createDateRangeString";
import dayjs from "dayjs";
// Translation Package
import { useTranslation } from "react-i18next";
require("dayjs/locale/ar");

const useButtonLabel = () => {
  // Translation hook
  const { t, i18n } = useTranslation();
  const filters = useFilters();
  const [buttonLabel, setButtonLabel] = useState("");

  useEffect(() => {
    const searchType = filters[FILTERS.searchType.paramKey];

    if (searchType === SEARCH_TYPES.calendar) {
      const dates = {
        start: isDateValid(filters[FILTERS.startDate.paramKey]),
        end: isDateValid(filters[FILTERS.endDate.paramKey]),
      };

      const label = createDateRangeString(dates.start, dates.end);
      setButtonLabel(label);
    }

    if (searchType === SEARCH_TYPES.flexible) {
      const flexibleOptions = {
        type: filters[FILTERS.flexibility_type.paramKey],
        months: filters[FILTERS.flexibility_months.paramKey],
      };

      const typeLabel =
        FLEXIBLE_TYPES[flexibleOptions.type]?.label || t("week");
      const months = flexibleOptions.months.split(",");
      const monthNames = months
        ? months.map((month) =>
            dayjs(month).locale(i18n.languages[0]).format("MMM")
          )
        : dayjs().locale(i18n.languages[0]).format("MMM");
      const monthLabel = monthNames.join(", ");

      setButtonLabel(`${t(typeLabel)} ${t("in")} ${monthLabel}`);
    }
  }, [filters, i18n.languages, t]);

  return buttonLabel;
};
export default useButtonLabel;
