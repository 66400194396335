import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../Constants/Routes";
import useCities from "../../Booking/Search/hooks/useCities";
import styles from "./index.module.scss";
// Translation Package
import { useTranslation } from "react-i18next";

import dubaiImg from "./images/dubai.webp";
import istanbulImg from "./images/istanbul.webp";
import montrealImg from "./images/montreal.webp";
import manamaImg from "./images/manama.webp";
import riyadhImg from "./images/riyadh.webp";
import londonImg from "./images/london.webp";

const featuredCitiesContent = {
  dubai: {
    imgURL: dubaiImg,
    isFeatured: false,
  },
  london: {
    imgURL: londonImg,
    isFeatured: false,
  },
  montreal: {
    imgURL: montrealImg,
    isFeatured: false,
  },
  manama: {
    imgURL: manamaImg,
    isFeatured: false,
  },
  istanbul: {
    imgURL: istanbulImg,
    isFeatured: false,
  },
  riyadh: {
    imgURL: riyadhImg,
    isFeatured: false,
  },
};

const findCityInStaticData = (city) => {
  const findCity = featuredCitiesContent[city.toLowerCase()] ?? {};
  const cityData = {
    ...findCity,
    name: city,
    linkPath: city,
  };
  return cityData;
};

// Featured City Card
const FeaturedCityCard = ({ city, classes }) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <Link
      to={`${ROUTES.SEARCH}?city=${city.linkPath}&guests=1&search_type=calendar`}
      className={[
        "d-flex flex-column justify-content-end align-items-stretch",
        styles.city,
        city?.isFeatured ? styles.isFeatured : null,
        classes,
      ].join(" ")}
    >
      <div className={styles.thumb}>
        <img src={city.imgURL} alt={t(city?.name)} />
        <div className={styles.overlay}></div>
      </div>
      <div
        className={`${styles.description} d-flex flex-row justify-content-between align-items-center`}
      >
        <h3 className="m-0 stella_h4">{t(city?.name.toLowerCase())}</h3>
      </div>
    </Link>
  );
};

// Featured City showcase component
const FeaturedCities = () => {
  // Translation hook
  const { t } = useTranslation();
  const { cities } = useCities();

  return (
    <div className="row gx-4 gy-2 gy-lg-4">
      <div className="col-12">
        <div className="header">
          <h2 className="stella_h2">{t("title_where_next")}</h2>
          <p className="homepage-section-subtitle">
            {t("subtitle_where_next")}
          </p>
        </div>
      </div>

      <div className="col-12">
        <div className={`row ${styles.citiesSection}`}>
          {/* Show Rest of cities */}
          {cities.map((city) => {
            return (
              <div
                className={["col-6 col-md-4", styles.customBorder].join(" ")}
                key={city}
              >
                <FeaturedCityCard
                  key={city}
                  city={findCityInStaticData(city)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCities;
