import React from "react";

// Functions
import toMoney from "./../../../Utils/functions/toMoney";

// Misc
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "Components/Utils/Tooltip";
import Loading from "Components/Utils/Loading";
import dayjs from "dayjs";
import { TWO_DAYS_IN_HOURS } from "Constants/globals";

// Translation Package
import { useTranslation } from "react-i18next";

const FutureSDNotice = (props) => {
  // Translation hook
  const { t, i18n } = useTranslation();

  const { quotationData, quotationDataLoading, showDueDate=true, sdDueDateMarginBeforeCheckIn=TWO_DAYS_IN_HOURS } = props;

  const difference = dayjs(quotationData?.startDate).diff(dayjs(), "hours");
  const holdSDToday = difference <= sdDueDateMarginBeforeCheckIn;

  return (
    <div className="confirm-and-pay__suite">
      {/* Quotation Block */}
      <div className="d-flex flex-column position-relative">
        {/* Security Deposit Notice */}
        <div className="total d-flex flex-row align-items-start my-0 gap-2 position-relative py-4">
          <div className="d-flex flex-column">
            <div className="total__label total__deposit">
              <Tooltip text={t("refundable_security_deposit_desc")}>
                <div className="flex flex-row align-items-center" role="button">
                  <span>{t("refundable_security_deposit_title")}</span>
                  <BiInfoCircle fill="#576f76" className="icon mx-1" />
                </div>
              </Tooltip>
              <p className="m-0 fw-normal">
                {showDueDate && <small>
                  {holdSDToday
                    ? `${t("due")} ${t("today")}`
                    : `${t("due_on")} ${dayjs(quotationData?.sdDueDate)
                        .locale(i18n.languages[0])
                        .format("DD MMM YYYY")}`}
                </small>}
              </p>
            </div>
          </div>

          <div className="total__price total__deposit text-nowrap">
            {toMoney(
              quotationData.securityDepositAmount,
              quotationData.currency
            )}
          </div>
        </div>

        <hr
          className="mt-0 d-sm-none"
          style={{
            borderTop: "5px solid rgba(61, 78, 83, 0.12)",
            opacity: "100%",
          }}
        />

        {quotationDataLoading && (
          <div
            className="position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-start px-5 pt-2  rounded-3"
            style={{ background: "rgba(255,255,255,0.8)", zIndex: 1 }}
            id="calendar-calc-loader"
          >
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default FutureSDNotice;
