import React from "react";

// Translation Package
import { useTranslation } from "react-i18next";

export default function MessageSent(props) {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div className="message-sent text-center" {...props}>
      <div className="row">
        <div className="col-12">
          <h3>{t("thanks_for_reaching_out")}</h3>
          <h5>{t("we_will_be_in_touch")}</h5>
        </div>
      </div>
    </div>
  );
}
