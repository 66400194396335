import React, { useState } from "react";

import SearchFilterButton from "../Buttons/SearchFilter";

// Translation Package
import { useTranslation } from "react-i18next";

const GuestInput = ({
  update,
  initialValue,
  maxGuests = 10,
  className,
  showMaxPlus,
  startEmpty,
  tabIndex,
  ...props
}) => {
  //Translation hook
  const { t } = useTranslation();

  initialValue = parseInt(initialValue) || 1;
  const [guests, setGuests] = useState(!startEmpty ? initialValue : 0);

  const handleGuests = (e, type) => {
    // e.preventDefault();
    let newGuests = guests;

    if (type === "increase") {
      if (maxGuests === guests) {
        newGuests = parseInt(maxGuests);
      } else {
        newGuests = newGuests + 1;
      }
    }

    if (type === "decrease") {
      if (guests === 1) {
        newGuests = 1;
      } else {
        newGuests = newGuests - 1;
      }
    }

    if (update && typeof update === "function") {
      update(newGuests);
    }
    setGuests(newGuests);
  };

  return (
    <SearchFilterButton
      label={t("guests")}
      className={className}
      tabIndex={tabIndex || 1}
    >
      <div className="guests__holder">
        <div className="guests__button-label" data-empty={guests === 0}>
          {startEmpty && guests === 0 ? (
            t("add_guests")
          ) : (
            <>
              {guests}
              {showMaxPlus && guests === maxGuests ? "+" : ""}
              <span className="guests__label-text">
                {" "}
                {guests > 1 ? t(`guests_counter`) : t("guest")}
              </span>
            </>
          )}
        </div>

        <div className="guests__buttons">
          <button
            onClick={(e) => handleGuests(e, "decrease")}
            disabled={guests <= 1}
            tabIndex={tabIndex || 1}
            {...props}
          >
            <span>-</span>
          </button>
          <button
            onClick={(e) => handleGuests(e, "increase")}
            disabled={guests === maxGuests}
            tabIndex={tabIndex || 1}
            {...props}
          >
            <span>+</span>
          </button>
        </div>
      </div>
    </SearchFilterButton>
  );
};

GuestInput.defaultProps = {
  update: () => {},
  maxGuests: 10,
  initialValue: 1,
};

export default GuestInput;
