import moment from "moment";
import { DATE_FORMAT } from "Constants/url_params";

/*--------------------------------------
            Main Function
--------------------------------------*/
const getOutsideRange = (day, tempDates, availableDatesConfig) => {
  /*---------Catch blocks >>>>>>>>>>*/
  // Generic calendar check for dates before tempDates; this helps homepage,search page component
  if (!tempDates.start) return false;
  if (day.isBefore(tempDates?.start, "day")) return true;

  // Default setting
  if (!tempDates.start || !availableDatesConfig) return false;
  /*---------Catch blocks<<<<<<<<<<*/

  // find Los config for start day
  const startDayConfig = availableDatesConfig?.find(
    (config) =>
      moment(tempDates?.start).format(DATE_FORMAT.apiFormat) ===
      config.losDay.day
  );

  // this day los should be less than last possible checkout
  const isOutside =
    day.clone().add(1, "days").isBefore(tempDates.start) ||
    day.isAfter(moment(startDayConfig?.lastPossibleCheckout).add(1, "days"));
  return isOutside;
};
export default getOutsideRange;
