import React from "react";

import { CiX } from "../../../CustomIcons";

const CloseButton = ({ onClick, size, className, color, ...props }) => {
  return (
    <button
      className={`close-button ${className || ""}`}
      onClick={onClick}
      aria-label="Close"
      {...props}
    >
      <CiX className="icon" color={color} size={size} />
    </button>
  );
};

CloseButton.defaultProps = {
  onClick: () => {},
  size: 24,
  color: "#000000",
  className: "",
};

export default CloseButton;
