import React from "react";

import { CiLocation } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";

const SuiteHeader = ({ suite, loading }) => {
  return (
    <div className="container container-max-xl suite__header" id="header">
      <div className="suite__section">
        {!loading && (
          <SectionTitle
            icon={<CiLocation />}
            subtitle={suite.location.address}
            title={suite.listingName}
            large={true}
          />
        )}
      </div>
    </div>
  );
};

export default SuiteHeader;
