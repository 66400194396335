import React from "react";
import moment from "moment";

// Translation Package
import { useTranslation } from "react-i18next";

const CalendarMonth = ({ month }) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <p>
      {t(moment(month).format("MMMM").toLowerCase())}{" "}
      {moment(month).format("YYYY")}
    </p>
  );
};
export default CalendarMonth;
