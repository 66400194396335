import React, { useState, useEffect } from "react";

import * as ROUTES from "../../../../Constants/Routes";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { RemoveScroll } from "react-remove-scroll";

import CityInput from "../../../Form/CityInput";
import DateInput from "../../../Form/DatePicker";
import GuestInput from "../../../Form/GuestInput";
import FiltersButton from "./FiltersButton";
import PrimaryButton from "../../../Buttons/Primary";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { BsX, BsSearch } from "react-icons/bs";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";
import useUpdateFilters from "../../../Utils/hooks/useUpdateFilters";
import formatForParams from "../../../Utils/functions/formatForParams";

import useEscapeButton from "../../../Utils/hooks/useEscapeButton";
import {
  SEARCH_TYPES,
  FILTERS,
  DATE_FORMAT,
} from "../../../../Constants/url_params";
import useFilters from "../../../Utils/hooks/useFilters";
import createSearchString from "../../../Utils/functions/createSearchString";
import { isNullableType } from "graphql";
import { useReactiveVar } from "@apollo/client";
import { toggleFilterVisibility } from "../FilterVisibility";

// Translation Package
import { useTranslation } from "react-i18next";

const Filters = ({
  topbarButton,
  hideTemporarily,
  guestStartEmpty,
  zIndex,
}) => {
  const location = useLocation();
  const isMobile = useMobileDetect(992);
  const isAccountDropDownVisible = useReactiveVar(toggleFilterVisibility);

  const [showFilters, setShowFilters] = useState(false);
  const [filtersStyle, setFiltersStyle] = useState(null);
  const toggleShowFilters = () => setShowFilters(!showFilters);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const pre = prevPos.y;
      const pos = currPos.y;

      if (isMobile === false) return;

      // Auto close filters on mobile if user agressively scrolls
      const diff = Math.abs(pre - pos);
      const filterCloseThreshold = 100;
      if (diff > filterCloseThreshold && showFilters) {
        setShowFilters(false);
      }
    },
    null,
    null,
    null,
    50
  );

  useEffect(() => {
    if (showFilters) {
      setFiltersStyle({ zIndex: 10 });
    } else {
      setTimeout(() => setFiltersStyle(null), 300);
    }
  }, [showFilters, zIndex]);

  useEffect(() => {
    setShowFilters(false);
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "instant",
    });
  }, [location]);

  const filtersAreaProps = {
    showFilters: showFilters,
    toggleShowFilters: toggleShowFilters,
    isMobile: isMobile,
    topbarButton: topbarButton,
    guestStartEmpty: guestStartEmpty,
    isAccountDropDownVisible,
  };

  return (
    <>
      <div className="search_page__filters" style={filtersStyle}>
        <FiltersButton
          onClick={toggleShowFilters}
          topbarButton={topbarButton}
          hideTemporarily={hideTemporarily}
          isFilterBoxOpen={showFilters && !isAccountDropDownVisible}
        />
        {isMobile && <FiltersArea {...filtersAreaProps} />}
      </div>
      {!isMobile && <FiltersArea zIndex={zIndex} {...filtersAreaProps} />}
    </>
  );
};

const FiltersArea = (props) => {
  const {
    showFilters,
    toggleShowFilters,
    isMobile,
    topbarButton,
    guestStartEmpty,
    zIndex,
    isAccountDropDownVisible,
  } = props;

  // Translation hook
  const { t } = useTranslation();
  const showClass = showFilters ? "show" : "hide";

  const history = useHistory();
  const onClickOutside = () => {
    if (isMobile) return;
    if (!showFilters) return;
    toggleShowFilters();
  };

  useEscapeButton(() => {
    if (!isMobile && showFilters) toggleShowFilters();
  });

  const filters = useFilters();

  const [states, setStates] = useState({
    city: filters[FILTERS.city.paramKey],
    dates: {
      start: filters[FILTERS.startDate.paramKey],
      end: filters[FILTERS.endDate.paramKey],
    },
    flexible: {
      type: filters[FILTERS.flexibility_type.paramKey],
      months: filters[FILTERS.flexibility_months.paramKey],
    },
    guests: filters[FILTERS.guests.paramKey],
    searchType:
      filters[FILTERS.searchType.paramKey] || FILTERS.searchType.defaultValue,
    buildings: filters[FILTERS.buildings.paramKey],
  });

  const updateCity = (c) => {
    setStates((s) => ({ ...s, city: c, buildings: '' }));
  };
  const updateDates = (d) =>
    setStates((s) => ({
      ...s,
      dates: d,
      flexible: { type: null, months: null },
      searchType: SEARCH_TYPES.calendar,
    }));
  const updateGuests = (g) => setStates((s) => ({ ...s, guests: g }));
  const updateFlexible = (f) =>
    setStates((s) => ({
      ...s,
      flexible: f,
      dates: {
        start: null,
        end: null,
      },
      searchType: SEARCH_TYPES.flexible,
    }));

  const [emptyCity, setEmptyCity] = useState(null);

  // Start date
  useUpdateFilters(
    formatForParams(FILTERS.startDate.paramKey, states.dates.start),
    "no-scroll",
    isMobile
  );
  // End date
  useUpdateFilters(
    formatForParams(FILTERS.endDate.paramKey, states.dates.end),
    "scroll",
    isMobile
  );
  // Search type
  useUpdateFilters(
    `${FILTERS.searchType.paramKey}=${states.searchType}`,
    "scroll",
    isMobile
  );
  // Flexibility type
  useUpdateFilters(
    `${FILTERS.flexibility_type.paramKey}=${states.flexible.type}`,
    "scroll",
    isMobile
  );
  // Flexibility months
  useUpdateFilters(
    `${FILTERS.flexibility_months.paramKey}=${states.flexible.months}`,
    "scroll",
    isMobile
  );
  // City
  useUpdateFilters(
    `${FILTERS.city.paramKey}=${states.city}`,
    "scroll",
    isMobile
  );
  // Guests
  useUpdateFilters(
    `${FILTERS.guests.paramKey}=${states.guests}`,
    "scroll",
    isMobile,
    true
  );

  useEffect(() => {
    if (states.city) {
      setEmptyCity(isNullableType);
    }
  }, [states.city]);

  const search = () => {
    setEmptyCity(null);

    if (!states.city) {
      setEmptyCity({ menuIsOpen: true });
      return;
    }

    const city = states.city && `${FILTERS.city.paramKey}=${states.city}`;

    const flexibleType =
      states.flexible.type &&
      `${FILTERS.flexibility_type.paramKey}=${states.flexible.type}`;

    const flexibleMonths =
      states.flexible.months &&
      `${FILTERS.flexibility_months.paramKey}=${states.flexible.months}`;

    const startDate =
      states.dates.start &&
      `${FILTERS.startDate.paramKey}=${moment(states.dates.start).format(
        DATE_FORMAT.paramFormat
      )}`;

    const endDate =
      states.dates.end &&
      `${FILTERS.endDate.paramKey}=${moment(states.dates.end).format(
        DATE_FORMAT.paramFormat
      )}`;

    const guests = `${FILTERS.guests.paramKey}=${states.guests || 1}`;

    const searchType = `${FILTERS.searchType.paramKey}=${states.searchType}`;

    const buildings = states.buildings && `${FILTERS.buildings.paramKey}=${states.buildings}`

    const searchString = createSearchString([
      city,
      startDate,
      endDate,
      guests,
      flexibleType,
      searchType,
      flexibleMonths,
      buildings
    ]);

    history.push({
      pathname: ROUTES.SEARCH,
      search: searchString,
    });
  };

  const [focused, setFocusedOnInputs] = useState({
    focused: false,
    item: "",
    attention: false,
  });

  const tabIndex = {
    tabIndex: showFilters ? "0" : "-1",
  };

  return (
    <RemoveScroll enabled={!isMobile && showFilters}>
      <div
        className={`search_page__filters-area ${
          isAccountDropDownVisible ? "hide" : showClass
        } `}
        style={{ zIndex: zIndex || "" }}
      >
        <div
          className="bg"
          onClick={onClickOutside}
          onDoubleClick={onClickOutside}
        />

        <div className="content">
          <div className="header">
            <h5 className="title">
              {topbarButton ? t("start_search") : t("mobile_edit_filters")}
            </h5>
            <button
              className="close-button"
              onClick={toggleShowFilters}
              {...tabIndex}
              aria-label="Close filters area"
            >
              <BsX className="icon" />
            </button>
          </div>

          <div className="filters">
            <div className="filters__inputs">
              <div className="filters__city-input">
                <CityInput
                  update={updateCity}
                  className="city-input big-input"
                  onFocus={() =>
                    setFocusedOnInputs({
                      focused: true,
                      item: "city",
                      attention: true,
                    })
                  }
                  onBlur={() =>
                    setFocusedOnInputs({
                      focused: false,
                      item: "city",
                      attention: true,
                    })
                  }
                  {...emptyCity}
                  {...tabIndex}
                />
              </div>

              <div className="filters__group">
                <div className="filters__group-item filters__date-input">
                  <DateInput
                    updateDates={updateDates}
                    updateFlexible={updateFlexible}
                    autoClose={showFilters}
                    forceClose={
                      !isMobile &&
                      focused.focused &&
                      focused.item !== "datepicker"
                    }
                    allowFlexible={true}
                    className="date-input big-input"
                    header={t("datepicker_question")}
                    onFocus={() =>
                      setFocusedOnInputs({
                        focused: true,
                        item: "datepicker",
                        attention: true,
                      })
                    }
                    onBlur={() =>
                      setFocusedOnInputs({
                        focused: false,
                        item: "datepicker",
                        attention: true,
                      })
                    }
                    {...tabIndex}
                  />
                </div>
                <div className="filters__group-item filters__guest-input">
                  <GuestInput
                    update={updateGuests}
                    className="guest-input big-input"
                    initialValue={filters.guests}
                    showMaxPlus={true}
                    startEmpty={guestStartEmpty}
                    onFocus={() =>
                      setFocusedOnInputs({
                        focused: true,
                        item: "guest",
                        attention: false,
                      })
                    }
                    onBlur={() =>
                      setFocusedOnInputs({
                        focused: false,
                        item: "guest",
                        attention: false,
                      })
                    }
                    {...tabIndex}
                  />
                </div>
              </div>

              <PrimaryButton
                className="filters__search-button"
                onClick={search}
                circular
                {...tabIndex}
              >
                <BsSearch />
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default Filters;
