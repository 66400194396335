import moment from "moment";
import { DATE_FORMAT } from "Constants/url_params";

/*--------------------------------------
            Main Function
--------------------------------------*/
const isDayBlocked = (day, availableDatesConfig) => {
  if (day.isBefore(moment(), "day")) return true;

  if (!day || !availableDatesConfig) return false;

  return !availableDatesConfig?.some(
    (config) => day.format(DATE_FORMAT.apiFormat) === config.losDay.day
  );
};
export default isDayBlocked;
