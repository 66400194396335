import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Portal } from "react-portal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { RemoveScroll } from "react-remove-scroll";

import toMoney from "../../../Utils/functions/toMoney";
import SlickWrapper from "../../../Utils/SlickWrapper";
import useFilters from "../../../Utils/hooks/useFilters";
import createDateString from "../../../Utils/functions/createDateString";
import { FILTERS } from "../../../../Constants/url_params";
import logListingClickEvent from "../functions/logListingClickEvent";

import * as ROUTES from "../../../../Constants/Routes";

import { CiCalendar } from "../../../../CustomIcons";

// Constants
import { DEFAULT_MARKER_STATES } from "./map_constants";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

const placeholders = {
  title: "Listing",
  availability: "N/A",
  price: "AED N/A",
  bedrooms: "N/A",
  bathrooms: "N/A",
  guests: "N/A",
  image: "",
  url: ROUTES.SEARCH,
};

const composeSearchQuery = (filters) => {
  const queries = [
    filters.start_date && `${FILTERS.startDate.paramKey}=${filters.start_date}`,
    filters.end_date && `${FILTERS.endDate.paramKey}=${filters.end_date}`,
    filters.guests && `${FILTERS.guests.paramKey}=${filters.guests}`,
  ];
  return `?${queries.join("&")}`;
};

const useListingInfo = (data) => {
  const [info, setInfo] = useState(placeholders);

  useEffect(() => {
    if (data === undefined || !data) return;

    const price = toMoney(
      data.avgPrice || data.basePrice,
      data.displayCurrency,
      0
    );
    setInfo({
      title: data.listingName.replace("Stella Stays | ", ""),
      availability: data?.quotationStartDate
        ? createDateString(data.quotationStartDate, data.quotationEndDate)
        : null,
      price: price,
      showFrom: !data.quotationStartDate && !data.quotationEndDate,
      bedrooms: data.bedrooms,
      bathrooms: data.baths,
      guests: data.maxGuests,
      images: data.images,
      quotationStartDate: data?.quotationStartDate,
      quotationEndDate: data?.quotationEndDate,
      url: `${ROUTES.SUITE}/${data.id}/${data.urlFriendlyID}`,
    });
  }, [data]);

  return info;
};

const getCardClasses = (isShown) => {
  return Object.values({
    main: "search_page__mobile-swipeable-card",
    show: isShown ? "show" : "hide",
  })
    .map((c) => c)
    .join(" ");
};

const CARD_DRAG_THRESHOLD = 30;
const cardHeight = 700;

const MobileListingCard = (props) => {
  const {
    data,
    markerStates,
    setMarkerStates,
    clusterStates,
    setClusterStates,
    isMapOpen,
    intactListingData,
  } = props;

  const isCluster = clusterStates.id ? true : false;
  const isPassedThreshold = clusterStates.isPassedThreshold;

  const [selectedListing, setSelectedListing] = useState(false);

  const info = useListingInfo(selectedListing);

  const [{ y }, springApi] = useSpring(() => ({ y: cardHeight }));
  const open = () => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    springApi.start({
      y: 0,
      immediate: false,
      config: config.stiff,
    });
  };
  const close = (velocity = 0) => {
    setTimeout(() => {
      setMarkerStates(DEFAULT_MARKER_STATES.LISTING);
      setClusterStates(DEFAULT_MARKER_STATES.CLUSTER);
      setSelectedListing(false);
    }, 200);

    springApi.start({
      y: cardHeight,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };
  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(
    ({ last, vxvy: [, vy], axis, movement: [, my], cancel }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position

      // Detect horizontal scrolling to prevent accidental dragging caused by carousel
      if (axis === "x") return;

      // Can be
      if (my < -CARD_DRAG_THRESHOLD) {
        cancel();
      }

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        my > cardHeight * 0.5 || vy > 0.5 ? close(vy) : open();
      } else {
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        springApi.start({ y: my, immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  // Selected listing
  useEffect(() => {
    const isCluster = clusterStates.id ? true : false;
    const isPassedThreshold = clusterStates.isPassedThreshold;

    // If cluster set data as cluster
    if (isCluster && isPassedThreshold) {
      const listings = clusterStates.leaves.map((leave) => leave.marker);

      setSelectedListing(listings[0].data);
      setMarkerStates(DEFAULT_MARKER_STATES.LISTING);
      return;
    }

    // Try to set it by marker directly
    const selectedByMarker = data.find(
      (i) => i.id === markerStates.selected.id
    );
    if (selectedByMarker) {
      setSelectedListing(selectedByMarker.data);
      return;
    } else {
      springApi.start({
        y: cardHeight,
        immediate: false,
        config: { ...config.default },
      });
    }
  }, [data, markerStates, clusterStates, setMarkerStates, springApi]);

  // Auto open on marker select
  useEffect(() => {
    if (selectedListing) {
      springApi.start({
        y: 0,
        immediate: false,
        config: config.default,
      });
    }
  }, [selectedListing, springApi]);

  // Auto close on map close
  useEffect(() => {
    if (!isMapOpen) {
      setClusterStates(DEFAULT_MARKER_STATES.CLUSTER);
      setSelectedListing(false);

      springApi.start({
        y: cardHeight,
        immediate: false,
        config: { ...config.default, y: 0.2 },
      });
    }
  }, [isMapOpen, setClusterStates, springApi]);

  const onDoubleClick = () => {
    setMarkerStates(DEFAULT_MARKER_STATES.LISTING);
    setClusterStates(DEFAULT_MARKER_STATES.CLUSTER);
    setSelectedListing(false);
  };

  const cardClass = getCardClasses(selectedListing);

  return (
    <RemoveScroll enabled={selectedListing}>
      <Portal>
        <animated.div
          className={cardClass}
          style={{
            y,
            touchAction: "pan-x",
          }}
          hidden={!isMapOpen}
          {...bind()}
        >
          <div className="top">
            <div className="top__close" onDoubleClick={onDoubleClick}>
              <div className="closer"></div>
            </div>
          </div>

          <div className="card-content">
            {isCluster && isPassedThreshold && (
              <ClusterBubbles
                cluster={clusterStates}
                setSelectedListing={setSelectedListing}
                selectedListing={selectedListing}
              />
            )}
            <ListingArea
              info={info}
              data={selectedListing}
              isCluster={isCluster}
              intactListingData={intactListingData}
            />
          </div>
        </animated.div>
      </Portal>
    </RemoveScroll>
  );
};

const ClusterBubbles = (props) => {
  // Translation hook
  const { t } = useTranslation();
  const { cluster, setSelectedListing, selectedListing } = props;

  return (
    <div className="card-content__cluster-bubbles">
      <div className="cluster-bubbles__title">
        <Trans
          i18nKey={"listing_results_found"}
          values={{ listingCount: cluster.leaves.length }}
        />
      </div>
      <div className="cluster-bubbles__bubbles" dir="ltr">
        {cluster.leaves.map((item) => {
          const data = item.marker.data;

          const isSelected = selectedListing.id === data.id;
          const selectedClass = isSelected ? "selected" : "";

          const price = toMoney(
            data.avgPrice || data.basePrice,
            data.displayCurrency,
            0
          );
          const image = {
            url: data.images && data.images[0].url,
            alt: data.images && data.images[0].description,
          };

          const showFrom = !data.quotationStartDate && !data.quotationEndDate;

          const onClick = () => setSelectedListing(data);

          return (
            <button
              className={`bubble ${selectedClass}`}
              key={data.id}
              onClick={onClick}
            >
              <div className="bubble__image">
                <img src={image.url} alt={image.alt} />
              </div>
              <div className="bubble__price">
                {showFrom ? t("from") + " " : ""}
                <span style={{ marginTop: showFrom ? "" : "0px" }}>
                  {price}
                </span>
                {!showFrom ? (
                  <span style={{ marginTop: "-4px", fontSize: "85%" }}>
                    {t("per")} {t("night")}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ListingArea = (props) => {
  // Translation hook
  const { t } = useTranslation();
  const { info, isCluster, intactListingData, data } = props;

  const filters = useFilters();
  const searchQuery = composeSearchQuery({
    guests: filters.guests,
    start_date: info?.quotationStartDate || filters.start_date,
    end_date: info?.quotationEndDate || filters.end_date,
  });

  const clusterClass = isCluster ? "cluster-item" : "";
  const handleGaEvent = () => {
    logListingClickEvent(intactListingData, filters, data, "mapPin");
  };

  return (
    <div className={`card-content__listing-area ${clusterClass}`}>
      <div className="listing-carousel">
        <div className="carousel" key={info.url}>
          {info.images && (
            <SlickWrapper
              items={[...info.images].splice(0, 10).map((image, index) => (
                <LazyLoadImage
                  key={index}
                  effect="opacity"
                  src={image.url}
                  alt={image.title}
                  threshold={500}
                />
              ))}
            />
          )}
        </div>
      </div>
      <div className="content">
        {info.availability && (
          <div className="content__availability">
            <CiCalendar
              className="icon"
              color="#75969f"
              size="16"
              viewBox="0 0 16 16"
            />
            {t("available")} {info.availability}
          </div>
        )}

        <div className="content__title">{info.title}</div>

        <div className="content__meta gap-4">
          <div className="meta__item">
            {info.bedrooms} {t(`bedroom${info.bedrooms > 1 ? "s" : ""}`)}
          </div>
          <div className="meta__item">
            {info.bathrooms} {t(`bathroom${info.bathrooms > 1 ? "s" : ""}`)}
          </div>
          <div className="meta__item">
            {info.guests} {info.guests > 1 ? t(`guests_counter`) : t("guest")}
          </div>
        </div>

        <div className="content__bottom">
          <div className="bottom__price">
            {info.showFrom ? t("from") + " " : ""} <span>{info.price}</span>/
            {t("night")}
          </div>

          <div className="bottom__anchor">
            <Link
              target="_blank"
              onClick={handleGaEvent}
              to={{
                pathname: info.url,
                search: searchQuery,
              }}
              className="anchor"
            >
              {t("view_listing")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileListingCard;
