import React, { useState } from "react";
import Select, { components } from "react-select";

import useFilters from "../Utils/hooks/useFilters";
import useMobileDetect from "../Utils/hooks/useMobileDetect";
import useWindowSize from "../Utils/hooks/useWindowSize";
import useCities from "../Booking/Search/hooks/useCities";

import SearchFilterButton from "../Buttons/SearchFilter";

// Translation Package
import { useTranslation } from "react-i18next";

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#000000",
    paddingLeft: "2px",
    paddingTop: "4px",
    fontWeight: 500,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      paddingTop: "4px",
      marginLeft: "2px",
    };
  },
};

const CityInput = (props) => {
  // Translation hook
  const { t } = useTranslation();
  const transformCityNamesToLookupKey = (city) => {
    if (typeof city === "string") {
      return city.split(" ").join("_").toLowerCase();
    }
    return city;
  };

  const { update, className, autoScrollIntoView, tabIndex } = props;

  const isMobile = useMobileDetect();
  const windowHeight = useWindowSize().height;

  const { cities, loading: citiesLoading } = useCities();

  const filters = useFilters();
  const initialCity = filters.city;

  const [selectedCity, setSelectedCity] = useState(
    initialCity
      ? {
          value: initialCity,
          label: t(transformCityNamesToLookupKey(initialCity)),
        }
      : null
  );

  const handleChange = (city) => {
    setSelectedCity(city);
    if (update) update(city.value);
  };

  // Auto scroll down
  const handleFocus = () => {
    if (!autoScrollIntoView) return;
    if (!isMobile) return;
    if (windowHeight > 600) return;

    const cityInput = document.getElementById("city-input");
    const offsetTop = getOffset(cityInput).top;
    window.scrollTo({
      top: offsetTop - 340,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="search-filters city_input-holder" id="city-input">
      <SearchFilterButton
        label={t("city")}
        className={className}
        hasValue={!selectedCity}
        tabIndex={tabIndex || 1}
      >
        <Select
          isClearable={false}
          isSearchable={false}
          isLoading={citiesLoading}
          name="city"
          aria-labelledby={t("city")}
          components={{ Placeholder }}
          styles={customStyles}
          maxMenuHeight={350}
          options={cities.map((city) => ({
            value: city,
            label: t(transformCityNamesToLookupKey(city)),
          }))}
          className="select-filter-container"
          classNamePrefix="select-filter"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#e3eaec",
              primary50: "#bacbcf",
              primary75: "#91abb2",
              primary: "#75969f",
            },
          })}
          onChange={(val) => handleChange(val)}
          onFocus={handleFocus}
          value={selectedCity}
          placeholder={t("select_a_city")}
          {...props}
        />
      </SearchFilterButton>
    </div>
  );
};

export default CityInput;
