const calculateSentences = (str) => {
  const maxLength = 300;
  let length = str.length;
  let is_long = false;
  let short_text = "";

  if (length > maxLength) {
    is_long = true;
    let sentences = str.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|");

    let total_length = 0;
    let i = 0;

    while (sentences) {
      if (total_length > maxLength) {
        break;
      }

      total_length += sentences[i].length;
      short_text = `${short_text} ${sentences[i]}`;
      i++;
    }
  } else {
    short_text = str;
  }

  return { is_long, short_text };
};

export default calculateSentences;
