import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "Components/Form/PhoneNumberInput";

function ContactReviewForm({ user }) {
  const { t } = useTranslation();

  const { register, control, setValue, formState: errors } = useFormContext();

  return (
    <div>
      <div className="confirm-and-pay__form">
        {/* Title */}
        <div className="form-title">
          <h5 className="form-title__title">
            {t("inline_signup_personal_information")}
          </h5>
        </div>

        {/* Form */}
        <div className="row">
          {/* First Name */}
          <div className="col-12 col-md-6">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: t("error_last_name"),
              }}
              defaultValue={user?.firstName}
              render={(field) => (
                <div className="form-group floating-label">
                  <div className="floating-label__input-area">
                    <input
                      type="text"
                      className="form-control floating-label__text-input"
                      id="firstName"
                      placeholder=""
                      {...field}
                    />
                    <label
                      className="floating-label__label"
                      htmlFor="firstName"
                    >
                      {t("first_name")}
                    </label>
                  </div>
                  {errors?.firstName && (
                    <div className="error-text">
                      <span className="error-text__icon">
                        <BsExclamationCircleFill className="icon" />
                      </span>
                      {errors?.firstName?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          {/* Last Name */}
          <div className="col-12 col-md-6">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: t("error_last_name"),
              }}
              defaultValue={user?.lastName}
              render={(field) => (
                <div className="form-group floating-label">
                  <div className="floating-label__input-area">
                    <input
                      type="text"
                      className="form-control floating-label__text-input"
                      id="lastName"
                      placeholder=""
                      {...field}
                    />
                    <label className="floating-label__label" htmlFor="lastName">
                      {t("last_name")}
                    </label>
                  </div>
                  {errors?.lastName && (
                    <div className="error-text">
                      <span className="error-text__icon">
                        <BsExclamationCircleFill className="icon" />
                      </span>
                      {errors?.lastName?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="col-12">
            <p className="info">{t("name_id_notice")}</p>
          </div>

          {/* Mobile and Country */}
          <div className="col-12">
            <Controller
              name="mobile"
              control={control}
              rules={{
                required: t("error_phone_number_valid"),
                validate: (value) => {
                  try {
                    const parsedNumber = parsePhoneNumber(
                      value?.replace(/\s+/g, "")
                    );
                    if (!parsedNumber?.isValid()) {
                      return t("error_phone_number_valid");
                    }
                  } catch {
                    return t("error_phone_number_valid");
                  }
                },
              }}
              defaultValue={user?.mobile}
              render={(field) => (
                <PhoneNumberInput
                  phoneNumberRegister={field}
                  phoneNumberError={errors?.mobile}
                  countryError={errors?.country}
                  countryRegister={register("country", {
                    required: t("error_country_code"),
                  })}
                  triggerCountryValidation={() => {}}
                  defaultNumber={field.value}
                  onCountryChange={(value) => setValue("country", value)}
                  onPhoneNumberChange={(value) => {
                    let formattedValue = value.replace(/\s+/g, "");
                    if (!formattedValue.startsWith("+")) {
                      formattedValue = `+${formattedValue}`;
                    }
                    formattedValue = new AsYouType().input(formattedValue);
                    field.onChange(formattedValue);
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactReviewForm;
