const validNumbers = {
  // Day
  0: [0, 1, 2, 3],
  1: {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    2: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    3: [0, 1],
  },

  // Month
  2: [0, 1],
  3: {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    1: [0, 1, 2],
  },

  // Year
  4: [1, 2],
  5: {
    1: [8, 9],
    2: [0, 1],
  },
  6: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  7: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
};

const formatDateOfBirth = (value, callback) => {
  const divider = `/`;
  const dividerIndexes = [2, 5];
  let inputVal = value.replaceAll(".", divider);
  let numericVal = inputVal.replaceAll(divider, "");

  // Detect is numeric
  let latestKey = numericVal.substr(numericVal.length - 1);
  if (latestKey === divider) {
    latestKey = numericVal.substr(numericVal.length - 2).charAt(0);
  }
  if (latestKey === "") {
    callback("");
    return;
  }

  const isValueNumeric = !isNaN(latestKey);
  if (isValueNumeric === false) return;

  // Format with divider
  if (inputVal.length > 10) return;
  if (dividerIndexes.includes(inputVal.length)) inputVal += divider;

  latestKey = parseInt(latestKey);

  // Format days
  const inputValIndex = numericVal.length - 1;
  let selectedObject = validNumbers[inputValIndex];
  const isArray = Array.isArray(selectedObject) ? true : false;

  if (isArray) {
    if (!selectedObject.includes(latestKey)) return;
  } else {
    let previousChar = numericVal.substr(numericVal.length - 2).charAt(0);
    previousChar = parseInt(previousChar);
    selectedObject = validNumbers[inputValIndex][previousChar];

    if (selectedObject === undefined) return;
    if (!selectedObject.includes(latestKey)) return;
  }

  callback(inputVal);
  return;
};
export default formatDateOfBirth;
