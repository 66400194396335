import moment from "moment";
import { DATE_FORMAT } from "Constants/url_params";

const onDatesChange = ({
  // States
  startDate,
  endDate,
  tempDates,
  focus,
  // State handlers
  setLos,
  setTempDates,
  setFocus,
  setShow,
  setDates,
  // Functions
  updateDates,
  // Additional info
  availableDatesConfig,
  // Misc
  autoClose,
  allowFlexible,
  isMobile,
  FOCUS_KEYS,
}) => {
  // If focus start, only update start date end set end to null

  const oldDate = tempDates.start;
  const newDate = startDate;

  // Check for violation of start date
  if (availableDatesConfig) {
    const dayToTest = availableDatesConfig?.find(
      (config) => startDate.format(DATE_FORMAT.apiFormat) === config.losDay.day
    );

    if ((dayToTest && dayToTest.checkOutOnly) || !dayToTest) {
      return;
    }
    if (dayToTest) {
      setLos(dayToTest.losDay.los);
    }
  }

  if (newDate.isSameOrBefore(oldDate) && focus === FOCUS_KEYS.start) {
    setTempDates({
      start: startDate,
      end: null,
    });
    setFocus(FOCUS_KEYS.end);
    return;
  }

  if (tempDates.start && !tempDates.end) {
    // Check for end date and in between violation
    if (availableDatesConfig) {
      // End date violation
      // find Los config for start day
      const startDayConfig = availableDatesConfig?.find(
        (config) =>
          moment(tempDates?.start).format(DATE_FORMAT.apiFormat) ===
          config.losDay.day
      );
      const violatesEndDate = endDate?.isAfter(
        moment(startDayConfig?.lastPossibleCheckout),
        "day"
      );

      if (violatesEndDate) return;
    }

    setTempDates({
      start: startDate,
      end: endDate,
    });

    setFocus((state) => {
      return state === FOCUS_KEYS.start ? FOCUS_KEYS.end : FOCUS_KEYS.start;
    });

    if (autoClose && !allowFlexible && !isMobile) {
      setShow(false);
      setDates({ start: startDate, end: endDate });
      updateDates({ start: startDate, end: endDate });
    }
  } else {
    setTempDates({
      start: startDate,
      end: null,
    });
    setFocus(FOCUS_KEYS.end);
  }
};
export default onDatesChange;
