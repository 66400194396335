import mapStyles from "./mapStyles";
import isTouchDevice from "is-touch-device";

export const DEFAULT_LOCATION = {
  lat: 25.204849,
  lng: 55.270782,
  // Dubai
};

export const MAP_OPTIONS = {
  styles: mapStyles,
  disableDefaultUI: isTouchDevice(),
  gestureHandling: isTouchDevice() ? "greedy" : "",
  zoom: 13,
  center: DEFAULT_LOCATION,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  fullscreenControl: false,
  clickableIcons: false,
};
