const getDaySize = (windowSize) => {
  if (windowSize > 400) return 60;
  if (windowSize > 380) return 56;
  if (windowSize > 370) return 54;
  if (windowSize > 360) return 52;
  if (windowSize > 340) return 50;
  if (windowSize > 320) return 47;

  return 45;
};

export default getDaySize;
