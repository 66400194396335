import React from "react";

const SvgItem = ({ className, styles, size, color, i }) => {
  return (
    <svg
      className={className}
      style={styles}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={i.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d={i.d} fill={color} />
    </svg>
  );
};

SvgItem.defaultProps = {
  size: 24,
  color: "#000000",
  style: {},
  className: "custom-icon",
};

export default SvgItem;
