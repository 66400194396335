import React from "react";
import styles from "./index.module.scss";

// Translation Package
import { useTranslation } from "react-i18next";

import dateImg from "./assets/dates.svg";
import locationImg from "./assets/location.png";
import techImg from "./assets/designed.png";

const FlexItem = ({ img, title, description }) => {
  return (
    <div
      className={`d-flex flex-column align-items-center gap-2 text-center ${styles.flexItem}`}
    >
      <img src={img} alt={title} className="image" />
      <div className={styles.description}>
        <h3 className="stella_h3">{title}</h3>
        <p className="stella_featured_para">{description}</p>
      </div>
    </div>
  );
};

// Flexible component
const Flexible = () => {
  // Translation hook
  const { t } = useTranslation();

  const data = [
    {
      img: dateImg,
      title: t("subtitle_future_of_living_1"),
      description: t("text_future_of_living_1"),
    },
    {
      img: techImg,
      title: t("subtitle_future_of_living_2"),
      description: t("text_future_of_living_2"),
    },
    {
      img: locationImg,
      title: t("subtitle_future_of_living_3"),
      description: t("text_future_of_living_3"),
    },
  ];

  return (
    <div className={`container my-5 ${styles.featured}`}>
      <div className={`row gy-4`}>
        <div className="col-12">
          <div className="header text-center">
            <h2 className="stella_h2 mx-auto">{t("title_future_of_living")}</h2>
          </div>
        </div>

        {data.map((item, i) => {
          return (
            <div className="col-12 col-lg-4" key={i}>
              <FlexItem {...item} />
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="container px-0 py-5"></div>
        </div>
      </div>
    </div>
  );
};

export default Flexible;
