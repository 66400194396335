import React from "react";

const Dots = (props) => {
  const { indexes, dots, total } = props;
  const transformDots = () => {
    if (total < 5) return 0;
    if (indexes.new < 3) return 0;
    const width = 12;

    const lasts = [total, total - 1];

    // Fix if infinite transition
    if (indexes.old === 0 && indexes.new === total) {
      // setDisableTransition({ transition: "" });
    }
    if (indexes.old === 0 && lasts.includes(indexes.new)) {
      return total * width - width * 4;
    }

    if (lasts.includes(indexes.new)) {
      return indexes.new * width - width * total - indexes.new;
    }

    return indexes.new * width - width * 2;
  };
  const transform = transformDots();

  const hasFew = total < 5;

  return (
    <div className="slick__dots-wrapper">
      <div className="slick__dots-content">
        <div className="slick__dots-bg" />
        <div className="slick__dots-holder">
          <ul
            className="slick__dots"
            style={{
              transform: `translate3d(-${transform}px, 0, 0)`,
              position: hasFew ? "unset" : "",
              width: hasFew ? "auto" : "",
            }}
          >
            {dots}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dots;
