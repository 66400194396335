const handleMonthSelect = (item, isSelected, state, months) => {
  months = [...months];

  // Do not remove the last item
  if (months.length === 1 && isSelected) return;

  if (isSelected) {
    // remove selected month
    months = months.filter((i) => i.value !== item.value);
  } else {
    // or add
    months.push(item);
  }
  state((s) => ({
    type: s.type,
    months: months,
  }));
};
export default handleMonthSelect;
