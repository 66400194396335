import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

export const DEFAULT_DEBOUNCE_AMOUNT = 500; //ms

const useWindowSize = (debounceAmount = DEFAULT_DEBOUNCE_AMOUNT) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };
    const debouncedHandleResize = debounce(handleResize, debounceAmount);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [debounceAmount]);

  return { width: size.width, height: size.height };
};
export default useWindowSize;
