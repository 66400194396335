import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import createUrlParams from "../functions/createUrlParams";
import _ from "lodash";

const invalids = ["null", "", "undefined"];

const debounceAmount = 800;
const debouncedUpdate = _.debounce((callback) => callback(), debounceAmount);

const useUpdateFilters = (newParams, scrolling, allow, debounced) => {
  const history = useHistory();

  useEffect(() => {
    if (!allow) return;

    const search = history.location.search;
    const filters = {};
    search
      .substring(1) // remove ?
      .split("&")
      .forEach((param) => {
        const split = param.split("=");
        const key = split[0];
        const val = split[1];
        if (!key || !val || invalids.includes(val)) return;

        filters[key] = val;
      });

    const newFilters = { ...filters };

    // Get new params
    newParams.split("&").forEach((param) => {
      const split = param.split("=");
      const key = split[0];
      const val = split[1];
      if (!val || invalids.includes(val)) {
        delete newFilters[key];
        return;
      }
      newFilters[key] = val;
    });

    // Check if the old query is the same with new
    const oldQuery = createUrlParams(filters);
    const newQuery = createUrlParams(newFilters);

    if (oldQuery === newQuery) return;

    // Scroll to top
    if (scrolling !== "no-scroll") {
      window.scrollTo(0, 0);
    }

    // Update url
    const historyOption = {
      pathname: history.location.pathname,
      search: "?" + newQuery,
    };

    if (debounced) {
      debouncedUpdate(() => {
        history.push(historyOption);
      });
    } else {
      history.push(historyOption);
    }
  }, [history, newParams, scrolling, allow, debounced]);
};

export default useUpdateFilters;
