import React, { useCallback, useEffect, useRef } from "react";
import ClockIcon from "./icons/timer.svg";
// Translation Package
import { useTranslation } from "react-i18next";

const TOTAL_HOLD_SECONDS = 20 * 60;

const CountDown = ({ coin, setIsRateExpired }) => {
  // Translation hook
  const { t } = useTranslation();

  const expiryInterval = useRef(null);

  /**
   * Formats clock
   */
  const formatClock = useCallback((value) => {
    let clockMinutes = Math.floor(value / 60) + "";
    let clockSeconds = (value % 60) + "";
    return `${clockMinutes.length === 2 ? clockMinutes : `0${clockMinutes}`}:${
      clockSeconds.length === 2 ? clockSeconds : `0${clockSeconds}`
    }`;
  }, []);

  useEffect(() => {
    let counter = TOTAL_HOLD_SECONDS;

    if (expiryInterval.current !== null) {
      clearInterval(expiryInterval.current);
    }
    expiryInterval.current = setInterval(() => {
      if (counter === TOTAL_HOLD_SECONDS) {
        document.querySelector("#counter-container").style.display = "inline";
        document.querySelector("#counter-expired").style.display = "none";
      }
      counter -= 1;
      if (counter === 0) {
        clearInterval(expiryInterval.current);
        setIsRateExpired(true);
        document.querySelector("#counter-container").style.display = "none";
        document.querySelector("#counter-expired").style.display = "inline";
      }
      document.querySelector("#counter").innerHTML = formatClock(counter);
    }, 1000);
    return () => {
      clearInterval(expiryInterval.current);
    };
  }, [coin, formatClock, expiryInterval, setIsRateExpired]);

  return (
    <div className="d-inline-flex flex-row gap-2">
      <img src={ClockIcon} style={{ width: "20px" }} alt={"Refreshes in"} />
      <span id={"counter-container"}>
        {t("crypto_quote_disclaimer")}{" "}
        <span id={"counter"}>{formatClock(TOTAL_HOLD_SECONDS)}</span>
      </span>
      <span id={"counter-expired"} style={{ color: "red", display: "none" }}>
        {t("crypto_quote_expired")}
      </span>
    </div>
  );
};

export default React.memo(CountDown);
