import React, { useState, useEffect, useRef } from "react";

const ToolTip = (props) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const [position, setPosition] = useState(24);

  useEffect(() => {
    if (wrapperRef.current) {
      setPosition(wrapperRef?.current?.clientHeight ?? 24);
    }

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const showTooltip = () => {
    setShow(!show);
  };

  return (
    <div
      ref={wrapperRef}
      className="d-inline-flex flex-row align-items-center position-relative"
      onClick={showTooltip}
    >
      {show && (
        <p
          className="lh-sm position-absolute start-0 end-0 p-2 border rounded shadow"
          style={{
            fontWeight: "normal",
            transform: `translate(0,-${position}px)`,
            transformOrigin: "50% 100% 0",
            background: "#fff",
            maxWidth: "300px",
            bottom: 0,
            zIndex: 10,
            ...props.style
          }}
        >
          <small>{props.text}</small>
          <svg
            height="12"
            width="12"
            className="position-absolute top-100 start-50"
            style={{ transform: "translateY(-1px)" }}
          >
            <polygon
              className="border"
              points="0,0 6,12 12,0"
              style={{ fill: "white" }}
            />
          </svg>
        </p>
      )}
      <div className="w-100 h-auto">{props.children}</div>
    </div>
  );
};

export default ToolTip;
