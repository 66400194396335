import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Step from "./Step";
import "./index.scss";

/**
 * StepForm Component
 *
 * A form component that displays a series of steps and allows navigation between them.
 *
 * Props:
 * - steps: An array of step names or titles.
 * - initialStep: The initial step index to start from.
 * - children: A render prop function that provides the current step's content.
 * - onNextStep: Callback function invoked when moving to the next step.
 * - onPrevStep: Callback function invoked when moving to the previous step.
 *
 * children example:
 * - {({ currentStep, goToNextStep, goToPreviousStep, goToStep }) => (
 * -    <>
 * -       {currentStep === 0 && (<Step1 />)}
 * -       {currentStep === 1 && (<Step2 />)}
 * -       {currentStep === 2 && (<Step3 />)}
 * -    </>
 */
export default function StepForm({
  steps,
  initialStep,
  children,
  onNextStep,
  onPrevStep,
  getCurrentStep,
}) {
  // State to keep track of the current active step
  const [currentStep, setCurrentStep] = useState(initialStep);
  /**
   * Memoized computation of step statuses based on the current step.
   * Step statuses can be 'complete', 'active', or 'inactive'.
   */
  const stepsStatuses = useMemo(() => {
    return steps.map((_, index) => {
      if (index < currentStep) return "complete";
      if (index === currentStep) return "active";
      return "inactive";
    });
  }, [currentStep, steps]);

  // scroll user to top when navigating the steps
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  /**
   * Handler to proceed to the next step.
   * Increments the current step and calls onNextStep callback if provided.
   */
  const goToNextStep = useCallback(() => {
    if (currentStep < steps.length - 1) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
      scrollToTop();
      if (onNextStep) onNextStep(newStep);
    }
  }, [currentStep, steps.length, onNextStep]);

  /**
   * Handler to go back to the previous step.
   * Decrements the current step and calls onPrevStep callback if provided.
   */
  const goToPreviousStep = useCallback(() => {
    if (currentStep > 0) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
      scrollToTop();
      if (onPrevStep) onPrevStep(newStep);
    }
  }, [currentStep, onPrevStep]);

  /**
   * Handler to g to a specific step.
   * Set the currentStep to be the new step .
   */
  const goToStep = useCallback((stepNumber) => {
    setCurrentStep(stepNumber);
    scrollToTop();
  }, []);

  useEffect(() => {
    getCurrentStep(currentStep);
  }, [currentStep, getCurrentStep]);

  /**
   * Renders the content for the current step using the render prop pattern.
   * Expects 'children' to be a function that accepts an object with navigation handlers.
   */
  const renderContent = useMemo(() => {
    if (typeof children === "function") {
      return children({
        currentStep,
        goToNextStep,
        goToPreviousStep,
        goToStep,
      });
    }
    return null;
  }, [children, currentStep, goToNextStep, goToPreviousStep, goToStep]);

  return (
    <div className="step-form">
      <section className="">
        {/* Steps Navigation */}
        <div className="step-form__steps-container ">
          {steps.map((step, index) => (
            <React.Fragment key={step?.key ?? "" + index}>
              {/* Individual Step */}
              <Step
                stepNumber={index + 1}
                stepName={step?.title ?? step}
                status={stepsStatuses[index]}
              />
              {/* Separator between steps, omitted after the last step */}
              {index < steps.length - 1 && (
                <div className="step-form__separator" />
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Content Area for the Current Step */}
        <div className="step-form__content">{renderContent}</div>
      </section>
    </div>
  );
}

// PropTypes for type checking and documentation
StepForm.propTypes = {
  steps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  ]).isRequired,
  initialStep: PropTypes.number,
  children: PropTypes.func.isRequired,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
  getCurrentStep: PropTypes.func,
};

// Default values for props
StepForm.defaultProps = {
  initialStep: 0,
  steps: [],
  onNextStep: undefined,
  onPrevStep: undefined,
  getCurrentStep: undefined,
};
