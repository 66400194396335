import React from "react";

const MarkerSvg = () => {
  return (
    <svg
      width="84"
      height="86"
      viewBox="0 0 84 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M42.0001 7.16663C28.1469 7.16663 16.9167 18.3968 16.9167 32.25C16.9167 46.4615 32.6977 67.4383 39.2266 75.5044C40.6599 77.2745 43.3402 77.2745 44.7736 75.5044C51.3024 67.4383 67.0834 46.4615 67.0834 32.25C67.0834 18.3968 55.8532 7.16663 42.0001 7.16663Z"
          fill="#4D707D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.916748"
          y="-8.83337"
          width="82.1667"
          height="101.665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MarkerSvg;
