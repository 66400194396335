import { useLocation } from "react-router-dom";

import BookingPayment from "./index";
import { setCookie } from "Providers/CookieConsentProvider";

import { PAYMENT_CONTEXT } from "Constants/globals";

const BookingPaymentForMobileApp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  setCookie(true);
  const token = searchParams.get("token");
  if (token) {
    window.localStorage.setItem("token", token);
  }

  return <BookingPayment context={PAYMENT_CONTEXT.MOBILE_APP} />;
};

export default BookingPaymentForMobileApp;
