import React from "react";
const HouseSvg = () => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7194 23.2389H16.9894C16.2636 23.2389 15.6755 22.6508 15.6755 21.925V17.195C15.6755 16.6142 15.2051 16.1439 14.6244 16.1439H12.5222C11.9414 16.1439 11.4711 16.6142 11.4711 17.195V21.925C11.4711 22.6508 10.883 23.2389 10.1572 23.2389H5.42717C4.70138 23.2389 4.11328 22.6508 4.11328 21.925V11.8937C4.11328 10.6854 4.66722 9.54393 5.61585 8.79659L13.085 2.91089C13.3715 2.68595 13.7751 2.68595 14.061 2.91089L21.5312 8.79659C22.4799 9.54393 23.0333 10.6849 23.0333 11.8926V21.925C23.0333 22.6508 22.4452 23.2389 21.7194 23.2389Z"
        fill="white"
      />
    </svg>
  );
};

export default HouseSvg;
