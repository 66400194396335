import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import QUERIES from "../../../../Constants/Queries";

const useCities = () => {
  const { data, loading, error } = useQuery(QUERIES.COM_ENUMS);

  const [states, setStates] = useState({
    cities: [],
    loading: true,
  });

  useEffect(() => {
    setStates({
      cities: loading || error ? [] : data.result.cities,
      loading: loading || error,
    });
  }, [data, loading, error]);

  return {
    cities: states.cities,
    loading: states.loading,
  };
};

export default useCities;
