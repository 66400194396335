import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { Portal } from "react-portal";

// Components
import GoogleMap from "./GoogleMap";

// Hooks
import useMapLocation from "../hooks/useMapLocation";
import useMapMarkers from "../hooks/useMapMarkers";

// Constants
import { DEFAULT_MARKER_STATES } from "./map_constants";
import ConditionalWrapper from "../../../Utils/ConditionalWrapper";
import { getMapZoomLevelByCity } from "Components/Booking/Search/functions/getMapZoomByCity.js";

const SearchMap = (props) => {
  const {
    map,
    toggleMap,
    data,
    city,
    loading,
    error,
    markerStates,
    setMarkerStates,
    isMobile,
  } = props;

  // Map settings
  const mapLocation = useMapLocation(data, city);
  const mapMarkers = useMapMarkers(data, markerStates, isMobile);

  // JSX Styling
  const mapClass = map ? "show" : "hide";

  // Swiper
  const cardRef = useRef();
  const [cardHeight, setCardHeight] = useState(5000);
  useEffect(() => {
    setCardHeight(cardRef.current.clientHeight);
  }, [cardRef]);

  const [{ y }, springApi] = useSpring(() => ({ y: cardHeight }));
  const open = () => {
    springApi.start({
      y: 0,
      immediate: false,
      config: config.stiff,
    });
  };
  const close = (velocity = 0) => {
    setTimeout(() => {
      toggleMap();
    }, 200);

    springApi.start({
      y: cardHeight,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };
  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position

      if (my < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        my > cardHeight * 0.5 || vy > 0.5 ? close(vy) : open();
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else {
        springApi.start({ y: my, immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  // Open on click
  useEffect(() => {
    if (isMobile && map) {
      springApi.start({
        y: 0,
        immediate: false,
        config: config.default,
      });
    }
  }, [isMobile, springApi, map]);

  // Force close if map closed
  useEffect(() => {
    if (!map) {
      springApi.start({
        y: cardHeight,
        immediate: false,
        config: { ...config.default },
      });
    }
  }, [springApi, y, cardHeight, map, isMobile]);

  const handleMarkerOnClick = (item, index) => {
    setMarkerStates((s) => {
      // If clicked again, remove states
      if (s.selected.id === item.id) return DEFAULT_MARKER_STATES.LISTING;

      return {
        selected: { index: index, id: item.id },
        hovered: s.hovered,
      };
    });
  };

  const bgStyle = {
    opacity: y.to([0, cardHeight], [0.4, 0], "clamp"),
  };

  const mapHolderStyle = isMobile ? { y } : {};

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children) => <Portal>{children}</Portal>}
    >
      <animated.div
        className={`search_page__map-area ${mapClass}`}
        style={{ bottom: isMobile && map ? "0" : "" }}
        ref={cardRef}
      >
        <animated.div className="map-bg" style={bgStyle} />
        <animated.div className="map-holder" style={mapHolderStyle}>
          <div
            className="map-swiper"
            style={{ cursor: "grab", touchAction: "pan-x" }}
            {...bind()}
          >
            <div className="handle">
              <div className="closer"></div>
            </div>
          </div>

          <div className="map-container">
            <GoogleMap
              key={city ?? 'default'}
              isMobile={isMobile}
              location={mapLocation}
              loading={loading}
              error={error}
              mobileMap={map}
              intactListingData={data}
              markers={mapMarkers}
              markerStates={markerStates}
              setMarkerStates={setMarkerStates}
              handleMarkerOnClick={handleMarkerOnClick}
              isMapOpen={map}
              toggleMap={toggleMap}
              defaultZoom={getMapZoomLevelByCity(city)}
            />
          </div>
        </animated.div>
      </animated.div>
    </ConditionalWrapper>
  );
};

export default SearchMap;
