import { useState, useEffect } from "react";

import QUERIES from "../../../../../Constants/Queries";

import { useLazyQuery } from "@apollo/client";
import { PAYMENT_METHOD } from "Constants/globals";

const useQuotationData = (dates, id) => {
  const [data, setData] = useState(null);
  const [getPriceQuotation, fetch] = useLazyQuery(
    QUERIES.PROPERTIES.PRICE_QUOTATION,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (!dates.start || !dates.end) {
      setData(null);
      return;
    }

    getPriceQuotation({
      variables: {
        payload: {
          propertyID: id,
          startDate: dates.start,
          endDate: dates.end,
          paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
        },
      },
    });
  }, [dates, getPriceQuotation, id]);

  useEffect(() => {
    if (fetch.loading) return;
    setData(fetch.data?.result);
  }, [fetch]);

  const loading = fetch.loading;

  const error =
    !loading &&
    (data?.violatesLOS === true || data?.isRangeAvailable === false);

  return {
    quotationData: data,
    quotationLoading: loading,
    quotationError: error,
  };
};

export default useQuotationData;
