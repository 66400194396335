import React, { useState, createContext, useContext } from "react";
import { MODALS as MODAL_CONSTANTS } from "../Constants/Modals";
const ModalContext = createContext();
const ModalUpdateContext = createContext();

export const MODALS = MODAL_CONSTANTS;

export const useModal = () => useContext(ModalContext);
export const useUpdateModal = () => useContext(ModalUpdateContext);
export const DEFAULT_STATE = { modal: null, props: null };

const FloatingModalProvider = (props) => {
  const { children } = props;

  const [currentModal, setCurrentModal] = useState(DEFAULT_STATE);
  const updateModal = ({ modal, props }) =>
    setCurrentModal({ modal: modal, props: props });

  return (
    <ModalContext.Provider value={currentModal}>
      <ModalUpdateContext.Provider value={updateModal}>
        {children}
      </ModalUpdateContext.Provider>
    </ModalContext.Provider>
  );
};

export default FloatingModalProvider;
