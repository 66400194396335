const detectClusterHover = (markers, leaves) => {
  if (!leaves) return false;

  const hovered = markers.find((m) => m.hovered);
  if (hovered === undefined) return false;

  const found = leaves.find((leaf) => leaf.marker.data.id === hovered.id);
  if (found === undefined) return false;

  return true;
};
export default detectClusterHover;
