import React from "react";
import moment from "moment";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

const CalendarDay = ({
  day,
  availabilityOfDates,
  startDate,
  endDate,
  thisDayConfig,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const startDateSelected =
    startDate && !endDate ? "custom_calendar_day--only_start_selected" : "";

  const isCheckoutOnly =
    !startDate && thisDayConfig?.checkOutOnly ? "checkout_only" : null;

  const losNoticeTranslationKey =
    thisDayConfig?.losDay?.los === 1
      ? "los_notice_label_singular"
      : "los_notice_label";

  const losNoticeLabel = 
      <Trans
        i18nKey={losNoticeTranslationKey}
        values={{ availabilityDataLos: thisDayConfig?.losDay?.los || 0 }}
      />

  return (
    <div
      className={[
        `custom_calendar_day`,
        isCheckoutOnly,
        startDateSelected,
      ].join(" ")}
      data-test-id={day.format("YYYY-MM-DD")}
    >
      <div className="day">
        {moment(day).format("DD")}
        {thisDayConfig && (
          <span className="los">
            {thisDayConfig?.checkOutOnly
              ? t("checkout_date_label")
              : losNoticeLabel}
          </span>
        )}
      </div>
    </div>
  );
};
export default CalendarDay;
