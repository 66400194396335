import React from "react";
import firebase from "firebase/app";

import { Portal } from "react-portal";
import * as ROUTES from "Constants/Routes";

import { AUTH_TITLES } from "../../Constants/globals";

// Translation Package
import { useTranslation } from "react-i18next";

const LogoutModal = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { toggle } = props;

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    window.location.href = ROUTES.HOME;
  };

  return (
    <Portal>
      <div className="logout-modal">
        <div className="content_holder">
          <div className="modal_content">
            <h2 className="title">{t("log_out_confirmation")}</h2>
            <p className="descriptison">{t("log_out_message")}</p>
            <div className="buttons">
              <button onClick={toggle} className="btn cancel">
                {t("cancel")}
              </button>
              <button onClick={logout} className="btn log-out">
                {t(AUTH_TITLES.LOG_OUT)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default LogoutModal;
