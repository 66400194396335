import React, { useState } from "react";

import { useUser } from "../../../../Providers/UserProvider";

import MobileGroup from "./Group";
import LogoutModal from "../../../Modals/LogoutModal";

// Translation Package
import { useTranslation } from "react-i18next";

import {
  ABOUT_ITEMS_MOBILE,
  CORPORATE_ITEMS,
  USER_ITEMS,
  GUEST_ITEMS,
  DOWNLOAD_ITEMS,
  LEGAL_ITEMS,
  SOCIAL_MEDIA_ITEMS,
} from "../items";

const TopbarMobileContent = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { user, loading } = useUser();

  const accountItems = user ? USER_ITEMS : GUEST_ITEMS;

  const groups = {
    account: {
      title: t("account"),
      items: loading ? [] : accountItems,
      className: "",
    },
    about: {
      title: t("nav_menu_about"),
      items: ABOUT_ITEMS_MOBILE,
      className: "small-group",
    },
    corporate: {
      title: t("nav_menu_corporate"),
      items: CORPORATE_ITEMS,
      className: "small-group",
    },
    download: {
      title: t("download"),
      items: DOWNLOAD_ITEMS,
      className: "small-group",
    },
    follow: {
      title: t("follow_us"),
      items: SOCIAL_MEDIA_ITEMS,
      className: "small-group",
    },
  };

  const [logoutModalShown, setLogoutModal] = useState(false);
  const toggleLogoutModal = () => !setLogoutModal(!logoutModalShown);

  return (
    <div className="topbar__mobile-content">
      <div className="main-content">
        <MobileGroup group={groups.account} toggleLogout={toggleLogoutModal} />
        <div className="row">
          <div className="col-12 col-sm-6 order-sm-2">
            <MobileGroup group={groups.about} />
          </div>
          <div className="col-12 col-sm-6 order-sm-2">
            <MobileGroup group={groups.corporate} />
          </div>
          <div className="col-12 col-sm-6">
            <div className="row">
              <div className="col-12">
                <MobileGroup group={groups.download} />
              </div>
              <div className="col-12">
                <MobileGroup group={groups.follow} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-content">
        <div className="bottom-group legals">
          {LEGAL_ITEMS.map((legal) => (
            <a
              key={legal.link}
              className="bottom-group__link legals__link"
              href={legal.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(legal.title)}
            </a>
          ))}
        </div>
      </div>
      {logoutModalShown && <LogoutModal toggle={toggleLogoutModal} />}
    </div>
  );
};

export default TopbarMobileContent;
