import { useState, useEffect } from "react";
import toMoney from "../../../Utils/functions/toMoney";

const useMapMarkers = (data, markerStates) => {
  const [mapMarkers, setMapMarkers] = useState([]);

  useEffect(() => {
    if (!data) {
      setMapMarkers([]);
      return;
    }

    const markers = data
      //Filter duplicates
      .filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id)
      )
      .map((item) => {
        const price = toMoney(
          item.avgPrice || item.basePrice,
          item.displayCurrency,
          0
        );

        return {
          id: item.id,
          label: price,
          location: item.location,
          hovered: markerStates.hovered.id === item.id,
          selected: markerStates.selected.id === item.id,
          data: item,
          clickOrigin: "mapPin",
        };
      });

    setMapMarkers(markers);
  }, [data, markerStates]);

  return mapMarkers;
};
export default useMapMarkers;
