import React from "react";
import PropTypes from "prop-types";
import Tooltip from "Components/Utils/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

/**
 * SDCard Component
 *
 * Displays the security deposit information in a card format, including the amount and due date.
 *
 * @param {Object} props - Component properties.
 * @param {string|number} props.amount - The amount of the security deposit.
 * @param {boolean} props.show - Determines if the card is visible.
 */
export default function SDCard({ amount, show }) {
  // Translation hook
  const { t } = useTranslation();

  return (
    <section
      className={`summary-card__container mt-3 mx-auto ${show ? "" : "d-none"}`}
    >
      <div className="p_4">
        <dl>
          <dt className="mb-2 d-flex align-items-center justify-content-between">
            <div>
              {/* Tooltip providing additional information about the security deposit */}
              <Tooltip text={t("refundable_security_deposit_desc")}>
                <div
                  className="d-flex flex-row align-items-center"
                  role="button"
                >
                  <span className="summary-card__info__title">
                    {t("refundable_security_deposit_title")}
                  </span>
                  {/* Information icon */}
                  <BiInfoCircle
                    fill="#576f76"
                    className="icon mx-1"
                    size="17px"
                  />
                </div>
              </Tooltip>
            </div>
            <div>{amount}</div>
          </dt>
          <dd className="summary-card__info__subtitle"></dd>
        </dl>
      </div>

      {/* SD Amount */}
      {/* <hr className="p-0 m-0" />
      <div className="p_4 d-flex align-items-center justify-content-between summary-card__info__title">
        <div>{t("total")}</div>
        <div>{amount}</div>
      </div> */}
    </section>
  );
}

// Define PropTypes for type-checking
SDCard.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

// Define default props
SDCard.defaultProps = {
  amount: 0,
  show: false,
};
