import React from "react";
import PrimaryButton from "../../Buttons/Primary";

// Translation Package
import { useTranslation } from "react-i18next";

const Footer = ({ onClear, onClose, compact, isMobile, onApply }) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div className="custom_datepicker__footer">
      <button
        className="custom_datepicker__button button button__clear"
        type="button"
        onClick={onClear}
      >
        {t("clear")}
      </button>

      <div className="right-buttons gap-4">
        <button
          className="custom_datepicker__button button button__close text-close"
          type="button"
          onClick={onClose}
          hidden={!compact || isMobile}
        >
          {t("close")}
        </button>
        <PrimaryButton
          type="button"
          onClick={onApply}
          circular={true}
          label={t("apply_dates")}
          className="custom_datepicker__button button__apply"
        />
      </div>
    </div>
  );
};
export default Footer;
