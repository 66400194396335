/**
 * Next.js will not be required!
 *
 * https://support.google.com/webmasters/thread/15430775?hl=en
 *
 */
import React, { Suspense } from "react";
import LoadingLogoFullScreen from "Components/Utils/LoadingLogoFullScreen";

import ReactDOM from "react-dom";

import "./i18n";
import "./App.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

/**  Enable Sentry for error reporting **/
import "./lib/sentry";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    {/* Added Suspense for i18n loading */}
    <Suspense fallback={<LoadingLogoFullScreen />}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
