import moment from "moment";

import isInclusivelyAfterDay from "react-dates/src/utils/isInclusivelyAfterDay";

import { START_DATE, END_DATE } from "react-dates/constants";
import { FILTERS, FLEXIBLE_TYPES } from "../../Booking/Search/search_constants";

export const DEFAULT_PROPS = {
  hideKeyboardShortcutsPanel: true,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
};

export const DATE_FORMAT = "YYYY-MM-DD";

export const FOCUS_KEYS = {
  start: START_DATE,
  end: END_DATE,
};

export const DEFAULT_DATES = {
  start: null,
  end: null,
};

const createFlexibleMonthList = () => {
  // Force eng locale on momentjs, months names are translated directly by Stella API
  const today = moment().locale("en");
  const months = [
    {
      value: today.format("YYYY-MM"),
      label: today.format("MMMM"),
      year: today.format("YYYY"),
    },
  ];
  for (let i = 1; i < 12; i++) {
    // Force eng locale on momentjs, months names are translated directly by Stella API
    const month = moment().locale("en").add(i, "months");
    months.push({
      value: month.format("YYYY-MM"),
      label: month.format("MMMM"),
      year: month.format("YYYY"),
    });
  }

  return months;
};
export const FLEXIBLE_MONTH_LIST = createFlexibleMonthList();

export const DEFAULT_FLEXIBLE = {
  type: FLEXIBLE_TYPES.weekend,
  months: [FLEXIBLE_MONTH_LIST[0]],
};

export const PARAM_KEYS = {
  start: FILTERS.startDate.paramKey,
  end: FILTERS.endDate.paramKey,
};
