import React from "react";

import { CiBed } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";

import groupObjectsBy from "../../../Utils/functions/groupObjectsBy";

// Translation Package
import { useTranslation } from "react-i18next";

const SleepingArrangements = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  if (!suite.sleepingArrangements || suite.sleepingArrangements.length === 0)
    return <></>;

  const groupedRooms = groupObjectsBy(
    suite.sleepingArrangements,
    "bedroomMapping"
  );

  return (
    <div className="suite__sleeping-arrangements" id="sleeping-arrangements">
      <div className="suite__section sleeping-arrangements__section">
        <SectionDivider className="mb-4 mt-2" />
        <SectionTitle
          icon={<CiBed size={22} />}
          title={t("sleeping_arrangements")}
        />
        <div className="section__content sleeping-arrangements__content">
          <div className="content__items d-flex align-items-start flex-wrap">
            {groupedRooms.map((room, index) => {
              return <Room room={room} bedroomIndex={index} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const getBedName = (bedType, bedCount) => {
  let bedName = bedType?.split(" ").join("_").toLowerCase();
  if (bedCount > 1) bedName = bedName + "s";
  return bedName;
};

const Room = ({ room, bedroomIndex }) => {
  //Translation hook
  const { t } = useTranslation();

  return (
    <div className="sleeping__room">
      <div className="room__index">
        {t("bedroom")} {bedroomIndex + 1}
      </div>
      <div className="room__beds">
        {room.map((bed, index) => {
          const bedName = `${bed.bedCount} ${t(
            getBedName(bed.bedType, bed.bedCount)
          )}`;
          return (
            <div className="bed__title" key={index}>
              {bedName}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SleepingArrangements;
