import React from "react";

import { BsChevronDown } from "react-icons/bs";

import useFilters from "../hooks/useFilters";
import { FILTERS, SEARCH_TYPES } from "../search_constants";
import useButtonLabel from "../hooks/useButtonLabel";
import { CiSearch } from "../../../../CustomIcons";
import composeJsxClass from "../../../Utils/functions/composeJsxClass";

// Translation Package
import { useTranslation } from "react-i18next";

const FiltersButton = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { onClick, topbarButton, isFilterBoxOpen, hideTemporarily } = props;
  const filters = useFilters();

  const labels = {
    city: t("city"),
    dates:
      filters[FILTERS.searchType.paramKey] === SEARCH_TYPES.flexible
        ? `${t("flexible")} ${t("dates")}`
        : t("dates"),
    guests: `${filters.guests}${filters.guests === "10" ? "+" : ""} ${
      filters.guests > 1 ? t(`guests_counter`) : t("guest")
    }`,
  };

  const buttonLabel = useButtonLabel();
  const emptyData = {
    city: filters.city === "",
    dates: buttonLabel === t("select_dates"),
  };

  const buttonClass = composeJsxClass("filters-button", {
    hidden: isFilterBoxOpen || hideTemporarily,
    basic: topbarButton,
  });

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      tabIndex={isFilterBoxOpen ? "-1" : ""}
    >
      {topbarButton ? (
        <>
          <CiSearch className="icon mx-1" size={20} />
          <span className="text">{t("find_a_stella")}</span>
        </>
      ) : (
        <>
          <div className="location section">
            <span className="title">{labels.city.toUpperCase()}</span>
            <span className={`data ${emptyData.city ? "data--empty" : ""}`}>
              {t(filters?.city.toLowerCase())}
            </span>
          </div>
          <div className="right">
            <div className="point" />
            <div className="dates section">
              <span className="title">{labels.dates.toUpperCase()}</span>
              <span className={`data ${emptyData.dates ? "data--empty" : ""}`}>
                {t(buttonLabel)}
              </span>
            </div>

            <div className="point" />
            <div className="guests section">
              <span className="title" />
              <span className={`data ${emptyData.dates ? "data--empty" : ""}`}>
                {labels.guests}
              </span>
            </div>

            <div className="chevron">
              <BsChevronDown className="icon" />
            </div>
          </div>
        </>
      )}
    </button>
  );
};
export default FiltersButton;
