import React from "react";

const scaleDot = (dot, index, total) => {
  const big = 1.1;
  const mid = 0.85;
  const sm = 0.65;

  if (index === dot) return big;
  if (index === 0 || index === 1) {
    let bigs = [1, 2];
    if (bigs.includes(dot)) return big;
    if (dot === 3) return mid;
    if (dot === 4) return sm;
  }

  if (index === total || index === total - 1) {
    let bigs = [total, total - 1, total - 2];
    if (bigs.includes(dot)) return big;
    if (dot === total - 3) return mid;
    if (dot === total - 4) return sm;
  }

  if (index - 1 === dot || index + 1 === dot) return mid;
  if (index - 2 === dot || index + 2 === dot) return sm;

  return 0;
};

const Dot = (props) => {
  const { dot, index, total } = props;

  const scale = scaleDot(dot, index, total);

  return (
    <div
      className={`slick__dot ${index === dot ? "active" : ""}`}
      style={{
        transform: `scale(${scale})`,
      }}
      onClick={props.onClick}
    />
  );
};

export default Dot;
