import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * InvoiceDetailsCard Component
 *
 * Displays invoice details such as the number of nights stayed,
 * property name, and the total amount.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Determines if the card is visible.
 * @param {string|number} props.amount - Total amount for the stay.
 * @param {string} props.description - stay description.
 */
export default function InvoiceDetailsCard({ show, description, amount }) {
  // Translation hook
  const { t } = useTranslation();

  return (
    <section
      className={`summary-card__container mt-3 mx-auto ${show ? "" : "d-none"}`}
    >
      {/* Invoice Description */}
      <div className="p_4">
        <dl className="m-0">
          <dt className="mb-2 summary-card__info__title">
            {t("invoice_description")}
          </dt>
          <dd className="summary-card__info__subtitle">{description}</dd>
        </dl>
      </div>

      <hr className="p-0 m-0" />

      {/* Total Amount */}
      <div className="p_4 d-flex align-items-center justify-content-between summary-card__info__title">
        <div>{t("total_amount")}</div>
        <div>{amount}</div>
      </div>
    </section>
  );
}

// Define PropTypes
InvoiceDetailsCard.propTypes = {
  show: PropTypes.bool.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
};

// Define default props
InvoiceDetailsCard.defaultProps = {
  show: false,
  amount: 0,
  propertyName: "",
};
