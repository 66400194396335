import React from "react";
import { Link } from "react-router-dom";

// Translation Package
import { useTranslation } from "react-i18next";

const MobileGroup = ({ group, toggleLogout }) => {
  // Translation hook
  const { t } = useTranslation();

  const titleClass = `${group.title.replace(" ", "_").toLowerCase()}-group`;

  return (
    <div
      className={`group ${titleClass} ${group.className || ""}`}
      key={group.title}
    >
      <span className="group__title">{group.title}</span>
      <div className="group__items">
        {group.items.map((item) => {
          if (item?.functionKey === "logout") {
            return (
              <button
                className="group__item"
                onClick={() => toggleLogout()}
                key={item.title}
              >
                {t(item.title)}
                {item.icon && <span>{<item.icon />}</span>}
              </button>
            );
          }

          if (item?.external) {
            return (
              <a
                className="group__item"
                href={item.link}
                key={item.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(item.title)}
                {item.icon && <span>{<item.icon />}</span>}
              </a>
            );
          }

          return (
            <Link
              className="group__item"
              to={item.link}
              key={item.title}
              {...item?.linkProps}
            >
              {t(item.title)}
              {item.icon && <span>{<item.icon />}</span>}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MobileGroup;
