import { getScreenOffset } from "./geo-utils";

import { CLUSTER_ZOOM_THRESHOLD } from "../constants";

const zoomAt = (map, pt, zoom) => {
  const center = map.getCenter();
  const mapCurrentZoom = map.getZoom();
  const centerLatLng = {
    lat: center.lat(),
    lng: center.lng(),
  };

  const zoomTo =
    mapCurrentZoom > CLUSTER_ZOOM_THRESHOLD
      ? mapCurrentZoom
      : zoom >= CLUSTER_ZOOM_THRESHOLD
      ? CLUSTER_ZOOM_THRESHOLD
      : zoom;

  const offsetA = getScreenOffset(pt, centerLatLng, mapCurrentZoom);
  const offsetB = getScreenOffset(pt, centerLatLng, zoomTo);
  const x = offsetA.x - offsetB.x;
  const y = offsetA.y - offsetB.y;

  map.setZoom(zoomTo);
  map.panBy(x, y);

  return zoomTo;
};

export default zoomAt;
