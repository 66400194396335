import { useState, useEffect } from "react";
import getMapBounds from "../../../../Utils/GoogleMap/getMapBounds";

const POS = {
  DOWN: "down",
  UP: "up",
};

const useCardPosition = (map, pointCoordinates) => {
  const [pos, setPos] = useState(POS.DOWN);

  useEffect(() => {
    const rawBounds = getMapBounds(map);
    const bounds = {
      left: rawBounds[0],
      bottom: rawBounds[1],
      right: rawBounds[2],
      top: rawBounds[3],
    };

    const measures = {
      pointToLeft: Math.abs(pointCoordinates.lng - bounds.left),
      pointToBottom: Math.abs(bounds.bottom - pointCoordinates.lat),
      pointToRight: Math.abs(bounds.right - pointCoordinates.lng),
      pointToTop: Math.abs(pointCoordinates.lat - bounds.top),
    };

    if (measures.pointToTop >= measures.pointToBottom) {
      setPos(POS.DOWN);
    } else {
      setPos(POS.UP);
    }
  }, [map, pointCoordinates]);

  return pos;
};
export default useCardPosition;
