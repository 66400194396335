import { useEffect } from "react";

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let moved;

    const downListener = () => {
      moved = false;
    };

    const moveListener = () => {
      moved = true;
    };

    const upListener = (e) => {
      if (moved) return;
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      handler();
    };

    document.addEventListener("mousedown", downListener);
    document.addEventListener("mousemove", moveListener);
    document.addEventListener("mouseup", upListener);

    return () => {
      document.removeEventListener("mousedown", downListener);
      document.removeEventListener("mousemove", moveListener);
      document.removeEventListener("mouseup", upListener);
    };
  }, [ref, handler]);
};

export default useClickOutside;
