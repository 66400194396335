const createFlexibleMonthTitle = (months, t) => {
  // t is Translation hook passed from parent component
  const sorted = months.sort((a, b) => a.value.localeCompare(b.value));
  const labels = sorted.map((month) => t(month.label.toLowerCase()));

  if (labels.length > 3) {
    return labels.slice(0, 3).join(", ") + "...";
  } else {
    return labels.join(", ");
  }
};
export default createFlexibleMonthTitle;
