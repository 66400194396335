import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18n_TYPES } from "../Constants/i18n";

const LanguageDetectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "localStorage", "fallback"],

  // keys or params to lookup language from
  lookupLocalStorage: i18n_TYPES.lookupLocalStorage,
  lookupQuerystring: i18n_TYPES.lookupQuerystring,

  // cache user language on
  caches: ["localStorage"],
  checkWhitelist: true,
};

const fallBackErrorEN = {
  error_oops: "Oops, something went wrong.",
  error_unknown:
    "Something went wrong, please try again shortly or reach out to our team for assistance.",
};
const fallBackErrorAR = {
  error_oops: "عفوا. حدث خطأ",
  error_unknown:
    "يُرجى المحاولة مرة أخرى قريبًا أو التواصل مع فريقنا للحصول على المساعدة",
};

const detector = new LanguageDetector();

detector.addDetector({
  name: "fallback",
  lookup() {
    return "en-GQL";
  },
});

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...fallBackErrorEN,
        },
      },
      ar: {
        translation: {
          ...fallBackErrorAR,
        },
      },
    },
    load: "languageOnly",
    partialBundledLanguages: true,
    ns: [],
    whitelist: ["en", "ar"],
    fallbackLng: ["en"],
    detection: LanguageDetectorOptions,
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
      prefix: "[",
      suffix: "]",
    },
    debug: false,
  });

export default i18n;
