import React, { useState } from "react";
import * as ROUTES from "../../../Constants/Routes";

// Packages
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Components
import FormInput from "../../Form/FormInput";
import trimWhiteSpace from "../../Utils/validate";
import PrimaryButton from "../../Buttons/Primary";
// Translation Package
import { useTranslation } from "react-i18next";

/**
 * @note All emails are verified by default at the moment, this feature
 * is for future integration.
 */
const VerifyEmailForm = ({ setModal }) => {
  // Translation hook
  const { t } = useTranslation();

  // Modal handling
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm();

  const [buttonContent, setButtonContent] = useState("Verify code");

  const onSubmit = (data) => {
    // Input your verification logic here

    // Timeout is for a dummy effect
    // since real verification is a promise
    // so remove timeout on production
    setTimeout(() => {
      // Reset button text
      setButtonContent("Verify code");

      // Set modal close
      if (setModal) {
        setModal(null);
      } else {
        history.push(ROUTES.HOME);
      }
    }, 1000);
  };

  return (
    <div className="auth-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form__verify-email"
      >
        <div className="row">
          <div className="col-12">
            <h6 className="auth-form__form-title">{t("verify_email")}</h6>
          </div>
        </div>
        {/* EMAIL */}
        <div className="row">
          <div className="col-12 verify-email-text">
            {/* i18n not applicable, feature disabled. */}
            <p>
              Please enter the code you received. Do not forget to check your
              spams.
            </p>
          </div>
          {/* Verify code */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
                minLength: {
                  value: 6,
                  message: "Your code should be longer than 6 digits.",
                },
                validate: trimWhiteSpace(),
              })}
              type="number"
              name="verifyCode"
              label={t("code")}
              autoFocus={true}
              error={errors.verifyCode}
            />
          </div>

          <div className="col-12">
            <div className="verify-code-button mt-5">
              <PrimaryButton label={buttonContent} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyEmailForm;
