import React, { useEffect, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import { FILTERS } from "../../../../Constants/url_params";
import useFilters from "../../../Utils/hooks/useFilters";

import composeJsxClass from "../../../Utils/functions/composeJsxClass";
import useButtonLabel from "../../../Utils/hooks/useButtonLabel";

import isElementInViewport from "./isElementInViewport";
import getPrice from "../BookModal/functions/getPrice";

import {
  CiCheck,
  CiLocation,
  CiBed,
  CiRules,
  CiPhotos,
} from "../../../../CustomIcons";
import PrimaryButton from "../../../Buttons/Primary";

// Translation Package
import { useTranslation } from "react-i18next";

const buttonIconSize = 18;

const FloatingTopbar = ({ suite, quotation, handleOnPriceDatesClick }) => {
  //Translation hook
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentCanDeleted, setCurrentCanDeleted] = useState(false);

  const filters = useFilters();

  // Filters
  const dates = {
    start: filters[FILTERS.startDate.paramKey] || null,
    end: filters[FILTERS.endDate.paramKey] || null,
  };

  const price = getPrice(suite, quotation);

  const [inViewport, setInViewport] = useState({
    contact: false,
    bookModal: false,
  });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currentCanDeleted) {
        setCurrent(null);
        setCurrentCanDeleted(false);
      }

      if (currPos.y < -540) setShow(true);
      else setShow(false);
    },
    [current, currentCanDeleted],
    null,
    false,
    40
  );

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const qBoxInView = isElementInViewport("question-box");
      const bookModalInView = isElementInViewport("book-modal");
      setInViewport({
        contact: qBoxInView,
        bookModal: bookModalInView,
      });
    },
    [],
    null,
    false,
    300
  );

  const tobparClass = composeJsxClass("suite__floating-topbar", {
    show: show,
  });

  const handleNav = (nav) => {
    if (!nav || nav === "" || nav === null) return;
    if (nav === "photos") {
      window.scrollTo({
        left: 0,
        top: 0,
      });
      return;
    }

    setCurrent(nav);
    let node = document.getElementById(nav);
    if (!node) return;

    node.scrollIntoView();
  };

  useEffect(() => {
    if (current === false) return;

    const timeout = setTimeout(() => {
      setCurrentCanDeleted(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [current]);

  const isDatesSelected = dates.start && dates.end;

  const handleOnDatesClick = () => {
    if (isDatesSelected) {
      document.getElementById("about").scrollIntoView();
    } else {
      handleOnPriceDatesClick();
    }
  };

  const handleOnContactClick = () => {
    document.getElementById("question-box").scrollIntoView();
  };

  const datesLabel = useButtonLabel("calendar", dates.start, dates.end);
  const bookModalButtonStyle = composeJsxClass("book-modal-button", {
    shown: inViewport.bookModal === false,
  });

  const contactButtonStyle = composeJsxClass("contact-button", {
    shown: inViewport.contact === false,
  });

  return (
    <div className={tobparClass}>
      <div className="container floating-container container-max-xl">
        <div className="nav-buttons">
          <button className="nav-button" onClick={() => handleNav("photos")}>
            <div className="icon-holder">
              <CiPhotos className="icon" size={buttonIconSize} />
            </div>
            {t("photos")}
          </button>
          <button
            className="nav-button"
            data-active={current === "our-place-offers"}
            onClick={() => handleNav("our-place-offers")}
          >
            <div className="icon-holder">
              <CiCheck className="icon" size={buttonIconSize} />
            </div>
            {t("amenities")}
          </button>
          <button
            className="nav-button"
            data-active={current === "sleeping-arrangements"}
            onClick={() => handleNav("sleeping-arrangements")}
          >
            <div className="icon-holder">
              <CiBed className="icon" size={buttonIconSize} />
            </div>
            {t("sleeping")}
          </button>
          <button
            className="nav-button"
            data-active={current === "neighbourhood"}
            onClick={() => handleNav("neighbourhood")}
          >
            <div className="icon-holder">
              <CiLocation className="icon" size={buttonIconSize} />
            </div>
            {t("neighbourhood_and_building")}
          </button>
          <button
            className="nav-button"
            data-active={current === "house-rules"}
            onClick={() => handleNav("house-rules")}
          >
            <div className="icon-holder">
              <CiRules className="icon" size={buttonIconSize} />
            </div>
            {t("house_rules")}
          </button>
        </div>
        <div className="right-buttons">
          <div className={bookModalButtonStyle}>
            <div className="book-button-left">
              {!isDatesSelected && (
                <span className="price__from">{t(price.from)}</span>
              )}
              <div className="price__holder">
                <span className="price__data">{price.price}</span>
                <span className="price__night">/ {t("night")}</span>
              </div>

              {isDatesSelected && (
                <button
                  className="price__dates-button"
                  onClick={handleOnPriceDatesClick}
                >
                  {datesLabel}
                </button>
              )}
            </div>
            <PrimaryButton
              className="book-button"
              onClick={handleOnDatesClick}
              label={isDatesSelected ? t("continue") : t("add_dates")}
            />
          </div>

          <div className={contactButtonStyle}>
            <button className="text-button" onClick={handleOnContactClick}>
              {t("need_help")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingTopbar;
