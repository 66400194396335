import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

/*
  Select GTM ID based on Environment
*/
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

/**
 *
 * Enable Sentry for error reporting
 *
 */
if (SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}
