import React from "react";
import styles from "./index.module.scss";
// Translation Package
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import * as ROUTES from "Constants/Routes";

import corporatePic from "./assets/corporate_picture.webp";

// UserPersonas showcase component
const CorporateBanner = () => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div className={`container-fluid ${styles.corporateBanner}`}>
      <div className={styles.overlay}></div>
      <div className="container">
        <div className={`row gx-lg-4`}>
          <div className="col-12 col-lg-6">
            <div className="header d-flex flex-column justify-content-center h-100">
              <h2 className="stella_h2">{t("title_are_you_a_business")}</h2>
              <p className="stella_h4">{t("subtitle_are_you_a_business")}</p>
              <Link
                to={ROUTES.CORPORATE}
                className="stella_btn mx-auto mx-lg-0"
              >
                {t("button_are_you_a_business")}
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-row align-items-center justify-content-center justify-content-lg-end">
            <img
              className={styles.img}
              src={corporatePic}
              alt={t("title_are_you_a_business")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateBanner;
