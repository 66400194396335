import React, { useState, useContext, useEffect, useRef } from "react";
import * as ROUTES from "../../../Constants/Routes";
import QUERIES from "../../../Constants/Queries";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";

// Apollo
import { useQuery, useLazyQuery } from "@apollo/client";
import { BsArrowLeftShort } from "react-icons/bs";

// Packages
import { Helmet } from "react-helmet";
import SuiteInfo from "./Components/SuiteInfo";
import ContactReviewForm from "./Forms/ContactReview/ContactReviewForm";
import LoadingLogoFullScreen from "Components/Utils/LoadingLogoFullScreen";
import NotFoundCard from "../../Utils/NotFoundCard";
import Topbar from "../../Layout/Topbar";
import Footer from "../../Layout/Footer";
import PaymentCard from "./Forms/PaymentCard";
import { contactReviewFormState } from "./Forms/ContactReview/formState";
import ChoosePaymentMethod from "./Components/ChoosePaymentMethod";
import FutureSDNotice from "./Components/FutureSDNotice";
import {
  PAYMENT_METHOD,
  PAYMENT_STEP,
  PAYMENT_CONTEXT,
} from "Constants/globals";
import useMobileDetect from "Components/Utils/hooks/useMobileDetect";
import { URL_PARAMS } from "Constants/globals";
import dayjs from "dayjs";
import calcSetShowSDSeparate from "Components/Booking/Payment/Forms/functions/shouldShowSDSeparate.js";

// Translation Package
import { useTranslation, Trans } from "react-i18next";
import SignUpWithEmailForm from "Components/Auth/SignUp/WithEmail";
import PrimaryButton from "Components/Buttons/Primary";
import Loading from "Components/Utils/Loading";
import SocialMethods from "Components/Auth/Helpers/SocialMethods";
import { BiCheck } from "react-icons/bi";

// Create context to share property data in children components
export const PaymentContext = React.createContext();
export function usePaymentContext() {
  return useContext(PaymentContext);
}

function BookingPayment({ context = PAYMENT_CONTEXT.WEBSITE }) {
  // BookingPayment ref to use onSubmit from SignUpWithEmailForm & handleNextBtn from ChoosePaymentMethod
  const bookingPaymentRef = useRef(null)
  
  // Translation hook
  const { t } = useTranslation();
  const isMobile = useMobileDetect();

  const { id, startDate, endDate, numberOfGuests } = useParams();
  const skip = !startDate || !endDate || !numberOfGuests;
  const skipQuotationFetch =
    dayjs(startDate).isBefore(dayjs(), "days") ||
    dayjs(endDate).isBefore(dayjs(), "days");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invCodeFromEmail = searchParams.get(URL_PARAMS.RESERVATION_CODE);

  //------------ Context State ------------
  //Payment Context
  //Payment Method
  const [paymentMethod, setPaymentMethod] = useState(
    PAYMENT_METHOD.CREDIT_CARD
  );
  //Payment Stage
  const [paymentStep, setPaymentStep] = useState(PAYMENT_STEP.SELECT_METHOD);

  // Handle isSubscribedToNewsLetter
  const [isSubscribedToNewsLetter, setIsSubscribedToNewsLetter] = useState(true)

  const [propertyData, setPropertyData] = useState(null);
  const [quotationData, setQuotationData] = useState(null);
  const [invData, setInvData] = useState(null);
  const [showSDSeparate, setShowSDSeparate] = useState(false);
  const [isSecurityDepositEligible, setIsSecurityDepositEligible] = useState(false);
  
  // Signup 
  const [isSigningUp, setIsSigningUp] = useState(false)

  //------------ Context State ------------

  // Fetch Profile info
  const userProfileFetch = useQuery(QUERIES.USER.GET_PROFILE, {
    skip,
  });

  // Fetch Property info
  const propertyFetch = useQuery(QUERIES.PROPERTIES.GET_PROPERTIES, {
    variables: { filter: { propertyID: id } },
    skip,
    onCompleted: (data) => {
      setPropertyData(data?.result?.matches[0]);
    },
  });

  // Fetch Quotation from invite
  const [
    inviteFetch,
    { error: reservationFetchError, loading: reservationFetchLoading },
  ] = useLazyQuery(QUERIES.PROPERTIES.GET_RESERVATION_BY_CODE, {
    variables: { code: invCodeFromEmail, paymentMethod: paymentMethod },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setQuotationData(data?.result?.quotation);
      setInvData(data?.result);
      const shouldShowSDSeparate = calcSetShowSDSeparate(
        data?.result?.quotation,
        paymentMethod
      );
      setShowSDSeparate(shouldShowSDSeparate);
      setIsSecurityDepositEligible(data?.result?.quotation.isSecurityDepositEligible);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // Fetch Quotation
  const quotationPayload = {
    propertyID: id,
    startDate: startDate,
    endDate: endDate,
    paymentMethod: paymentMethod,
  };
  const [
    quotationFetch,
    { error: quotationFetchError, loading: quotationFetchLoading },
  ] = useLazyQuery(QUERIES.PROPERTIES.PRICE_QUOTATION, {
    variables: { payload: quotationPayload },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setQuotationData(data.result);
      const shouldShowSDSeparate = calcSetShowSDSeparate(
        data?.result,
        paymentMethod
      );
      setShowSDSeparate(shouldShowSDSeparate);
      setIsSecurityDepositEligible(data?.result?.isSecurityDepositEligible);
      if (
        !data?.result?.isCreditCardEligible &&
        !data?.result?.isCryptoEligible &&
        !data?.result?.isBankEligible
      ) {
        setPaymentStep(PAYMENT_STEP.PAY);
      }
    },
  });

  useEffect(() => {
    if (skip || skipQuotationFetch) {
      return;
    }
    if (!invCodeFromEmail) {
      quotationFetch();
    } else {
      inviteFetch();
    }
  }, []);

  const history = useHistory();

  // Redirect if there is no booking data
  if (!startDate || !endDate || !numberOfGuests) {
    history.push(ROUTES.HOME);
  }

  if (userProfileFetch.loading || propertyFetch.loading) {
    return <LoadingLogoFullScreen />;
  }

  if (
    quotationFetchError ||
    propertyFetch.error ||
    reservationFetchError
  ) {
    return (
      <>
        <Topbar />
        <div className="container">
          <NotFoundCard>
            <p className="notfound__message">{t("error_booking_details")}</p>
          </NotFoundCard>
        </div>
        <Footer />
      </>
    );
  }

  if (contactReviewFormState() === null) {
    contactReviewFormState(userProfileFetch?.data?.result);
  }

  const isQuotationLoading =
    !!quotationFetchLoading || !!reservationFetchLoading;

  return (
    <PaymentContext.Provider
      value={{
        context: context,
        propertyData: propertyData,
        quotationData: quotationData,
        paymentMethod,
        setPaymentMethod,
        paymentStep,
        setPaymentStep,
        invCode: invCodeFromEmail,
        invData,
        showSDSeparate,
        isSecurityDepositEligible
      }}
    >
      {context === PAYMENT_CONTEXT.WEBSITE && <Topbar hideFindAStella />}
      <div className="container confirm-and-pay">
        <Helmet>
          <title>
            {t("confirm_your_booking")} - {t("stella_stays")}
          </title>
        </Helmet>

        <div className="row content justify-content-between">
          {context === PAYMENT_CONTEXT.WEBSITE && (
            <div className="col-12">
              <Link
                className="go-back-button d-inline-flex flex-row"
                to={{
                  pathname: `${ROUTES.SUITE}/${propertyData?.id}/${propertyData?.urlFriendlyID}`,
                }}
                style={{ textDecoration: "none" }}
              >
                <h6 className="confirm-and-pay__breadcrumb">
                  <button className="go-back-button">
                    <BsArrowLeftShort className="icon" />
                    <span>{t("back_to_property")}</span>
                  </button>
                </h6>
              </Link>
            </div>
          )}

          <div className="col-12 col-lg-5 col-xxl-4 order-lg-2">
            <SuiteInfo
              propertyData={propertyData}
              quotationData={quotationData}
              quotationDataLoading={isQuotationLoading}
              startDate={startDate}
              endDate={endDate}
              guests={numberOfGuests}
              invData={invData}
              showQuotation={!isMobile || paymentStep === PAYMENT_STEP.PAY}
              showSDSeparate={showSDSeparate}
              isSecurityDepositEligible={isSecurityDepositEligible}
            />
            {isSecurityDepositEligible &&
              showSDSeparate &&
              quotationData &&
              propertyData &&
              (!isMobile || paymentStep === PAYMENT_STEP.PAY) && (
                <FutureSDNotice
                  quotationData={quotationData}
                  quotationDataLoading={isQuotationLoading}
                />
              )}
          </div>

          {/* Not available notice */}
          {!quotationData?.isRangeAvailable && !isQuotationLoading && (
            <div className="col-12">
              <div className="alert alert-warning" role="alert">
                {t("error_booking_dates")}
              </div>
            </div>
          )}
          {quotationData?.violatesLOS && !isQuotationLoading && (
            <div className="col-12">
              <div className="alert alert-warning" role="alert">
                <Trans
                  i18nKey="los_notice"
                  values={{ dataMinLOS: quotationData?.minLOS }}
                />
              </div>
            </div>
          )}

          <div className="col-12 col-lg-7 col-xxl-7 order-lg-1">
            { userProfileFetch.error && 
              <>
                <SignUpWithEmailForm
                  isInlineSignup 
                  ref={bookingPaymentRef}
                  setIsSigningUp={(newValue) => setIsSigningUp(newValue)}
                  isSubscribedToNewsLetter={isSubscribedToNewsLetter}
                />
                <SocialMethods type="signup" socialMethodColSpan={isMobile ? 12 : 6} isSubscribedToNewsLetter={isSubscribedToNewsLetter}/>
                <div className="row">
                  <div className="mt-2 mb-2 confirm-and-pay__agreement pb-3">
                    <div className="agreement d-flex gap-2">
                      <div className="agreement__button">
                        <button
                          className={`check-button my-1 ${
                            isSubscribedToNewsLetter ? "check-button--checked" : ""
                          }`}
                          onClick={() => setIsSubscribedToNewsLetter(!isSubscribedToNewsLetter)}
                          style={{ border: "0px" }}
                        >
                          {isSubscribedToNewsLetter && <BiCheck className="icon" />}
                        </button>
                      </div>
                      <div className="agreement__text d-flex flex-row align-items-center">
                        <p className="text mb-0">
                          {t("newsLetter_subscription_notice")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            {(!isMobile || paymentStep === PAYMENT_STEP.PAY) && !userProfileFetch.error && (
              <ContactReviewForm profile={userProfileFetch?.data?.result} />
            )}
            {paymentStep === PAYMENT_STEP.SELECT_METHOD && (
              <ChoosePaymentMethod
                quotationDataLoading={isQuotationLoading}
                isMobile={isMobile}
                isInlineSignup={!!userProfileFetch.error}
              />
            )}
            {paymentStep === PAYMENT_STEP.PAY  && <PaymentCard />}
            {/* Form submit button (before inline signup) was implemented in ChoosePaymentMethod component but it validates data from another component (ContactReviewForm) */}
            {/* Hiding the "Next" button from ChoosePaymentMethod & showing it here is easier for submission although it makes the code looks bad */}
            {/* We use forwardRef & useImperativeHandle to signup with submit function in the SignupWithEmail component */}
            {userProfileFetch.error && <PrimaryButton
              label={isSigningUp ? <Loading light /> : t("next")}
              disabled={isSigningUp}
              onClick={() => bookingPaymentRef.current.signup()}
              className="my-5"
            />}
          </div>
        </div>
      </div>

      {context === PAYMENT_CONTEXT.WEBSITE && <Footer />}
    </PaymentContext.Provider>
  );
}

export default BookingPayment;
