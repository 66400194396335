const camelCaseToHypen = (v = ``, debug = false) => {
  let ret = "",
    prevLowercase = false,
    prevIsNumber = false;
  for (let s of v) {
    const isUppercase = s.toUpperCase() === s;
    const isNumber = !isNaN(s);
    if (isNumber) {
      if (prevLowercase) {
        ret += "-";
      }
    } else {
      if (isUppercase && (prevLowercase || prevIsNumber)) {
        ret += "-";
      }
    }
    ret += s;
    prevLowercase = !isUppercase;
    prevIsNumber = isNumber;
  }
  ret = ret.replace(/-+/g, "-").toLowerCase();

  if (debug) console.log(ret);
  return ret;
};
export default camelCaseToHypen;
