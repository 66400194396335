import React from "react";
import { BsX, BsCheck } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";

const PasswordInfo = ({ password }) => {
  //Translation hook
  const { t } = useTranslation();

  const items = {
    strength: {
      validation: password.strength !== "low",
      text: {
        valid: t("password_strength") + `: ${t(password.strength)}`,
        invalid: t("password_strength") + `: ${t(password.strength)}`,
      },
    },
    minDigits: {
      validation: password.validation.minDigit,
      text: {
        valid: t("password_length"),
        invalid: t("error_password_length"),
      },
    },
    upperCase: {
      validation: password.validation.upperCase,
      text: {
        valid: t("password_uppercase"),
        invalid: t("error_password_uppercase"),
      },
    },
    lowerCase: {
      validation: password.validation.lowerCase,
      text: {
        valid: t("password_lowercase"),
        invalid: t("error_password_lowercase"),
      },
    },
  };

  return (
    password.focused && (
      <div className="password-strength">
        {password.isValid ? (
          <div className={`password-strength__item valid ${password.strength}`}>
            <span className="item-icon">
              <BsCheck className="icon" />
            </span>
            <span className="item-text">
              {t("password_strength")}: {t(password.strength)}
            </span>
          </div>
        ) : (
          Object.values(items).map((item, index) => {
            const isValid = item.validation;
            const validClass = isValid ? "valid" : "invalid";
            const validProperty = isValid ? "valid" : "invalid";
            return (
              <div
                className={`password-strength__item ${validClass}`}
                key={index}
              >
                <span className="item-icon">
                  {isValid ? (
                    <BsCheck className="icon" />
                  ) : (
                    <BsX className="icon" />
                  )}
                </span>
                <span className="item-text">{item.text[validProperty]}</span>
              </div>
            );
          })
        )}
      </div>
    )
  );
};
export default PasswordInfo;
