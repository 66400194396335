import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import QUERIES from "../../Constants/Queries";
import * as ROUTES from "../../Constants/Routes";
import { URL_PARAMS } from "../../Constants/globals";
import PageTranslationHOC from "Components/Utils/i18n/PageTranslationHOC";

import LoadingLogoFullScreen from "Components/Utils/LoadingLogoFullScreen";
import Error from "./Error";

const PrivateRoute = ({ component: RouteComponent, lookupKey, ...rest }) => {
  const { pathname, search } = useLocation();
  const token = localStorage.getItem("token");

  const getSearchParams = new URLSearchParams(search)?.toString();
  let setSearchParams = null;
  if (getSearchParams) {
    setSearchParams = `&${new URLSearchParams(search)?.toString()}`;
  }

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(QUERIES.USER.VERIFY_TOKEN, {
    variables: { token },
    skip: token === null,
  });
  if (userError?.message === "unauthenticated!") {
    //token expired, or is invalid!
    localStorage.removeItem("token");
    return (
      <Redirect
        to={`${ROUTES.LOGIN}?${URL_PARAMS.REDIRECT}=${pathname}${
          setSearchParams ?? ""
        }`}
      />
    );
  }
  if (userLoading) return <LoadingLogoFullScreen />;
  if (userError) return <Error />;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!userData && !!userData.result ? (
          <PageTranslationHOC pageName={lookupKey}>
            <RouteComponent {...routeProps} />
          </PageTranslationHOC>
        ) : (
          <Redirect
            to={`${ROUTES.LOGIN}?${URL_PARAMS.REDIRECT}=${pathname}${
              setSearchParams ?? ""
            }`}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
