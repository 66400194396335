import camelCaseToHypen from "./camelCaseToHypen";

const composeJsxClass = (main = "", states) => {
  if (!states) return main;
  let composed = main;

  Object.entries(states).forEach((state) => {
    const className = camelCaseToHypen(state[0]);
    const condition = state[1];
    if (condition) {
      composed = `${composed} ${main}--${className}`;
    }
  });

  return composed;
};

export default composeJsxClass;
