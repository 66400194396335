import { URL_PARAMS } from "Constants/globals";

/** @function omitRedirectToParam
 * omits redirectTo from URL search params
 *
 * @param params: string
 *
 */
const omitRedirectToParam = (params) => {
  const urlParams = new URLSearchParams(params);
  urlParams.delete(URL_PARAMS.REDIRECT);

  return urlParams?.toString();
};

export default omitRedirectToParam;
