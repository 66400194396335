import { useEffect } from "react";
import firebase from "firebase/app";
import { useHistory } from "react-router";

import { useUser } from "../../Providers/UserProvider";

export default function GoogleAnalyticsProvider(props) {
  // Set user
  const user = useUser();
  useEffect(() => {
    if (user.loading) return;
    if (firebase.auth().currentUser) {
      firebase.analytics().setUserId(firebase.auth().currentUser?.uid || null);
    }
  }, [user]);

  // Screen view
  const history = useHistory();
  useEffect(() => {
    history.listen(({ pathname }) => {
      firebase.analytics().logEvent(`screen_view`, {
        firebase_screen_id: pathname,
      });
    });
  }, [history]);

  return props.children;
}
