import { useMutation } from "@apollo/client";
import QUERIES from "Constants/Queries";
import { getProviderId } from "../functions/getProviderId";
import firebase from "firebase/app";
import logGaEvent from "lib/ga/logGaEvent";
import { useUser } from "Providers/UserProvider";

/**
 * This hook handel sign-in flow using 3rd party methods like [Google, Facebook, Apple]. 
 * @returns `signIn` callback function that **require** a `provider` parameter to sign-in the user.
 * It returns a promise that returns a "jwtToken" on fulfill.
 * 
 * @example
 * const myComponent () => {
      const signIn = useSocialMethodsSignIn();
 *    
      const onSignIn = async (provider) => {
        try {
          const jwtToken = await signIn(provider);
          // do some stuff ...
        } catch(error) {
          // handel error flow
        }
      }

      return (
        <div>
          <button onClick={() => onSignIn(PROVIDERS.GOOGLE)}>Google</button>
        </div>
      )
 * }
 */
const useSocialMethodsSignIn = () => {
  const [signInWithProvider] = useMutation(QUERIES.USER.SIGN_IN_WITH_PROVIDER);
  
  const singIn = async (provider, isSubscribedToNewsLetter) => {
    if (!provider) throw Error('No "provider" found in signIn function');

    const providerId = getProviderId(provider);

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => res.user.getIdToken(true))
      .then(async (firebaseToken) => {
        return await signInWithProvider({
          variables: {
            payload: { firebaseToken: firebaseToken, birthdate: null, isSubscribedToNewsLetter },
          },
        });
      })
      .then((res) => {
        return res.data.result.jwtToken;
      })
      .then((jwtToken) => {
        if (!jwtToken) {
          // GA event
          logGaEvent("sign_up", providerId);
          return jwtToken;
        }

        // Set token
        logGaEvent("login", providerId);
        localStorage.setItem("token", jwtToken);

        return jwtToken;
      })
  }

  return singIn
};

export default useSocialMethodsSignIn;
