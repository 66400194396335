import styles from "./index.module.scss";
// Translation Package
import { useTranslation } from "react-i18next";

import VideoBlock from "./VideoBlock";

import LiveBG from "./assets/live-bg.webp";
import LiveThumb from "./assets/live-cover.webp";
import VideoLiveSrc from "./assets/live.mp4";
import VideoConnectSrc from "./assets/connect.mp4";
import ConnectThumb from "./assets/connect-cover.webp";
import ConnectBG from "./assets/connect-bg.webp";
import VideoExperienceSrc from "./assets/experience.mp4";
import ExperienceThumb from "./assets/thrive-cover.webp";
import ExperienceBG from "./assets/thrive-bg.webp";

// BuildingHero showcase component
const BuildingExperience = () => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div
      className={`container ${styles.buildingExperience} p-0 overflow-hidden my-5`}
    >
      {/* Live */}
      <div className={`row`}>
        <div className="col-lg-6 p-5">
          <div className="header d-flex flex-column justify-content-center h-100">
            <h2 className="stella_h2">{t("subtitle_a_new_way_to_live")}</h2>
            <p className="stella_h4 mb-0">{t("text_the_stella_way_live")}</p>
          </div>
        </div>
        <div className="col-lg-6 position-relative d-inline-flex flex-row justify-content-start align-items-center">
          <VideoBlock
            bgImage={LiveBG}
            videoThumb={LiveThumb}
            videoSrc={VideoLiveSrc}
          />
        </div>
      </div>

      {/* Connect */}
      <div className={`row`}>
        <div className="col-lg-6 order-1 order-lg-2 p-5">
          <div className="header d-flex flex-column justify-content-center h-100">
            <h2 className="stella_h2">
              {t("subtitle_the_stella_way_connect")}
            </h2>

            <p className="stella_h4 mb-0">{t("text_the_stella_way_connect")}</p>
          </div>
        </div>
        <div className="col-lg-6 order-2 order-lg-1 position-relative d-inline-flex flex-row justify-content-start align-items-center">
          <VideoBlock
            bgImage={ConnectBG}
            videoThumb={ConnectThumb}
            isLeft
            videoSrc={VideoConnectSrc}
          />
        </div>
      </div>

      {/* Thrive */}
      <div className={`row`}>
        <div className="col-lg-6 p-5">
          <div className="header d-flex flex-column justify-content-center h-100">
            <h2 className="stella_h2">{t("subtitle_the_stella_way_thrive")}</h2>
            <p className="stella_h4 mb-0">{t("text_the_stella_way_thrive")}</p>
          </div>
        </div>
        <div className="col-lg-6 position-relative">
          <VideoBlock
            bgImage={ExperienceBG}
            videoThumb={ExperienceThumb}
            videoSrc={VideoExperienceSrc}
          />
        </div>
      </div>
    </div>
  );
};

export default BuildingExperience;
