import dayjs from "dayjs";

const createDateRangeString = (date, apiFormat) => {
  if (!date) return null;

  const isDateValid = dayjs(date || null).isValid();
  if (!isDateValid) return null;

  const today = dayjs();
  date = dayjs(date);

  const isSame = {
    today: date.isSame(today, "day"),
    month: date.isSame(today, "month"),
    year: date.isSame(today, "year"),
  };

  const strings = {
    today: "Today",
    monthly: date.format("DD MMM"),
    yearly: date.format("DD MMM YYYY"),
  };

  if (isSame.today) return strings.today;
  if (isSame.year) return strings.monthly;
  return strings.yearly;
};

export default createDateRangeString;
