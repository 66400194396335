const getLosAvailabilityDatesConfigFromWorker = (
  sortedLosDates,
  sortedUnavailableRanges
) => {
  return new Promise(function (resolve, reject) {
    /*-------------------------Catch errors >>>>>>>
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    if (!sortedLosDates || !sortedUnavailableRanges) {
      resolve([]);
    }

    if (!window.Worker) {
      reject([]);
    }

    /*-------------------------Step1 >>>>>>>
    * Create worker
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    const worker = new window.Worker("/workers/los-avail-config.js");
    worker.onerror = (err) => reject(err);

    /*-------------------------Step2 >>>>>>>
    * Send data to worker
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    worker.postMessage({
      sortedLosDates: sortedLosDates,
      sortedUnavailableRanges: sortedUnavailableRanges,
    });

    /*-------------------------Step3 >>>>>>>
    * return data from worker
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    worker.onmessage = (e) => {
      // time is time taken to calculate
      // datesConfig is array of available dates with their config
      const { datesConfig, time } = e.data;
      resolve({ time: time, datesConfig: datesConfig });
      worker.terminate();
    };
  });
};

export default getLosAvailabilityDatesConfigFromWorker;
