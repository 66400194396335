import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// Translation Package
import { useTranslation } from "react-i18next";

// Constants
import * as ROUTES from "../../../../Constants/Routes";
import QUERIES from "../../../../Constants/Queries";
import {
  NEW_PASSWORD_PROPERTIES,
  EMAIL_PATTERN,
  NAME_PATTERN,
  ERROR_MESSAGES,
} from "Components/Auth/constants";

// Packages
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import firebase from "firebase/app";

import redirectAfterLoginSuccess from "Components/Auth/functions/redirectAfterLoginSuccess.js";

// Components
import FormInput from "Components/Form/FormInput";

// Providers
import {
  useModal,
  useUpdateModal,
  DEFAULT_STATE as DEFAULT_MODAL,
} from "../../../../Providers/FloatingModalProvider";

// Functions
import trimWhiteSpace from "Components/Utils/validate";
import validatePhone from "Components/Utils/validatePhone";
import { URL_PARAMS } from "Constants/globals";
import PhoneNumberInput from "Components/Form/PhoneNumberInput";
import { contactReviewFormState } from "Components/Booking/Payment/Forms/ContactReview/formState";
import { useUser } from "Providers/UserProvider";
import validateSignupForm from "Components/Auth/functions/validateSignupForm";
import checkNewPassword from "Components/Auth/functions/checkNewPassword";
import PasswordInfo from "Components/Auth/Helpers/PasswordInfo";
import logGaEvent from "lib/ga/logGaEvent";
import { triggerPaymentMethodSelectionVar } from "Components/Auth/SignUp/trigger";

const SignUpWithEmailForm = forwardRef((formProps, ref) => {
  const {
    redirectTo,
    urlParams,
    routeStateEmail,
    setIsSigningUp = () => {},
    isSubscribedToNewsLetter,
    defaultFormValues = {},
  } = formProps;

  // Translation hook
  const { t } = useTranslation();

  // Location data
  const { pathname, search } = useLocation();
  const getSearchParams = new URLSearchParams(search)?.toString();
  let setSearchParams = null;
  if (getSearchParams) {
    setSearchParams = `&${new URLSearchParams(search)?.toString()}`;
  }

  // Handle setting user
  const { setUser } = useUser();

  const history = useHistory();

  const { props } = useModal();
  const updateModal = useUpdateModal();

  // Handle password
  const [password, setPassword] = useState(NEW_PASSWORD_PROPERTIES);
  const handlePasswordOnChange = (e) => {
    const pass = e.target.value;
    const validations = checkNewPassword(pass);

    setPassword((p) => ({
      ...p,
      ...validations,
      value: pass,
    }));
  };
  const handlePasswordOnFocus = () => {
    setPassword((p) => ({ ...p, focused: true }));
  };

  // Form handling
  const [mutationError, setMutationError] = useState(null);
  const formRef = useRef(null);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    trigger,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: {
      email: routeStateEmail || props?.email,
      ...defaultFormValues,
    },
  });

  const [addNewUser] = useMutation(QUERIES.USER.SET_SIGN_UP);
  const [login] = useMutation(QUERIES.USER.SET_LOGIN);

  const resetDisabledButton = () => {
    // UX feature
    // Add additional waiting to indicate the form is working smoothly
    setTimeout(() => {
      setIsSigningUp(false);
    }, 700);
    return;
  };

  const onSubmit = async (data) => {
    setIsSigningUp(true);
    clearErrors();
    setMutationError(null);

    // Validations and set custom errors
    const validations = validateSignupForm(data, false);
    const doesAllPass = validations.doesAllPass;
    if (doesAllPass === false) {
      if (!validations.isValidPhoneNumber) {
        setError("mobile", {
          type: "manual",
          message: t(ERROR_MESSAGES.mobile.invalidFormat),
        });
      }
      resetDisabledButton();
      return;
    }

    // Password validation
    if (password.isValid === false) {
      setError("password", { type: "manual", message: "" });
      resetDisabledButton();
      return;
    }

    // Password confirm validation
    if (data.password !== data.passwordConfirm) {
      setError("passwordConfirm", {
        type: "manual",
        message: t(ERROR_MESSAGES.passwordConfirm.doesNotMatch),
      });
      resetDisabledButton();
      return;
    }

    const mobile = data.mobile.replaceAll(" ", "");

    let payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: mobile,
      password: data.password,
      isSubscribedToNewsLetter,
    };

    await addNewUser({ variables: { payload } })
      .then((response) => {
        const jwtToken = response.data.result.jwtToken;
        localStorage.setItem("token", jwtToken);
      })
      .then(async () => {
        // Login and redirect
        await firebase
          .auth()
          .signInWithEmailAndPassword(data.email, data.password)
          .then(async (res) => await res.user.getIdToken())
          .then(async (token) => {
            return await login({
              variables: { firebaseToken: token },
              update: () => {
                setUser({ ...payload });
              },
            });
          })
          .then((res) => {
            localStorage.setItem("token", res.data.result.jwtToken);

            contactReviewFormState({
              firstName: payload.firstName,
              lastName: payload.lastName,
              email: payload.email,
              mobile: payload.mobile,
            });
            triggerPaymentMethodSelectionVar(payload.email);
          })
          .then(() => {
            logGaEvent("sign_up", "email");

            setTimeout(() => {
              if (redirectTo) {
                redirectAfterLoginSuccess(redirectTo, urlParams);
              }
            }, 300);
          })
          .catch(() => {
            setMutationError(t("error_general"));
            setIsSigningUp(false);
          });

        updateModal(DEFAULT_MODAL);
        return;
      })
      .catch((error) => {
        error = { ...error };
        setMutationError(error.message ?? t("error_general"));
        setIsSigningUp(false);
        window.scrollTo({ top: 0, left: 0 });
        return error;
      })
      .finally(() => {
        setIsSigningUp(false);
      });
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        async signup() {
          //   formRef.current?.dispatchEvent(new Event("submit"));
          formRef?.current?.click();
        },
      };
    },
    []
  );

  return (
    <div style={{ marginBottom: "-20px" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form__signup-with-email mb-3"
        // ref={formRef}
      >
        <div className="confirm-and-pay__form">
          {/* Title */}
          <div className="form-title mt-0">
            <h5 className="form-title__title">
              {t("inline_signup_personal_information")}
            </h5>
          </div>
          <div className="col-12 info" style={{ fontSize: "small" }}>
            <span>{`${t("sign_in_question")} `}</span>
            <Link
              to={`${ROUTES.LOGIN}?${URL_PARAMS.REDIRECT}=${pathname}${
                setSearchParams ?? ""
              }`}
            >
              {t("login")}
            </Link>
          </div>
          <div className="row mt-2">
            {/* First name */}
            <div className="col-12 col-md-6">
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.firstName.required),
                  },
                  validate: trimWhiteSpace(),
                  pattern: {
                    value: NAME_PATTERN,
                    message: t("error_name_characters"),
                  },
                })}
                type="text"
                name="firstName"
                label={t("first_name")}
                error={errors.firstName}
                data-test-id="first-name"
              />
            </div>
            {/* Last name */}
            <div className="col-12 col-md-6">
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.lastName.required),
                  },
                  validate: trimWhiteSpace(),
                  pattern: {
                    value: NAME_PATTERN,
                    message: t("error_name_characters"),
                  },
                })}
                type="text"
                name="lastName"
                label={t("last_name")}
                error={errors.lastName}
                data-test-id="last-name"
              />
            </div>
          </div>
          <div className="col-12">
            <p className="info mb-3">{t("name_id_notice")}</p>
          </div>
          <div className="row">
            <div className="col-12">
              <PhoneNumberInput
                phoneNumberRegister={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.mobile.required),
                  },
                  minLength: {
                    value: 5,
                    message: t(ERROR_MESSAGES.mobile.invalidFormat),
                  },
                  validate: validatePhone,
                })}
                countryRegister={register({
                  required: true,
                  validate: trimWhiteSpace,
                })}
                getCountry={() => getValues("country")}
                triggerCountryValidation={() => trigger("country")}
                autoFocus={routeStateEmail || props?.email ? true : false}
                phoneNumberError={errors.mobile}
                countryError={errors.country}
                data-test-id="mobile-input"
                countryColSpan={"col-6"}
                phoneNumberColSpan={"col-6"}
                defaultNumber={defaultFormValues?.mobile}
              />
            </div>
          </div>
          <div className="row">
            {/* Email address */}
            <div className="col-12 col-md-6">
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.email.required),
                  },
                  pattern: {
                    value: EMAIL_PATTERN,
                  },
                  validate: trimWhiteSpace(),
                })}
                type="email"
                name="email"
                label={t("email")}
                autoFocus={routeStateEmail || props?.email ? false : ""}
                error={errors.email}
                data-test-id="email-input"
              />
            </div>
          </div>
          <div className="col-12">
            <p className="info mb-3">{t("inline_signup_email_field_notice")}</p>
          </div>
          <div className="row">
            {/* Password */}
            <div className="col-12 col-md-6">
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.password.required),
                  },
                })}
                type="password"
                name="password"
                label={t("password")}
                value={password.value}
                onFocus={handlePasswordOnFocus}
                onChange={handlePasswordOnChange}
                autoComplete="new-password"
                spellCheck="false"
                error={errors.password}
                data-test-id="password"
              />
              <PasswordInfo password={password} />
            </div>

            {/* Password confirm*/}
            <div className="col-12 col-md-6">
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.passwordConfirm.required),
                  },
                })}
                type="password"
                name="passwordConfirm"
                label={t("confirm_password")}
                autoComplete="new-password"
                spellCheck="false"
                error={errors.passwordConfirm}
                data-test-id="confirm-password"
              />
            </div>
          </div>
          {mutationError && (
            <div className="row">
              <div className="col-12 auth-form__error fs-6">
                {mutationError}
              </div>
            </div>
          )}
        </div>
        <button type="submit" ref={formRef} className="d-none"></button>
      </form>
    </div>
  );
});

export default SignUpWithEmailForm;
