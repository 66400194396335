import { isValidPhoneNumber } from "libphonenumber-js";
import {contactReviewFormState} from "./formState";
import {contactReviewFormValidity} from "./formValidity";
import { extractCountryFromPhoneNumber, getParsedPhoneNumber } from "Components/Form/PhoneNumberInput";

export const validateForm = () => {
    const formData = contactReviewFormState();
    if(formData === null)
    {
        return {isFormValid: false};
    }
    const guestProfile = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        mobile: getParsedPhoneNumber(formData.mobile),
        country: formData.country || extractCountryFromPhoneNumber(formData.mobile),
        email: formData.email,
        birthdate: formData.birthdate
    };
    const formValidity = {
        firstName: guestProfile.firstName.length > 1,
        lastName: guestProfile.lastName.length > 1,
        mobile: isValidPhoneNumber(guestProfile.mobile),
        country: guestProfile.country.length > 1
    };
    contactReviewFormValidity(formValidity);
    delete guestProfile.country;
    return {
        ...guestProfile,
        isFormValid: formValidity.firstName && formValidity.lastName && formValidity.mobile && formValidity.country
    };
};