/**
 * Check if any of the fields in the given array are dirty.
 * @param {string[]} fields The fields to check.
 * @param {Object[string, boolean]} dirtyFields An object with field names as keys and boolean
 * values indicating if the field is dirty.
 * @returns {boolean} True if any of the given fields are dirty, false otherwise.
 */
export function areFieldsDirty(fields, dirtyFields) {
  return Object.keys(dirtyFields).some((item) => fields.includes(item));
}
