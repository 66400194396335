// Deps
import { Helmet } from "react-helmet";

import { useInView } from "react-intersection-observer";

import Footer from "../Layout/Footer";
import Topbar from "../Layout/Topbar";
import AppShowcase from "./AppShowcase";
import CorporateBanner from "./CorporateBanner";
import Featured from "./Featured";
import FeaturedCities from "./FeaturedCities";
import Flexible from "./Flexible";
import Header from "./Header";
import InputArea from "./Header/InputArea";
import BuildingHero from "./BuildingHero";
import BuildingExperience from "./BuildingExperience";

import styles from "./index.module.scss";

//Hero Section Image
import hero_image from "./assets/homepage_banner.webp";

// Translation Package
import { useTranslation } from "react-i18next";
import React from "react";

const Home = () => {
  // Translation hook
  const { t } = useTranslation();

  const { ref, inView: inputAreaInView } = useInView({
    initialInView: true,
    delay: 500,
  });

  return (
    <>
        <Helmet>
            <title>
                {t("stella_stays")} | {t("website_tab_title_main")}
            </title>
            <meta name="description" content={t('website_tab_description_main')}/>
        </Helmet>

      <Topbar fixed={true} hideFindAStellaTemporarily={inputAreaInView} />

      <Header
        cover={hero_image}
        title={"title_future_of_living"}
        subtitle={"heading_subtitle_discover"}
        style={{ objectPosition: "left center", objectSize: "110%" }}
      />

      <div className={`container-fluid homepage ${styles.inputArea}`}>
        <div className="homepage__header-area" ref={ref}>
          <InputArea />
        </div>
      </div>

      <div className="container homepage">
        <div className="homepage__featured-cities my-5 mb-4">
          <FeaturedCities />
        </div>
      </div>

      <Featured />

      <div className="container">
        <BuildingHero />
      </div>
      <div className="container">
        <BuildingExperience />
      </div>

      <CorporateBanner />

      <div className="container-fluid homepage">
        <div className="homepage__app-showcase">
          <AppShowcase />
        </div>
      </div>

      <Flexible />

      <Footer />
    </>
  );
};

export default Home;
