import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Utils/Loading";
import QUERIES from "../../Constants/Queries";
import { CiGlobe } from "../../CustomIcons";
import Dropdown from "../Layout/Topbar/Desktop/Dropdown";
import Error from "../Utils/Error";
import { i18n_TYPES } from "../../Constants/i18n";
import useSearchQuery from "hooks/useSearchQuery";

const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation();
  const [_, setLangParam] = useSearchQuery("lng");

  // Fetch Supported locales from server
  const { loading, error, data } = useQuery(QUERIES.FETCH_SUPPORTED_LOCALES);

  // Reload page on language changeLanguage, force true for firefox
  const reloadPage = () => {
    window.location.reload(true);
  };

  //   Save changed language in User Profile
  const [saveLangToUserProfile] = useMutation(
    QUERIES.USER.SET_PREFERRED_LANGUAGE,
    {
      onCompleted: () => {
        reloadPage();
      },
    }
  );
  //   get Auth status of User
  const token = localStorage.getItem("token");
  const verifyState = useQuery(QUERIES.USER.VERIFY_TOKEN, {
    variables: { token },
    skip: token === null,
  });
  //   Run mutation only if User is authenticated
  const saveLangCodeToUserProfile = (code) => {
    if (verifyState.data) {
      saveLangToUserProfile({
        variables: { language: code },
      });
    } else {
      reloadPage();
    }
  };

  //   Change Language on button click
  const changeLanguage = (lng) => {
    if (lng.code !== i18n.languages[0]) {
      localStorage.setItem(i18n_TYPES.lookupLocalStorage, lng.code);
      saveLangCodeToUserProfile(lng.code);
      setLangParam(lng.code);
    }
  };

  //   Handle loading / Error States
  if (loading) return <Loading />;
  if (error) return <Error />;

  // Remap data according to Dropdown structure
  const remappedLanguagesAsPerDropdown = (lngArray) => {
    let fetchedLangs = [];
    fetchedLangs = lngArray.map((lng) => {
      return {
        id: lng.id,
        title: lng.label,
        code: lng.language,
        direction: lng.direction,
      };
    });
    return fetchedLangs;
  };

  const CurrentLanguage = () => {
    return data?.result?.find((lang) => i18n.languages[0] === lang.language)?.label ?? '';
  };

  if (props.forFooter) {
    return remappedLanguagesAsPerDropdown(data?.result).map((lng) => {
      return (
        <p
          onClick={() => changeLanguage(lng)}
          key={lng.code}
          className="btn text-white w-auto p-0"
        >
          {lng.title}
        </p>
      );
    });
  }

  return (
    <Dropdown
      label={<CurrentLanguage />}
      items={remappedLanguagesAsPerDropdown(data?.result)}
      noLabelBorder={true}
      btnCallback={changeLanguage}
      btnTestId="language-switcher"
      btnPrefixChild={
        <CiGlobe
          className="svgicon"
          size="24"
          styles={{ paddingRight: "4px", paddingLeft: "4px" }}
        />
      }
    />
  );
};

export default LanguageSwitcher;
