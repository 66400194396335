// Logo
import AmeInfoLogo from "./logos/ame-info.png";
import BloombergLogo from "./logos/bloomberg.png";
import HotelierLogo from "./logos/hotelier.png";
import ArabianBusinessLogo from "./logos/arabian-business.png";
import ArabNewsLogo from "./logos/arab-news.svg";
import KhaalejTimesLogo from "./logos/khaleej-times.svg";
import TheNationalLogo from "./logos/the-national.svg";
import GulfBusinessLogo from "./logos/gulf-business.png";
import ZawyaLogo from "./logos/zawya.svg";
import EntrepreneurLogo from "./logos/entrepreneur.svg";
import EconomyLogo from "./logos/economy-logo.svg";
import SmashiTvLogo from "./logos/smashitv.svg";
import HCNMELogo from "./logos/hcnme-logo.png";
import RFFLogo from "./logos/rff-logo.jpeg";
import CBNMELogo from "./logos/cbnme-logo.png";

// Article media
import HospitalityStartupStellaStaysImage from "./images/hospitality-start-up-stella-stays-announces-expansion-into-bahrain.jpg";
import UaeOwnedAirbnbRivalImage from "./images/uae-owned-airbnb-rival-to-challenge-traditional-hotels-in-the-region.jpg";
import TheImportanceOfGuestExperienceImage from "./images/the-importance-of-guest-experience.jpg";
import NewPlayersInGCCImage from "./images/new-players-in-gcc-short-stay-hospitality-market-compete-for-market-share.jpg";
import NewPlayersInGCCMedia01 from "./images/new-players-in-gcc-short-stay-hospitality-market-compete-for-market-share-media-01.jpg";
import HowCovid19Image from "./images/how-covid-19-and-expo-2020-have-impacted-booking-demand-in-dubai.jpg";
import UseTechToEnhanceGuestExperiencesImage from "./images/use-tech-to-enhance-guest-experiences.jpg";
import FiveHiddenGemsInMontreal from "./images/five-hidden-gems-in-montreal.jpg";
import GenerationStartUpHowStellaStaysImage from "./images/generation-start-up-how-stella-stays-is-changing-the-hospitality-landscape.jpg";
import GenerationStartUpQAImage from "./images/generation-start-up-qa.jpg";
import UAEProptechSaudi from "./images/uae-proptech-startup-stella-stays-enters-saudi-market.jpg";
import GulfBusinessCover from "./images/gulf-business-cover.jpeg";
import GulfBusinessWinnerCover from "./images/gulf-business-awards-cover.webp";
import GulfBusinessOlaya2 from "./images/gulf-business-olaya-2-cover.webp";
import ZawyaCover from "./images/zawya-cover.jpeg";
import EntrepreneurCover from "./images/entrepreneur-cover.jpeg";
import EntrepreneurArticleImg1 from "./images/entrepreneur/entrepreneur-1.webp";
import EntrepreneurArticleImg2 from "./images/entrepreneur/entrepreneur-2.jpeg";
import EntrepreneurArticleImg3 from "./images/entrepreneur/entrepreneur-3.jpeg";
import EntrepreneurArticleImg4 from "./images/entrepreneur/entrepreneur-4.jpeg";

import EconomyCover from "./images/economy-cover.jpeg";
import EconomyArticleImg1 from "./images/economy/economy-1.jpeg";
import EconomyArticleImg2 from "./images/economy/economy-2.jpeg";
import EconomyArticleImg3 from "./images/economy/economy-3.jpeg";
import EconomyArticleImg4 from "./images/economy/economy-4.jpeg";

import NationalCover from "./images/national-cover.webp";
import SmashiTvCover from "./images/smashitv-cover.webp";
import RFFKeynoteCover from "./images/rff-keynote-cover.webp";

import InnovativeCover from "./images/innovative-business/innovative-business-cover.webp";
import InnovativeMoImg from "./images/innovative-business/stella-stays-ceo-mohannad-zikra.webp";
import GBRiseOfHospitalityCover from "./images/gulf-business-rise-of-residential-hospitality-cover.webp";
import GBRiseOfHospitalityAricleImg from "./images/gulf-business-rise-of-residential-hospitality-image.webp";

import EntrepreneurMostInnovativeAward2023 from "./images/entrepreneur/most-innovative-esolution-2023.jpeg";

import EntrepreneurFutureOfRentalsCover from "./images/entrepreneur/future-of-rentals-entrepreneur-cover.webp";
import EntrepreneurFutureOfRentalsArticleImg1 from "./images/entrepreneur/future-of-rentals-entrepreneur-1.jpeg";
import EntrepreneurFutureOfRentalsArticleImg2 from "./images/entrepreneur/future-of-rentals-entrepreneur-2.jpeg";

import TheHeartOfLondon from './images/Inside-the-newly-launched-Stella-Stays-residences-in-the-heart-of-London.jpeg'
import StellaStaysFutureOfLiving from './images/stella-stays-future-of-living.jpeg'
import PioneersOfFutureCorporateLiving from './images/pioneers-of-future-corporate-living.jpeg'

import ZawyaNewBoardOfDirectorsCover from "./images/stella-stays-new-board-of-director.jpg";
import ZawyaChicDebutCover from "./images/zawya-chic-debut-cover.webp";

import CBNMECover from "./images/cbnme-stella-prop.webp";

// Pages
import FiveHiddenGems from "./CustomPages/FiveHiddenGems";
import GenerationStartUp from "./CustomPages/GenerationStartUp";


const getArticle = ({
  title = "",
  subtitle = "",
  author = "",
  content = ``,
  originalUrl = "",
  category = "",
  datePublished = "",
  publishedOn = "",
  headerImage = null,
  headerImageCredit = null,
  logo = null,
  featured = false,
  latest = true,
  homepage = false,
  homepageIndex = 0,
  homepageSubtitle = "",
  media = null,
}) => {
  return {
    id: title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, ""),
    title,
    subtitle,
    author,
    content,
    originalUrl,
    category,
    datePublished,
    publishedOn,
    headerImage,
    headerImageCredit,
    logo,
    featured,
    latest,
    homepage,
    homepageIndex,
    homepageSubtitle,
    media,
  };
};

const articles = [
  // November 14 2023
  // Zawya
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title: "Stella Stays pioneers the future of corporate living",
    subtitle: "Through technology, flexibility, comfort, and community",
    homepageSubtitle: "Stella Stays pioneers the future of corporate living",
    category: "Article",
    datePublished: "2023-11-14",
    publishedOn: "Zawya",
    headerImage: PioneersOfFutureCorporateLiving,
    logo: ZawyaLogo,
    content: `<strong>Dubai, UAE:</strong> Stella Stays, a leading proptech start-up offering tech-enabled residences in seven global markets, including the recently-launched Stella Stays Olaya Towers in Riyadh and Stella Stays Al Barari in Dubai, UAE; has garnered attention from corporations and startups of all scales for revolutionizing corporate living.\n 
    Rather than merely offering accommodations like traditional hotels and serviced apartments, Stella Stays curates a comprehensive and digital experience for enterprise clients, emphasizing simplicity and cost-efficiency, catering to multiple use cases such as executive residence, business trips, relocation, seasonal staffing, etc.\n 
    The brand’s digital experience encompasses the entire corporate living journey from booking to billing, ensuring consistent quality of branded apartments, proximity to business hubs, and 24/7 support. The company’s tech-forward approach simplifies the booking process via its website and provides added conveniences like housekeeping, grocery delivery and transportation via an intuitive mobile app.\n 
    Acknowledging the rise in demand for flexible corporate accommodations, Stella Stays introduced the "Show Up and Start Living" concept, fostering a vibrant urban lifestyle for professionals and entrepreneurs across major cities.\n 
    In current digital age where global dynamics of living, working, and connectivity are changing at high speed, Stella Stays is at the forefront - blending technology, interior design, and community to cater to the evolving needs of modern corporate travellers, such as self-check in, on-demand modern services, co-working spaces and connecting with like-minded people. The company’s CEO and Co-Founder, Mohannad Zikra, remarked, "The shift towards remote, hybrid and flexible work has positioned us as a reliable corporate living partner for business clients. We offer flexible stays ranging from a few days to months, supporting professionals and enterprises across varied locations in MENA and globally."\n 
    Stella Stays is poised as the disruptor creating the future of corporate livings that is built on flexibility, community, cost-efficiency and digital experiences that empowers businesses and economic growth.\n 
    <strong>Stella Stays Olaya Towers, Riyadh</strong>\n 
    Stella Stays Olaya Towers is strategically located in the heart of Riyadh, within 10-minute drive to King Abdullah Financial District, offering corporate travelers the perfect combination of convenience, comfort and modern design. Guests can enjoy Stella Stays’ signature “Show Up and Start Living” concept through the comfort of feeling at home, convenience of self-checkin, modern on-demand services, easy access to business hubs, a vibrant community, and co-working spaces.\n 
    <strong>Stella Stays Al Barari, UAE</strong>\n 
    Nestled in the vibrant heart of Al Barari's lush green landscape, Stella Stays residences seamlessly blend in with an array of premier recreational, commercial, and community amenities. Residents can revel in the peaceful exclusivity Al Barari is recognized for, while also enjoying access to a selection of high-end facilities such as gyms, pools, co-working spaces, restaurants, and supermarkets. The dance of natural beauty and modern amenities forges a living experience that stands unrivaled in Dubai. It's no surprise that Al Barari has quickly become a sought-after destination for executives and entrepreneurs.`,
    originalUrl:
      "https://www.zawya.com/en/press-release/companies-news/stella-stays-pioneers-the-future-of-corporate-living-q73nos4s",
  }),
    // November 09 2023
  // Construction Business News Middle East
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title: `Is Stella Stays the ‘future of living’?`,
    subtitle: "Stella Stays’ Co-Founder and CEO, Mohannad Zikra, details his mission to reinvent global rental and living experience through their portfolio of tech-enabled branded residences",
    homepageSubtitle: `Is Stella Stays the ‘future of living’?`,
    category: "Article",
    datePublished: "2023-11-09",
    publishedOn: "Construction Business News Middle East",
    headerImage: StellaStaysFutureOfLiving,
    logo: CBNMELogo,
    content: `<strong>Stella Stays is a prop-tech startup with a clear mission to revolutionise the global rental landscape. The company envisions a future where individuals can seamlessly integrate into new cities, kickstart their careers, embark on adventures, and immerse themselves in cultural experiences, all while surrounded by like-minded people.</strong>\nTo achieve this vision, Stella Stays is reimagining the global rental and living experience. They do this by offering a portfolio of tech-enabled branded residences that provide residents and guests with a dynamic, community-driven lifestyle. These residences are equipped with modern amenities like co-working spaces, and they offer the unique flexibility to live, work, and travel anywhere.<br><br>Mohannad Zikra, Co-Founder and CEO of <a href="https://www.constructionweekonline.com/tags/stella-stays" target="_blank" data-type="link" data-id="https://www.constructionweekonline.com/tags/stella-stays" rel="noreferrer noopener">Stella Stays</a> highlights his vision, which centres around fostering community living and embodies the Future of Living movement in Saudi Arabia.`,
    originalUrl:
    "https://www.constructionweekonline.com/business/is-stella-stays-the-future-of-living",
  }),
    // October 25 2023
  // Zawya
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title: "Inside the newly launched Stella Stays residences in the heart of London",
    subtitle: "With a presence in markets including UAE, KSA, Turkey, Bahrain, Egypt, the UK, and Canada, Stella Stays boasts a masterfully curated portfolio of tech-enabled branded residences",
    homepageSubtitle: "Inside the newly launched Stella Stays residences in the heart of London",
    category: "News",
    datePublished: "2023-10-25",
    publishedOn: "Zawya",
    headerImage: TheHeartOfLondon,
    logo: ZawyaLogo,
    content: `Dubai, UAE - October 2023 - Stella Stays, the UAE proptech start-up renowned for its disruptive approach to flexible rentals, proudly announces the grand opening of its branded residences in the heart of Baker Street, London. Expanding its real estate portfolio to its seventh market, Stella Stays reaffirms its commitment to redefining global rental experiences. With a presence in markets including UAE, KSA, Turkey, Bahrain, Egypt, the UK, and Canada, Stella Stays boasts a masterfully curated portfolio of tech-enabled branded residences.

    These newly unveiled apartments located at 98 Baker Street, London, epitomize Stella Stays' vision of interior design excellence, embodying the company’s "Show up and Start Living" concept. Nestled in the prestigious Marylebone neighborhood of London, these luxury one and two-bedroom units deliver an unparalleled, tech-infused living experience. Conveniently situated just a brief four-minute stroll from Baker Street Station, Stella Stays Baker Street residences offer immediate access to Central London, including iconic neighborhoods like Mayfair, Regent Street, Soho, and Camden. Marylebone, a bastion of elegance in the capital, grants privileged proximity to two of London's renowned Royal Parks - Regent's Park and Hyde Park.
    
    The apartments emanate an ambience of refined comfort through their carefully curated interiors. Every detail has been thoughtfully selected, from the warmth of heated herringbone wood floors to the intricate stone tiling and sophisticated brass finishes. The kitchens, blending style and functionality seamlessly, feature sleek countertops and ambient lighting. Bedrooms offer luxurious built-in wardrobes, while beautifully tiled bathrooms provide a striking visual contrast within the space.
    
    The open-concept layout showcases a masterclass in space optimization, with strategically positioned windows welcoming abundant natural light while safeguarding residents' privacy.
    
    Stellar space utilization, a hallmark of urban living, has been artfully executed by Stella Stays' in-house design team. The living spaces are adorned with cool greys, subtle earthy tones, and accents of wood and marble. This blend exudes luxury while creating a comfortable retreat from London's ever-changing climate. Bedrooms maintain an airy ambience with delicate fabrics that promote tranquility and relaxation. The incorporation of natural elements, such as indoor plants, pays homage to the surrounding parks and seamlessly blurs the line between indoor and outdoor living. The earthy tones foster an environment conducive to creativity and productivity throughout one's stay.
    
    What sets Stella Stays apart is its cutting-edge, in-house technology platform that underpins efficient operations and a truly modern guest experience. Residents can use the Stella Stays app to streamline the entire process, from booking to payment and ID submission, making it hassle-free and convenient. Integrated smart home devices, including personalized access codes for smart door locks, enhance security and tailor each stay to the individual, ushering in an innovative era of technology for a seamless and contemporary living experience.
    
    Mohannad Zikra, CEO and Co-Founder of Stella Stays, expressed his enthusiasm for the company’s London expansion, stating, "The harmonious blend of interior elements and design at Stella Stays Baker Street culminates in an exceptional living experience within one of London's most desirable neighborhoods. Each detail has been carefully woven together to create an interior space that not only speaks to comfort and style but also encapsulates the essence of city living at its finest.”
    
    As the recipient of the esteemed 2023 Entrepreneur E-Business Awards for "The Most Innovative E-Solution of The Year," Stella Stays continues to redefine flexible rentals by placing technology and inclusive interior design excellence at its core. Stella Stays Baker Street caters to the modern audience, including tech-savvy residents, business travelers, and tourists, setting a new benchmark for flexible living in the city of London and globally.
    
    `,
    originalUrl:
      "https://www.zawya.com/en/press-release/companies-news/inside-the-newly-launched-stella-stays-residences-in-the-heart-of-london-ys9fh8os",
  }),
  // August 9 2023
  // Zawya
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title: "Stella Stays debuts chic, inclusive living spaces in Al Barari",
    subtitle: "Ushering in a new era of design-led residential experiences",
    homepageSubtitle: "",
    category: "News",
    datePublished: "2023-08-09",
    publishedOn: "Zawya",
    headerImage: ZawyaChicDebutCover,
    logo: ZawyaLogo,
    content: `Dubai, United Arab Emirates: Stella Stays, the pioneering proptech startup acclaimed for their groundbreaking approach to flexible rental and modern living, has unveiled its latest venture into the esteemed Al Barari district of Dubai with two remarkable bespoke properties.

    Bringing fresh, inclusive design to the forefront, Stella Stays introduces an exclusive look at their 90 residential units, each exuding an aura of sophistication while personifying the brand's "Show Up and Start Living" ethos. These ultramodern residences, lavishly appointed with contemporary amenities, are set to redefine the tenets of comfort, style, and convenience.
    
    Enveloped in Al Barari's verdant expanse, Stella Stays has masterfully integrated their residences with an eclectic array of recreational and lifestyle facilities, such as  co-working spaces, top-notch gyms, kids’ playground, pools, restaurants, and retails, offering the ideal blend of urban and natural living. These meticulously designed apartments are poised to captivate discerning residents with their effortless fusion of contemporary elegance and functionality, striking a balance between professional and personal life that lends itself beautifully to both business-focused individuals and families alike.

    From their cleverly designed open-plan living spaces that blur the lines between indoor and outdoor life, to their awe-inspiring floor-to-ceiling windows that frame breathtaking views while bathing the interior in a soft cascade of natural light, these units create a serene haven that nurtures creativity and tranquility.

    Reflecting a design ethos of minimalism and sophistication, the tasteful selection of ergonomic furniture and the innovative use of space inspire effortless living, enhancing productivity while keeping clutter at bay. A delicate palette of soothing whites, tranquil greys, and earthy tones are the backdrop to each living space, invoking a sense of calm, while the bedrooms, with their bespoke shades of tranquil blues and greens, provide a peaceful sanctuary for residents to refresh and reinvigorate.

    "Meticulously curated living and socialising experiences are at the heart of Stella Stays, as we strive to redefine the essence of rental living and pioneer new standards of luxury," stated Mohannad Zikra, CEO & Co-Founder at Stella Stays. "The Al Barari residences represent a crucial milestone in our journey, embodying our unique 'Show Up and Start Living' philosophy in one of Dubai's most illustrious neighbourhoods. By creating an idyllic synthesis of comfort, convenience, and the intrinsic beauty of nature, we are dedicated to offering our residents an unparalleled lifestyle experience."
    `,
    originalUrl:
      "https://www.zawya.com/en/press-release/companies-news/stella-stays-debuts-chic-inclusive-living-spaces-in-al-barari-x042wkos",
  }),
  // July 12 2023
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title:
      "Leading UAE Proptech Start-up, Stella Stays, Launches Two Branded Residences in Al Barari, Dubai",
    subtitle: "",
    homepageSubtitle: "",
    category: "News",
    datePublished: "2023-07-12",
    publishedOn: "Construction Business News Middle East",
    headerImage: CBNMECover,
    logo: CBNMELogo,
    content: `Stella Stays, the renowned proptech start-up disrupting the rental landscape with its pioneering flexible living solutions and technology-integrated branded residences, announces the launch of two iconic residences in the prestigious Al Barari, Dubai this July. This highly anticipated launch represents a significant milestone in Stella Stays’ journey to reshape rental experiences.

    Stella Stays Al Barari residences, composed of 90 state-of-the-art units, showcases the brand’s distinctive “Show Up and Start Living” concept. The meticulously designed, fully-equipped apartments offer an unparalleled level of comfort, convenience, and elegance, ensuring a sophisticated living environment and personalized rental experience for residents.
    
    Nestled in the heart of Al Barari’s lush green landscape, Stella Stays residences seamlessly blend with premier recreational, commercial, and community amenities. Residents can immerse themselves in the peaceful exclusivity that Al Barari is renowned for, while also enjoying access to high-end facilities and amenities such as indoor and outdoor sporting venues, swimming pools, botanical gardens, cycling and walking pathways, restaurants, and supermarkets. The harmonious fusion of natural beauty, wellness and modern amenities creates an unrivaled living experience in Dubai.
    
    At Stella Stays Al Barari, residents and guests will embark on a tech-enabled seamless journey from effortless booking and stay management to smart home systems control and guest services – all through the intuitive Stella Stays app. The company offers customized lengths of stay and flexible payment methods, including credit, debit cards, and even cryptocurrencies.
    
    Stella Stays’ strategic expansion into Al Barari aligns perfectly with the company’s vision to become the market leader in furnished rentals, providing their residents with flexible living options in major cities worldwide. Since its inception in 2019, Stella Stays has successfully expanded its presence globally, including the Middle East, Europe, Africa and North America, with over 1,500 apartments and confirmed plans for further expansion. The company, a successful start-up story of the UAE, recently announced its Board of Directors, including His Excellency Faras Al Ramahi, UAE Director-General of the General Pension and Social Security Authority (GPSSA), and former CEO of Abu Dhabi Investment Company.
    
    Mohannad Zikra, CEO and Co-Founder of Stella Stays expressed great excitement: “The launch of Stella Stays Al Barari is a significant milestone in our journey of becoming the unrivaled leader in flexible living, both in the UAE and globally. We aim to deliver an extraordinary level of comfort, convenience, and a uniquely personalized rental experience, distinctively setting Stella Stays brand apart.”
    
    Munaf Ali and Bijan Alizadeh, Founders of Chromium Realty – the property owner, shared their enthusiasm about the impactful partnership: “It is a pleasure to collaborate with Stella Stays to introduce their innovative concept of flexible living in Al Barari. Our synergy will unquestionably redefine the UAE’s rental sector. With the inauguration of these unique residences, we look forward with optimism to more Stella Stays-managed properties that will continue to innovate and set new industry standards.”
    
    As the grand opening of Stella Stays Al Barari approaches, residents and visitors can anticipate a unique and flexible living experience and an innovative rental solution within one of Dubai’s most distinguished neighborhoods.
    `,
    originalUrl:
      "https://www.cbnme.com/news/leading-uae-proptech-start-up-stella-stays-launches-two-branded-residences-in-al-barari-dubai/",
  }),
  // March 02 2023
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title:
      "Leading proptech start-up Stella Stays appoints new member of the Board of Director",
    subtitle:
      "Appoints H E Faras Al Ramahi, UAE Director-General of the GPSSA and former CEO of Abu Dhabi Investment Company to its Board of Directors",
    homepageSubtitle: "",
    category: "News",
    datePublished: "2023-05-11",
    publishedOn: "Zawya",
    headerImage: ZawyaNewBoardOfDirectorsCover,
    logo: ZawyaLogo,
    content: `Dubai, United Arab Emirates: Stella Stays, the leading Middle Eastern proptech start-up with the mission the reinvent rentals globally and the vision to create the future of living, has appointed His Excellency Faras Al Ramahi, UAE Director-General of the General Pension and Social Security Authority (GPSSA) and former CEO of Abu Dhabi Investment Company to its Board of Directors. 

    H.E. Al Ramahi’s key experience and insights in private equity and real estate allows the company to further excel in its financial objectives and accelerate expansion, leveraging his background of 26 years in asset management, corporate finance and investment banking. H.E. Al Ramahi was the Chief Executive Officer of Invest AD - Abu Dhabi Investment Company, having held this position for five years leading to July 2021. 

    H.E. Al Ramahi comments on his new position at Stella Stays: “I am overjoyed to have witnessed Stella Stays’ exponential growth and look forward to its bright future. The company is a successful start-up story of the Middle East and is redefining the future of living by reinventing rentals globally and building technology infrastructure for residential real estate. I am confident they will continue striving.”

    Mohammed Al Ghussein, Chairman of Stella Stays, comments, “With the support of H.E. Al Ramahi’s strategic advice and expert opinion, we are projecting exponential growth for Stella Stays, strengthening our pioneer and market leader position of the new “residential hospitality” category in our core MENA markets and pursue aggressive regional and global expansion plans in 2023.”

    Stella Stays is disrupting rentals globally with its innovative business model and key offerings of tech-enabled branded residences. The company offers residents, business travellers, and holidaymakers a seamless ‘Show Up and Start Living’ experience in major cities around the world. The company is recognised for its innovative approach, providing consumers with on-demand furnished apartments and a community-driven lifestyle with modern services. 

    Residents and guests at Stella Stays can navigate the entire journey, from booking, checking in, and controlling smart home systems, to ordering guest services on the Stella Stays mobile app. The company further simplifies the rental experience by offering flexible payment terms, which can be made through various methods, including debit, credit cards and crypto payments. 

    On the real estate supply side, Stella Stays comes in as a one-stop-shop for property owners, real estate developers and investors to monetize their properties. The company offers master leases or profit-sharing agreements; while infusing the latest technology to increase operations efficiency, allowing for up to 40% higher return than traditional residential or hospitality tenants.

    Set to become the world’s most-loved residential hospitality brand, Stella Stays has witnessed exponential growth and is now present in seven markets across MENA, Europe and North America since its inception only three years ago. The company currently operates in UAE, KSA, Turkey, Egypt, Bahrain, the United Kingdom, and Canada with more than 1,500 contracted units. The proptech start-up is profitable and cash flow positive. 
    `,
    originalUrl:
      "https://www.zawya.com/en/press-release/people-in-the-news/leading-proptech-start-up-stella-stays-appoints-new-member-of-the-board-of-director-ejgotfti",
  }),
  // March 02 2023
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title:
      "The Future of Rentals Is Hospitality-Inspired And Community-Oriented",
    subtitle:
      "The future of rentals in the MENA region is hospitality and community-inspired, and Stella Stays is at the forefront of this trend.",
    homepageSubtitle: "",
    category: "Article",
    datePublished: "2023-05-02",
    publishedOn: "Entrepreneur",
    headerImage: EntrepreneurFutureOfRentalsCover,
    logo: EntrepreneurLogo,
    content: `As the world emerges post the COVID-19 pandemic, the way we live, work and connect with each other has changed. For many people, flexible work arrangement has become viable options and prompted a shift in priorities towards flexibility and mobility. People are no longer tied to traditional economic hub cities and are moving to different locations to find better opportunities and enjoy a better quality of life.

    However, finding quality rental spaces that feel like home in safe neighborhoods and creating meaningful connections remain a challenge. The traditional rental process can be complicated and lengthy. Even worse, many rental experiences are soulless and lack a sense of community. It's no surprise that social isolation and loneliness are becoming more prevalent. <a href="https://www.webmd.com/balance/what-to-know-about-emotional-health" target="_blank" rel="noopener noreferrer">Research shows that the impact of loneliness and lack of social connection on our health equals to smoking 15 cigarettes a day.</a>

    Amina is a young professional who recently moved to Dubai in search of job opportunities. She quickly found that traditional rental is a frustrating and expensive experience. She spent weeks searching for a place to live, dealing with fake listings and untransparent real estate agents. As a newcomer to the city, she also struggled to connect with like-minded people. Then she discovered Stella Stays' tech-enabled residences in the upcoming Jumeriah Village Circle neighborhood, with flexible payment terms and modern services, such as housekeeping and grocery shopping at a tap of a button of the guest app. Not only did she find a high-quality living space, but she also quickly became part of a vibrant community, making friends with other residents coming from around the world.

    The Alis, a family of three, faced significant challenges in their search for a comfortable, modern home in Riyadh following a job transfer related to Vision 2030. However, their luck changed when they discovered Stella Stays- a premier residence located a mere five minutes away from the bustling financial district where Ali works. From the moment they moved in, the Alis were struck by the vibrant community of newly relocated professionals and families with children that had also made Stella Stays their home. Their children quickly found friends among their peers, while the parents formed close bonds and offered each other invaluable support with everyday tasks like school drop-offs and pickups. More than three months have now passed since the Alis settled into their Stella Stays residence, and they have no intention of leaving any time soon, having found a true home away from home.

    A digital nomad named John travels the world for work and was looking for a flexible living solution that would allow him to focus on his work and also enjoy the local culture while scaling his business across the Middle East. After discovering Stella Stays residences, John was impressed by the "show up and start living" and pay-as-you-go rental concept, the branded experience, and the community-oriented approach. He has been living at various Stella Stays residences around the region for over a year now and has made friends in different cities. He enjoys attending Stella Stays community events and networking with other digital nomads and entrepreneurs. Stella Stays' vibrant and productive community has made John's life more expansive and productive.

    <img src="${EntrepreneurFutureOfRentalsArticleImg1}" class="rounded w-100 h-auto" />

    That's where Stella Stays comes in. The Middle Eastern prop-tech startup took three years to refine its offer around one of the biggest consumer groups in the world- the millennials and Gen Z renters, hungry for more flexible rentals, and better quality living. The result is a portfolio of tech-enabled branded residences that are thoughtfully designed, offering community experience with flexible terms in major cities globally.

    Stella Stays residences are intentionally designed for a multidimensional experience- Live, Work, Connect, Unwind; and equipped with community-oriented amenities such as coworking spaces and communal areas. The company organizes community events to foster meaningful connections between residents and guests. In a recent focus group, 90% of Stella Stays' residents cited "community" and "feeling of home" as the reasons they choose to stay at Stella Stays residences for a longer term, transfer between Stella Stays properties around the world, and refer new customers.

    At the heart of Stella Stays' successful business model lie three pillars that have cemented its reputation as a leader in the hospitality industry: exceptional living spaces, a vibrant sense of community, and innovative housing solutions. With a presence in seven countries spanning three continents, the company has established a global footprint that consistently operates at near-full capacity throughout the year. Driven by an unwavering commitment to meet growing demand, Stella Stays has bold plans for continued regional and global expansion, signalling a determination to deliver on its promise of exceptional living experiences for guests across the world.

    In today's rapidly changing world, the need for innovative housing solutions has never been greater. Stella Stays is well-positioned to meet this demand, delivering exceptional living experiences that cater to the needs of next-generation consumers. For many, the dream of homeownership and expansive living spaces is fast becoming unattainable, while businesses seeking to expand globally often face daunting challenges when it comes to housing their workforce. Governments, too, are grappling with the sustainability challenges posed by the staggering growth of the global population, which now stands at eight billion people.

    Stella Stays addresses these challenges head-on with tech-enabled, high-quality housing solutions that can be scaled to meet the needs of individuals, communities, and nations. With a range of offerings that includes both rentals and ownership (in the pipeline), Stella Stays empowers people to thrive, businesses to grow, and nations to flourish.

    <img src="${EntrepreneurFutureOfRentalsArticleImg2}" class="rounded w-100 h-auto" />

    Stella Stays residences are built on the principles of flexibility, digitalization, and instant access while adding the extra touches inspired by hospitality. The company also offers flexible lease terms, various payment options, and modern guest services ranging from housekeeping, and grocery delivery to babysitting and on-demand chef, etc. The Stella Stays app offers a seamless and personalized experience for selecting and booking a place, signing contracts, making payments, submitting ID, and checking in within minutes.

    As modern consumer preferences shift towards access over ownership of housing, mobility and flexibility, and expansive living experience, innovative prop-tech solutions like Stella Stays will continue to gain traction. The future of rentals in the MENA region is hospitality and community-inspired, and Stella Stays is at the forefront of this trend.
    `,
    originalUrl:
      "https://www.entrepreneur.com/en-ae/lifestyle/the-future-of-rentals-is-hospitality-inspired-and/450721",
  }),

  // March 14 2023
  // Entrepreneur The E-Business Awards 2023
  getArticle({
    featured: false,
    homepage: false,
    author: "",
    title:
      "Entrepreneur.com named Stella Stays 'The Most Innovative E-Solution of 2023",
    subtitle: "",
    homepageSubtitle:
      "Entrepreneur.com named Stella Stays 'The Most Innovative E-Solution of 2023",
    category: "Awards",
    datePublished: "2023-03-14",
    publishedOn: "Entrepreneur",
    headerImage: EntrepreneurMostInnovativeAward2023,
    logo: EntrepreneurLogo,
    content: ``,
    media: [
      {
        url: "https://www.youtube.com/embed/n4NLmJqq4Ps",
        type: "video",
      },
    ],
    originalUrl: "https://e-businessawards.com/",
  }),

  // March 02 2023
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    author: "Mohannad Zikra",
    title: "The rise of residential hospitality",
    subtitle:
      "Consumer demand for a hassle-free and engaging community-driven living experience is driving the growing popularity of residential hospitality",
    homepageSubtitle: "The rise of residential hospitality",
    category: "Interview",
    datePublished: "2023-03-02",
    publishedOn: "Gulf Business",
    headerImage: GBRiseOfHospitalityCover,
    logo: GulfBusinessLogo,
    content: `The new generation of consumers want to show up in cities (across the world), start living and connecting with like-minded people within minutes; instead of wasting time and money on traditional rentals or overpriced hotels.

    This trend has led to the emergence of a new concept called "residential hospitality", which is defined as offering "new living experiences that make the world everyone's oyster".
        
    The market opportunity for this new category is primarily driven by the following trends and factors.
        
    The next generation accounts for half of the world's consumers and they have new needs and behaviours, which are fundamentally changing the way people live, work and consume. Millennials and Generation Z accounted for 46 percent of the global population as of 2021, making them the most important consumer segment of the present and future.
        
    Empowered by technology, younger consumers are increasingly driven by speed, convenience, value and experience (for example Uber, Airbnb, Amazon, Instashop, Deliveroo). They prefer instant access over ownership (for example Netflix, Spotify). They value modern amenities, worldly opportunities, connection and entertainment (for example WeWork). However, their biggest spending - housing, which accounts for about 30 per cent of an individual's monthly budget - is a painful, inflexible, costly and outdated experience - for them.
        
    Traditional home ownership is becoming increasingly inaccessible for the next generation, leading to rentals becoming a permanent housing solution. Existing rental solutions, however, are unfit for the new generation. Current rental solutions require significant upfront costs (rent payment, furniture and other costs), have limited flexibility, usually with a year-long lease term, and provide zero to limited access to services, modern amenities (such as coworking spaces) and social networking.
        
    Traditional rental is not only a painful and inflexible process, but also an isolating and lonely experience. Social isolation is the lack of social ties or social contact, while loneliness is the subjective feeling of being lonely or having inadequate social support. These conditions were thrust into the spotlight as the world grappled with the Covid-19 pandemic.
        
    The future of living definitely features residential hospitality. While it is still in its infancy, we are witnessing incredible customer adoption of this concept in the region, as well as selected global markets. A testament to this fact is that our own tech-enabled branded residences, which also provide community-oriented amenities and events, reached 100 per cent occupancy within eight weeks of launching.
    `,
    originalUrl: GBRiseOfHospitalityAricleImg,
  }),
  // January 24 2023
  // Real Estate Future Forum l منتدى مستقبل العقار
  getArticle({
    featured: false,
    homepage: false,
    author: "Mohannad Zikra",
    title:
      "Stella Stays' CEO Mohannad Zikra on imagining the future of living in Saudi Arabia",
    subtitle: "",
    homepageSubtitle:
      "Stella Stays' CEO Mohannad Zikra on imagining the future of living in Saudi Arabia",
    category: "Keynote",
    datePublished: "2023-01-24",
    publishedOn: "Real Estate Future Forum l منتدى مستقبل العقار",
    headerImage: RFFKeynoteCover,
    logo: RFFLogo,
    content: `<a href="https://www.linkedin.com/company/stella-stays/" rel="nofollow">Stella Stays</a> on imagining the future of living in Saudi Arabia - a keynote by our CEO <a href="https://www.linkedin.com/in/ACoAAALQBlMB_8mo437uhFcras317oq0gLpJZD8" rel="nofollow">Mohannad Zikra</a> at the 2023 KSA <a href="https://www.linkedin.com/company/real-estate-future-forum/" rel="nofollow">Real Estate Future Forum l منتدى مستقبل العقار</a>.`,
    media: [
      {
        url: "https://www.youtube.com/embed/_Gryq_oUOVo",
        type: "video",
      },
    ],
    originalUrl: "",
  }),
  // January 21, 2023
  // Arab News
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "Stella Stays",
    title: `PropTech to expand Saudi footprint with its innovative business model`,
    subtitle: "",
    homepageSubtitle: ``,
    category: "News",
    datePublished: "2023-01-21",
    publishedOn: "Arab News",
    headerImage: InnovativeCover,
    logo: ArabNewsLogo,
    content: `RIYADH: Aiming to reimagine the global living experience through its portfolio of tech-enabled branded residences Stella Stays is planning to become the biggest residential hospitality player in the region, said its co-founder and CEO.

    Speaking to Arab News in an exclusive interview, Mohannad Zikra said that the Dubai-based PropTech startup that is disrupting the global residential real estate sector with its innovative business model will be adding about 2,500 apartments regionally this year of which 50 percent is going to be in Saudi Arabia.
    
    “We’re adding just over 1,200 apartments mainly focused on Riyadh but we’re also planning to launch soon in Jeddah,” he said. “We’re also looking at Dammam and Alkhobar.”
    
    Zikra went on to add that he is eyeing opportunities in projects in the Kingdom where they are creating new cities. The Saudi Downtown Co., a master and lead developer owned by the Public Investment Fund, with 12 projects located in 11 regions across the Kingdom, for instance, is witnessing a lot of growth and Zikra is keen to tap opportunities in such projects.
    
     Having started in 2019 by creating an offering where people can find and rent and move into a place within a few minutes, Stella Stays has rapidly grown its portfolio. It is keen to continue its expansion across major cities in the Middle East and North Africa region, Europe and North America.
    
    Zikra wants to continue to focus on the region over the next 18 months. “We will obviously continue our growth in the UAE, Saudi Arabia, Egypt, and Turkey but we’re also looking at Morocco as a huge market for us,” he said. “Then we’re looking at Qatar as a potential market as well after what happened in the World Cup.”
    
    Moving forward, he explained, Stella Stays will be looking at some of the emerging markets that have huge growth opportunities. Particularly countries like India, Indonesia, and Vietnam.
    
    “You have Portugal as well that’s introduced the freelance visas,” he informed. “So in the next 24 months, we’ll go to markets like Asia that have huge growth potential and are among the top contenders in gross domestic product growth.”
    
    Staying focused
    
    When Stella Stays started it had some private investors in the UAE but today a lot of what the company is doing is partnering up directly with real estate developers. “When we partner with real estate developers, we’re able to take the buildings and we’re able to rebuild technology that allows us to reach 100-percent occupancy in our buildings within eight weeks,” Zikra informed.
    
    “Hence, we’re able to generate cash flow very quickly from these buildings. And that’s been helping us fund a lot of our growth. So we took a different approach than some of these other startups that are just raising money to raise money.”
    
    Unlike a lot of startups that are struggling because they focused on growing without caring about profitability, Zikra was always clear about building a profitable business from the very outset.
    
    Not surprisingly, Stella Stays is not only profitable but also cash flow positive.
    
    “We’re growing increasingly fast,” said Zikra. “Our average growth rate is about 250 to 300 percent per year since we started in 2019 until 2022.”
    
    Having been successful in utilizing its funds, Zikra is now working with banks as well to help build a strong foundation and grow the team.
    
    Stella Stays is also looking at partnering up with real estate investment trust funds because, according to Zikra, they have often faced difficulties in finding the right real estate investments. His company could be a good match for these funds as it has a successful business model with around 80 percent of its furnished apartments at full capacity at any one time.
        
    <img src="${InnovativeMoImg}" class="rounded w-100 h-auto" />
    <figure class="text-center">
  <blockquote class="blockquote">"We’re growing increasingly fast. Our average growth rate is about 250 to 300 percent per year since we started in 2019 until 2022."</p></blockquote><figcaption class="blockquote-footer">Mohannad Zikra</figcaption></figure>
  By all accounts, there is a lot of demand for such apartments and people are also willing to pay a premium for them.

  He added: “By 2024 we are looking at adding around 5,100 units. And for that, we’re starting to look at strategic partners and investors in the region, especially investors that are backed by sovereign investment funds because we’re seeing that there’s a very close relationship between what we’re doing and what we can contribute toward a lot of the government initiatives from a housing perspective.
  
  “You look at Saudi Arabia and Vision 2030, there is a huge plan to grow the population,” Zikra continued. “And with that, they have needs to add over 100,000 homes over the next three years. And this means that these homes are going to have to be rented out. And they’re looking for partners where they can simplify that process.”
  
  This is where a company like Stella Stays comes in. “We want to come in as a professional furnished apartment operator where we can come in and provide that consistency that you get in a hotel and provide that service,” said Zikra.
  
  Branded, tech-enabled experience
  
  “We don’t just take single apartments but we actually work with real estate developers and we take over buildings,” Zikra said.
  
  “When you book a furnished apartment at Stella Stays, you are coming into a fully managed, branded tech-enabled operator, where from the moment you walk into the building, it’s our brand,” he added.
  
  “In all of the apartments that are managed by us we provide that same consistency where you know you’re going to get a clean place and you know there’s going to be 24/7 support for your stay, whether it’s for one night, one week or one month or more.”
  
  To its credit, Stella Stays has digitized the whole guest journey. It has done much the same thing with home rentals that Uber did with ride-hailing. A lot of its guests and residents come to its app or website, see all the different apartments that they have across the different cities, and choose the one they want.
  
  “They can then choose their dates and pay by credit cards or pay by crypto,” explained Zikra.
  
  “Then after that, they receive information for them to check-in. We have smart door locks across all of our units and access. They find the place, they pay for it, they move in, they can request services and all this without having to ever deal with a person.”
  
  He added: “There’s no need to deal with real estate agents, no need to pick up the phone and make calls. Instead, we have completely digitized the experience.”
  
  Way forward
  
  Asked who his competitors were, and pat came Zikra’s reply: “Our competitors today are nothing other than just traditional landlords who own properties and rent them out on Property Finder and all these different marketplaces or these building owners who want to rent out their apartment on their own. That’s what we want to take over.”
  
  “And that’s why more than anything, we’re partnering up with them and saying, listen, we can take over your assets,” he explained. “Just like Amazon’s done with shopping goods, we can do that with furnished apartments.”
  
  He added that they have entered a brand new space that they have termed “residential hospitality.” According to Zikra residential hospitality is the ability to rent a residential apartment in a branded way.
  
  “We want to take over and become the biggest landlord globally with our concept of allowing people to just show up and start living,” he concluded.
    `,
    originalUrl: "https://www.arabnews.com/node/2236641/business-economy",
  }),

  // January 19, 2023
  // Hotel News ME
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "Stella Stays",
    title: `Residential hospitality PropTech Startup Stella Stays lands in London`,
    subtitle:
      "The luxury branded residences located in Baker Street, at the heart of London, will mark the company’s seventh geographical market, on its continued mission to reinvent rental globally.",
    homepageSubtitle: ``,
    category: "News",
    datePublished: "2023-01-19",
    publishedOn: "Hotel News ME",
    headerImage: ZawyaCover,
    logo: HCNMELogo,
    content: `Stella Stays, the award-winning, leading “PropTech start-up disrupting the global residential real estate sector with its innovative business model and key offering, officially announces its expansion into the United Kingdom. The luxury branded residences located in Baker Street, at the heart of London, will mark the company’s seventh geographical market, on its continued mission to reinvent rental globally.

    The newly-launched apartments further represent Stella Stays’ “Show up and Start Living” concept, boasting luxury units perfectly situated in Marylebone, London, offering modern, fully-equipped one and two-bedroom spaces, along with other amenities.
    
    The tower is conveniently located within a four-minute walk of Baker St. Station, a prime location providing immediate access to Central London, including, Mayfair, Regent Street, Soho and Camden, all within the vicinity. One of the most desirable and well-placed addresses in the capital, Marylebone, offers a multitude of world-class eateries, such as Nobu, as well as being home to the finest artisanal suppliers and a myriad of high-end boutique stores. Baker Street offers its residents an opportunity to revel in a city like no other. Immersed in a part of London surrounded by an array of spacious and spectacular parks, the residence creates an oasis of calm amidst the hustle and bustle of London city living. Residents will benefit from an unrivalled ease of access to some of the world’s most famous parks, including two of the famed Royal Parks – Regent’s Park and Hyde Park, which are filled with luscious greenery and glorious foliage.
    
    Residents and guests staying at Stella Stays residences are able to navigate their entire journey – from booking and managing their stays, checking in, and controlling smart home systems, to ordering a wide range of guest services – through the Stella Stays app. Payment terms are flexible and can be made through various methods, including debit, credit cards and cryptocurrencies.
    
    Mohannad Zikra, CEO and Co-Founder of Stella Stays, commented on the expansion: “In 2023, our strategy is to focus on global expansion. London is a vibrant and multicultural city that broke a new record by welcoming over 40 million foreign visitors in the last few years and is expecting to host more than 60 million tourists by 2025. At the same time, London now ranks second in terms of the number of short lets available, with more than 60,000 properties listed. We have witnessed a tremendous demand for homes in London in the last few months, with both the long-term and short-term rental market at its peak, making it the best time for us to enter into the UK market.”
    
    Winner of the 2022 Gulf Business Award for “PropTech Company of the Year”, the 2021 Stevie Awards for the “Most Innovative Tech Start-Up of the Year” and “Innovative Achievement in Sales” for its technology and revenue generation strategy, Stella Stays continues to redefine rentals, creating tech and community-oriented living experience, catering to a new generation of tech-savvy residents, business travellers and tourists.
    
    Stella Stays is a successful start-up story from the Middle East. The profitable “PropTech company has grown exponentially since its inception in 2019 and is now present in
    
    seven markets. The company currently operates in the UAE, KSA, Turkiye, Bahrain, Egypt, London and Canada, with over 1,000 contracted units, and confirms plans for further global expansion.`,
    originalUrl:
      "https://www.hotelnewsme.com/hotel-catering-me/residential-hospitality-proptech-start-up-stella-stays-lands-in-london/",
  }),

  // December 12, 2022
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "Stella Stays",
    title: `PropTech start-up Stella Stays expands footprint, opens second residence tower in Riyadh`,
    subtitle:
      "The Olaya Tower 2 offers furnished apartments, a co-working space and other recreational and commercial services",
    homepageSubtitle: `Stella Stays named the winner of the Gulf Business Awards 2022 for "PropTech Company Of The Year"`,
    category: "News",
    datePublished: "2022-12-12",
    publishedOn: "Gulf Business",
    headerImage: GulfBusinessOlaya2,
    logo: GulfBusinessLogo,
    content: `Winner of the 2022 Gulf Business Award for “PropTech company of the year”, Stella Stays continues to redefine rentals and hospitality, catering to a new generation of tech-savvy residents, business travellers and tourists.

    Five months after the launch of Olaya Tower 1, its first concept residences in the kingdom, the “PropTech company continues the growth trajectory with the opening of the second building – Olaya Tower 2.
    
    The recently unveiled skyscraper, which has 80 units spread across 16 stories and offers contemporary, completely furnished apartments, a co-working area, as well as other recreational and commercial services, is another example of the company’s “Show up and Start living” philosophy.
    
    The tower is located in North Riyadh, within 16 minutes of Riyadh Airport, 10 minutes to King Abdullah Financial District, 10 minutes to Kingdom Tower and seven minutes to Riyadh Park Mall.
    
    Menawhile, the first Olaya Tower operates at full capacity, welcoming business travellers, residents and tourists seeking modern accommodation.
    
    Residents and guests at the Olaya towers are able to navigate their entire journey – from booking and managing their stays, checking in, and controlling smart home systems, to ordering a wide range of guest services – through the Stella Stays app. Payment terms are flexible and can be made through various methods, including debit, credit cards and cryptocurrencies.
    
    Mohannad Zikra, CEO and co-founder of Stella Stays, commented on the expansion: “We are pleased to have witnessed exceptional demand for our tech-enabled branded residences – which offer residents and guests a modern, community-driven lifestyle – in the city of Riyadh. We look forward to further expanding in the Kingdom of Saudi Arabia, providing our solutions to residents and real estate developers, assisting the government for the years to come, as the population and the economy grow exponentially”`,
    originalUrl:
      "https://gulfbusiness.com/proptech-stella-stays-expands-footprint/",
  }),

  // NOVEMBER 23, 2022
  // Economy
  getArticle({
    featured: true,
    homepage: false,
    homepageIndex: 1,
    author: "Stella Stays",
    title: `Stella Stays named the winner of the Gulf Business Awards 2022 for "PropTech Company Of The Year"`,
    subtitle: "",
    homepageSubtitle: `Stella Stays named the winner of the Gulf Business Awards 2022 for "PropTech Company Of The Year"`,
    category: "News",
    datePublished: "2022-11-23",
    publishedOn: "Gulf Business",
    headerImage: GulfBusinessWinnerCover,
    logo: GulfBusinessLogo,
    content: `Gulf Business held a dazzling ceremony for the 10th edition of the Gulf Business Awards on November 23, with the region’s business leaders and trailblazers in close attendance.

    The prestigious awards, which completed a decade this year, were held at the Central Park Towers DIFC in Dubai, to laud stalwarts and pioneers who not only helmed their companies, but also steered them to greater heights and visibility during the year.
    
    A total of 35 awards were presented to eminent business leaders and companies across multiple industrial verticals, including tourism, hospitality, transport and logistics, real estate, banking, finance and investment, healthcare and retail. The real estate and construction category was expanded this year to include coveted awards for subcategories, such as developer, innovative project, real estate agency and PropTech of the year.
    
    Stella Stays named the winner of the Gulf Business Awards 2022 for "PropTech Company Of The Year`,
    originalUrl:
      "https://gulfbusiness.com/revealed-winners-at-the-gulf-business-awards-2022/",
  }),
  // October 31, 2022
  // Economy
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "",
    title: "There’s a smart way to rent and live and this PropTech found it",
    subtitle: "Stella Stays putting rental power in the hands of its residents",
    homepageSubtitle:
      "There’s a smart way to rent and live and this PropTech found it",
    category: "Interview",
    datePublished: "2022-10-31",
    publishedOn: "Economy Middle East",
    headerImage: EconomyCover,
    logo: EconomyLogo,
    content: `
    <img src="${EconomyArticleImg1}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Mohannad Zikra, CEO and co-founder of Stella Stays
    </small>
    Picture a day when there is no need to meet a landlord or real estate agent, and no need to pay commissions when renting an apartment. Imagine a day when you can decide how long to stay, how much to pay, and who from a community of like-minded people to hang out with. 

    Sounds like a rental wonderland? Oh, it’s real. This new concept in residential hospitality is disrupting age-old ways of renting apartments. 
    
    “We believe this is going to be the future of renting. People will no longer sign year-long tenancy contracts, will want to pay monthly, and will be able to personalize services, co-work, and network, and have the flexibility to move,” Mohannad Zikra, CEO and co-founder of Stella Stays, told Economy Middle East in a recent interview.
    
    <img src="${EconomyArticleImg2}" class="rounded w-100 h-auto" />
    
    <strong>Who is Stella Stays?</strong>
     
    
    Stella Stays is not a developer with slick ideas on how to run their business. No, they are a 3-year-old proptech startup that negotiates deals with developers, allowing the company to take over the design, setup, and management of entire apartment buildings, allowing real estate investors, property owners, and builders to truly capitalize on their investments. 
    
    Three years into operation, the company grew from managing a single apartment in Dubai to now handling over 1000 units across several countries, all while enjoying healthy margins. 
    
    “In the beginning, it was a hospitality brand where the average booking was 4-5 nights. When COVID-19 hit, we started to shift towards longer-term stays. We’ve had people stay with us for an entire year. Our average length of booking is currently 28 nights,” Zikra revealed.
    
    So, what is it that Stella Stays is disrupting exactly?
     
    
    Well, mainly the hospitality sector and online marketplaces promoting short stays. 
    
    Zikra said the hotel sector is one of the hospitality industries that did not innovate, despite recent digital transformation efforts on their part.
    
    “It’s largely still about wasting time in the lobby, waiting in line for passport checking, getting a room’s keycard, and receiving food service on a tray. It’s antiquated,” Zikra opined.
    
    “What hotels have in their favor is consistency, which is what Airbnb does not have. Airbnb is a marketplace that invites people to list their residences, and while one could find great short-term stay deals, many people, especially in this region, are hesitant to book with Airbnb because they don’t know what they’re going to get. It’s not a brand that is consistent like the Four Seasons or the Holiday Inn.” 
    
    Stella Stays has its unique market positioning, where they manage the whole experience in the building, introducing unique designs, tech-enabled devices, smart living spaces, and community activities. 
    
    “We encode the lobby into your phone, allowing residents to use smart devices to extend their stay or personalize services. We embed workstations and coworking spaces for tenants, allowing residents to experience a community in the building,” Zikra described.
    
    The business concept behind Stella Stays represents a three-party win-win-win scenario. To find out how and why it works, let’s start with the developers.
    
    <img src="${EconomyArticleImg3}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Stella Stays signs with Tameer
    </small>
    <strong>Developers: Unique branding and steady cash flow</strong>
     
    
    A good place to start is Egypt. In September 2022, Stella Stays announced its expansion into Egypt, introducing a prime location in New Cairo, through its agreement with Tameer, Egypt’s oldest residential developer. 
    
    “Egypt is a great market in terms of size and investments taking place in real estate, so we created a completely different segment for developers – which we call “residential hospitality” -other than the option of either selling or renting, which was something they have been doing over the last 100 years,” Zikra said.
    
    Following the devaluation of the Egyptian currency, developers there also tended to keep ownership of their buildings but were not sure what to do with them.
    
    “We came in and said we can help you monetize and generate more revenue for your property, offering technology, establishing a residential hospitality brand, setting up and renting the units out. That’s our collaboration with Tameer,” Zikra described.
    
    And while currency devaluation was incentivizing enough for developers in Egypt, investors and property owners all over the world are seeing the value of Stella Stays’ offer.
    
    “We have 2 models in place. One is the master lease where we would rent out the whole building for 10 years with an option for renewal,” Zikra began. 
    
    “The second model, a revenue sharing scheme, is where we essentially operate the building on behalf of the developer, investor, or real estate owner, and we pay them a fixed percentage of the net revenues. In this revenue sharing model, they can generate up to 40% more returns in comparison to the traditional rental approach.”
    
    Why you may ask? 
    
    In the current inflationary times, prices of building materials are going up and rental yields are going down. 
    
    “The rental is a commodity. At the end of the day, what differentiates one property from another is brand creation, setting up the building, and putting in place proper amenities, all of which help property assets become more valuable,” Zikra clarified.
    
    “We also realized that we are collecting a lot of data about rental sizes, occupancy percentages, and revenue per rented unit. These are helping developers decide what to build.”
    
    And when it comes to master leases, the first model, developers there are guaranteed returns over an extended period, even in the event of a real estate downturn.
    
    “We get a discounted rate when leasing in bulk, as we take on 50 to 100 units plus, and developers are guaranteed a big chunk of rental income. To mitigate our risks, we have a very detailed underwriting process with every contract that we sign, which typically includes clauses that allow us to exit, tying those clauses to rental indices or other economic factors.” Zikra said.
    
    <strong>Stella Stays: In good times or bad</strong>
     
    
    The really interesting thing about Stella Stays is that it delivers a product that surprisingly works well in times of prosperity or insecurity.
    
    “Our business model works well when the economy is doing great and when business and tourism travel is vigorous. However, there are many use cases in an economic downturn as well when residents are not sure whether they should sign a one-year contract. Potential tenants want the flexibility of payment options, contract terms, and the ability to upsize or downsize.”
    
    According to Zikra, “Hotels had extremely low occupancy rates during COVID times, “while we maintained an 85% occupancy rate across all of our properties, and 75% was from the residents of the city looking for that new model of flexible rental, where they can cancel anytime.”
    
  
    <img src="${EconomyArticleImg4}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Tenants: Trendy, flexible, and practical spaces
    </small>
    
    A community-driven living concept is at the core of Stella Stays’ offering to tenants, featuring, whenever possible, co-working and communal spaces as part of the accommodations and rental contract. 
    
    In Riyadh, Stella Stays enjoys a 100% long-term and corporate tenancy from companies such as management consulting firms or startup founders, who get together in a co-working space to meet like-minded people.
    
    “Those staying with us from the beginning are the early adopters, the tech-savvy, the next-gen. Our unique concept of building and community experience allowed us to differentiate and set up a pricing strategy that works based on the added value that tenants are receiving,” Zikra explained.
    
    The co-working space becomes an amenity in most Stella Stays buildings like the gym or pool usually is.
    
    “Within the app, we incorporate things such as personalized services, showing the best coffee shops or the best gyms in the area, all of which we pre-screen. We’re trying to make the transition into a neighborhood or a city as easy as possible by creating the neighborhood and city guide for our guests and residents,” Zikra said. 
    
    “In Egypt, where we are launching, the building has a cinema room and a large open kitchen area where tenants can gather. The “cool” amenities allow us to blur the lines between short-term and long-term bookings. It’s a furnished apartment in a great building. The longer one stays, the better the pricing incentive is.” 
    
    Tenants also get to enjoy apartments with different interior design perspectives catered to the length of their stays. 
    
    “We will dedicate a certain number of floors for shorter-term units and others for longer-stay units. Short-term units are vibrant to match the personas of the people that rent them for their holidays, while longer-term ones focus more on neutral colors, extra comfort, and have all the amenities that make sense for extended living,” Zikra illustrated.
    
    Zikra said that Dubai, Riyadh, Jeddah, and New Cairo are the main focus areas for Stella Stays. 
    
    “Lots of growth potential for great accommodation spaces in the UAE and Saudi. The population in Saudi, in particular, is young and entrepreneurial. In New Cairo, developers are looking to differentiate themselves from the competition with new concepts,” Zikra explained.
    
    “People love staying with us because they just need to show up and start living. It’s smart, hassle-free, and socially alive. We call it residential hospitality.”`,
    originalUrl:
      "https://economymiddleeast.com/news/theres-a-smart-way-to-rent-and-live-and-this-proptech-found-it/",
  }),
  // Oct 17, 2022
  // Entrepreneur
  getArticle({
    featured: false,
    homepage: true,
    homepageIndex: 1,
    author: "",
    title:
      "PropTech Firm Stella Stays Is Reinventing How We Find Homes For Ourselves",
    subtitle:
      "By combining the best of hospitality and real estate, Stella Stays offers flexible terms of stay at its furnished rental units while also claiming to provide amenities that foster a sense of community.",
    homepageSubtitle:
      "PropTech Firm Stella Stays Is Reinventing How We Find Homes For Ourselves",
    category: "Interview",
    datePublished: "2022-10-17",
    publishedOn: "Entrepreneur",
    headerImage: EntrepreneurCover,
    logo: EntrepreneurLogo,
    content: `In April 2022, the launch of the UAE's remote working visa scheme -an arrangement that allows for people to live in the UAE while working for employers outside the country- opened the nation's doors to remote workers from across the globe. The potential influx of such digital nomads -described by Statista as a faction of the global population that "generally combine remote work and travel for various reasons and lengths of time"- thus presents quite a unique challenge to the UAE's real estate and hospitality markets.

    <img src="${EntrepreneurArticleImg1}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Stella Stays
    Mohannad Zikra, co-founder and CEO, Stella Stays
    </small>
    "A lot of professionals, especially millennials and Gen Zs, these days are on freelance visas; they're digital nomads who want to come to Dubai to check it out for a few months," says Mohannad Zikra, co-founder and CEO of Dubai-based proptech company Stella Stays. "But what options do they have [for their stay]? Hotels are often very costly and inconvenient, but they can't sign a one year lease for an apartment either. What we thus realized is that this next generation needs flexibility- the ability to pay by credit card, pay monthly, and be in a place that gives them the value, the experiences, and the community that they want."
    
    And this is exactly what Zikra's enterprise, Stella Stays, offers. Launched in 2019, the company offers furnished residences and suites on a subscription-basis for as short a duration as one night to longer periods of more than a year. The vision that Stella Stays aims to achieve is to help people simply "show up and live," and apart from the UAE, its furnished residences can also be found in Saudi Arabia, Bahrain, Turkey, and Canada.
    
    <img src="${EntrepreneurArticleImg2}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    A Source: Stella Stays
    </small>
    According to Zikra, Stella Stays' main strength lies in its promise to offer a seamless and quick selection process using the company's own digital platform- an aspect, he believes, is beneficial to both the property developers as well as the tenants. "What we do is we take a full residential building, and instead of having the real estate developer put it on the market and deal with many different tenants, we convert it into our own designed, furnished rentals- ones that are enabled with technology and amenities," Zikra adds. "We have our own in-house technology team that looks into everything from the way we manage the operations and introduce automations, to giving guests an app that simplifies and digitizes the whole experience."
    
    
    Plus, in a step to further ease the process of moving into an accommodation in the UAE, home-seekers aren't required to use a local bank's check or pay a real estate agency fee at any Stella Stays unit, regardless of the duration of a tenant's stay. There is also no need to present an Emirates ID -an official ID from any country will suffice- and the hassles of activating one's accounts with Dubai Electricity and Water Authority and/or telco provider du are also taken care of by the startup. These are just a few of the factors that make the Stella Stays offering stand out, and that the name that Zikra chose for his company only serves to assist in this regard. "What we wanted to do is build a brand name the same way that customers talk about a Marriott or a Four Seasons," Zikra explains. "'Stella' means 'star' in Italian, and it held a lot of significance for us because, the way we see it, our customers have so many 'stars' to always find and come back to. So, they can say, 'Oh, today I'm staying at a Stella in Jumeirah Lakes Towers; tomorrow, I'll stay at the Stella in Marina.' It's a very catchy name, and so I think we did a good job!"
    
    
    <img src="${EntrepreneurArticleImg3}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Source: Stella Stays
    </small>
    It is vital to point out here, of course, that what shaped the fabric of these services by Stella Stays is the very occurence to that led to the migration of digital nomads and the shift to working from home: the COVID-19 pandemic. "When the COVID-19 crisis happened, there was so much demand for flexibility, because, owing to financial instability, not everyone was sure about whether they should renew their rental contracts or not," Zikra explains. "And so there was a 50% increase in furnished rental bookings at Stella Stays during this period. People didn't know how long this pandemic was going to last, and so they needed something that's more flexible."
    
    
    And it was precisely during that period that the Stella Stays team saw the potential in how their model could be used by a plethora of people, and not just digital nomads and travelers. "Initially, when we started, our focus was really around hospitality and tourism, but when the COVID-19 crisis hit us, we realized our furnished apartments actually serve diverse use cases," Zikra explains. "It can work very well for a traveler, but it also works very well for a resident. Because if you really think about it, an apartment is a necessity for pretty much every person in the whole world. It just depends on what persona you apply: is it someone that's traveling for work that needs a place for three months, or is it a family that's in between moving houses within a city and needs somewhere to stay? And so, we really focused a lot of our technologies and strategies in understanding how we actually build a product that works for residents to adopt the 'show up and live' concept as well."
    
    Here, Zikra states that the model adopted by Stella Stays -from offering a flexible way of living, to easing the process of finding the right home- is an important step towards breaking some of the rigidity that has often characterized the real estate industry. "Globally, technology has come in as an enabler of change- Netflix changed the way people watched content, Amazon changed the shopping experience, Uber changed the transportation business; but when it came to accommodations, it is still one of the most traditional businesses that has not changed whatsoever," Zikra explains. "Whether you want to purchase or rent, the processes within the real estate space are still very traditional; they have not been digitized or innovated. That is the big loophole we're really addressing, by allowing people to find the right place at a reasonable price that they could move into, and start living in within a few clicks, without all the headaches that come with it. It was very transparent and very personalized as well- if our tenants have specific requests, they can make that. So, we're really creating the future of living overall- whether you're living for a week or two, or a month, or a year, this has become an essential part of what we're doing."
    
   
    <img src="${EntrepreneurArticleImg4}" class="rounded w-100 h-auto" />
    <small class="fst-italic">
    Source: Stella Stays
    </small>
    Zikra reveals here that the accommodations provided by his company in Riyadh are witnessing 100% occupancy rates- a statistic that is especially significant given that Stella Stays moved into the Saudi Arabia market not too long ago, in June 2022. "If you also take a look at our global occupancies today, we're at over 80%, and the reason why we have that is because one of the first things that we've done is we've built a technology platform," Zikra adds. "We've actually built something called a dynamic pricing engine that makes sure that not only do we market our products across channels like PropertyFinder, Airbnb, Booking.com, and Expedia, but also in our own system. So along with diversifying our primary offerings to serve many different use cases, we also have the technology that makes sure that we're always competitively priced."
    
    
    But for a company that prides itself on its digital prowess, Zikra believes there is good reason to believe that such results stem from the human element of Stella Stays model. "Part of what we have to do in the vision of Stella Stays is thinking about how we can foster communities and make people feel at home," he says. "And that's why we have our own interior design teams. At our recently launched building in Riyadh, we've engineered amenities, such as co-working spaces, rooftop, lounges, and different types of areas that allow people to get together and communicate. Yes, the process of booking is tech-enabled, and that's really what helped us get to 100% occupancy there. But it is three things together -the technology, the building and the stay, and the community that really put together the offering that we're providing!"
    
    So, what's next for Zikra and Stella Stays? "We're on a mission to grow really quickly globally, but part of our strategy is to really dominate the MENA region for now," Zikra declares. "As a company, we want to become the biggest landlord globally. And so our focus right now is to build a model that works, that's proven, that's multi-city, that's tech enabled, and make sure that we can build branded residences across all neighborhoods." Here's hoping!`,
    originalUrl:
      "https://www.entrepreneur.com/en-ae/growth-strategies/dubai-based-proptech-firm-stella-stays-is-reinventing-how/437309",
  }),
  // Oct 05, 2022
  // National News Business
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "",
    title: "PropTech start-up Stella Stays partners with Tameer to enter Egypt",
    subtitle:
      "Established in 2019, Stella is present in major cities across the Middle East, Europe and North America",
    homepageSubtitle:
      "PropTech start-up Stella Stays partners with Tameer to enter Egypt",
    category: "Article",
    datePublished: "2022-10-05",
    publishedOn: "The National",
    headerImage: NationalCover,
    logo: TheNationalLogo,
    content: `Stella Stays, a Dubai-based PropTech start-up, joined forces with Cairo-headquartered real estate company Tameer to facilitate its expansion into the Egyptian market, the Arab world’s most populous economy.

    The agreement, signed last month, marked the “joint endeavour between the two companies to build [new] communities”, the entities said in a statement on Wednesday.
    
    Stella has introduced a prime location in New Cairo city through its agreement with Tameer. It is strategically located 15 minutes from Cairo International Airport, 10 minutes from downtown Katameya and within walking distance from the American University in Cairo.
    
    The new property will offer ready-to-live, smart apartments with open kitchens, as well as modern recreational and commercial amenities.
    
    “We are very proud of our expansion into Egypt, and we believe that our vision for the future of living aligns perfectly with Tameer to develop lively communities that offer everything the modern residents and travellers would need,” Mohammed Al Ghussein, chairman of Stella, said.
    
    PropTech refers to the use of technology and innovation within the real estate industry. Investment volume in PropTech companies worldwide grew to a peak of $24.3 billion in 2021 from $600 million in 2010, according to Statista research.
    
    Established in 2019, Stella is present in major cities across the Middle East, Europe and North America. It offers individually designed and equipped homes and provides end-to-end digital services to guests.
    
    Residents and guests can use the Stella app to navigate the entire journey, from finding, booking and managing their stays, checking in, controlling smart home systems, to ordering a wide range of guest services. Payment can be made through various methods, including credit, debit cards and cryptocurrencies.
    
    “As we continue to cultivate our culture and legacy, we look forward to the future with this strategic move which will enhance the experience for modern travellers, residents, independent trendsetters and everyone in between”, Mr Al Wazzan said.
    
    To create a global real estate pipeline, Stella uses proprietary algorithms that identify, evaluate and select the most desirable properties around the world, based on location, financial projection and trends.
    
    The company currently operates in the UAE, Saudi Arabia, Turkey, Bahrain and Canada, with more than 1,000 contracted units.`,
    originalUrl:
      "https://www.thenationalnews.com/business/start-ups/2022/10/05/dubais-proptech-start-up-stella-stays-partners-with-tameer-to-enter-egypt/",
  }),
  // August 25 2022
  // SmashiTv
  getArticle({
    featured: false,
    homepage: false,
    author: "Mohannad Zikra",
    title: "Stella Stays' CEO Mohannad Zikra discussed the future of living",
    subtitle: "",
    homepageSubtitle:
      "Stella Stays' CEO Mohannad Zikra discussed the future of living",
    category: "TV Interview",
    datePublished: "2022-08-25",
    publishedOn: "SmashiTV",
    headerImage: SmashiTvCover,
    logo: SmashiTvLogo,
    content: `Leading PropTech company Stella Stays discusses the ‘Future of living’ with SmashiTV on how we are transforming the residential hospitality space, and our revolutionary concept which allows consumers to simply ‘Show up and Start Living’.`,
    media: [
      {
        url: "https://www.youtube.com/embed/_Ay3FX6kPh8",
        type: "video",
      },
    ],
    originalUrl: "",
  }),
  // June 15, 2022
  // Zawya
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "Stella Stays",
    title: "Stella Stays to expand into Europe",
    subtitle:
      "UAE: Stella Stays, a UAE proptech start-up with a vision to create the future of living and hospitality, announces expansion into Europe, launching two towers in prime locations in Istanbul, Turkiye.",
    homepageSubtitle: "Stella Stays to expand into Europe",
    category: "Interview",
    datePublished: "2022-06-15",
    publishedOn: "Zawya",
    headerImage: ZawyaCover,
    logo: ZawyaLogo,
    content: `Stella Stays Istanbul Etiler Tower and Stella Stays Istanbul Galata Tower will welcome guests from June and July 2022 respectively, offering tech-enabled, designer apartments with spacious living space, full kitchens and dedicated workspaces to both travellers and long-term residents.

    The launches in Istanbul mark the company’s first move into European markets, as part of an aggressive global expansion plan, after launching in Saudi Arabia in early June 2022. Stella Stays is actively exploring further opportunities in Turkey, as well as top destinations in Europe and globally.
    
    <strong>Mohannad Zikra, Co-founder and CEO of Stella Stays, said:</strong> “We are very excited about our expansion into Istanbul as it is one of the most visited cities in the world with north of 13 million annual visitors. The Ministry of Culture and Tourism of Turkiye projected about 45 million tourists and $35 billion in revenue for 2022 - a sharp recovery from the pandemic impact, with the majority of visitors coming from Europe.
    
    Istanbul is renowned for its rich culture, history, impressive architecture, a modern skyline and delightful shopping experience, which contribute to its popularity. Istanbul is also the gateway between Europe and Asia with a buzzing residential and hospitality real estate sector. These are the attributes that make Istanbul a perfect destination for Stella Stay’s global expansion.
    
    We plan to continue developing Stella Stays’s portfolio in Turkiye’s top cities and the surrounding regions.”
    
    Stella Stays enters global markets through master leases or profit-sharing agreements with real estate developers and property owners. The company uses proprietary algorithms that identify, evaluate, and select the most desirable properties around the world, based on location, financial projection, and trends.
    
    <strong>Mr Zikra further commented on Stella Stays’ innovation:</strong> “In traditional hotel models, hotel brands make operating commission while the property owners bear huge expenses, such as massive headcount and other operating costs. Stella Stays eliminates all the headaches for the property owner by offering hands-off management, while injecting technology and advanced data analytics into every aspect of operations. We have been able to reduce headcount by up to 70% and 50% of other operating costs compared to traditional hotels, which result in a much higher profit margin for property owners and our company.”
    
    As the creator of the residential hospitality concept, Stella Stays offers holidaymakers, business travellers, and residents a seamless living and hospitality experience by leveraging ground-breaking technology, guest-centric interior design, and modern service. Guests and residents can use the Stella Stays app to navigate the entire journey - from booking and managing their stays, checking in, controlling smart home systems, to ordering a wide range of guest services. Payment terms are flexible and can be made through various methods, including debit, credit cards and crypto payments.
    
    Stella Stays is well recognised for its innovative and guest-centric approach. The company currently operates in the UAE, Bahrain, Canada, Saudi Arabia, and now Turkiye. Stella Stays expects to expand rapidly into major cities in the MENA region and around the world in the next 12-18 months, with flagship projects in key markets.
    
    -Ends-
    
    <strong>About Stella Stays</strong>
    
    Stella Stays is a UAE proptech start-up with a vision to create the future of living and hospitality.  The company offers tech-enabled residences and suites that are beautifully designed, thoughtfully equipped, and a seamless digital guest experience.
    
    Established in 2019, Stella Stays is present and growing rapidly in major cities across the Middle East and North Africa (MENA), Europe and North America. The company’s proprietary technology empowers unmatched efficiency across real estate supply growth, interior design, pricing, economics, bookings, guest service, and property management.
    
    Stella Stays is the winner of the 2021 Stevie Awards</strong> for the <strong>“Most Innovative Tech Start-up of The Year”</strong> and <strong>“Innovative Achievement in Sales”</strong> for its technology and revenue generation strategy respectively. `,
    originalUrl:
      "https://www.zawya.com/en/press-release/companies-news/stella-stays-to-expand-into-europe-rebd4gjh",
  }),
  // MARCH 11, 2022
  // Gulf Business
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 1,
    author: "",
    title: "Hospitality brand Stella Stays to accept cryptocurrencies",
    subtitle:
      "Customers will be able to complete online transactions by paying in Bitcoin, Ethereum and over 35 altcoins directly through Stella Stays’ website.",
    homepageSubtitle:
      "Hospitality brand Stella Stays to accept cryptocurrencies",
    category: "Article",
    datePublished: "2022-03-11",
    publishedOn: "Gulf Business",
    headerImage: GulfBusinessCover,
    logo: GulfBusinessLogo,
    content: `Homegrown hospitality brand Stella Stays has announced it is now accepting crypto payments through its e-commerce platform.
    
    The brand offers fully-equipped accommodation, for both short and long-term stays, where guests, including corporate travellers, digital nomads, groups of friends and families, can enjoy the comfort of a home and the service quality of a hotel.
    
    Co-founder and CEO, Mohannad Zikra, said “Stella Stays is a tech-centric hospitality brand that continues to build all digital offerings in-house from the development of our website with a sophisticated pricing engine to our iOS and Android apps. We are constantly striving to offer our customers the best guest experience and the introduction of crypto payments is just the beginning toward futuristic tech offerings plans in the pipeline!”
    
    Customers will be able to complete online transactions by paying in Bitcoin, Ethereum and over 35 altcoins. When guests proceed to checkout, they can simply select crypto as the preferred method, and scan the QR code available to finalise their booking.
    
    Stella Stays’ transactions use a USD-denominated stablecoin trading at 1:1 with the US Dollar, ensuring that there is no risk of fluctuations as BUSD and USDT are pegged to the dollar.
    
    The brand, which started out with a single penthouse in 2019, currently has more than 300 properties as part of its portfolio, and is present in Dubai, Manama and Montreal. The company also plans to launch more than 100 units in Abu Dhabi by end of 2022.`,
    originalUrl:
      "https://gulfbusiness.com/uae-hospitality-brand-stella-stays-to-accept-cryptocurrencies/",
  }),
  getArticle({
    featured: false,
    homepage: true,
    homepageIndex: 1,
    author: "Mohannad Zikra, Mohammed Al Ghussein",
    title: "PropTech startup Stella Stays enters Saudi Arabia market",
    subtitle:
      "The Stella Stays Riyadh Olaya Tower, which will open its door in June, will offer modern, fully equipped, tech-enabled apartments and hotel suites.",
    homepageSubtitle:
      "PropTech startup Stella&nbsp;Stays enters Saudi&nbsp;Arabia market",
    category: "Interview",
    datePublished: "2022-05-24",
    publishedOn: "Arab News",
    headerImage: UAEProptechSaudi,
    logo: ArabNewsLogo,
    content: `Emirati PropTech startups Stella Stays has announced its expansion into Saudi Arabia, introducing the first tech-enabled residential hospitality concept in the region.

    The Stella Stays Riyadh Olaya Tower, which will open its door in June, will offer modern, fully equipped, tech-enabled apartments and hotel suites, a co-working space, and recreational and commercial amenities.

    Stella Stays offers holidaymakers, business travelers, and residents a seamless hospitality experience, through leveraging ground-breaking technology and modern service. Guests and residents in Saudi Arabia will be able to use the Stella Stays app to navigate the entire journey — from booking and managing their stays, checking-in, controlling smart home systems, to ordering a wide range of guest services. Payment terms are flexible and can be made through various methods, including debit, credit cards and crypto payments.

    Riyadh and Jeddah are strategic locations for Stella Stays’ expansion with more than $1 trillion in real estate and infrastructure developments underway. The Kingdom’s economy has fueled solid growth in residential construction with developers progressing a booming number of freehold apartment projects. Saudi Arabia also offers some of the most attractive setup costs in the real estate sector in the region.
    
    Mohammed Al-Ghussein, chairman of Stella Stays, said: “We are extremely proud of our launch in Saudi Arabia, and we believe that our vision for the future of living and hospitality aligns perfectly with Vision 2030, in transforming the Kingdom into a world economic center with blossoming tourism and entertainment industries. The speed of national development is phenomenal, with around 730,000 homes to be added to Riyadh by 2030.

    Stella Stays aims to disrupt the Kingdom’s hospitality sector landscape by partnering with real estate developers and property owners, such as Four Direction Development, to provide an innovative residential hospitality concept, with unmatched profitability potential.”

    Four Direction Development has extensive experience in terms of investment returns since 2014 with several development projects around the Kingdom.

    “We are excited to announce our agreement with Stella Stays in the Kingdom of Saudi Arabia that will boost both the hospitality and travel industries. Stella Stays offers us, the property owner, hands-off management with real-time analytics that delivers significantly more income and less operational costs compared to traditional hotel models,” said Abdullah Al-Majed, co-founder at Four Directions Development.

    Stella Stays’ real estate portfolio growth strategy in Saudi Arabia is through master leases or profit-sharing agreements with property owners and developers. To create a global real estate pipeline, the company uses proprietary algorithms that identify, evaluate, and select the most desirable properties around the world, based on location, financial projection, and trends.

    Mohannad Zikra, CEO and co-founder of Stella Stays, said: ‘‘The digital economy, COVID-19, and tech-savvy millennials are driving massive behavioral shifts related to how people live, travel and work. Business travel is also bouncing back and increasing significantly, requiring the flexibility for longer stays.
       `,
    originalUrl: "https://www.arabnews.com/node/2088966/corporate-news",
  }),
  // 22 November 2021, Monday
  // Generation Start-up: Q&A
  getArticle({
    title: "Generation Start-up: Q&A",
    subtitle:
      "Q&A with Mohannad Zikra, co-founder and chief executive of Stella Stays",
    author: "Muhannad Zikra",
    publishedOn: "The National News",
    category: "Interview",
    datePublished: "2021-11-21",
    content: GenerationStartUp,
    originalUrl:
      "https://www.thenationalnews.com/business/start-ups/2021/11/21/generation-start-up-how-stella-stays-is-changing-the-hospitality-landscape/",
    featured: false,
    headerImage: GenerationStartUpQAImage,
  }),
  // 22 November 2021, Monday
  // Generation Start-up: How Stella Stays is changing the hospitality landscape
  getArticle({
    title:
      "Generation Start-up: How Stella Stays is changing the hospitality landscape",
    subtitle:
      "Stella Stays plans to revolutionise hospitality businesses with its brand of individually designed spaces and end-to-end services for guests in the region and beyond",
    author: "Muhannad Zikra, Mohammed Al Ghussein",
    publishedOn: "The National News",
    category: "Interview",
    datePublished: "2021-11-21",
    content: `
		Extensive travelling and flying from one global business centre to another was part of Mohannad Zikra’s life for a decade. The former high-flying corporate executive, who started his career with Ericsson and rose in ranks to become its sales director in Canada, stayed at plenty of hotels around the world over a decade. It’s an experience he did not enjoy.

“I was gone for 320 days of a year,” Mr Zikra says. “When I leave, whether for a week or a month, I was always stuck at these hotels and when I came back home to Canada, my friends would say 'man you are living the dream, must be great to travel', but I absolutely hated that experience.”

After moving on from Ericsson in 2015, he joined UXP Systems, a tech start-up in Toronto as director of sales, again travelling extensively. However, instead of the corporate-allocated, high-end hotels, this time he chose cheaper options including Airbnb, which was gaining popularity in North America.

It was a much better experience, offering a personal touch and homely feeling, but sometimes services were unprofessional and inconsistent.

"It was a hit and miss," he says.

After experiencing both ends of the hospitality spectrum, Mr Zikra thought there has to be a better way to manage the business of vacation and long-term rental properties. The whole model needed reimagining to offer guests – whether vacationers, corporate executives or residents seeking longer term and hassle-free stays – a personalised hospitality experience that was much lighter on the pocket.

"I realised there was a huge opportunity," he says. "There was a huge shift in people's minds and they were transitioning towards staying in apartments that were furnished instead of hotels."

That realisation was the foundation stone for Stella Stays, a Dubai-headquartered hospitality start-up that offers individually designed and equipped homes and provides end-to-end digital services to guests. It's a value proposition, which, its founders say, is better than a hotel.

Mr Zikra, who grew up in Kuwait and moved to Canada at an early age, has a computer engineering degree. After UXP was acquired in 2017, he decided to move on from the corporate sector. With a group of friends he put together a portfolio of a few properties in Canada and ran them in 2018 as short-term rentals, experimenting with improving hospitality experiences.

However, it was a chat with an old friend in Dubai, a former Ericsson colleague, about the emirate being the real estate and hospitality capital of the Middle East that convinced Mr Zikra to bring his model of personalised hospitality to the emirate.

"Within a week, It was myself and one of co-founders, we jumped on a plane. We didn’t have a plan, we didn’t know what was going to happen," he said.

Mohammed Al Ghussein, an Emirati entrepreneur and an old acquaintance of Mr Zikra, was among the first people in Dubai to learn of the idea to revolutionise the hospitality sector. A day later, Mr Al Ghussein, who is now a very hands-on chairman of the company, decided to join the team.

The founding team bootstrapped the venture, pouring in about Dh1 million ($272,479) of their savings into the venture. The company was formally launched in March 2019 and like most start-ups, it wasn't an easy journey.

"Even with Mohammed as our initial investor it was very difficult to raise funds," Mr Zikra says. "It was either we were going to make it or break it."

Running operations from a tiny office, Mr Zikra, along with co-founders Hassan Al Saadi, Marc Diab and Hamza Al Saadi, visited a lot of properties trying to convince homeowners to let the company rent their units, furnish them and re-rent to tourists in Dubai.

"They [homeowners] thought we were ludicrous. They thought we were absolutely out of our minds and nobody wanted to talks to us," Mr Zikra says of initial struggles when he and his cash-strapped Canadian compatriots survived on the cheapest meals in Dubai.

Stella Stays landed its first penthouse in the marina, when a homeowner with "entrepreneurial spirit" decided to let the company manage the property.

"He decided to take a risk on us and so far he has been really happy. He has been there from the day one," Mr Zikra says.

At the end of last year the company had 85 properties in its portfolio and is growing rapidly, now taking whole floors of buildings, where each unit is individually furnished to avoid “cut-paste” décor themes of hotels, he says.

Stella Stays made a major pivot during the pandemic that brought the global travel and tourism industry on its knees amid travel restrictions. As the number of tourists dried up, the company shifted focus to UAE residents looking for managed accommodations to steer the company through the rough period, Mr Al Ghussein says.

“When you get hit with something unpredictable and unique like a pandemic, there are only two possibilities for every start-up: either the company shuts down or it is forced to develop a brand-new strategy to stay afloat,” he says.

The company did the latter, and it also revamped its business model, marketing, pricing and “everything in between” to survive through the lean patch and ultimately grew as a business as markets recovered and travel restrictions eased, Mr Al Ghussein says.

However, despite rapid growth, Stella Stays has delayed its funding round as much as possible as “getting to a valuation that makes sense”, was difficult, with investors valuing Stella Stays at “pretty much nothing” initially.

The company, which is now profitable, reinvested revenue generated from operations back into the businesses and earlier this year expanded into Bahrain. It currently has a portfolio of 300 managed properties dotted around the UAE, Bahrain and Canada, and plans to increase that number to 1,000 in 2022.

To fund its expansion, the company is in talks with investors for a “large” Series-A growth financing round next year, Mr Zikra says, declining to give the potential size of the financing.

Stella Stays is also open to bringing strategic investors on board to expand its footprint to other regional markets in the Middle East and beyond. Part of the potential fundraising will be invested in improving its technology platform and hiring staff to keep up with the pace of growth, he says.
	`,
    originalUrl:
      "https://www.thenationalnews.com/business/start-ups/2021/11/21/generation-start-up-how-stella-stays-is-changing-the-hospitality-landscape/",
    featured: false,
    homepageSubtitle: "Stella Stays is changing the hospitality landscape ",
    logo: TheNationalLogo,
    homepage: true,
    homepageIndex: 2,
    headerImage: GenerationStartUpHowStellaStaysImage,
  }),
  // 02 November 2021, Tuesday
  // 5 hidden gems in Montreal
  getArticle({
    title: "5 hidden gems in Montreal",
    subtitle:
      "We have curated a list of our favourite spots in Montreal that can not be missed whenever you are in town!",
    author: "Stella Stays Team",
    publishedOn: "https://stellastays.com",
    category: "Blog",
    datePublished: "2021-10-31",
    featured: false,
    headerImage: FiveHiddenGemsInMontreal,
    content: FiveHiddenGems,
  }),

  // 24 October 2021, Sunday
  // Seamless digital guest experience & unique business model
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 0,
    homepageSubtitle:
      "Seamless digital guest experience & unique business model",
    author: "Mohannad Zikra",
    title: "Use tech to enhance guest experiences",
    subtitle:
      "A tech-driven outlook and a unique business model can ensure a great experience for travellers and guests, hospitality experts have said.",
    category: "Interview",
    datePublished: "2021-10-13",
    publishedOn: "Khaleej Times",
    headerImage: UseTechToEnhanceGuestExperiencesImage,
    logo: KhaalejTimesLogo,
    content: `Speaking to Khaleej Times, Mohannad Zikra, CEO and co-founder of Stella Stays, an Emirati hospitality brand, explained that what sets a brand apart is an innovative and guest-centric approach to hospitality, and a sharp focus on guest needs. “Our company launched with a vision to redefine the hospitality industry, using the ‘deconstructed hotel’ model but with a unique approach. We offer guests well designed and equipped accommodation where they experience the comforts of both a home and a hotel. Additionally, when staying at a Stella, guests are guaranteed a hassle-free process throughout the length of their stay with an efficient, fully digital experience.”

		Stella Stays, which launched in 2019 with the aim of managing properties for short and long-term stays, now has over 300 properties in its portfolio. “We fully understand what today’s modern family and business travellers require and expect when travelling and looking for a place to stay. This is offered to them in a variety of ways, from apartments to beachside villas and high-rise penthouses, all of which are fully designed and maintained by our own team, ensuring quality and standard,” Zikra explained.
		
		The idea, he said, is to reinvent the ‘renting process’ with technology and make it simpler and more convenient, ensuring a comfortable stay. The Stella Stays application makes it easier to browse through the property collection, book the stay, and then check-in without any in-person communication requirement. “Additionally, by following a 150-point checklist before signing on a property, we ensure ease of living. All our properties are in close proximity to landmarks, supermarkets and restaurants. We also offer reserved parking arrangements, a 24/7 concierge and an in-house maintenance team on call.”
		
		Asked how the brand prioritizes customer needs and expectations, Zikra revealed that all the Stella Stays properties are designed and equipped for a work-play lifestyle. “It is important for us that our guests, who are usually travellers, have a ‘home’ to go back to after work or exploring the city. No matter what city, for how long they’re staying or the purpose of their trip, guests staying in a Stella will experience the utmost quality and comfort, where they can unwind and relax, and even enjoy a wonderful time with their friends and family.”
		
		Guests can expect to see additions such as a PlayStation as well as a coffee machine to help kick start the day, and even toys for children. Furthermore, the brand is available all day with a range of more than 100 services that can be requested on demand.
		
		“We go above and beyond for our guests by planning and arranging everything from romantic dinners to big Indian weddings,” Zikra said. “We sometimes receive unusual requests, but we always do our best to offer the finest experience. Some of the craziest requests we have catered to include delivering large ice cubes to cool down the pools and wedding planning. We often also get long lists of grocery requests from guests, so we now stock up on items from the list before guests check-in.”
		
		Stella Stays is currently present in three cities - Dubai, Montreal, and Manama - with a strong pipeline in place that will see it end the year with another 300 Stella Stays properties.
		
		“As a start-up, we are currently on growth mode and looking to be present in major cities across the Mena region by 2022 and then focus on expansion into North America and Europe in 2023,” Zikra revealed. “In regards to Expo 2020 Dubai, we have been preparing for it and have signed a number of enterprise agreements with multinational companies who are looking for short term accommodations for their employees flying into the city for the event.”
		
		Asked how the brand had been received by the public, he said: “We have hosted over 30,000 guests in the last two years and have been fortunate to receive a 4.9 Google rating and a five-star Apple App Store and Google Play rating. We are seeing a steady increase of repeat guests that book with us regularly; currently, the number is around 10 per cent. Additionally, when we began operations in 2019, the average length of stay was three nights per booking, however today, our average length of stay has increased to 18 nights per booking which is six times longer than when we first started.”
		
		Looking ahead, he noted that traditionally defined luxury rentals do not have the same potential as they did years ago. “Luxury can now be considered a feeling evoked through perceptions of quality, comfort, and elegance. Branded furniture, 24K gold interior decors, and marble countertops are the luxuries of the past. The future of luxury rentals is an experience tailored to each individual and improved with cutting-edge technology to provide a comfortable stay.”`,
    originalUrl:
      "https://www.khaleejtimes.com/business/use-tech-to-enhance-guest-experiences",
  }),

  // 7 October 2021, Thursday
  // Expo 2020 increases booking demand in Dubai
  getArticle({
    featured: false,
    homepage: false,
    homepageSubtitle: "Expo 2020 increases booking demand in Dubai",
    author: "Mohannad Zikra, Mohammed Al Ghuessein",
    title: "How Covid-19 and Expo 2020 have impacted booking demand in Dubai",
    subtitle:
      "Digital nomads make up 50 percent of bookings on hospitality start-up Stella Stays while 30 percent of every 10 bookings is Expo related",
    category: "Interview",
    datePublished: "2021-10-06",
    publishedOn: "Arabian Business",
    headerImage: HowCovid19Image,
    logo: ArabianBusinessLogo,
    content: `A combination of changes brought forth by coronavirus and the impact of Expo 2020 have made the long-stay accommodation business a booming one in Dubai.

		Expo 2020 Dubai, which opened its doors on October 1, has brought an increase in business for Dubai-based hospitality startup Stella Stays.
		
		“There's two aspects to Expo’s impact on us, one of which is people that are coming to visit Expo. I would say that out of every ten bookings that we’re getting in, there's at least 20 to 30 percent of them that are related to Expo,” said Mohannad Zikra, CEO and co-founder of Stella Stays.
		
		“The other piece is working with enterprises that have their staff and partners coming to Expo and who want to house them for a longer period of time. So here we're talking about five months plus booking from people that are coming out to support Expo and we're fully booked on most of our larger properties specifically due to this,” he continued.
		
		Launched in 2019, Stella Stays has positioned itself as a rival to traditional hotel chains and operates a portfolio of almost 300 properties scattered around the UAE, Bahrain and Canada.
		
		Now that remote working is a global reality, thanks to coronavirus, digital nomads and freelancers are on the rise with Stella Stays indicating that 50 percent of its business comes from this category.
		
		Unlike business or leisure travellers, digital nomads tend to visit Dubai for work projects and hence have a longer length of stay, explained Zikra.
		
		“Around 50 percent of our travellers are millennials, coming in to Dubai as freelancers or digital nomads to work on projects in the country and so they come in towards the longer stay,” said Zikra.
		
		“So, we've seen our average stay go up from three nights per booking before coronavirus and now we're almost at a 29-days average per booking. We have people who’ve booked apartments with us for a whole year and they like the flexibility of paying by credit card (versus cheques) and not being tied down to regulations,” he continued.
		
		In March this year, the UAE cabinet adopted a new remote work visa to enable employees from all over the world to enter the country under self-sponsorship and work in line with terms and conditions issued with the visa.
		
		Another way the pandemic impacted the hospitality startup is through shifting its focus from visitors to residents of Dubai, a measure which saw them achieve 85 percent occupancy last year – following the lockdown period.
		
		“We’ve seen, over the pandemic, that a lot of people had unstable jobs, their salaries cut or had to shift their families back to their home countries. So there was high demand for short term accommodation as people moved away from yearly contracts,” said Mohammed Al Ghussein, chairman of Stella Stays.
		
		“Our option made it much more convenient for these individuals to take short-term leases and renew as things progress and it opened up another business- line for us that we were able to accommodate,” he added.
		
		This category is still growing, clarified Al Ghussein, nowadays “especially from people that are still worried about how things are going to go for the next twelve months.”
		
		Stella Stays allows its guests to move between its properties, a concept which Al Ghussein called a “deconstructed hotel”.
		
		“Travelers like the concept of a deconstructed hotel where they can shift locations according to their preference. They can move to the Marina when their family is in town and stay in JVC when they are alone, for example,” he said.
		
		“That flexibility helps us a lot with maintaining clients and having them relocate within our properties. It also makes a big difference when we discuss corporate deals with a lot of our corporate clients and helps a lot,” continued Al Ghussein.
		
		Among the factors that differentiates Stella Stays from other property management companies or hotels is its use of technology. Guests can manage all aspects of their stay – from accessing the apartment to requesting the fridge be stocked with food – through an application on their phone, with no need for human contact.
		
		“Our vision is to build an internationally-known hospitality brand. We’re currently focused on MENA but by 2022, we want to be able to launch in additional cities in the region. After that, we're looking strongly at North America, Europe and Asia,” said Zikra.
		
		“Ultimately, we want to be no different than any other hotel, except instead of managing small rooms we’re managing a portfolio of beautifully designed apartments, houses and villas through tech. That's where we really feel the evolution of hospitality and accommodation is going towards,” he explained.`,
    originalUrl:
      "https://www.arabianbusiness.com/travel-hospitality/469387-how-covid-19-expo-2020-impacted-dubais-booking-demand/",
  }),

  // 31 August 2021, Wednesday
  // New players in GCC short stay hospitality market compete for market share
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 2,
    author: "",
    title:
      "New players in GCC short stay hospitality market compete for market share",
    subtitle:
      "It’s no longer Airbnb solely competing against traditional hotels in the GCC. Other players are vying for some action as the rebound in hospitality continues",
    homepageSubtitle: "Stella Stays announces expansion into Bahrain",
    category: "Article",
    datePublished: "2021-09-19",
    publishedOn: "AME info",
    headerImage: NewPlayersInGCCImage,
    logo: AmeInfoLogo,
    content: `After experimenting with holiday homes for vacation and business trips, Stella Stays noticed that consistency was always an issue with temporary stay management experience, design, and cleanliness.  The UAE-owned hospitality brand has expanded its operations to Bahrain in a bid to rival Airbnb and disrupt traditional hotel models in the Middle East.

		The hospitality start-up was launched in late 2019 and, as of August 2021, boasts ~300 units across the UAE, Bahrain, and Canada using a range of managed properties for short, medium, and long stays.`,
    media: [
      {
        url: NewPlayersInGCCMedia01,
        type: "image",
      },
    ],
    originalUrl:
      "https://www.ameinfo.com/business/new-players-in-gcc-short-stay-hospitality-market-compete-for-market-share/",
  }),
  // UAE-owned ‘Airbnb rival’ to challenge traditional hotels in the region
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: 0,
    author: "",
    title: "‘Airbnb rival’ to challenge traditional hotels in the region",
    subtitle:
      "Stella Stays has a portfolio of managed properties across the UAE, Bahrain and Canada",
    homepageSubtitle: "‘Airbnb rival’ challenges traditional hotels",
    category: "Blog",
    datePublished: "2021-08-31",
    publishedOn: "Hotelier Middle East",
    headerImage: UaeOwnedAirbnbRivalImage,
    logo: HotelierLogo,
    content: `UAE-owned hospitality brand Stella Stays has expanded its operations to Bahrain as it continues its mission to challenge traditional hotel models in the Middle East.

		The hospitality start-up was launched in late-2019, and thanks to securing one round of initial funding, already has 300 properties across the UAE, Bahrain and Canada. Calling itself a “model to rival Airbnb”, Stella Stays has a range of managed properties for short-, medium- and long-stays.
		
		“We have an aggressive growth plan and the new properties being built in Bahrain will play a crucial role in our strategy.” said Mohannad Zikra, CEO and co-founder at Stella Stays. “Our expansion into Bahrain coincides with the Kingdom’s major real estate developments. Our model is the perfect fit for the Bahrain market and will be an excellent stepping stone in rolling the concept out to key locations in the region and eventually expanding to Europe and North America.”       
		
		Bahrain’s market is well suited for Stella Stays’ expansion. The Kingdom’s economy has fueled solid growth in residential construction with developers progressing a healthy number of freehold apartment projects. The Kingdom also offers some of the best value set-up costs in the real estate sector in the region.
		
		Ali Al Mudaifa, executive director – investment origination at Bahrain’s Economic Development Board, said: “The Kingdom’s hospitality sector is evolving rapidly amid ambitious real estate developments across the island. Major global hotel brands and small hospitality innovators alike view Bahrain as an attractive place to expand in the wake of the pandemic, which has catalysed innovation.
		
		“We are seeing disruptive business models attracted to Bahrain’s dynamic real estate market in part because of the Real Estate Regulatory Authority’s (RERA) forward looking, effective regulation.	The Kingdom’s 100 percent foreign ownership regulation also makes Bahrain an attractive place to set up. We look forward to supporting Stella Stays and many other hospitality companies from around the region and beyond in their growth plans in the coming months.” `,
    media: [],
    originalUrl:
      "https://www.hoteliermiddleeast.com/business/uae-owned-airbnb-rival-to-challenge-traditional-hotels-in-the-region",
  }),
  // Hospitality start-up Stella Stays announces expansion into Bahrain
  getArticle({
    featured: false,
    homepage: false,
    homepageIndex: null,
    author: "",
    title: "Hospitality start-up Stella Stays announces expansion into Bahrain",
    subtitle:
      "Company targeting every major city in MENA region before moving on to expand into Europe and North America",
    homepageSubtitle: "UAE-owned ‘Airbnb rival’ challenges traditional hotels",
    category: "Press release",
    datePublished: "2021-08-31",
    publishedOn: "Arabian Business",
    headerImage: HospitalityStartupStellaStaysImage,
    logo: ArabianBusinessLogo,
    content: `Hospitality brand Stella Stays has launched operations in Bahrain ahead of a major expansion in the region. The firm’s expansion plans seek to take advantage of Bahrain’s booming real estate sector, with $12 billion worth of real estate projects in the pipeline.
		Stella Stays operates a portfolio of managed properties scattered around UAE, Bahrain and Canada. Positioned to rival traditional hotel chains, the company is targeting tourists and residents who want short, medium, and long-term stays in well designed, furnished, regulated apartments, townhouses, and villas. Each of Stella Stays’ units is designed by its in-house interior design team and features stylish modern furniture, pre-loaded Netflix accounts, PlayStations, espresso machines and 100+ on demand services in addition to standard hotel like facilities.  
		
		The start-up launched in late 2019, and has grown to manage nearly 300 properties as of August 2021. “We have an aggressive growth plan and the new properties being built in Bahrain will play a crucial role in our strategy.” said Mohannad Zikra, CEO and Co-founder at Stella Stays. “Our expansion into Bahrain coincides with the Kingdom’s major real estate developments. Our model is the perfect fit for the Bahrain market and will be an excellent stepping stone in rolling the concept out to key locations in the region and eventually expanding to Europe and North America.”     
			
		Despite the onset of the pandemic, Stella Stays was able to pivot from targeting tourists to offering residents and inbound travellers places to work remotely and undergo quarantine. The company has already secured one round of initial funding. Now the business aims to achieve a comparable level of success to a similar firm in the US; Sonder, which announced earlier this year that it would be going public at a value of USD 2.2 billion. 
		
		Bahrain’s market is well suited for Stella Stays’ expansion. The Kingdom’s economy has fueled solid growth in residential construction with developers progressing a healthy number of freehold apartment projects. The Kingdom also offers some of the best value set-up costs in the real estate sector in the region. 
		Ali Al Mudaifa, Executive Director – investment Origination at Bahrain’s Economic Development Board, said: “The Kingdom’s hospitality sector is evolving rapidly amid ambitious real estate developments across the island. Major global hotel brands and small hospitality innovators alike view Bahrain as an attractive place to expand in the wake of the pandemic, which has catalysed innovation. 
		
		“We are seeing disruptive business models attracted to Bahrain’s dynamic real estate market in part because of the Real Estate Regulatory Authority’s (RERA) forward looking, effective regulation. The Kingdom’s 100% foreign ownership regulation also makes Bahrain an attractive place to set up. We look forward to supporting Stella Stays and many other hospitality companies from around the region and beyond in their growth plans in the coming months.” 
		`,
    media: [],
    originalUrl:
      "https://www.arabianbusiness.com/travel-hospitality/467838-hospitality-start-up-stella-stays-announces-expansion-into-bahrain",
  }),
  // The importance of guest experience
  getArticle({
    featured: false,
    homepage: false,
    author: "Mohannad Zikra",
    title: "The importance of guest experience",
    subtitle:
      "Stella Stays talks to Asharq business by bloomberg about the business model.",
    homepageSubtitle: "Guest experience for short & long stays",
    category: "TV Interview",
    datePublished: "2021-08-14",
    publishedOn: "Asharq Business by Bloomberg",
    headerImage: TheImportanceOfGuestExperienceImage,
    logo: BloombergLogo,
    content: `Our CEO, Mohannad Zikra discussed the importance of guest experience for the new generation of travellers as well as short and long-stay guests at the evening show on Asharq Business by Bloomberg

		 Stella Stays is introducing an innovative, new business model that will redefine hospitality and fill the gaps within the industry. With a strong growth pipeline in place for the UAE, Mohannad explains how we plan to execute the next steps of our expansion in all major cities across the MENA and North America in the coming years.
		 `,
    media: [
      {
        url: "https://www.youtube.com/embed/RrYU3PPj20w",
        type: "video",
      },
    ],
    originalUrl: "",
  }),
];
export default articles;
