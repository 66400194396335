import React from "react";
import { BsChevronLeft } from "react-icons/bs";

const NavPrev = (props) => {
  const { setMonthsAnimationRunning } = props;

  const onClick = () => {
    setMonthsAnimationRunning((e) => {
      if (typeof e === "number") {
        return e + 1;
      }
      return 0;
    });
  };

  return (
    <button className="custom-nav nav-left" onClick={onClick}>
      <BsChevronLeft />
    </button>
  );
};
export default NavPrev;
