import React from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import QUERIES from "../../Constants/Queries";

import Loading from "../Utils/Loading";

import Topbar from "../Layout/Topbar";
import Footer from "../Layout/Footer";

// Translation Package
import { useTranslation } from "react-i18next";

export default function TermsAndConditions({ showCompact = false }) {
  // Translation hook
  const { t } = useTranslation();

  const fetch = useQuery(QUERIES.SETTINGS);
  if (fetch.loading || fetch.error) {
    return <Loading />;
  }
  return (
    <>
      {!showCompact && <Topbar />}
      {!showCompact && (
        <Helmet>
          <title>
            {t("terms_and_conditions")} - {t("stella_stays")}
          </title>
        </Helmet>
      )}

      <div className="container legal" dir="ltr">
        <div className="row">
          <div className="col-12">
            {/* TODO Arabic version of Terms Page */}
            <h2>Terms and Conditions</h2>
          </div>
          <div
            className="col-12 text-justify"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {fetch.data.result.termsConditions}
          </div>
        </div>
      </div>
      {!showCompact && <Footer />}
    </>
  );
}
