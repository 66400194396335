// React
import { useMemo } from "react";

// Misc
import Tooltip from "Components/Utils/Tooltip";
import { BiInfoCircle } from "react-icons/bi";

// gql
import { useQuery } from "@apollo/client";
import QUERIES from "Constants/Queries";

// Components
import Loading from "Components/Utils/Loading";

// Utils
import groupBy from "Components/HelpCenter/groupBy";

// Hooks
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const LOSDiscountTooltip = ({label, amount, propertyID, className="summary-item"}) => {
    // Translation hook
    const { t } = useTranslation();

    const {data: losDiscountTooltip, loading: loadingTooltipContent, error} = useQuery(QUERIES.LOS_DISCOUNT, {
      variables: { propertyID },
    })

    const groupedLOSDiscountTiers = useMemo(() => {
      if(losDiscountTooltip?.result && !loadingTooltipContent) {
        return groupBy(losDiscountTooltip?.result, 'label')
      }
      return []
    }, [losDiscountTooltip, loadingTooltipContent])

    const tooltipContent = <div>
      {isEmpty(groupedLOSDiscountTiers) ?
        <Loading/>
        :
        <div className="p-2 text-center">
          {t('long_stay_discount')}
          <br/>
          <br/>
          {Object.keys(groupedLOSDiscountTiers).map((tierLabel) => (
            <div>
                <strong>{groupedLOSDiscountTiers[tierLabel][0].label}</strong>
                <br/>
                {groupedLOSDiscountTiers[tierLabel].map((tier) => {
                  return <div>
                      <span>{tier.tierDescription}</span>
                      <br/>
                    </div>
                })}
                <br/>
            </div>
          ))}
          <br/>
          {t('extend_for_next_tier')}
        </div>
      }
    </div>

    return (
      <div className={className}>
        <div className="item__label d-flex flex-row gap-2 align-items-center">
            <Tooltip style={{width: '300px'}} text={tooltipContent}>
              <div
                className="flex flex-row align-items-center"
                role="button"
              >
                {label}
                <BiInfoCircle fill="#576f76" className="icon mx-1" />
              </div>
            </Tooltip>
        </div>
        <div className="d-flex align-items-end" dir="ltr">
          <span style={{color: 'rgb(63, 151, 66)'}}>-</span>
          {amount}
        </div>
      </div>
    )
}

export default LOSDiscountTooltip;