import React from "react";

// Translation Package
import { useTranslation } from "react-i18next";

import {
  CiHouse,
  CiGroupOfPeople,
  CiBed,
  CiBathtub,
} from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";
import calculateSentences from "../../../Utils/functions/calculateSentences";

import getParagraphs from "../functions/getParagraphs";

const About = ({ suite }) => {
  const isMobile = useMobileDetect();

  //Translation hook
  const { t } = useTranslation();

  const getMetaText = (data, text) => {
    switch (text) {
      case "Bedroom": {
        if (data === 0) {
          return t("studio");
        }
        if (data > 1) {
          return data + " " + t(`${text + "s"}`.toLowerCase());
        } else {
          return data + " " + t(text.toLowerCase());
        }
      }
      case "Guest": {
        if (data > 1) {
          return data + " " + t("guests_counter");
        } else {
          return data + " " + t(text.toLowerCase());
        }
      }

      default: {
        if (data > 1) {
          return data + " " + t(`${text + "s"}`.toLowerCase());
        } else {
          return data + " " + t(text.toLowerCase());
        }
      }
    }
  };

  return (
    <div className="suite__about" id="about">
      <div className="suite__section">
        <div className="about__meta no-scrollbar gap-4">
          <div className="meta__item">
            <CiBed size={20} className="meta-icon" />{" "}
            {getMetaText(suite.bedrooms, "Bedroom")}
          </div>
          <div className="meta__item">
            <CiBathtub size={20} className="meta-icon" />{" "}
            {getMetaText(suite.baths, "Bathroom")}
          </div>
          <div className="meta__item">
            <CiGroupOfPeople size={20} className="meta-icon" />{" "}
            {getMetaText(suite.maxGuests, "Guest")}
          </div>
        </div>
        <SectionDivider className="my-4" hide={!isMobile} />
        <SectionTitle icon={<CiHouse size={22} />} title={t("about_listing")} />
        <div className="section__content about__content">
          {getParagraphs(
            suite.aboutProperty ||
              calculateSentences(suite.description).short_text
          )}
          <br />
        </div>
      </div>
    </div>
  );
};
export default About;
