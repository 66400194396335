import React from "react";

import { Paragraph, Title, Div } from "./Tags";

const FiveHiddenGems = () => {
  const divStyle = { marginTop: "70px" };
  return (
    <Div>
      <Div>
        <Title>
          What other start-up do you wish you might have started instead of
          Stella Stays?
        </Title>

        <Paragraph>
          During the initial phase, while we were discussing business ideas,
          there were a lot of opportunities around real estate and around
          property technology. I have always been interested in the idea of
          technology enhancing the broker and agent experience. This was one of
          the potential start-up projects I was looking into that would have
          really solved a critical problem which exists today.
        </Paragraph>
      </Div>

      <Div style={divStyle}>
        <Title>What would you do differently if you had to start over?</Title>

        <Paragraph>
          Stella Stays came through quite successfully. So, we wouldn’t
          necessarily do much differently but the one advice that we would have
          been grateful to receive while just starting out, is to never
          underestimate the potential for the growth of any business idea,
          especially in a market that is still growing. I know many
          entrepreneurs are afraid of taking big decisions early on but that’s
          what’s important. Sometimes it is okay to jump into things that you
          might think you are not ready for, but don’t be afraid. You will be
          pleasantly surprised at the outcome.
        </Paragraph>
      </Div>

      <Div style={divStyle}>
        <Title>What skills have you learned in setting up your business?</Title>

        <Paragraph>
          The most essential skill I have learned while setting up the business
          is sales. Sometimes all you have is two minutes, or maybe 30 seconds,
          to sell your idea. You have to figure out and execute a good pitch as
          fast as possible, especially when you are at the early stages. The
          other crucial aspect I brushed up on is finance, as it is essential to
          understand the end-to-end finances as a start-up. As entrepreneurs, we
          understand the product, company’s operations and what needs to happen,
          but finances are kept aside as an afterthought during initial stages.
          Eventually, you will need to deal with it and dig in deep.
        </Paragraph>
      </Div>

      <Div style={divStyle}>
        <Title>What is the one quality that an entrepreneur should have?</Title>

        <Paragraph>
          You have to be a salesperson or just really have that skill to sell
          your perspective and your idea to somebody else, whether an investor,
          an adviser, a client and even to the first set of people you hire. If
          you do a great job at selling your product and your dream, then you’re
          up for something great. If you can’t, that’s when you’ll see your
          company struggle.
        </Paragraph>
      </Div>

      <Div style={divStyle}>
        <Title>
          What advice would you give to entrepreneurs who are struggling?
        </Title>

        <Paragraph>
          There are a couple of things: focus and prioritise. That’s what we
          learned as a growing company. Think about ‘what’s the best effort that
          will get you the best return?’ Focus on small steps, think carefully
          and take one step at a time. Don’t look around and simply follow
          trends, calculate your own moves and create your own timelines. Also,
          feedback is crucial. One of the things we do is that we send updates
          to our investors, showing them the good and bad bits, and also the
          really ugly side of things. Entrepreneurs are afraid to talk about the
          ugly bits, but you have to be able to put it out there to obtain
          quality feedback.
        </Paragraph>
      </Div>
    </Div>
  );
};
export default FiveHiddenGems;
