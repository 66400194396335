import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";

import PrimaryButton from "Components/Buttons/Primary";
import UploadGuestDocumentsCard from "./UploadDocumentsCard/index";
import IncrementalInput from "./UploadDocumentsCard/IncrementInput";
import RadioBox from "Components/Form/RadioBox";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import QUERIES from "Constants/Queries";
import Loading from "Components/Utils/Loading";
import { isNonEmptyObj } from "../helpers/isNoneEmptyObject";
import { areFieldsDirty } from "../helpers/areFieldsDirty";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

/**
 * Default object to append when adding a new guest or visitor.
 */
const defaultGuestObject = {
  firstName: "",
  lastName: "",
  documentType: "passport",
  docs: [],
};

const MAX_VISITORS_COUNT = 5;

function sanitizeFieldArray(fieldArray) {
  return fieldArray
    ?.map(
      ({
        documentType,
        rejectionRemarksTranslationKey,
        showGreenCheck,
        id,
        ...rest
      }) => rest
    )
    ?.map((item) => ({ ...item, docs: item?.docs?.filter((doc) => doc) }));
}

function processDocuments(documents, isGuest) {
  return documents
    .filter((doc) => doc?.isGuest === isGuest)
    .map((doc) => ({
      firstName: doc?.firstName || "",
      lastName: doc?.lastName || "",
      rejectionRemarksTranslationKey: doc?.rejectionRemarksTranslationKey,
      showGreenCheck: !doc?.rejectionRemarksTranslationKey,
      documentType: doc?.docs
        ?.map((item) => item?.docType)
        ?.includes("passport")
        ? "passport"
        : "nationalID",
      docs: doc?.docs?.map((d) => ({
        url: d?.url,
        name: d?.name,
        docType: d?.docType,
      })),
    }));
}
/**
 * IDVerification Component
 * This component renders the ID verification form for guests and visitors.
 *
 * @component
 * @param {object} props - Component props.
 * @param {number} [props.initialGuests] - Initial number of guests to start with.
 * @param {number} [props.documents] - the previously uploaded documents array.
 * @param {number} [props.city] - the city of the property.
 * @param {number} [props.country] - the country of the property.
 * @param {boolean} [props.isLastStep] - IF current step is last step.
 * @param {function} props.refetch - Function to refetch the reservation data.
 * @returns {JSX.Element}
 */
function IDVerification({
  initialGuests,
  documents,
  city,
  country,
  isLastStep,
  maxGuests,
  refetch,
}) {
  const { t } = useTranslation();

  // Browser history
  const history = useHistory();

  const { id = undefined } = useParams();

  const { guests, visitors } = useMemo(() => {
    if (documents.length > 0) {
      return {
        guests: processDocuments(documents, true),
        visitors: processDocuments(documents, false),
      };
    } else {
      return {
        guests: Array.from({ length: initialGuests }, () => ({
          ...defaultGuestObject,
        })),
        visitors: [],
      };
    }
  }, [documents, initialGuests]);

  const [showVisitors, setShowVisitors] = useState(
    visitors?.length > 0 ? "yes" : "no"
  );

  // Initialize form methods from react-hook-form
  const methods = useForm({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      guests: guests,
      visitors: visitors,
    },
    shouldUnregister: true,
  });

  // Use field arrays for guests
  const {
    fields: guestsDetails,
    append: appendGuestDetails,
    remove: removeGuestDetails,
  } = useFieldArray({
    control: methods.control,
    name: "guests",
    rules: { minLength: 1 },
  });

  // Use field arrays for visitors
  const {
    fields: visitorsDetails,
    append: appendVisitorDetails,
    remove: removeVisitorDetails,
  } = useFieldArray({
    control: methods.control,
    name: "visitors",
  });

  // Mutation for submitting documents of the guests and visitors.
  const [submitDocuments, { error: documentsErrors }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SUBMIT_UPLOADED_GUESTS_DOCUMENTS
  );

  const [submitOnlineCheckIn, { error: onlineCheckInErrors }] = useMutation(
    QUERIES.ONLINE_CHECK_IN.SUBMIT_ONLINE_CHECK_IN
  );

  const shouldUpdateDocuments = areFieldsDirty(
    ["guests", "visitors"],
    methods?.formState?.dirtyFields
  );

  /**
   * Handles the form submission.
   * @param {object} data - The form data.
   */
  const onSubmit = async (data) => {
    try {
      // Exclude documentType from the guests array of object.
      const guests = sanitizeFieldArray(data?.guests);

      const payload = { guests };

      // Add visitors if there any.
      if (data?.visitors?.length > 0 && showVisitors === "yes") {
        payload.visitors = sanitizeFieldArray(data?.visitors);
      }

      // submit the documents if the fields were dirty

      if (shouldUpdateDocuments && !(await submitDocumentsStep(payload)))
        return;

      const { data: newData } = await refetch();

      if (newData?.result?.documents) {
        methods.reset({
          guests: processDocuments(newData?.result?.documents, true),
          visitors: processDocuments(newData?.result?.documents, false),
        });
      }

      if (isLastStep) {
        // if is last step we submit the final step mutation (submit online-check-in)
        if (!(await submitFinalStep())) return;
        history.replace(`/online-check-in/${id}/details`);
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const submitDocumentsStep = async (payload) => {
    const { data: documentsResponse, error: documentsError } =
      await submitDocuments({
        variables: {
          code: id,
          ...payload,
        },
      });

    if (documentsError) return false;

    return documentsResponse?.result || false;
  };

  const submitFinalStep = async () => {
    const { data: submitOnlineCheckInResponse, error: onlineCheckInError } =
      await submitOnlineCheckIn({
        variables: {
          code: id,
        },
      });

    if (onlineCheckInError) return false;

    return submitOnlineCheckInResponse?.result || false;
  };

  const cities = ["dubai", "riyadh", "istanbul", "london"];

  return (
    <section className="id-verification">
      {/* Page Title */}
      <h1 className="online-check-in__subtitle">{t("guests_information")}</h1>

      {/* Description */}
      <p className="online-check-in__description mt-4">
        {cities.includes(city)
          ? t(`${city ?? "dubai"}_id_submission_policy`)
          : null}
      </p>

      {/* Guests Incremental Input */}
      <section className="col-12 col-lg-6 mt-4">
        <IncrementalInput
          update={(index, type) => {
            if (type === "increase") {
              appendGuestDetails({ ...defaultGuestObject });
            } else {
              removeGuestDetails(index);
            }
          }}
          className="guest-input big-input"
          initialValue={initialGuests}
          updateCount={guestsDetails?.length}
          label={t("guests")}
          pluralLabel={t("guests")}
          singularLabel={t("guest")}
          startEmptyLabel={t("add_guests")}
          maxCount={maxGuests ?? 10}
        />
      </section>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="">
          {/* Guests Documents Cards */}
          <section className="mt-4">
            {guestsDetails?.map((item, index) => (
              <UploadGuestDocumentsCard
                key={item.id}
                title={`${t("guest")} ${index + 1}`}
                handleRemoveGuest={() => {
                  if (guestsDetails?.length > 1) removeGuestDetails(index);
                }}
                name={`guests.${index}`}
                field={item}
                rejectionRemarksTranslationKey={
                  item?.rejectionRemarksTranslationKey
                }
                errors={methods?.errors?.guests?.[index] ?? {}}
                country={country}
                city={city}
                showGreenCheck={item.showGreenCheck}
              />
            ))}
            {/*  */}
          </section>

          {/* Visitors Section */}
          <section>
            {city === "dubai" && (
              <section>
                <hr className="online-check-in__section-separator" />

                <h1 className="online-check-in__subtitle">{t("visitors")}</h1>

                <h4 className="online-check-in__info--dark-gray online-check-in__info--sm primary-font mt-3">
                  {t("plan_having_visitors")}
                </h4>

                <small className="online-check-in__description mt-4">
                  {t("register_visitors_rule")}
                </small>

                {/* Show Visitors Radio Buttons */}
                <section className="d-flex gap-3 my-4">
                  <RadioBox
                    name="showVisitors"
                    label={t("yes")}
                    selected={"yes"}
                    value={showVisitors}
                    onClick={() => setShowVisitors("yes")}
                  />
                  <RadioBox
                    name="showVisitors"
                    label={t("no")}
                    selected={"no"}
                    value={showVisitors}
                    onClick={() => {
                      setShowVisitors("no");
                      if (documents?.length === 0) removeVisitorDetails();
                    }}
                  />
                </section>
              </section>
            )}

            {/* Visitors Incremental Input */}
            {showVisitors === "yes" && (
              <section>
                <section className="col-12 col-lg-6">
                  <IncrementalInput
                    update={(index, type) => {
                      if (type === "increase") {
                        appendVisitorDetails({ ...defaultGuestObject });
                      } else {
                        removeVisitorDetails(index);
                      }
                    }}
                    className="guest-input big-input"
                    updateCount={visitorsDetails?.length}
                    label={t("visitors")}
                    pluralLabel={t("visitors")}
                    singularLabel={t("visitor")}
                    startEmptyLabel={t("add_visitor")}
                    startEmpty
                    maxCount={MAX_VISITORS_COUNT}
                  />
                </section>

                {/* Visitors Documents Cards */}
                <section className="mt-4">
                  {visitorsDetails?.map((item, index) => (
                    <UploadGuestDocumentsCard
                      key={item.id}
                      name={`visitors.${index}`}
                      title={`${t("visitor")} ${index + 1}`}
                      handleRemoveGuest={() => {
                        if (visitorsDetails?.length > 0)
                          removeVisitorDetails(index);
                      }}
                      rejectionRemarksTranslationKey={
                        item?.rejectionRemarksTranslationKey
                      }
                      errors={methods.errors?.visitors?.[index] ?? {}}
                      field={item}
                      country={country}
                      city={city}
                      showGreenCheck={item.showGreenCheck}
                    />
                  ))}
                </section>
              </section>
            )}
          </section>

          {(documentsErrors || onlineCheckInErrors) && (
            <small className="p-3 online-check-in__info--error--text online-check-in__info--error--bg text-center mt-5 d-block rounded-3">
              {t("error_general")}
            </small>
          )}

          {/* Submit Button */}
          <PrimaryButton
            label={
              methods?.formState?.isSubmitting ? (
                <Loading light />
              ) : (
                t("online_check_in_complete")
              )
            }
            disabled={
              methods.formState.isSubmitting || isNonEmptyObj(methods?.errors)
            }
            className="online-check-in__button my-5"
            type="submit"
          />
        </form>
      </FormProvider>
    </section>
  );
}

IDVerification.propTypes = {
  /** Initial number of guests to display. */
  initialGuests: PropTypes.number.isRequired,
  /** the previously submitted documents */
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      isGuest: PropTypes.bool,
      docs: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          docType: PropTypes.string,
        })
      ),
      rejectionRemarksTranslationKey: PropTypes.string,
    })
  ),
  /** the City of the property */
  city: PropTypes.string.isRequired,
  /** the Country of the property */
  country: PropTypes.string.isRequired,
  /** IF current step is last step*/
  isLastStep: PropTypes.bool.isRequired,
  /**Default Max Guest for the property*/
  maxGuests: PropTypes.number.isRequired,
  /** Refetch reservation Data*/
  refetch: PropTypes.func.isRequired,
};

IDVerification.defaultProps = {
  initialGuests: 1,
  documents: [],
  isLastStep: false,
  maxGuests: 10,
};

export default React.memo(IDVerification);
