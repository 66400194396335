import React from "react";

// Translation Package
import { useTranslation } from "react-i18next";

const Divider = () => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div className="auth-form__social-methods-divider">
      <div className="divider-text">{t("or")}</div>
      <div className="divide"></div>
      <div className="divide"></div>
    </div>
  );
};
export default Divider;
