import React from "react";
import WhatsAppFloatingIcon from "./floating-whatsapp.svg";
import styles from "./whatsapp-icon.module.scss";

const WhatsAppIcon = ({href = 'https://wa.me/18777703934?text=Hello%20Stella%20Stays!%20I%20am%20interested%20in%20making%20a%20reservation.'}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <img
        alt="floating-whatsapp-icon"
        src={WhatsAppFloatingIcon}
        class={styles["whatsapp-icon"]}
      />
    </a>
  );
};

export default WhatsAppIcon;
