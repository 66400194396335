import React from "react";
import { Portal } from "react-portal";
import ReservationSuccess from "../../Components/ReservationSuccess";
import fixListingName from "Components/Utils/functions/fixListingName";
import { useHistory } from "react-router-dom";

import { usePaymentContext } from "Components/Booking/Payment/index";
import { PAYMENT_CONTEXT } from "Constants/globals";
import PrimaryButton from "Components/Buttons/Primary";
import * as ROUTES from "Constants/Routes";

// Translation Package
import { useTranslation } from "react-i18next";

const BankTransferSuccessful = ({
  propertyData,
  startDate,
  endDate,
  reservationID,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const history = useHistory();
  const { context } = usePaymentContext();

  return (
    <Portal>
      <div className="logout-modal">
        <div className="content_holder">
          <div className="modal_content modal_content container-max-sm">
            <ReservationSuccess
              title={t("booking_tentatively_confirmed")}
              code={reservationID}
              message={t("payment_under_review_4_6_days")}
              propertyTitle={fixListingName(propertyData?.listingName)}
              propertyLocation={fixListingName(propertyData?.location?.address)}
              propertyImg={propertyData?.mainImageUrl}
              startDate={startDate}
              endDate={endDate}
            />

            {context !== PAYMENT_CONTEXT.MOBILE_APP && (
              <div className="d-flex px-2 mt-4">
                <PrimaryButton
                  onClick={() => {
                    history.push(ROUTES.HOME);
                  }}
                >
                  {t("continue_to_site")}
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default BankTransferSuccessful;
