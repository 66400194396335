import React from "react";

const SearchFilterButton = ({
  label,
  buttonText,
  className,
  onClick,
  id,
  divId,
  children,
  disabled,
  loading,
  hasValue,
  tabIndex,
  ...buttonProps
}) => {
  const mainClass = "search-filter-button";

  const composeButtonClass = (suffix) => {
    if (!className) {
      if (suffix) return `${mainClass}__${suffix}`;
      return mainClass;
    }
    if (suffix) {
      return `${mainClass}__${suffix} ${className}__${suffix}`;
    }
    return `${mainClass} ${className}`;
  };

  const holderClass = composeButtonClass("holder");
  const labelClass = composeButtonClass("label");
  const childrenClass = composeButtonClass("children");
  const buttonClass = composeButtonClass("button");

  const handleClick = (e) => {
    e.preventDefault();
    if (!onClick) return;
    if (typeof onClick !== "function") return;
    onClick();
  };
  const stateClass = `
	${loading ? "loading" : ""} ${disabled ? "disabled" : ""} `;

  return (
    <div className={`${mainClass} ${className || ""} ${stateClass}`} id={divId}>
      <div className={holderClass}>
        <label className={labelClass} id={label}>
          {label}
        </label>

        {children ? (
          <div className={childrenClass} data-empty={!hasValue}>
            {children}
          </div>
        ) : (
          <button
            id={id}
            className={`${buttonClass} ${stateClass}`}
            onClick={handleClick}
            disabled={disabled}
            style={{ fontWeight: hasValue ? 600 : "" }}
            data-empty={!hasValue}
            tabIndex={tabIndex || 1}
            {...buttonProps}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchFilterButton;
