import React, { useState, useReducer } from "react";

// Packages
import { useHistory } from "react-router-dom";
import moment from "moment";

// Constants
import * as ROUTES from "../../../../Constants/Routes";
import { FILTERS, DATE_FORMAT } from "../../../../Constants/url_params";
import { SEARCH_TYPES } from "../../../Booking/Search/search_constants";

// Functions
import createSearchString from "../../../Utils/functions/createSearchString";
import useMobileDetect from "../../../Utils/hooks/useMobileDetect";

// Components
import DateRangeInput from "../../../Form/DatePicker";
import CityInput from "../../../Form/CityInput";
import GuestsInput from "../../../Form/GuestInput";
import PrimaryButton from "../../../Buttons/Primary";

import { CiSearch } from "../../../../CustomIcons";

// Translation Package
import { useTranslation } from "react-i18next";

const initialSearchState = {
  city: null,
  dates: {
    start: null,
    end: null,
  },
  flexible: {
    type: null,
    months: null,
  },
  guests: 1,
  searchType: SEARCH_TYPES.calendar,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "city":
      return { ...state, city: action.payload };
    case "dates":
      const dateFormat = DATE_FORMAT.paramFormat;
      const start = moment(action.payload.start).format(dateFormat);
      const end = moment(action.payload.end).format(dateFormat);

      return {
        ...state,
        flexible: initialSearchState.flexible,
        searchType: SEARCH_TYPES.calendar,
        dates: {
          start: start,
          end: end,
        },
      };
    case "flexible":
      return {
        ...state,
        flexible: {
          type: action.payload.type,
          months: action.payload.months,
        },
        searchType: SEARCH_TYPES.flexible,
        dates: initialSearchState.dates,
      };
    case "guests":
      return { ...state, guests: action.payload };
    default:
      throw new Error();
  }
};

const createParams = (states) => {
  const params = {
    [FILTERS.city.paramKey]: states.city,
    [FILTERS.startDate.paramKey]: states.dates.start,
    [FILTERS.endDate.paramKey]: states.dates.end,
    [FILTERS.flexibility_months.paramKey]: states.flexible.months,
    [FILTERS.flexibility_type.paramKey]: states.flexible.type,
    [FILTERS.guests.paramKey]: states.guests,
    [FILTERS.searchType.paramKey]: states.searchType,
  };
  return Object.entries(params)
    .filter((p) => p[1])
    .map((p) => `${p[0]}=${p[1]}`);
};

const InputArea = () => {
  // Translation hook
  const { t } = useTranslation();

  const history = useHistory();
  const isMobile = useMobileDetect(992);

  const [states, dispatch] = useReducer(reducer, initialSearchState);

  const [emptyCity, setEmptyCity] = useState(null);
  const search = () => {
    setEmptyCity(null);

    if (!states.city) {
      setEmptyCity({ menuIsOpen: true });
      return;
    }

    const params = createParams(states);
    const searchString = createSearchString(params);

    history.push({
      pathname: ROUTES.SEARCH,
      search: searchString,
    });
  };

  return (
    <div className="homepage__input-area" id="input-area">
      <div className="input-area gap-1">
        <div className="holder holder__city" onClick={() => setEmptyCity(null)}>
          <CityInput
            update={(p) => dispatch({ type: "city", payload: p })}
            {...emptyCity}
            autoScrollIntoView={true}
          />
        </div>
        <div className="holder holder__dates">
          <DateRangeInput
            header={isMobile ? t("select_dates") : null}
            updateDates={(p) => dispatch({ type: "dates", payload: p })}
            updateFlexible={(p) => dispatch({ type: "flexible", payload: p })}
            allowFlexible={true}
            autoClose={false}
            forceClose={false}
            autoScrollIntoView={true}
            nodeId="homepage__date_input-holder"
          />
        </div>

        <div className="holder holder__guests">
          <GuestsInput
            update={(p) => dispatch({ type: "guests", payload: p })}
            showMaxPlus={true}
            startEmpty={true}
          />
        </div>

        <div className="holder holder__search">
          <PrimaryButton
            className="search-button d-flex flex-row gap-2 justify-content-center"
            onClick={search}
          >
            <CiSearch className="icon" color="#fff" size="20" source="icons8" />
            {t("search")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
export default InputArea;
