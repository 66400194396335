import React, { useState } from "react";
import QUERIES from "../../../Constants/Queries";
// Translation Package
import { useTranslation } from "react-i18next";

// Packages
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import firebase from "firebase/app";
import dayjs from "dayjs";

// Components
import FormInput from "../../Form/FormInput";
import Loading from "../../Utils/Loading";
import PrimaryButton from "../../Buttons/Primary";

import {
  useUpdateModal,
  DEFAULT_STATE,
} from "../../../Providers/FloatingModalProvider";

import { AGE_LIMIT, DATE_OF_BIRTH_FORMAT, ERROR_MESSAGES } from "../constants";
import formatDateOfBirth from "../functions/formatDateOfBirth";

import redirectAfterLoginSuccess from "Components/Auth/functions/redirectAfterLoginSuccess.js";

const VerifyBirthdateForm = ({ redirectTo, urlParams }) => {
  //Translation hook
  const { t } = useTranslation();

  // Modal handling
  const updateModal = useUpdateModal();

  // Handle date of birth masking
  const [dateOfBirth, setDateOfBirth] = useState("");
  const handleDateOfBirth = (e) => {
    formatDateOfBirth(e.target.value, setDateOfBirth);
  };
  const handleOnDateOfBirthKeyDown = (e) => {
    const keys = {
      backspace: 8,
    };
    let val = e.target.value;

    // Detect remove
    if (e.which === keys.backspace) {
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        setDateOfBirth(val);
      }
    }
  };

  // Mutations
  const [signInWithProvider] = useMutation(QUERIES.USER.SIGN_IN_WITH_PROVIDER);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setError(null);

    const format = DATE_OF_BIRTH_FORMAT.toUpperCase();
    const dob = dayjs(data.dateOfBirth, format, true);

    const doesDateExist = dob.isValid();
    if (!doesDateExist) {
      setError({ message: t(ERROR_MESSAGES.dateOfBirth.invalidFormat) });
      return;
    }

    const today = dayjs();
    const age = today.diff(dob, "year");
    if (age < AGE_LIMIT) {
      setError({ message: t(ERROR_MESSAGES.dateOfBirth.underAge) });
      return;
    }

    const formattedBirthdate = dob.format("YYYY-MM-DD");

    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      window.location.reload();
      return;
    }

    setSubmitting(true);
    const firebaseToken = await currentUser.getIdToken();

    await signInWithProvider({
      variables: {
        payload: {
          firebaseToken: firebaseToken,
          birthdate: formattedBirthdate,
        },
      },
    })
      .then((res) => res.data.result.jwtToken)
      .then((jwtToken) => {
        if (!jwtToken) {
          setError({ message: t(ERROR_MESSAGES.unknown) });
          return;
        }
        updateModal(DEFAULT_STATE);

        // Set token
        localStorage.setItem("token", jwtToken);

        // Redirect
        redirectAfterLoginSuccess(redirectTo, urlParams);
      })
      .catch((error) => {
        setError(error);
      });

    setSubmitting(false);
  };

  return (
    <div className="auth-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form__verify-email"
      >
        <div className="row">
          <div className="col-12 ">
            <h6 className="auth-form__form-title">{t("date_of_birth")}</h6>
          </div>
        </div>
        {/* EMAIL */}
        <div className="row">
          <div className="col-12 verify-email-text">
            <p>
              {t("error_birthdate_missing")} <br />
              {t("date_of_birth_notice")}
            </p>
          </div>
          {/* Verify code */}
          <div className="col-12 mt-2">
            <FormInput
              register={register({
                required: true,
                minLength: 10,
              })}
              inputMode="numeric"
              type="text"
              name="dateOfBirth"
              label={t("date_of_birth")}
              value={dateOfBirth}
              onChange={handleDateOfBirth}
              onKeyDown={handleOnDateOfBirthKeyDown}
              forceLabelToFloat={true}
              error={errors.dateOfBirth || error}
              placeholder={DATE_OF_BIRTH_FORMAT}
              autoComplete="off"
            />
          </div>

          <div className="col-12">
            <div className="verify-code-button mt-3">
              <PrimaryButton
                label={submitting ? <Loading light /> : t("continue")}
                disabled={submitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyBirthdateForm;
