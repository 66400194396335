import getMapBounds from "./../../../../../Utils/GoogleMap/getMapBounds";

const getBounds = (map) => {
  const mapBounds = getMapBounds(map);
  if (!mapBounds) return;
  return [
    // North west - top left
    { latitude: mapBounds[3], longitude: mapBounds[0] },
    // North east - top right
    { latitude: mapBounds[3], longitude: mapBounds[2] },
    // South east - bottom right
    { latitude: mapBounds[1], longitude: mapBounds[2] },
    // South west - bottom left
    { latitude: mapBounds[1], longitude: mapBounds[0] },
  ];
};

export default getBounds;
