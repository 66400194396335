import React, { useEffect, useState } from "react";
import QUERIES from "../../../Constants/Queries";
import { useQuery } from "@apollo/client";

// Packages
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import useMobileDetect from "../../Utils/hooks/useMobileDetect";

import JourneyArea from "./JourneyArea";
import Policies from "./Policies";
import ListingDetails from "./ListingDetails";
import Help from "./Help";

import Loading from "../../Utils/Loading";
import Error from "../../Utils/Error";

import Topbar from "../../Layout/Topbar";
import Footer from "../../Layout/Footer";
import logGaEvent from "../../../lib/ga/logGaEvent";

// Translation Package
import { useTranslation } from "react-i18next";

const BookingConfirmation = () => {
  // Translation hook
  const { t } = useTranslation();

  const isMobile = useMobileDetect(992, 10);

  const { id } = useParams();
  const {
    loading,
    error,
    data: fetchData,
  } = useQuery(QUERIES.RESERVATIONS.GET_RESERVATIONS, {
    variables: { reservationID: id },
    skip: !id,
  });

  // GA event
  const [calledGa, setCalledGa] = useState(false);
  useEffect(() => {
    if (calledGa) return;
    if (loading || error) return;
    if (fetchData.result.length === 0) return;

    const data = {
      reservation: fetchData.result[0].reservation,
      property: fetchData.result[0].property,
    };
    const gaPurchaseValue = {
      transaction_id: data.reservation.confirmationCode,
      currency: data.reservation.quotation.currency,
      value:
        data.reservation.quotation.totalPriceIncludingVAT ??
        data.reservation.quotation.totalPrice,
      items: [
        {
          item_id: data.property.id,
          item_name: data.property.internalName,
          price:
            data.reservation.quotation.totalPriceIncludingVAT ??
            data.reservation.quotation.totalPrice,
          quantity: 1,
        },
      ],
    };
    logGaEvent(`purchase`, gaPurchaseValue);
    setCalledGa(true);
  }, [fetchData, loading, error, calledGa]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  const doesExist = fetchData.result.length > 0;
  if (!doesExist)
    return (
      <Error errorHeadline={t(`payment_failure_message`)} redirect={false} />
    );

  const data = {
    reservation: fetchData.result[0].reservation,
    property: fetchData.result[0].property,
  };

  return (
    <>
      <Topbar hideFindAStella />
      <Helmet>
        <title>
          {t("booking_confirmed")} - {t("stella_stays")}
        </title>
      </Helmet>
      <div className="container booking-confirmation" id="confirmation-page">
        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="row confirmation-content">
              <div className="col-12">
                <JourneyArea city={data.property.city} data={data} />
              </div>
              {isMobile && <ListingDetails data={data} isMobile={isMobile} />}
              <div className="col-12">
                <Policies data={data} />
              </div>
              <div className="col-12">
                <Help data={data} />
              </div>
            </div>
          </div>
          {!isMobile && <ListingDetails data={data} isMobile={isMobile} />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingConfirmation;
