import dayjs from "dayjs";
import { FILTERS } from "../../../../Constants/url_params";
import logGaEvent from "../../../../lib/ga/logGaEvent";

const logListingClickEvent = (
  fullList,
  filters,
  data,
  clickOrigin,
  index = 0
) => {
  const availableProperties = fullList.filter((i) => !i.isAlternative);
  const alternativeProperties = fullList.filter((i) => i.isAlternative);

  const filterValues = {
    city: filters[FILTERS.city.paramKey].toLocaleLowerCase(), // city
    guests: filters[FILTERS.guests.paramKey], // number of guests

    search_type: filters[FILTERS.searchType.paramKey], // [calendar,flexible]

    ...(filters[FILTERS.searchType.paramKey] === "calendar" && {
      checkin_date: filters[FILTERS.startDate.paramKey], // YYYY-MM-DD
      checkout_date: filters[FILTERS.endDate.paramKey], // YYYY-MM-DD
      nights: dayjs(filters[FILTERS.endDate.paramKey]).diff(
        dayjs(filters[FILTERS.startDate.paramKey]),
        "days"
      ), // number of nights
      lead_time: dayjs(filters[FILTERS.startDate.paramKey]).diff(
        dayjs(),
        "days"
      ), // checkin_date - today_date
    }),
    ...(filters[FILTERS.searchType.paramKey] === "flexible" && {
      flexible_length: filters[FILTERS.flexibility_type.paramKey], // weekend, week, month
      flexible_months: filters[FILTERS.flexibility_months.paramKey]
        .split(",")
        .map((month) =>
          dayjs(month, "YYYY-MM").format("MMMM").toLocaleLowerCase()
        )
        .join(", "), // [august, september, ....]
    }),
    available_properties: availableProperties.length, // number of availables properties shown
    alternative_properties: alternativeProperties.length, // number of alternatives shown shown
    available_properties_list: availableProperties
      .map((i) => i.internalName)
      .join(", "), // (list of properties shown in the available section [DXB-JVC-LT307, DXB-JVC-LT310, ...]
    alternative_properties_list: alternativeProperties
      .map((i) => i.internalName)
      .join(", "), // (list of properties shown in the alternative section [DXB-JVC-LT307, DXB-JVC-LT310, ...]
  };

  const clickValues = {
    ...filterValues,
    listing_name: data.internalName,
    listing_bedrooms: data.bedrooms,
    listing_bathrooms: data.baths,
    listing_max_guests: data.maxGuests,
    average_price: data.avgPrice || data.basePrice, // value of label in listing card
    currency: data.displayCurrency,
    property_state: data.isAlternative ? "alternative" : "available", // available or alternative
    results_position: index, // order in the search results
    ...(data.isAlternative && {
      alternative_checkin_date: data.quotationStartDate, // if listing clicked was in alternatives section YYYY-MM-DD
      alternative_checkout_date: data.quotationEndDate, // if listing clicked was in alternatives section YYYY-MM-DD
    }),
    click_origin: clickOrigin, // map pin or listing card
  };

  logGaEvent(`listing_click`, clickValues);
};
export default logListingClickEvent;
