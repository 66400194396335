import React from "react";
import { Portal } from "react-portal";
import ReservationSuccess from "../../Components/ReservationSuccess";
import fixListingName from "Components/Utils/functions/fixListingName";
import { useHistory } from "react-router-dom";

import { usePaymentContext } from "Components/Booking/Payment/index";
import { PAYMENT_CONTEXT } from "Constants/globals";
import PrimaryButton from "Components/Buttons/Primary";

import styles from "../../Components/ReservationSuccess/index.module.scss";
import { CONTACT_NUMBER, CONTACT_EMAIL } from "Constants/globals";
import * as ROUTES from "Constants/Routes";

// Translation Package
import { Trans, useTranslation } from "react-i18next";

const InquirySuccessful = ({
  propertyData,
  startDate,
  endDate,
  reservationID,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const { context } = usePaymentContext();
  const history = useHistory();

  const text = encodeURI(
    `Hello! I have a question about this reservation: ${reservationID}.`
  );

  return (
    <Portal>
      <div className="logout-modal">
        <div className="content_holder mt-0">
          <div
            className="modal_content modal_content container-max-sm"
            style={{ height: "100vh", overflowY: "auto" }}
          >
            <ReservationSuccess
              title={t("booking_inquiry_sent")}
              codeTitle={t("reference_number")}
              code={reservationID}
              message={t("booking_inquiry_description")}
              propertyTitle={fixListingName(propertyData?.listingName)}
              propertyLocation={fixListingName(propertyData?.location?.address)}
              propertyImg={propertyData?.mainImageUrl}
              startDate={startDate}
              endDate={endDate}
            />

            {context !== PAYMENT_CONTEXT.MOBILE_APP && (
              <div
                className={`d-flex flex-column px-2 mt-4 ${styles.successFooter}`}
              >
                <h6>{t("looking_forward_to_host")}</h6>
                <p>
                  <Trans
                    i18nKey="reach_out_notice"
                    values={{
                      whatsapp: CONTACT_NUMBER.HUMAN,
                      email: CONTACT_EMAIL,
                    }}
                  >
                    Reach out to our team via WhatsApp on{" "}
                    <a
                      href={`https://wa.me/${CONTACT_NUMBER.EMBED.replace(
                        "+",
                        ""
                      ).replace(/\s/g, "")}?text=${text}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>{" "}
                    or email us on{" "}
                    <a
                      href={`mailto:${CONTACT_EMAIL}?subject=Inquiry&body=${text}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>{" "}
                  </Trans>
                </p>

                <div
                  className="custom_datepicker__footer d-flex flex-column gap-2 mt-2"
                  style={{ border: "none", height: "auto", position: "static" }}
                >
                  <PrimaryButton
                    onClick={() => {
                      history.push(ROUTES.HOME);
                    }}
                  >
                    {t("close")}
                  </PrimaryButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default InquirySuccessful;
