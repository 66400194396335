import React from "react";

import { FILTERS } from "../../../../Constants/url_params";

import { CiCancellation, CiChevronRight } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";

import useFilters from "../../../Utils/hooks/useFilters";
import dayjs from "dayjs";
import toMoney from "../../../Utils/functions/toMoney";

// Translation Package
import { useTranslation } from "react-i18next";
import { i18n_TYPES } from "../../../../Constants/i18n";
require("dayjs/locale/ar");

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

const CancellationPolicy = ({ suite, handleOnPriceDatesClick, quotation }) => {
  //Translation hook
  const { t } = useTranslation();

  const filters = useFilters();

  // Filters
  const dates = {
    start: filters[FILTERS.startDate.paramKey] || null,
    end: filters[FILTERS.endDate.paramKey] || null,
  };

  const isDatesSelected = dates.start && dates.end;

  return (
    <div className="suite__cancellation-policy">
      <div className="suite__section">
        <SectionDivider className="my-4" />
        <SectionTitle
          icon={<CiCancellation size={22} />}
          title={t("cancellation_policy_title")}
        />
        <div className="section__content cancellation-policy__content">
          <div className="description"></div>
          {isDatesSelected && quotation ? (
            <Policy dates={dates} quotation={quotation} />
          ) : (
            <div className="select-dates">
              {t("cancellation_policy_add_dates")}
              <br />
              <button
                className="text-button"
                style={{ marginTop: "10px" }}
                onClick={handleOnPriceDatesClick}
              >
                {t("add_dates")} <CiChevronRight size={12} />
              </button>
              <br />
              <CancellationDescription />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CancellationDescription = () => {
  //Translation hook
  const { t } = useTranslation();

  return (
    <div className="cancellation-description">
      <div className="line">{t("cancellation_policy_100")}</div>
      <div className="line">{t("cancellation_policy_50")}</div>
      <div className="line">{t("cancellation_policy_0")}</div>
    </div>
  );
};

const Policy = ({ dates, quotation }) => {
  //Translation hook
  const { t } = useTranslation();
  let locale = "en";
  if (window.localStorage.getItem(i18n_TYPES.lookupLocalStorage) === "ar") {
    locale = "ar";
  }

  const data = quotation;

  if (!data) return <></>;

  const fourteenDays = dayjs(dates.start).locale(locale).subtract(14, "days");
  const isFourteenDaysAway = dayjs().isSameOrBefore(
    fourteenDays.format("YYYY-MM-DD"),
    "days"
  );

  const sevenDays = dayjs(dates.start).locale(locale).subtract(7, "days");
  const isSevenDaysAway = dayjs().isSameOrBefore(
    sevenDays.format("YYYY-MM-DD"),
    "days"
  );

  // const sixDays = dayjs(dates.start).subtract(6, "days");

  return (
    <div className="policy">
      <div className="policy__text">
        {t("cancellation_policy_dates_added", {
          dateFrom: dayjs(dates.start).locale(locale).format("DD MMMM YYYY"),
          dateTo: dayjs(dates.end).locale(locale).format("DD MMMM YYYY"),
        })}
        <CancellationDescription />
      </div>

      <div className="policy__refunds">
        <div className="refund__title" hidden={!isSevenDaysAway}>
          {t("cancel_by")}
        </div>
        {isFourteenDaysAway && (
          <div className="refund full-refund">
            <div className="refund__dot"></div>
            <div className="refund__date">
              {t("48_hours")} <span>{t("after_booking")}</span>
            </div>
            <div className="refund__amount">
              <div className="amount__price">
                {toMoney(
                  data.totalPriceIncludingVAT || data.totalPrice,
                  data.currency
                )}
              </div>
              <div className="amount__title">{t("100_refund")}</div>
            </div>
          </div>
        )}

        {isSevenDaysAway && (
          <>
            <div className="refund half-refund">
              <div className="refund__dot"></div>
              <div className="refund__date">
                {sevenDays.format("MMMM DD")}
                <span>{t("check_in_hour")}</span>
              </div>
              <div className="refund__amount">
                <div className="amount__price">
                  {toMoney(
                    (data.totalPriceIncludingVAT || data.totalPrice) / 2,
                    data.currency
                  )}
                </div>
                <div className="amount__title">{t("50_refund")}</div>
              </div>
            </div>

            <div className="refund no-refunds">
              <div className="refund__dot"></div>
              <div className="refund__date">
                {dayjs(dates.start).locale(locale).format("MMMM DD")}{" "}
                <span>{t("check_in_hour")}</span>
              </div>

              <div className="refund__amount">{t("0_refund")}</div>
            </div>
          </>
        )}

        {!isSevenDaysAway && (
          <>
            <div className="refund full-refund">
              <div className="refund__dot"></div>
              <div className="refund__date">
                {t("non_refundable")} <span>{t("after_booking")}</span>
              </div>
            </div>

            <div className="refund no-refunds">
              <div className="refund__dot"></div>
              <div className="refund__amount"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CancellationPolicy;
