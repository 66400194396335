import * as ROUTES from "../../../Constants/Routes";
import {
  MENU_TITLES,
  AUTH_TITLES,
  LEGAL_TITLES,
} from "../../../Constants/globals";
import { MODALS } from "../../../Constants/Modals";
import {
  GUEST_APP_LINKS,
  SOCIAL_MEDIA_LINKS,
} from "../../../Constants/globals";

import { CiLogin, CiProfile, CiSignOut } from "../../../CustomIcons";

export const ABOUT_ITEMS = [
  {
    title: MENU_TITLES.WHO_WE_ARE,
    link: ROUTES.ABOUT_US,
  },
  {
    title: MENU_TITLES.CAREERS,
    link: ROUTES.CAREERS,
  },
  {
    title: MENU_TITLES.PRESS,
    link: ROUTES.PRESS
  }
];

export const ABOUT_ITEMS_MOBILE = [
  {
    title: MENU_TITLES.WHO_WE_ARE,
    link: ROUTES.ABOUT_US,
  },
  {
    title: MENU_TITLES.PRESS,
    link: ROUTES.PRESS,
  },
  {
    title: MENU_TITLES.CAREERS,
    link: ROUTES.CAREERS,
  },
  {
    title: MENU_TITLES.CONTACT_US,
    link: ROUTES.CONTACT_US,
  },
  {
    title: MENU_TITLES.FOR_REAL_ESTATE_PARTNERS,
    link: ROUTES.FOR_REAL_ESTATE_PARTNERS,
  },
];

export const CORPORATE_ITEMS = [
  {
    title: MENU_TITLES.CORPORATE,
    link: ROUTES.CORPORATE,
  },
];

export const GUEST_ITEMS = [
  {
    title: AUTH_TITLES.LOG_IN,
    link: ROUTES.LOGIN,
    modal: MODALS.LOGIN,
    icon: CiLogin,
  },
  {
    title: AUTH_TITLES.SIGN_UP,
    link: ROUTES.SIGN_UP,
    modal: MODALS.SIGN_UP,
    icon: CiProfile,
  },
];

export const USER_ITEMS = [
  {
    title: "edit_profile",
    link: ROUTES.EDIT_PROFILE,
    icon: CiProfile,
  },
  {
    title: AUTH_TITLES.LOG_OUT,
    functionKey: "logout",
    onClick: (f) => f(),
    icon: CiSignOut,
  },
];

export const DOWNLOAD_ITEMS = [
  {
    title: "ios",
    external: true,
    link: GUEST_APP_LINKS.APP_STORE,
  },
  {
    title: "android",
    external: true,
    link: GUEST_APP_LINKS.PLAY_STORE,
  },
];

export const LEGAL_ITEMS = [
  {
    title: LEGAL_TITLES.PRIVACY_POLICY,
    link: ROUTES.PRIVACY_POLICY,
  },
  {
    title: LEGAL_TITLES.TERMS_AND_CONDITIONS,
    link: ROUTES.TERMS_AND_CONDITIONS,
  },
];

export const SOCIAL_MEDIA_ITEMS = SOCIAL_MEDIA_LINKS.map((item) => {
  return { ...item, external: true };
});
