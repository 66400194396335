import { useState, useEffect } from "react";
import getMapBounds from "./../../../../Utils/GoogleMap/getMapBounds";
import Supercluster from "supercluster";

const supercluster = new Supercluster({
  radius: 30,
  extent: 512,
  maxZoom: 23,
});

const useClusters = (map, markers) => {
  const [clusters, setClusters] = useState([]);

  useEffect(() => {
    if (!map || !markers) return;

    const GeoJSONMarkers = markers.map((marker) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [marker.location.lng, marker.location.lat],
      },
      properties: {
        cluster: false,
        markerId: marker.id,
      },
      marker: marker,
    }));

    // Also listen on idle
    const boundsChangedListener = map.addListener("idle", () => {
      const zoom = map.getZoom();
      const bounds = getMapBounds(map);
      if (!bounds) return;

      supercluster.load(GeoJSONMarkers);
      const clusters = supercluster.getClusters(bounds, zoom);
      setClusters(clusters);
    });

    supercluster.load(GeoJSONMarkers);
    const zoom = map.getZoom();
    const bounds = getMapBounds(map);
    if (!bounds) return;

    const clusters = supercluster.getClusters(bounds, zoom);
    setClusters(clusters);

    return () => {
      boundsChangedListener.remove();
    };
  }, [map, markers]);

  return { clusters: clusters, supercluster: supercluster };
};

export default useClusters;
