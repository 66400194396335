import { useEffect, useState } from "react";

// Hooks
import useUnavailableDates from "./useUnavailableDates";
import useLosRange from "./useLosRange";
import logGaEvent from "lib/ga/logGaEvent";

// Functions
import getLosAvailabilityDatesConfig from "../../functions/getLosAvailabilityDatesConfig.js";

import sortLosDates from "../../functions/sortLosDates.js";
import sortUnavailableRanges from "../../functions/sortUnavailableRanges.js";
import getLosAvailabilityDatesConfigFromWorker from "../../functions/getLosAvailabilityDatesConfigFromWorker.js";

const logGaEventWorkerTime = (time) => {
  if (!time) return;
  let calcRange = "";
  const timeCeil = Math.ceil(time?.seconds);
  if (timeCeil <= 5) {
    calcRange = "0-5";
  } else if (timeCeil > 5 && timeCeil <= 10) {
    calcRange = "5-10";
  } else {
    calcRange = ">10";
  }
  const makeGAObj = {
    timeInSeconds: time.seconds,
    calcRange: calcRange,
  };
  logGaEvent(`calendar_time`, makeGAObj);
};

const useDatesData = (id) => {
  // Dates data
  const {
    unavailableDatesData,
    unavailableDatesLoading,
    unavailableDatesError,
  } = useUnavailableDates(id);

  // Los data
  const { losRangeData, losRangeLoading, losRangeError } = useLosRange(id);

  const [daysData, setDaysData] = useState({
    unavailableDates: [],
    availabilityOfDates: [],
    unavailableDatesData: [],
    losDatesData: [],
    availableDatesConfig: [],
    workerIsWorking: true,
  });

  useEffect(() => {
    if (!unavailableDatesData || !losRangeData) return;
    if (losRangeData?.result?.length === 0) {
      setDaysData({
        ...daysData,
        workerIsWorking: false,
      });
      return;
    }

    /*-------------------------Step1 >>>>>>>
    * Sort LOS dates and Unavailable ranges
    * Other functions depend on sorted Data
    * Unsorted data will result in unpredictable results
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    const sortedLosDates = sortLosDates(losRangeData);
    const sortedUnavailableRanges = sortUnavailableRanges(
      unavailableDatesData,
      sortedLosDates
    );

    /*-------------------------Step2 >>>>>>>
    * Send sorted data to worker function to calculate available dates config
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    let availableDatesConfig = [];
    getLosAvailabilityDatesConfigFromWorker(
      sortedLosDates,
      sortedUnavailableRanges
    )
      .then(({ datesConfig, time }) => {
        setDaysData({
          ...daysData,
          availableDatesConfig: datesConfig,
          workerIsWorking: false,
        });
        logGaEventWorkerTime(time);
      })
      .catch(() => {
        availableDatesConfig = getLosAvailabilityDatesConfig(
          sortedLosDates,
          sortedUnavailableRanges
        );
        setDaysData({
          ...daysData,
          availableDatesConfig: availableDatesConfig,
          workerIsWorking: false,
        });
      });

    /*-------------------------Step3 >>>>>>>
    * Set data in hook
    ---------------------------------------------------------<<<<<<<<<<<<<<*/
    setDaysData({
      sortedLosDates: sortedLosDates,
      sortedUnavailableRange: sortedUnavailableRanges,
      availableDatesConfig: availableDatesConfig,
      workerIsWorking: true,
    });
  }, [unavailableDatesData, losRangeData]);

  return {
    daysData,
    daysLoading: unavailableDatesLoading || losRangeLoading,
    daysError: unavailableDatesError || losRangeError,
  };
};

export default useDatesData;
