// Import necessary dependencies
import SearchFilterButton from "Components/Buttons/SearchFilter";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * IncrementalInput component allows users to increment or decrement a numeric value.
 * It displays the current count and provides increase and decrease buttons.
 */
const IncrementalInput = ({
  update,
  initialValue,
  maxCount,
  className,
  showMaxPlus,
  startEmpty,
  tabIndex,
  updateCount,
  label,
  pluralLabel,
  singularLabel,
  startEmptyLabel,
  ...props
}) => {
  // Translation hook
  const { t } = useTranslation();

  // Parse initial value and ensure it's a number
  const parsedInitialValue = parseInt(initialValue, 10) || 1;

  // Initialize count state
  const [count, setCount] = useState(!startEmpty ? parsedInitialValue : 0);

  // Update count when updateCount prop changes
  useEffect(() => {
    if (typeof updateCount === "number") setCount(updateCount);
  }, [updateCount]);

  /**
   * Handle count change based on the action type.
   * @param {string} type - Action type ('increase' or 'decrease')
   */
  const handleCountChange = (type) => {
    let newCount = count;

    if (type === "increase") {
      newCount = Math.min(count + 1, maxCount);
    } else if (type === "decrease") {
      newCount = Math.max(count - 1, startEmpty ? 0 : 1);
    }

    setCount(newCount);
    update(newCount, type);
  };

  return (
    <SearchFilterButton
      label={label}
      className={className}
      tabIndex={tabIndex}
      {...props}
    >
      <div className="guests__holder">
        {/* Display current count and label */}
        <div
          className="guests__button-label"
          data-empty={count === 0}
          aria-live="polite"
        >
          {startEmpty && count === 0 ? (
            // Display 'Add Guests' label when count is zero
            t(startEmptyLabel)
          ) : (
            <>
              {count}
              {/* Display '+' sign if showMaxPlus is true and count is at max */}
              {showMaxPlus && count === maxCount ? "+" : ""}
              <span className="guests__label-text">
                {/* Display singular or plural label based on count */}{" "}
                {count > 1 ? t(pluralLabel) : t(singularLabel)}
              </span>
            </>
          )}
        </div>

        {/* Increase and decrease buttons */}
        <div className="guests__buttons">
          <button
            onClick={(e) => handleCountChange("decrease")}
            disabled={count <= (startEmpty ? 0 : 1)}
            aria-label={t("decrease")}
            className="remove-button-background"
            type="button"
          >
            <span>-</span>
          </button>
          <button
            onClick={(e) => handleCountChange("increase")}
            disabled={count >= maxCount}
            aria-label={t("increase")}
            className="remove-button-background"
            type="button"
          >
            <span>+</span>
          </button>
        </div>
      </div>
    </SearchFilterButton>
  );
};

// PropTypes for component documentation and type checking
IncrementalInput.propTypes = {
  /** Function to update the parent component when count changes */
  update: PropTypes.func,
  /** Initial value of the count */
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Maximum allowed count */
  maxCount: PropTypes.number,
  /** Additional CSS class names */
  className: PropTypes.string,
  /** Show a plus sign when the count reaches maxCount */
  showMaxPlus: PropTypes.bool,
  /** Start with an empty count (zero) */
  startEmpty: PropTypes.bool,
  /** Tab index for accessibility */
  tabIndex: PropTypes.number,
  /** External count to update the internal state */
  updateCount: PropTypes.number,
  /** Label for the button */
  label: PropTypes.string,
  /** Label for plural count */
  pluralLabel: PropTypes.string,
  /** Label for singular count */
  singularLabel: PropTypes.string,
  /** Label when starting with an empty count */
  startEmptyLabel: PropTypes.string,
};

// Default props for the component
IncrementalInput.defaultProps = {
  update: () => {},
  initialValue: 1,
  maxCount: 10,
  className: "",
  showMaxPlus: false,
  startEmpty: false,
  tabIndex: 1,
  label: "guests",
  pluralLabel: "guests",
  singularLabel: "guest",
  startEmptyLabel: "add_guests",
};

export default IncrementalInput;
