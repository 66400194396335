import React, { useRef, useState } from "react";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
// Translation Package
import { useTranslation } from "react-i18next";

const Policies = (props) => {
  // Translation hook
  const { t } = useTranslation();

  const { data } = props;

  const policies = [
    {
      key: "cancellationPolicy",
      title: t("cancellation_policy_title"),
      data: data.property.cancellationPolicy,
    },
    {
      key: "houseRules",
      title: t("house_rules"),
      data: data.property.houseRules,
    },
  ];

  const [selectedPolicy, setSelectedPolicy] = useState();

  return (
    <div className="shadow-card p-0 policies" id="policies">
      {policies
        .filter((policy) => policy.data)
        .map((policy) => {
          const onPolicyClick = () => {
            setSelectedPolicy((selected) =>
              selected !== policy.key ? policy.key : null
            );
          };

          const isSelected = policy.key === selectedPolicy;
          return (
            <Policy
              key={policy.key}
              isSelected={isSelected}
              onPolicyClick={onPolicyClick}
              policy={policy}
            />
          );
        })}
    </div>
  );
};

const Policy = (props) => {
  const { policy, isSelected, onPolicyClick } = props;
  const policyClass = isSelected ? "selected" : "";
  const policyRef = useRef();

  const policyHeight = policyRef?.current?.scrollHeight || 0;
  const policyStyle = { height: isSelected ? `${policyHeight}px` : "" };

  return (
    <div className={`policy ${policyClass}`} key={policy.key}>
      <button className="policy__title" onClick={onPolicyClick}>
        {policy.title}
        <span>
          {isSelected ? (
            <BsChevronUp className="chevron" />
          ) : (
            <BsChevronDown className="chevron" />
          )}
        </span>
      </button>
      <div className="policy__content-holder" style={policyStyle}>
        <div className="policy__content" ref={policyRef}>
          {policy.data}
        </div>
      </div>
    </div>
  );
};

export default Policies;
