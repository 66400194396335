import React, { useState } from "react";

// Icons
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

// Components
import Divider from "./Divider";
import { ERROR_MESSAGES } from "../constants";

// Providers
import {
  useUpdateModal,
  MODALS,
} from "../../../Providers/FloatingModalProvider";
import redirectAfterLoginSuccess from "Components/Auth/functions/redirectAfterLoginSuccess.js";

// Translation Package
import { useTranslation } from "react-i18next";
import useSocialMethodsSignIn from "../hooks/useSocialMethodsSignIn";
import { PROVIDERS } from "../functions/getProviderId";

const SocialMethods = (componentProps) => {
  // Translation hook
  const { t } = useTranslation();

  const { type, redirectTo, urlParams, socialMethodColSpan="12", isSubscribedToNewsLetter=false, isInlineSignup=false } = componentProps;

  const updateModal = useUpdateModal();
  const signIn = useSocialMethodsSignIn();

  const [error, setError] = useState(null);

  const onClick = async (provider) => {

    await signIn(provider, isSubscribedToNewsLetter)
      .then((jwtToken) => {
        // Show birthdate modal if there is no token
        if (!jwtToken) {
          updateModal({
            modal: MODALS.VERIFY_BIRTHDATE,
            props: { redirectTo, urlParams },
          });
          return;
        }

        // Redirect
        if(!isInlineSignup) {
          redirectAfterLoginSuccess(redirectTo, urlParams);
        }
      })
      .catch((error) => {
        console.log(error);

        const errorType = typeof error;
        if (errorType === "string") {
          setError(t(error));
          return;
        }

        if (error?.code) {
          if (error.code === "auth/cancelled-popup-request") return;
          if (error.code === "auth/popup-closed-by-user") return;
        }

        setError(t(error?.message) || t(ERROR_MESSAGES.unknown));
      });
  };

  const label =
    type === "signup" ? `${t("continue_with")} ` : `${t("login_with")} `;

  return (
    <>
      <Divider />
      <div className="row auth-form__social-methods">
        {error && <div className="col-12 auth-form__error">{t(error)}</div>}

        {/* Google */}
        <div className={`col-${socialMethodColSpan} social-methods__method google`}>
          <button type="button" onClick={() => onClick(PROVIDERS.GOOGLE)}>
            <span>
              {label} {t("google")}
            </span>
            <span>
              <FcGoogle className="icon" />
            </span>
          </button>
        </div>

        {/* Apple */}
        <div className={`col-${socialMethodColSpan} social-methods__method apple`}>
          <button type="button" onClick={() => onClick(PROVIDERS.APPLE)}>
            <span>
              {label} {t("apple")}
            </span>
            <span>
              <AiFillApple className="icon" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default SocialMethods;
