import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

// Packages
import dayjs from "dayjs";

// Utils
import fixListingName from "../../../Utils/functions/fixListingName";

// Misc
import { BsArrowRight } from "react-icons/bs";

// import Loading from "Components/Utils/Loading";

// Translation Package
import { useTranslation } from "react-i18next";
import toMoney from "./../../../Utils/functions/toMoney";

import useMobileDetect from "Components/Utils/hooks/useMobileDetect";
import SDCard from "./SDCard";
import InvoiceDetailsCard from "./InvoiceDetailsCard";
import ChevronTOPIcon from "../Icons/chevron-top.svg";
import "dayjs/locale/ar";
import "./index.scss";

/**
 * SummaryCard Component
 * Displays a summary of the booking including property details,
 * stay dates, guest information, and total cost.
 *
 * @param {boolean} props.initialOpenForMobile - Determines if the card is open on mobile view initially
 * @param {Object} props.property - Data about the property
 * @param {Object} props.payment - Quotation and pricing data
 * @param {string} props.startDate - Start date of the stay
 * @param {string} props.endDate - End date of the stay
 * @param {number} props.guests - Number of guests
 * @param {string} props.reservationInvoiceDescription - Reservation invoice description
 * @param {string} props.confirmationCode - Booking confirmation code
 * @param {string} props.guestName - Name of the guest
 * @param {string} props.channel - Booking channel (e.g., Booking.com)
 * @param {boolean} props.isCurrentStepPayment - If the current step is payment step
 */

export default function SummaryCard({
  initialOpenForMobile,
  property,
  payment,
  startDate,
  endDate,
  guests,
  reservationInvoiceDescription,
  confirmationCode,
  guestName,
  channel,
  isCurrentStepPayment,
}) {
  // Translation hook
  const { t, i18n } = useTranslation();

  // State to manage the open/closed state of the card
  const [isOpen, setIsOpen] = useState(initialOpenForMobile);

  // Hook to detect if the device is mobile
  const isMobile = useMobileDetect();

  function handleCardHeaderClick(e) {
    setIsOpen((prevState) => !prevState);
  }

  const shouldToggleCard = useMemo(() => {
    // mobile screen check the isOpen value to toggle the card. else (large screens) show the card always.
    return isMobile ? isOpen : true;
  }, [isOpen, isMobile]);

  const shouldInvoiceDetailsCard = useMemo(() => {
    return (
      shouldToggleCard &&
      // show if reservation description was provided and reservationTotal more than zero
      reservationInvoiceDescription?.length > 0 &&
      payment?.reservationTotal &&
      payment?.reservationTotal > 0 &&
      isCurrentStepPayment
    );
  }, [
    shouldToggleCard,
    reservationInvoiceDescription?.length,
    payment?.reservationTotal,
    isCurrentStepPayment,
  ]);

  const shouldShowSDCard = useMemo(() => {
    return (
      shouldToggleCard &&
      // show if securityDepositTotal more than zero
      payment?.securityDepositTotal &&
      payment?.securityDepositTotal > 0 &&
      isCurrentStepPayment
    );
  }, [payment, shouldToggleCard, isCurrentStepPayment]);

  return (
    <>
      {/* Summary Card  */}
      <section className={"summary-card  summary-card__container mx-auto "}>
        <section
          className="summary-card__header position-relative"
          onClick={handleCardHeaderClick}
          data-toggle={shouldToggleCard}
        >
          <img
            src={property?.mainImageUrl}
            alt={property?.listingName}
            className="summary-card__header__image"
          />

          <hgroup className="">
            <h3 className="summary-card__header__title">
              {fixListingName(property?.listingName)}
            </h3>
            <h5 className="summary-card__header__subtitle">
              {fixListingName(property?.address)}
            </h5>
          </hgroup>

          {/* Chevron Icon */}
          <img
            src={ChevronTOPIcon}
            alt={"chevron-top"}
            className="summary-card__header__chevron-icon"
            data-toggle={shouldToggleCard}
          />
        </section>

        <div
          className="summary-card__separator"
          data-toggle={shouldToggleCard}
        />

        {/* Summary Card Body */}
        <section
          className="row  summary-card__body "
          data-toggle={shouldToggleCard}
        >
          <b className="col-12 mb-3 font-semibold">{t("your_stay")}</b>
          <div className="col-7">
            <dl>
              <dt className="mb-2">{t("confirmation_code")}</dt>
              <dd className="mb-3">{confirmationCode}</dd>
              <dt className="mb-2">{t("guest_name")}</dt>
              <dd className="mb-3">{guestName}</dd>
              <dt className="mb-2">{t("stay_dates")}</dt>
              <dd className="d-flex align-items-center gap-2">
                {dayjs(startDate ?? "")
                  .locale(i18n?.languages[0])
                  .format("DD MMM YYYY")}{" "}
                <BsArrowRight className="summary-card__body__arrow-right" />
                {dayjs(endDate ?? "")
                  .locale(i18n?.languages[0])
                  .format("DD MMM YYYY")}
              </dd>
            </dl>
          </div>

          <div className="col-5">
            <dl>
              <dt className="mb-2">{t("channel")}</dt>
              <dd className="mb-3">{channel}</dd>
              <dt className="mb-2">{t("guests")}</dt>
              <dd className="">
                {guests} {guests > 1 ? t(`guests_counter`) : t("guest")}
              </dd>
            </dl>
          </div>
        </section>
      </section>

      {/* Invoice Details */}
      <InvoiceDetailsCard
        description={reservationInvoiceDescription}
        show={shouldInvoiceDetailsCard}
        amount={toMoney(payment?.reservationTotal, payment?.currency)}
      />

      {/* Security Deposit Details */}
      <SDCard
        amount={toMoney(payment?.securityDepositTotal, payment?.currency)}
        show={shouldShowSDCard}
      />
    </>
  );
}

// Define PropTypes
SummaryCard.propTypes = {
  initialOpenForMobile: PropTypes.bool,
  property: PropTypes.shape({
    mainImageUrl: PropTypes.string,
    listingName: PropTypes.string,
    address: PropTypes.string,
  }),
  payment: PropTypes.shape({
    grandTotal: PropTypes.number,
    reservationTotal: PropTypes.number,
    securityDepositTotal: PropTypes.number,
    currency: PropTypes.string,
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  guests: PropTypes.number,
  confirmationCode: PropTypes.string,
  guestName: PropTypes.string,
  channel: PropTypes.string,
  isCurrentStepPayment: PropTypes.bool,
};

// Define default props
SummaryCard.defaultProps = {
  initialOpenForMobile: false,
  property: {},
  payment: null,
  startDate: "",
  endDate: "",
  guests: 1,
  confirmationCode: "",
  guestName: "",
  channel: "",
  isCurrentStepPayment: false,
};
