import QUERIES from "../../../../../Constants/Queries";
import { useQuery } from "@apollo/client";

const useLosRange = (id) => {
  const { data, loading, error } = useQuery(QUERIES.PROPERTIES.GET_LOS_RANGE, {
    variables: { propertyID: id },
  });

  return {
    losRangeData: data,
    losRangeLoading: loading,
    losRangeError: error,
  };
};

export default useLosRange;
