import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
// Translation Package
import useMobileDetect from "Components/Utils/hooks/useMobileDetect";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import { Portal } from "react-portal";

// BuildingHero showcase component
const VideoBlock = ({ bgImage, videoThumb, videoSrc, isLeft }) => {
  const isMobile = useMobileDetect();
  const divRef = useRef(null);
  const vidRef = useRef(null);

  const [showVideo, setShowVideo] = useState(false);
  const [screenOffset, setScreenOffset] = useState(0);

  const toggleVideo = () => {
    setShowVideo(!showVideo);

    if (!screenOffset) {
      setScreenOffset(window.pageYOffset);
    }
  };

  const requestVideoFullScreen = () => {
    let el = vidRef.current;

    setTimeout(() => {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitEnterFullScreen) {
        el.webkitEnterFullScreen();
      }
    }, 100);
  };

  const checkIfVideoIsFullScreen = () => {
    let isFSMode = false;
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Safari and Opera syntax */ ||
      document.msFullscreenElement /* IE11 syntax */
    ) {
      isFSMode = true;
    }
    return isFSMode;
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", (event) => {
      if (!checkIfVideoIsFullScreen()) {
        window.scrollTo({
          top: screenOffset,
          behavior: "instant",
        });
      }
    });

    if (showVideo && isMobile) {
      requestVideoFullScreen();
    }

    // 👇️ remove the event listener when component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", () => {});
    };
  }, [showVideo]);

  return (
    <div
      className={`w-100 d-inline-flex flex-row align-items-center ${
        styles.videoBlock
      } ${
        isLeft
          ? `${styles.isLeft} justify-content-center justify-content-lg-end`
          : "justify-content-center justify-content-lg-start"
      }`}
      ref={divRef}
    >
      {/* Bg image */}
      <img
        src={bgImage}
        alt="Live"
        loading="lazy"
        className={`position-absolute h-100 ${styles.bg}`}
      />

      {/* Video */}
      <div
        className={`position-relative d-inline-flex flex-row justify-content-center align-items-center ${styles.video}`}
        role="button"
        onClick={toggleVideo}
      >
        <img
          src={videoThumb}
          alt="Live"
          loading="lazy"
          className={`rounded ${styles.thumb}`}
          width={300}
          height={300}
        />
        <AiFillPlayCircle
          className={`position-absolute d-inline-flex ${styles.play}`}
          size={48}
          color="white"
        />
      </div>

      {showVideo && (
        <Portal>
          <div
            className={`floating-modal ${
              showVideo ? "show" : ""
            } show d-flex flex-row justify-content-center align-items-center ${
              styles.mainModal
            }`}
          >
            <div className="bg" onClick={toggleVideo} />
            <div
              className={`container auth-floating-container ${styles.videoModal}`}
            >
              <button
                className={`close-button ${styles.closeButton}`}
                onClick={toggleVideo}
              >
                <BsX className="close-icon" />
              </button>
              <video
                loop
                autoPlay
                controls
                playsInline
                id="full-screenVideo"
                ref={vidRef}
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default VideoBlock;
