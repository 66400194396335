import React from "react";
import { Link } from "react-router-dom";

const NotFoundCard = ({ children }) => {
  return (
    <div className="notfound">
      <div className="notfound__card">
        {children}
        <div className="notfound__link">
          <Link className="btn btn-primary" to="/contact-us" target="_blank">
            Please Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundCard;
