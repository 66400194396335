import { useQuery } from "@apollo/client";
import Error from "Components/Utils/Error";
import LoadingLogoFullScreen from "Components/Utils/LoadingLogoFullScreen";
import QUERIES from "Constants/Queries";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../Providers/UserProvider";
import CookieConsentProvider from "../../../Providers/CookieConsentProvider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTES from "../../../Constants/Routes";
import WhatsAppIcon from "../WhatsAppFloatingIcon";

const PageTranslationHOC = ({ children, pageName }) => {
  // Translation Hook
  const { i18n } = useTranslation();
  const { preferLang, loading } = useUser();
  const location = useLocation();

  const currentRoutePath = location.pathname.split('/')[1];  
  const showWhatsAppFloatingIcon = [
    ROUTES.SEARCH,
    ROUTES.BOOKING_PAYMENT,
    ROUTES.BOOKING_PAYMENT_MOBILE_APP,
    ROUTES.SUITE,
  ].includes(`/${currentRoutePath}`);

  // Function to remap Api response to key: value pairs for i18n package
  const remapApiLocalizationToi18n = (resArray) => {
    return resArray.reduce((acc, tr) => {
      return { ...acc, [tr.lookupKey]: tr.localization };
    }, {});
  };

  // Fetch Page specific Translations
  const {
    data: translations,
    loading: translationLoading,
    error,
  } = useQuery(QUERIES.FETCH_PAGE_LOCALIZATION, {
    variables: {
      language: i18n.languages[0],
      page: pageName ? pageName : null,
    },
  });

  useEffect(() => {
    /* {Deprecated} > We want to give priority to locally-cached user language; useful in localised campaign URLs
     * reload page when preferred language is different so that Apollo link "language" header
     * and google maps param can be updated
     */
    if (preferLang && (i18n.language === "en-GQL")) {
      i18n.changeLanguage(preferLang.language);
    }
  }, [i18n, preferLang]);

  if (translations) {
    i18n.addResourceBundle(
      i18n.languages[0],
      "translation",
      remapApiLocalizationToi18n(translations.result)
    );
  }

  if (error) return <Error />;
  if (translationLoading || loading) return <LoadingLogoFullScreen />;

  return (
    <>
      {children}
      <CookieConsentProvider />
      {showWhatsAppFloatingIcon ? <WhatsAppIcon /> : null}
    </>
  );
};

export default PageTranslationHOC;
