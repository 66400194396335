export const DEFAULT_ERROR = {
  error: null,
  message: null,
};

const ERROR_MESSAGES = {
  "auth/user-not-found": "error_failed_email_auth",
  "auth/wrong-password": "error_failed_email_auth",
  "auth/too-many-requests": "error_account_disabled",
  DEFAULT: "error_unknown",
};

export const handleLoginError = (error, setErrorState) => {
  if (Object.keys(ERROR_MESSAGES).includes(error?.code)) {
    setErrorState({
      error: error.code,
      message: ERROR_MESSAGES[error.code],
    });
    return;
  }

  setErrorState({
    error: "UNKNOWN_ERROR",
    message: ERROR_MESSAGES.DEFAULT,
  });
};
