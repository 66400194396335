import React from "react";

import Masonry from "react-masonry-css";

import SectionTitle from "../SectionTitle";
import SectionDivider from "../SectionDivider";

import SvgItem from "../../../../CustomIcons/SvgItem";
import decodeSVG from "../../../../CustomIcons/decodeSVG";

// Translation Package
import { useTranslation } from "react-i18next";

const breakpointColumnsObj = {
  default: 2,
  768: 1,
};

const HouseRules = ({ suite }) => {
  //Translation hook
  const { t } = useTranslation();

  const houseRules = suite.thingsToNote;

  if (!houseRules) return <></>;

  return (
    <div className="suite__house-rules" id="house-rules">
      <div className="suite__section">
        <SectionDivider className="my-4" />
        <SectionTitle title={t("house_rules")} />
        <div className="section__content house-rules__content">
          <div className="content__items">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonry-grid"
              columnClassName="masonry-column"
            >
              {houseRules.map((item, index) => {
                return <HouseRulesItem item={item} key={index} />;
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
};

const getTitle = (title) => {
  let newTitle = title.split("(");
  if (newTitle[0] === title) return newTitle;

  return newTitle
    .filter((t) => t)
    .map((title) => title.replace(")", "") + "\n");
};

const HouseRulesItem = ({ item }) => {
  const svg = decodeSVG(item.icon);

  const title = getTitle(item.title);

  return (
    <div className="house-rules-item">
      <div className="house-rules-item__content">
        <div className="house-rules-item__title-holder">
          <div className="house-rules-item__icon">
            <SvgItem className="icon" i={svg} size={22} />
          </div>
          <div className="house-rules-item__title">
            {title.map((title) => title)}
          </div>
        </div>
        <div className="house-rules-item__text">{item.content}</div>
      </div>
    </div>
  );
};
export default HouseRules;
