import zoomAt from "./zoomAt";
import { CLUSTER_ZOOM_THRESHOLD } from "../constants";

const handleClusterOnClick = (map, supercluster, cluster, lng, lat) => {
  if (!map) return;
  if (cluster.id === null) return;

  const pt = { lng, lat };
  const zoom = supercluster.getClusterExpansionZoom(cluster.id);
  if (zoom >= CLUSTER_ZOOM_THRESHOLD) return CLUSTER_ZOOM_THRESHOLD;

  const zoomTo = zoomAt(map, pt, zoom);
  return zoomTo;
};

export default handleClusterOnClick;
