import React from "react";

import { CORPORATE_NUMBER, CORPORATE_EMAIL } from "Constants/globals";
import { CiQuestionBubble } from "CustomIcons";
import logGaEvent from "lib/ga/logGaEvent";
import styles from "./index.module.scss";

// Translation Package
import { Trans } from "react-i18next";

const ContactBoxSmall = ({ suite }) => {
  const handleGaEvent = (method) => {
    logGaEvent(`contact_us_long_term`, {
      method: method,
    });
  };
  const textBody = encodeURIComponent(
    `Hello! I have a question about ${suite?.listingName}\n(Reference: ${suite?.internalName}).`
  );

  return (
    <div
      className={[
        styles.question,
        "d-flex flex-column justify-content-center align-items-center",
      ].join(" ")}
    >
      <div className={styles.section}>
        <div className={[styles.title, "d-flex flex-row gap-2"].join(" ")}>
          <CiQuestionBubble size={24} className={styles.icon} />

          <div className="d-flex flex-row flex-wrap gap-1 w-100">
            <Trans
              i18nKey="reach_out_notice_long_term"
              values={{
                whatsapp: CORPORATE_NUMBER.HUMAN,
                email: CORPORATE_EMAIL,
              }}
            >
              Looking for long term bookings? Reach out to{" "}
              <a
                href={`https://wa.me/${CORPORATE_NUMBER.EMBED.replace(
                  "+",
                  ""
                ).replace(/\s/g, "")}?text=${textBody}`}
                target="_blank"
                rel="noopener noreferrer"
                className="d-inline-flex"
                onClick={() => handleGaEvent("whatsapp")}
                dir="ltr"
              >
                {" "}
              </a>{" "}
              or email us on{" "}
              <a
                href={`mailto:${CORPORATE_EMAIL}?subject=Inquiry&body=${textBody}`}
                target="_blank"
                rel="noopener noreferrer"
                className="d-inline-flex"
                onClick={() => handleGaEvent("email")}
              >
                {" "}
              </a>{" "}
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactBoxSmall;
