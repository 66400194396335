import React from 'react';

const ShimmerLoading = () => {
    return (
        <div className="suite--loading skeleton-loader-holder">
            <div className="container container-max-xl">
                <div className="loading-bottom-section">
                    <div className="loading-box loading-box__book-modal skeleton-loader" />
                </div>
            </div>
        </div>
    );
};

export default ShimmerLoading;