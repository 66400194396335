import { useEffect } from "react";

let DEFAULT_CALLBACKS = {
  leftArrow: () => {},
  upArrow: () => {},
  rightArrow: () => {},
  downArrow: () => {},
};
const useEscapeButton = (
  callbackList = {
    ...DEFAULT_CALLBACKS,
  }
) => {
  useEffect(() => {
    const callbacks = {
      ...DEFAULT_CALLBACKS,
      ...callbackList,
    };
    const doCallback = (callback) => {
      if (typeof callback !== "function") return;
      callback();
    };

    const onLeftArrowPressed = (e) =>
      e.keyCode === 37 && doCallback(callbacks.leftArrow());
    const onUpArrowPressed = (e) =>
      e.keyCode === 38 && doCallback(callbacks.upArrow());
    const onRightArrowPressed = (e) =>
      e.keyCode === 39 && doCallback(callbacks.rightArrow());

    const onDownArrowPressed = (e) =>
      e.keyCode === 40 && doCallback(callbacks.downArrow());

    document.addEventListener("keydown", onLeftArrowPressed);
    document.addEventListener("keydown", onUpArrowPressed);
    document.addEventListener("keydown", onRightArrowPressed);
    document.addEventListener("keydown", onDownArrowPressed);
    return () => {
      document.removeEventListener("keydown", onLeftArrowPressed);
      document.removeEventListener("keydown", onUpArrowPressed);
      document.removeEventListener("keydown", onRightArrowPressed);
      document.removeEventListener("keydown", onDownArrowPressed);
    };
  }, [callbackList]);
};

export default useEscapeButton;
