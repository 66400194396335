import { parsePhoneNumber } from "libphonenumber-js";

const validatePhone = (value) => {
  let isValid = false;
  try {
    isValid = parsePhoneNumber(value).isValid();
  } catch (error) {}
  if (isValid) {
    return isValid;
  } else {
    return "error_phone_number_correct";
  }
};

export default validatePhone;
