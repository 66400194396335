import QUERIES from "../../../../../Constants/Queries";
import { useQuery } from "@apollo/client";

const useUnavailableDates = (id) => {
  const { data, loading, error } = useQuery(
    QUERIES.PROPERTIES.GET_UNAVAILABLE_DAYS,
    {
      variables: { propertyID: id },
    }
  );

  return {
    unavailableDatesData: data,
    unavailableDatesLoading: loading,
    unavailableDatesError: error,
  };
};

export default useUnavailableDates;
