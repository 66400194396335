import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styles from "./index.module.scss";

const Header = (props) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div className={styles.main} style={props.mainStyles}>
      <div className={`homepage__header ${styles.header}`} id="header-carousel">
        {/* Background Image */}
        <img
          src={props.cover}
          alt="banner"
          style={{
            ...props.style,
          }}
        />

        <div
          className={`container-fluid ${styles.text} d-flex justify-content-start align-items-center align-items-lg-center`}
          style={props.contentStyles}
        >
          {/* Text Block */}
          <div
            className={`my-1 ${styles.textBlock}`}
            style={{ position: "relative" }}
          >
            <div
              className={`${styles.textBg} ${
                props.alternateBG ? styles.alternateBG : ""
              }`}
            ></div>
            <h1 className="stella_h1">
              <Trans i18nKey={props.title} />
            </h1>
            <h2 className="stella_h4 px-1">{t(props.subtitle)}</h2>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
