import { gql } from "@apollo/client";

export const PRICE_QUOTATION_FIELDS = gql`
  fragment PriceQuotationFields on StellaStaysPriceQuotation {
    nights
    avgPrice
    cleaningPrice
    totalPriceExcludingCleaning
    totalPrice
    totalVAT
    totalPriceIncludingVAT
    isRangeAvailable
    violatesLOS
    minLOS
    dtcmFees
    currency
    creditCardFees
    startDate
    endDate
    channel
    markup
    isBankEligible
    isCryptoEligible
    isCreditCardEligible
    securityDepositAmount
    isFutureSD
    sdDueDate
    isSecurityDepositEligible
    waiverAmount
    losDiscount {
      label
      percentage
      discountedValue
    }
  }
`;
