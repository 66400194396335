import React, { useRef, useEffect } from "react";

const PrimaryButton = ({
  label,
  onClick,
  height,
  className,
  circular,
  children,
  ...buttonProps
}) => {
  const buttonRef = useRef(null);
  // throw your mousemove callback up here to "add" and "remove" later
  // might be worth a useCallback based on the containerRef as well!

  useEffect(() => {
    if (!buttonRef || !buttonRef.current) return;

    const mouseMoveEvent = (e) => {
      const { x } = buttonRef.current.getBoundingClientRect();
      const width = buttonRef.current.offsetWidth;

      const where = e.clientX - x;
      const calc = (100 * where) / width;
      const pos = `${-calc}% 50%`;

      buttonRef.current.style.backgroundPosition = pos;
    };
    const mouseLeaveEvent = (e) => {
      buttonRef.current.style.backgroundPosition = `0% 0%`;
      buttonRef.current.style.transition = `background-position 300ms ease`;
    };
    const mouseEnterEvent = (e) => {
      buttonRef.current.style.transition = `background-position 300ms ease`;
      setTimeout(() => {
        if (!buttonRef || !buttonRef.current) return;
        buttonRef.current.style.transition = ``;
      }, 300);
    };

    const button = buttonRef.current;
    button.addEventListener("mouseenter", mouseEnterEvent);
    button.addEventListener("mousemove", mouseMoveEvent);
    button.addEventListener("mouseleave", mouseLeaveEvent);
    return () => {
      button.removeEventListener("mouseenter", mouseEnterEvent);
      button.removeEventListener("mousemove", mouseMoveEvent);
      button.removeEventListener("mouseleave", mouseLeaveEvent);
    };
  }, [buttonRef]);

  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      ref={buttonRef}
      className={`primary-button ${className || ""} ${
        circular ? "primary-button--circular" : ""
      }`}
      onClick={onClick && handleClick}
      style={{
        height: height ? height : "",
      }}
      aria-label={label}
      {...buttonProps}
    >
      {children ? children : label ? label : "Submit"}
    </button>
  );
};

export default PrimaryButton;
