import { isPossiblePhoneNumber } from "libphonenumber-js";
import dayjs from "dayjs";

import { DATE_OF_BIRTH_FORMAT, AGE_LIMIT } from "../constants";

const validateSignupForm = (data, withBirthDate=true) => {
  const isValidPhoneNumber = isPossiblePhoneNumber(data.mobile);

  let doesDateExist = true
  let isDateOfBirthValidAge = true;
  let age;

  if(withBirthDate) {
    const format = DATE_OF_BIRTH_FORMAT.toUpperCase();
    const dob = dayjs(data.dateOfBirth, format, true);

    doesDateExist = dob.isValid();

    const today = dayjs();
    age = today.diff(dob, "year");

    isDateOfBirthValidAge = age >= AGE_LIMIT
  }
  const validations = {
    isValidPhoneNumber: isValidPhoneNumber,
    doesDateExist,
    isDateOfBirthValidAge,
  };

  const results = Object.values(validations).map((b) => b);

  return {
    ...validations,
    doesAllPass: results.includes(false) ? false : true,
  };
};

export default validateSignupForm;
