import React, { useState } from "react";

import { CONTACT_NUMBER, CONTACT_EMAIL } from "../../../../Constants/globals";
import { CiMail, CiQuestionBubble, CiWhatsapp } from "../../../../CustomIcons";
import SectionTitle from "../SectionTitle";

import ContactForm from "../../../Utils/ContactForm";

import PrimaryButton from "../../../Buttons/Primary";
import logGaEvent from "../../../../lib/ga/logGaEvent";

// Translation Package
import { useTranslation } from "react-i18next";

const QuestionBox = ({ suite, quotation, title = "reach_out_title" }) => {
  //Translation hook
  const { t } = useTranslation();

  const [contactShow, setContactShow] = useState(false);
  const toggleContact = () => setContactShow(!contactShow);

  const mail = {
    address: CONTACT_EMAIL,
    subject: `Inquiry`,
    body: encodeURI(`Hello, I have a question about ${suite?.listingName}.`),
  };
  const whatsapp = {
    number: CONTACT_NUMBER.EMBED.replace("+", ""),
    numberHuman: CONTACT_NUMBER.HUMAN,
    text: encodeURI(`Hello! I have a question about ${suite?.listingName}.`),
  };

  const handleGaEvent = (method) => {
    logGaEvent(`contact_us`, {
      method: method,
    });
  };

  return (
    <div
      className="suite__question-box mb-5"
      style={{ marginTop: "60px" }}
      id="question-box"
    >
      <div className="suite__section ">
        <SectionTitle icon={<CiQuestionBubble size={22} />} title={t(title)} />
        <div className="section__content question-box__content">
          <div className="short-text">{t("reach_out_notice_extended")}</div>
          <div className="buttons d-flex flex-wrap gap-2">
            <div className="buttons__contact">
              <PrimaryButton
                onClick={() => setContactShow(true)}
                label={t("contact_us_now")}
              />
            </div>

            <div className="d-flex py-3 gap-2">
              <div className="buttons__or m-0">{t("or")}</div>
              <div className="buttons__whatsapp">
                <a
                  href={`https://wa.me/${whatsapp.number}?text=${whatsapp.text}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleGaEvent("whatsapp")}
                  className="d-md-inline-flex flex-md-column flex-lg-row"
                  aria-label="Whatsapp"
                >
                  <CiWhatsapp className="icon" />{" "}
                  <span className="text-nowrap">{t("whatsapp_number")}</span>
                </a>
              </div>
              <div className="buttons__email">
                <a
                  href={`mailto:${mail.address}?subject=${mail.subject}&body=${mail.body}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleGaEvent("email")}
                  className="d-md-inline-flex flex-md-column flex-lg-row"
                  aria-label="Email"
                >
                  <CiMail className="icon" />
                  <span className="text-nowrap">{mail.address}</span>
                </a>
              </div>
            </div>
          </div>
          <div className="background">
            <CiQuestionBubble size={180} />
          </div>
        </div>
      </div>

      <ContactForm show={contactShow} toggleContact={toggleContact} />
    </div>
  );
};
export default QuestionBox;
