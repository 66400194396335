import React, { useState } from "react";

import { useForm } from "react-hook-form";
import FormInput from "../Form/FormInput";
import Select from "../Form/Select";
import { useMutation } from "@apollo/client";
import trimWhiteSpace from "../Utils/validate";
import QUERIES from "../../Constants/Queries";
import PrimaryButton from "../Buttons/Primary";
import { useUser } from "../../Providers/UserProvider";

// Translation Package
import { useTranslation } from "react-i18next";

export default function ContactForm({ setMessageSent, showAlert = true }) {
  // Translation hook
  const { t } = useTranslation();
  const user = useUser();
  const name = user.user
    ? user?.user?.firstName + " " + user?.user?.lastName
    : "";
  const email = user.user ? user?.user?.email : "";

  const REASON_OPTIONS = [
    t("reservations"),
    t("leasing_opportunities"),
    t("long_term_and_group_bookings"),
    t("press"),
    t("careers"),
    t("collaborations"),
    t("others"),
  ];

  const [state, setState] = useState(null);
  const [callMutation, { loading: mutationLoading }] = useMutation(
    QUERIES.SUBMIT_CONTACT_US,

    {
      onCompleted: () => setMessageSent(true),
    }
  );
  const { errors, handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    callMutation({
      variables: {
        payload: { ...data, reason: state ? state : REASON_OPTIONS[0] },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form h-100">
      <div className="row mt-3 form-row h-100">
        <div className="col-12 mb-2">
          <h4>{t("contact_us")}</h4>
        </div>
        <div className="col-12 col-md-4">
          <FormInput
            register={register({
              required: true,
              validate: trimWhiteSpace(),
            })}
            type="text"
            name="name"
            label={t("name")}
            defaultValue={name}
            error={errors.name}
          />
        </div>
        <div className="col-12 col-md-4">
          <FormInput
            register={register({ required: true, validate: trimWhiteSpace() })}
            type="email"
            name="email"
            label={t("email")}
            defaultValue={email}
            error={errors.email}
          />
        </div>
        <div className="col-12 col-md-4">
          <Select
            register={register({ required: true, validate: trimWhiteSpace() })}
            name="reason"
            label={t("reason")}
            error={errors.reason}
            options={REASON_OPTIONS}
            classSuffix="white-bg"
            reasons={true}
            onSelectChange={(e) => {
              setState(e.target.value);
            }}
          />
        </div>
        <div className="col-12">
          <FormInput
            register={register({ required: true, validate: trimWhiteSpace() })}
            name="message"
            label={t("message")}
            type="textarea"
            rows={5}
            error={errors.message}
          />
        </div>
        <div className="col-12">
          <PrimaryButton
            label={
              mutationLoading ? `${t("send_message")}...` : t("send_message")
            }
            disabled={mutationLoading}
          />
        </div>
      </div>
    </form>
  );
}
