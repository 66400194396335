import moment from "moment";
import { DATE_FORMAT } from "Constants/url_params";

const isFoundInUnAvailRange = (day, sortedUnavailableRanges) => {
  return sortedUnavailableRanges.some((unAvailDay) => {
    return day.isBetween(
      unAvailDay.startDate,
      unAvailDay.endDate,
      undefined,
      "[]"
    );
  });
};

const sortUnavailableRanges = (unavailableDatesData, sortedLosDates) => {
  if (!unavailableDatesData || !sortedLosDates || sortedLosDates.length === 0) {
    return [];
  }

  let sortedUnavailableRanges = [...(unavailableDatesData?.result || [])];

  // First Unavail Range Start
  const losFirstDay = moment(sortedLosDates[0].day).subtract(1, "days");
  if (!isFoundInUnAvailRange(losFirstDay, sortedUnavailableRanges)) {
    sortedUnavailableRanges.splice(0, 0, {
      startDate: losFirstDay.format(DATE_FORMAT.paramFormat),
      endDate: losFirstDay.clone().format(DATE_FORMAT.paramFormat),
      canCheckOutOnStartDate: false,
    });
  }

  // Last Unavail Range Start
  const losLastDay = moment(sortedLosDates[sortedLosDates.length - 1].day).add(
    1,
    "days"
  );
  if (!isFoundInUnAvailRange(losLastDay, sortedUnavailableRanges)) {
    sortedUnavailableRanges.push({
      startDate: losLastDay.format(DATE_FORMAT.paramFormat),
      endDate: losLastDay.clone().format(DATE_FORMAT.paramFormat),
      canCheckOutOnStartDate: false,
    });
  }

  // sort Unavailable Ranges
  sortedUnavailableRanges = sortedUnavailableRanges
    .slice()
    .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

  return sortedUnavailableRanges;
};

export default sortUnavailableRanges;
