import React from "react";

import toMoney from "../../../Utils/functions/toMoney";
// Translation Package
import { useTranslation } from "react-i18next";

// Component
import LOSDiscountTooltip from "../los-discount-details";

// Router
import { useParams } from "react-router-dom";

const QuotationSummary = ({ data, loading }) => {
  // Translation hook
  const { t } = useTranslation();
  const { id } = useParams();

  const nightsTranslationKey = data?.nights === 1 ? "night" : "nights";

  if (loading || !data) return <></>;
  return (
    <div className="quotation-summary">
      <div className="quotation-summary__details">
        <div className="summary-item">
          <div className="label d-flex flex-row gap-1">
            <span>
              {data.nights} {t(nightsTranslationKey)} x
            </span>
            <span>
              {data.currency} {data.avgPrice}
            </span>
          </div>
          <div className="data">
            {toMoney(data.totalPriceExcludingCleaning + data.losDiscount?.discountedValue ?? 0, data.currency)}
          </div>
        </div>
        {
          !!data.losDiscount?.discountedValue &&
          <LOSDiscountTooltip
            label={<span className="summary-item__positive-amount">{data.losDiscount.label}</span>}
            amount={<div className="summary-item__positive-amount">{toMoney(data.losDiscount.discountedValue, data.currency)}</div>}
            propertyID={id}
          />
        }

        <div className="summary-item">
          <div className="summary-item__label">{t("cleaning_fee")}</div>
          <div className="summary-item__data">
            {toMoney(data.cleaningPrice, data.currency)}
          </div>
        </div>

        <div className="summary-item">
          <div className="summary-item__label">{t("taxes_and_fees")}</div>
          <div className="summary-item__data">
            {toMoney(
              (data.totalVAT || 0) + (data.dtcmFees || 0) + data.creditCardFees + (!data.isSecurityDepositEligible ? data.waiverAmount : 0),
              data.currency
            )}
          </div>
        </div>
      </div>
      <div className="quotation-summary__total pb-2">
        <div className="total__label">{t("total")}</div>
        <div className="total__data">
          {toMoney(
            data.totalPriceIncludingVAT || data.totalPrice,
            data.currency
          )}
        </div>
      </div>
    </div>
  );
};
export default QuotationSummary;
