import React from "react";
import styles from "./index.module.scss";

const PriceNoticeBox = (props) => {
  // Translation hook
  const { title, subtitle, price } = props;

  return (
    <div
      className={`w-full border rounded-2 py-3 px-3 d-flex flex-row flex-wrap gap-2 justify-content-between ${styles.block}`}
    >
      <div className="d-flex flex-column justify-content-center">
        {title && (
          <h5
            className={`block text-gray-700 text-sm font-bold mb-0 capitalize ${styles.title}`}
          >
            {title}
          </h5>
        )}{" "}
        {subtitle}
      </div>

      {price && (
        <p
          className={`block text-gray-700 text-sm font-bold mb-0 capitalize ${styles.content}`}
        >
          {price}
        </p>
      )}
    </div>
  );
};

export default PriceNoticeBox;
