import firebase from "firebase/app";

export const PROVIDERS = {
  GOOGLE: new firebase.auth.GoogleAuthProvider(),
  APPLE: new firebase.auth.OAuthProvider("apple.com"),
};

export const getProviderId = (provider) => {
  switch (provider) {
    case PROVIDERS.GOOGLE:
      return "google";
    case PROVIDERS.APPLE:
      return "apple";
    default:
      throw Error('Unknowing 3rd party provider');
  }
};
