const createUrlParams = (data) => {
  if (data === undefined || data === null) {
    return "";
  }

  const ret = [];
  for (let d in data) ret.push(d + "=" + data[d]);
  return ret.join("&");
};
export default createUrlParams;
