import React, { useState } from "react";

import FloatingModal from "../Modals/FloatingModal";
import logGaEvent from "../../lib/ga/logGaEvent";
import { default as ContactFormComponent } from "../../Components/ContactUs/ContactForm";
import MessageSent from "./../ContactUs/MessageSent";

export default function ContactForm({ show, toggleContact }) {
  const [messageSent, setMessageSent] = useState(false);

  const formSubmitFinish = () => {
    logGaEvent(`contact_us`, {
      method: "email_form",
    });
    setMessageSent(true);
  };

  return (
    <FloatingModal state={show} toggle={toggleContact}>
      <div className="contact-form">
        {!messageSent ? (
          <ContactFormComponent
            setMessageSent={formSubmitFinish}
            showAlert={false}
          />
        ) : (
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <MessageSent style={{ marginBottom: "50px" }} />
          </div>
        )}
      </div>
    </FloatingModal>
  );
}
