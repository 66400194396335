import React from "react";

import { AiOutlineMeh } from "react-icons/ai";

const ErrorMaps = () => {
  return (
    <div className="google_maps__placeholder error ">
      <div className="content">
        <AiOutlineMeh className="icon" />
        <p>
          Something happened wrong while we are loading the map.
          <br />
          You may need to reload the page.
        </p>
      </div>
    </div>
  );
};
export default ErrorMaps;
