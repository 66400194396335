import BTCBNBSmartChainQRIcon from './qr-codes/BTC-BNB-SMARTCHAIN.png';
import BTCBitcoinQRIcon from './qr-codes/BTC-Bitcoin.png';
import ETHArbitrumOneQRIcon from './qr-codes/ETH-Arbitrum-One.png';
import ETHBNBSmartChainQRIcon from './qr-codes/ETH-BNB-SMARTCHAIN.png';
import ETHERC20QRIcon from './qr-codes/ETH-ERC20.png';
import USDCERC20QRIcon from './qr-codes/USDC-ERC-20.png';
import BNBBNBSmartChainQRIcon from './qr-codes/BNB-BNB-SMART-CHAIN.png';
import USDTBnbIcon from './qr-codes/usdt-bnb.png';
import USDTERC20QRIcon from './qr-codes/USDT-ERC20.png';
import USDCBNBSmartChainQRIcon from './qr-codes/USDC-BNB-SMART-CHAIN.png';
import XRPRippleQRIcon from './qr-codes/XRP-RIPPLE.png';
import XRPBNBQRIcon from './qr-codes/XRP-BNB.png';
import LunaTerraIcon from './qr-codes/luna-terra.png';
import LunaEthIcon from './qr-codes/luna-eth.png';
import ADACardanoIcon from './qr-codes/ada-cardano.png';
import ADABnbIcon from './qr-codes/ada-bnb.png';
import SOLSolanaIcon from './qr-codes/sol-solana.png';
import SOLBnbIcon from './qr-codes/sol-bnb.png';
import AVAXAvalancheIcon from './qr-codes/avax-avalanche.png';
import AVAXCChainIcon from './qr-codes/avax-avax-c-chain.png';
import BUSDBnbIcon from './qr-codes/busd-bnb.png';
import DOTPolkadotIcon from './qr-codes/dot-polkadot.png';
import DOTBnbIcon from './qr-codes/dot-bnb.png';
import DOGEDogeIcon from './qr-codes/doge-doge.png';
import DOGEBnbIcon from './qr-codes/doge-bnb.png';
import SHIBBnbIcon from './qr-codes/shib-bnb.png';
import SHIBErc20Icon from './qr-codes/shib-erc20.png';
import WBTCErc20Icon from './qr-codes/wbtc-erc20.png';
import MaticPolygonIcon from './qr-codes/matic-polygon.png';
import MATICErc20Icon from './qr-codes/matic-erc20.png';
import DAIBnbIcon from './qr-codes/dai-bnb.png';
import DAIErc20Icon from './qr-codes/dai-erc20.png';
import ATOMCosmosIcon from './qr-codes/atom-cosmos.png';
import ATOMBnbIcon from './qr-codes/atom-bnb.png';
import LTCLiteIcon from './qr-codes/ltc-litecoin.png';
import LTCBnbIcon from './qr-codes/ltc-bnb.png';
import NEARNearIcon from './qr-codes/near-near.png';
import NEARBnbIcon from './qr-codes/near-bnb.png';
import TRXTrc20Icon from './qr-codes/trx-trc20.png';
import TRXBnbIcon from './qr-codes/trx-bnb.png';
import LINKErc20Icon from './qr-codes/link-erc20.png';
import LINKBnbIcon from './qr-codes/link-bnb.png';
import UNIBnbIcon from './qr-codes/uni-bnb.png';
import UNIErc20Icon from './qr-codes/uni-erc20.png';
import FITErc20Icon from './qr-codes/fit-erc20.png';
import BCHBitcoinCashIcon from './qr-codes/bch-bitcoin-cash.png';
import BCHBnbIcon from './qr-codes/bch-bnb.png';
import ALGOAlgorandIcon from './qr-codes/algo-algorand.png';
import XLMStellarLumensIcon from './qr-codes/xlm-stellar-lumens.png';
import XLMBnbIcon from './qr-codes/xlm-bnb.png';
import ETCEthIcon from './qr-codes/etc-eth-classic.png';
import ETCBnbIcon from './qr-codes/etc-bnb.png';
import HBARHederaIcon from './qr-codes/hbar-hedera.png';

export const CRYPTO_NETWORKS = {
    'BTC': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: BTCBNBSmartChainQRIcon,
            memoTag: null
        },
        {
            key: 'Bitcoin',
            text: 'Bitcoin',
            value: 'Bitcoin',
            address: '1GU7Z8NnfjVXBGzhSVoC5xtS5n6za2teQm',
            qr: BTCBitcoinQRIcon,
            memoTag: null
        }
    ],
    'ETH': [
        {
            key: 'Arbitrum One',
            text: 'Arbitrum One',
            value: 'Arbitrum One',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ETHArbitrumOneQRIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ETHBNBSmartChainQRIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ETHERC20QRIcon,
            memoTag: null
        }
    ],
    'USDT': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: USDTBnbIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: USDTERC20QRIcon,
            memoTag: null
        }
    ],
    'BNB': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: BNBBNBSmartChainQRIcon,
            memoTag: null
        }
    ],
    'USDC': [
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: USDCERC20QRIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: USDCBNBSmartChainQRIcon,
            memoTag: null
        }
    ],
    'XRP': [
        {
            key: 'Ripple',
            text: 'Ripple',
            value: 'Ripple',
            address: 'rEb8TK3gBgk5auZkwc6sHnwrGVJH8DuaLh',
            qr: XRPRippleQRIcon,
            memoTag: '556878447'
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: XRPBNBQRIcon,
            memoTag: null
        },
    ],
    'LUNA': [
        {
            key: 'Terra',
            text: 'Terra',
            value: 'Terra',
            address: 'terra1ncjg4a59x2pgvqy9qjyqprlj8lrwshm0wleht5',
            qr: LunaTerraIcon,
            memoTag: '105230085'
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: LunaEthIcon,
            memoTag: null
        },
    ],
    'ADA': [
        {
            key: 'Cardano',
            text: 'Cardano',
            value: 'Cardano',
            address: 'DdzFFzCqrhspVW3rHkCDXACRaatyGUs7yLX97JDPfFFHHE5jBKAqBU3SyZdsAaRg4PgTxP1hwis9DU8my6TsFpPds8qpAu5mmVKS6akj',
            qr: ADACardanoIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ADABnbIcon,
            memoTag: null
        },
    ],
    'SOL': [
        {
            key: 'Solana',
            text: 'Solana',
            value: 'Solana',
            address: 'FwYacZDB45qrjr3E4V7zMa8qamQeHToewzUUZwmFGNzZ',
            qr: SOLSolanaIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: SOLBnbIcon,
            memoTag: null
        },
    ],
    'AVAX': [
        {
            key: 'Avalanche',
            text: 'Avalanche',
            value: 'Avalanche',
            address: 'X-avax16t66p5ych7j4kam8wl3x0049khmv5jc4erzvpg',
            qr: AVAXAvalancheIcon,
            memoTag: null
        },
        {
            key: 'AVAX C-Chain',
            text: 'AVAX C-Chain',
            value: 'AVAX C-Chain',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: AVAXCChainIcon,
            memoTag: null
        },
    ],
    'BUSD': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: BUSDBnbIcon,
            memoTag: null
        }
    ],
    'DOT': [
        {
            key: 'Polkadot',
            text: 'Polkadot',
            value: 'Polkadot',
            address: '13xBPaxFV4df6oo3VPXsAWMVVKtDYu1QownsQGLDmMVnab44',
            qr: DOTPolkadotIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: DOTBnbIcon,
            memoTag: null
        },
    ],
    'DOGE': [
        {
            key: 'Dogecoin',
            text: 'Dogecoin',
            value: 'Dogecoin',
            address: 'DMcisDfNdeRZAyRG5dXxM6VRtkWPn7bJMC',
            qr: DOGEDogeIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: DOGEBnbIcon,
            memoTag: null
        },
    ],
    'SHIB': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: SHIBBnbIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: SHIBErc20Icon,
            memoTag: null
        },
    ],
    'WBTC': [
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: WBTCErc20Icon,
            memoTag: null
        }
    ],
    'MATIC': [
        {
            key: 'Polygon',
            text: 'Polygon',
            value: 'Polygon',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: MaticPolygonIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: MATICErc20Icon,
            memoTag: null
        }
    ],
    'DAI': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: DAIBnbIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: DAIErc20Icon,
            memoTag: null
        },
    ],
    'ATOM': [
        {
            key: 'Cosmos',
            text: 'Cosmos',
            value: 'Cosmos',
            address: 'cosmos15v50ymp6n5dn73erkqtmq0u8adpl8d3ujv2e74',
            qr: ATOMCosmosIcon,
            memoTag: '105637497'
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ATOMBnbIcon,
            memoTag: null
        },
    ],
    'LTC': [
        {
            key: 'Litecoin',
            text: 'Litecoin',
            value: 'Litecoin',
            address: 'LggSoHseX7MX38nBkXQqQdTWxu4PwVCK6z',
            qr: LTCLiteIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: LTCBnbIcon,
            memoTag: null
        },
    ],
    'NEAR': [
        {
            key: 'NEAR Protocol',
            text: 'NEAR Protocol',
            value: 'NEAR Protocol',
            address: '86060e7884df0c6ceb4950944d2276b2c10f6c293c9c3f48047ad3b1ca503852',
            qr: NEARNearIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: NEARBnbIcon,
            memoTag: null
        },
    ],
    'TRX': [
        {
            key: 'Tron (TRC20)',
            text: 'Tron (TRC20)',
            value: 'Tron (TRC20)',
            address: 'TPg2EAe8kDBipcb2Qr9TwWg5babeJLU6Q5',
            qr: TRXTrc20Icon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: TRXBnbIcon,
            memoTag: null
        },
    ],
    'LINK': [
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: LINKErc20Icon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: LINKBnbIcon,
            memoTag: null
        },
    ],
    'UNI': [
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: UNIBnbIcon,
            memoTag: null
        },
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: UNIErc20Icon,
            memoTag: null
        },
    ],
    'FTT': [
        {
            key: 'Ethereum (ERC20)',
            text: 'Ethereum (ERC20)',
            value: 'Ethereum (ERC20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: FITErc20Icon,
            memoTag: null
        }
    ],
    'BCH': [
        {
            key: 'Bitcoin Cash',
            text: 'Bitcoin Cash',
            value: 'Bitcoin Cash',
            address: '1GU7Z8NnfjVXBGzhSVoC5xtS5n6za2teQm',
            qr: BCHBitcoinCashIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: BCHBnbIcon,
            memoTag: null
        },
    ],
    'ALGO': [
        {
            key: 'Algorand',
            text: 'Algorand',
            value: 'Algorand',
            address: 'MFQSTP6AFXTGLFDPJVW2W5DXNAVWWZ3OGMX5H36XBE7NDIWCHICQKWHMVI',
            qr: ALGOAlgorandIcon,
            memoTag: null
        }
    ],
    'XLM': [
        {
            key: 'Stellar Lumens',
            text: 'Stellar Lumens',
            value: 'Stellar Lumens',
            address: 'GAHK7EEG2WWHVKDNT4CEQFZGKF2LGDSW2IVM4S5DP42RBW3K6BTODB4A',
            qr: XLMStellarLumensIcon,
            memoTag: '476576093'
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: XLMBnbIcon,
            memoTag: null
        },
    ],
    'ETC': [
        {
            key: 'Ethereum Classic',
            text: 'Ethereum Classic',
            value: 'Ethereum Classic',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ETCEthIcon,
            memoTag: null
        },
        {
            key: 'BNB Smart Chain (BEP20)',
            text: 'BNB Smart Chain (BEP20)',
            value: 'BNB Smart Chain (BEP20)',
            address: '0x1a1a36791754dbffe5d31e4606eb9d84071f890f',
            qr: ETCBnbIcon,
            memoTag: null
        },
    ],
    'HBAR': [
        {
            key: 'Hedera Hashgraph',
            text: 'Hedera Hashgraph',
            value: 'Hedera Hashgraph',
            address: '0.0.16952',
            qr: HBARHederaIcon,
            memoTag: '1039794877'
        },
    ]
}
