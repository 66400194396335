import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../Constants/Routes";
import { BiX } from "react-icons/bi";
import { AiOutlineMeh } from "react-icons/ai";

// Translation Package
import { useTranslation, Trans } from "react-i18next";

const Error = ({
  floating,
  children,
  redirect,
  redirectInSeconds = 5,
  errorHeadline = "error_oops",
  height,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const [errorClass, setErrorClass] = useState(floating ? "floating" : "");

  useEffect(
    () => floating && setErrorClass((e) => `${e} slide-in`),
    [floating]
  );
  const onClick = () => setErrorClass((e) => `${e} slide-out`);

  return (
    <div className={`error ${errorClass}`} style={{ height: height || "" }}>
      {!floating && (
        <div className="error__icon">
          <AiOutlineMeh className="icon" />
        </div>
      )}

      <div className="error__content">
        {children ? (
          children
        ) : (
          <DefaultContent
            redirect={redirect}
            redirectInSeconds={redirectInSeconds}
            errorHeadline={t(errorHeadline)}
          />
        )}

        {floating && (
          <button className="error__close" onClick={onClick}>
            <BiX className="icon" />
          </button>
        )}
      </div>
    </div>
  );
};

const DefaultContent = ({ errorHeadline, ...props }) => {
  // Translation hook
  const { t } = useTranslation();

  const { redirect, redirectInSeconds } = props;
  const history = useHistory();

  useEffect(() => {
    if (!redirect) return;
    setTimeout(() => history.push(ROUTES.HOME), redirectInSeconds * 1000);
  });

  const [timeLeft, setTimeLeft] = useState(redirectInSeconds);
  useEffect(() => {
    if (!redirect) return;
    const interval = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(interval);
  });

  return (
    <>
      <h5
        className="error__title"
        style={{ marginBottom: redirect ? "15px" : "" }}
      >
        {errorHeadline}
      </h5>

      <p className="error__message">{t("error_unknown")}</p>

      {redirect && (
        <p className="error__redirect-message">
          <Trans
            i18nKey="redirection_notice_homepage"
            values={{ timeLeft: timeLeft }}
          >
            You will be redirected to <a href={ROUTES.HOME}>homepage</a> in
            [timeLeft] seconds.
          </Trans>
        </p>
      )}
    </>
  );
};

export default Error;
