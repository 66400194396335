import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true,
  debug: false,
};

export default function PixelTrackingProvider(props) {
  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_TRACKING, {}, options);
    ReactPixel.pageView();
  }, []);

  return props.children;
}
