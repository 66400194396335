import React, { useState } from "react";
import * as ROUTES from "../../../Constants/Routes";

// Translation Package
import { useTranslation } from "react-i18next";

// Deps
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

// Components
import Loading from "../../Utils/Loading";
import FormInput from "../../Form/FormInput";
import trimWhiteSpace from "../../Utils/validate";
import SocialMethods from "../Helpers/SocialMethods";
import firebase from "firebase/app";

import {
  useModal,
  useUpdateModal,
  MODALS,
} from "../../../Providers/FloatingModalProvider";
import { EMAIL_PATTERN, ERROR_MESSAGES } from "../constants";
import PrimaryButton from "../../Buttons/Primary";

const SignUpForm = (formProps) => {
  //Translation hook
  const { t } = useTranslation();
  const { redirectTo, urlParams, isRoute, routeStateEmail } = formProps;

  // Modal handling
  const { props } = useModal();
  const updateModal = useUpdateModal();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: isRoute ? routeStateEmail : props?.email,
    },
  });

  const [emailCheck, setEmailCheck] = useState({
    exists: false,
    attempt: 0,
    checking: false,
  });

  const onLoginClick = () => {
    if (isRoute) {
      history.push({
        pathname: ROUTES.LOGIN,
        search: urlParams,
        state: { email: email },
      });
      return;
    }

    updateModal({
      modal: MODALS.LOGIN,
      props: { email: email || null },
    });
    return;
  };

  const onSubmit = async (data) => {
    const email = data.email || null;

    setEmailCheck((e) => ({
      ...e,
      checking: true,
    }));

    const doesEmailExist = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((res) => (res.length === 0 ? false : true))
      .catch((err) => console.log(err));

    setEmailCheck((e) => ({
      ...e,
      exists: doesEmailExist,
      attempt: doesEmailExist ? e.attempt++ : 0,
      checking: false,
    }));

    if (emailCheck.attempt === 2) {
      onLoginClick();
      return;
    }

    if (doesEmailExist) {
      return;
    }

    if (isRoute) {
      history.push({
        pathname: ROUTES.SIGN_UP_WITH_EMAIL,
        search: urlParams,
        state: { email: email },
      });
      return;
    }

    updateModal({
      modal: MODALS.SIGN_UP_WITH_EMAIL,
      props: { email: email || null },
    });
  };

  const [email, setEmail] = useState(routeStateEmail);
  const updateEmailValue = (e) => setEmail(e.target.value);

  return (
    <div className="auth-form">
      <div className="auth-form__signup">
        <div className="row">
          <div className="col-12 title">
            <h6 className="auth-form__form-title">{t("sign_up")}</h6>
          </div>
        </div>

        {/* EMAIL */}
        <div className="row ">
          {/* EMAIL */}
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                register={register({
                  required: {
                    value: true,
                    message: t(ERROR_MESSAGES.email.required),
                  },
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: t(ERROR_MESSAGES.email.invalidFormat),
                  },
                  validate: trimWhiteSpace(),
                })}
                type="email"
                name="email"
                label={t("email")}
                onChange={updateEmailValue}
                error={
                  errors.email || (emailCheck.exists && !emailCheck.checking)
                }
                errorMessage={
                  emailCheck.exists && !emailCheck.checking
                    ? t("error_email_in_use")
                    : errors?.email?.message ?? ""
                }
                data-test-id="email-input"
              />

              <PrimaryButton
                label={
                  emailCheck.checking ? (
                    <Loading light />
                  ) : (
                    t("continue_with_email")
                  )
                }
                disabled={emailCheck.checking}
                data-test-id={"continue-with-email"}
              />
            </form>
          </div>
        </div>

        {/* Methods */}
        <div className="row">
          <div className="col-12">
            <SocialMethods type="signup" redirectTo={redirectTo} />
          </div>
        </div>

        {/* Or login */}
        <div className="row sign-up">
          <div className="col-12 mt-3">
            {t("sign_in_question")}
            <button className="auth-form__link" onClick={onLoginClick}>
              {t("login")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
