import React, { useState } from "react";

// Deps
import { useForm } from "react-hook-form";
import firebase from "firebase/app";
import FormInput from "../../Form/FormInput";

import PrimaryButton from "../../Buttons/Primary";
import Loading from "../../Utils/Loading";
// Translation Package
import { useTranslation } from "react-i18next";

export default function ResetPasswordForm() {
  // Translation hook
  const { t } = useTranslation();

  // Form handling
  const { register, handleSubmit, errors, watch } = useForm({});

  // Login
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (data) => {
    setSubmitting(false);
    try {
      await firebase.auth().confirmPasswordReset("", data.password);
    } catch (error) {
      setError({ message: error });
    }
    setSubmitting(true);
  };

  return (
    <div className="auth-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form__reset-password"
      >
        <div className="row">
          <div className="col-12 ">
            <h6 className="auth-form__form-title">{t("reset_password")}</h6>
          </div>
        </div>
        {/* RECREATE PASSWORD */}
        <div className="row">
          <div className="col-12 reset-password-text">
            <p>{t("new_password")}</p>
          </div>
          {/* Create password */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
                minLength: {
                  value: 8,
                  message: t("error_pasword_length"),
                },
              })}
              type="password"
              name="password"
              label={t("error_password")}
              error={errors.password || (error && error.message)}
            />
          </div>

          {/* Create password */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
                validate: (value) => value === watch("password"),
              })}
              type="password"
              name="confirmPassword"
              label={t("error_password_confirm")}
              error={errors.confirmPassword}
            />
          </div>
          <div className="col-12">
            <div className="reset-password-button mt-1">
              <PrimaryButton
                label={submitting ? <Loading light /> : t("reset_password")}
                disabled={submitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
