import React from "react";
import { FiChevronDown } from "react-icons/fi";

// Translation Package
import { useTranslation } from "react-i18next";

const Select = ({
  register,
  options,
  error,
  label,
  id,
  rows,
  classSuffix,
  reasons,
  onSelectChange,
  ...inputProps
}) => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div
      className={`form-group floating-label show-floating-label ${classSuffix} 
			${error ? "error" : ""}`}
    >
      <div className="floating-label__input-area">
        <select
          className="form-control floating-label__text-input"
          ref={register}
          id={id}
          {...inputProps}
          onChange={onSelectChange}
          defaultValue={reasons ? t("reasons") : null}
        >
          {reasons && <option disabled>{t("reasons")}</option>}
          {options.map((option, index) => {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          })}
        </select>
        <span>
          <FiChevronDown />
        </span>
        <label htmlFor={id} className="floating-label__label">
          {label}
        </label>
      </div>
      {error && <div className="error-text">{error.message}</div>}
    </div>
  );
};

export default Select;
