import { useEffect } from "react";
import logGaEvent from "./logGaEvent";
const useLogGaEvent = (eventName, eventValue, condition) => {
  useEffect(() => {
    // If condition is not met, return
    if ((condition !== null || condition !== undefined) && condition === false)
      return;

    logGaEvent(eventName, eventValue);
  }, [eventName, eventValue, condition]);
};

export default useLogGaEvent;
