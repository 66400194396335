// React
import React, { useEffect, useState } from "react";

// Other components
import FormInput from "./FormInput";
import { useTranslation } from "react-i18next";

// Utils
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import CountryListInput from "./CountryListInput";
import Countries from "./Helpers/JSON/Countries.json";

export const getParsedPhoneNumber = (phoneNumber = "") => {
  try {
    return new AsYouType().input(parsePhoneNumber(phoneNumber).number);
  } catch {
    return "+";
  }
};

export const extractCountryFromPhoneNumber = (phoneNumber = "", lng = "en") => {
  try {
    return phoneNumber.length > 1
      ? Countries.find((c) => c.code === parsePhoneNumber(phoneNumber).country)
          .name[lng]
      : "";
  } catch {
    return "";
  }
};

const PhoneNumberInput = ({
  autoFocus,
  phoneNumberError,
  countryError,
  getCountry,
  phoneNumberRegister,
  countryRegister,
  triggerCountryValidation,
  defaultNumber = "+",
  countryColSpan = "col-6",
  phoneNumberColSpan = "col-6",
  onCountryChange = (country) => {},
  onPhoneNumberChange = (country) => {},
}) => {
  //Translation hook
  const { t, i18n } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState({
    number: getParsedPhoneNumber(defaultNumber),
    isDirty: false,
  });
  const defaultCountry =
    extractCountryFromPhoneNumber(defaultNumber, i18n.languages[0]) || "";
  const defaultCountryObj = Countries.find(
    ({ name }) => name[i18n.languages[0]] === defaultCountry
  );

  const [country, setCountry] = useState(defaultCountryObj);

  const handlePhoneNumber = (e) => {
    const valBeforePlus = e.target.value.split("+").shift();
    let val = `+${e.target.value.split("+").splice(1).join("")} ${
      valBeforePlus ?? ""
    }`;

    if (val.length <= 1 + country.dial_code.length) {
      setPhoneNumber({ number: `+${country.dial_code}`, isDirty: true });
      return;
    }

    const transformedVal =
      val.length === 1 + country.dial_code.length ? `+${val}` : val;
    const asYouType = new AsYouType().input(transformedVal);
    setPhoneNumber({ number: asYouType, isDirty: true });
  };

  const handelCountryChange = (newCountry) => {
    const country = Countries.find(
      ({ name }) => name[i18n.languages[0]] === newCountry
    );
    setCountry(country);
  };

  useEffect(() => {
    if (country) {
      // phoneNumber without the dial code
      const absolutePhoneNumber = phoneNumber.number
        .split(" ")
        .splice(1)
        .join(" ");

      handlePhoneNumber({
        target: { value: `+${country.dial_code} ${absolutePhoneNumber}` },
      });
      onCountryChange(country.name[i18n.languages[0]]);
    }
  }, [country]);

  useEffect(() => {
    onPhoneNumberChange(phoneNumber.number);
  }, [phoneNumber]);

  return (
    <div className="row">
      <div className={countryColSpan} dir="ltr">
        <CountryListInput
          register={countryRegister}
          error={countryError}
          name="country"
          label={t("country_code")}
          defaultValue={defaultCountry}
          setSelectedCountry={(newCountry) => handelCountryChange(newCountry)}
          triggerCountryValidation={triggerCountryValidation}
        />
      </div>
      <div className={phoneNumberColSpan}>
        <FormInput
          register={phoneNumberRegister}
          type="text"
          name="mobile"
          id="mobile"
          label={t("mobile")}
          inputMode="numeric"
          value={phoneNumber.number}
          onChange={handlePhoneNumber}
          autoFocus={autoFocus}
          error={phoneNumberError}
          data-test-id="mobile-input"
          disabled={!country}
          dir="ltr"
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;