import React from "react";

import groupBy from "./functions/groupBy";
import onMonthSelect from "./functions/onMonthSelect";
import createFlexibleMonthTitle from "./functions/createFlexibleMonthTitle";

import { FLEXIBLE_MONTH_LIST } from "./constants";
import { FLEXIBLE_TYPES } from "../../Booking/Search/search_constants";

import { BsCheck } from "react-icons/bs";

// Translation Package
import { useTranslation } from "react-i18next";

const FlexibleArea = ({
  allowFlexible,
  isMobile,
  tempFlexible,
  setTempFlexible,
  focus,
}) => {
  // Translation hook
  const { t } = useTranslation();

  if (!allowFlexible || (!isMobile && !focus)) return <></>;

  const flexibleMonthTitle = createFlexibleMonthTitle(tempFlexible.months, t);

  return (
    <div className="custom_datepicker__flexible">
      {/* Month, week, weekend */}
      <div className="flexible__duration-text">
        {t("stay_for_a")}{" "}
        <span>{t(tempFlexible.type.label.toLowerCase())}</span>
      </div>
      <div className="flexible__types">
        {Object.values(FLEXIBLE_TYPES).map((type, index) => {
          const isActive = type.value === tempFlexible.type.value;
          const activeClass = isActive ? "active" : "";

          return (
            <button
              key={index}
              onClick={() => {
                setTempFlexible((f) => ({ ...f, type: type }));
              }}
              className={`flexible__button ${activeClass}`}
            >
              {type.label ? t(type.label.toLowerCase()) : ""}
            </button>
          );
        })}
      </div>

      {/* Month selection */}
      <div className="flexible__duration-text flexible__month-text">
        {t("go_in")} <span>{flexibleMonthTitle}</span>
      </div>

      <div className="flexible__months">
        {groupBy(FLEXIBLE_MONTH_LIST, "year").map((year, index) => {
          return (
            <div className="flexible__year-group" key={index}>
              <div className="flexible__year-divider">
                <span>{year[0].year}</span>
              </div>

              <div className="flexible__months-group">
                {year.map((month, index) => {
                  const isSelected = tempFlexible.months.some(
                    (m) => m.value === month.value
                  );
                  const selectedClass = isSelected ? "selected" : "";

                  return (
                    <button
                      className={`button__month ${selectedClass}`}
                      onClick={() =>
                        onMonthSelect(
                          month,
                          isSelected,
                          setTempFlexible,
                          tempFlexible.months
                        )
                      }
                      key={index}
                    >
                      {t(month.label.toLowerCase())}

                      {isSelected && (
                        <span className="button__icon">
                          <BsCheck className="icon" />
                        </span>
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlexibleArea;
