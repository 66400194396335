import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiUpload } from "react-icons/fi";
import QUERIES from "Constants/Queries";
import { useMutation } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import Loading from "Components/Utils/Loading";
import { Controller } from "react-hook-form";
import { BsExclamationCircleFill } from "react-icons/bs";
import PropTypes from "prop-types";
import FloatingModal from "Components/Modals/FloatingModal";

/**
 * FileInput component allows users to upload files with a custom styled input.
 * It integrates with form libraries like React Hook Form and supports translations and accessibility features.
 *
 * @param {object} props - The props object.
 * @param {string} props.name - The name attribute for the input field.
 * @param {string} props.label - The label text displayed next to the upload icon.
 * @param {string} [props.containerClassName] - Additional class names for the container div.
 * @param {string} [props.accept] - Acceptable file types for the input.
 * @param {object} [props.error] - Validation errors for the form fields
 * @param {string} [props.docType] - Document Type either "id-front", "id-back", or "Passport".
 * @param {string} [props.rejectionRemarksTranslationKey] - document rejection remarks translation key
 * @returns {JSX.Element} The rendered FileInput component.
 */
const FileInput = ({
  name,
  label,
  containerClassName,
  accept,
  error,
  docType,
  rejectionRemarksTranslationKey,
  field,
}) => {
  // Translation hook for internationalization
  const { t } = useTranslation();

  const { control, setValue, setError, clearErrors } = useFormContext();

  const [openModal, setOpenModal] = useState(false);

  // mutation for file upload
  const [uploadFile, { loading, error: uploadError }] = useMutation(
    QUERIES.RESERVATIONS.UPLOAD_MEDIA
  );

  const errorRequiredMessage = {
    passport: "upload_passport",
    "id-front": "upload_national_id",
    "id-back": "upload_back_national_id",
  };

  // handle file change
  async function handleFileChange(file) {
    clearErrors(name);

    try {
      const res = await uploadFile({
        variables: {
          file,
        },
      });
      setValue(name, {
        docType,
        url: res?.data?.result?.url,
        name: res?.data?.result?.name,
      });
      // set the File Data object to the form value (name, url, and docType)
    } catch (err) {
      console.error("File upload failed:", err);
    }
  }
  useEffect(() => {
    if (rejectionRemarksTranslationKey) {
      const errorObj = {
        message: rejectionRemarksTranslationKey,
        type: "manual",
        cause: "rejection",
      };
      setError(name, errorObj);
    }
  }, [name, rejectionRemarksTranslationKey, setError]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={field || null}
      render={({ onChange, value }) => {
        return (
          <div className={`col-12 ${containerClassName} position-relative`}>
            <label
              htmlFor={name}
              className={`w-100 d-flex align-items-center justify-content-between px-3 file-input__label h-100  ${
                error ? " online-check-in__info--error--border  " : ""
              }`}
            >
              <div
                className={`d-flex align-items-center justify-content-center gap-2 online-check-in__info--gray online-check-in__info--lg  online-check-in__info--light-gray  primary-font font-medium  ${
                  error ? "online-check-in__info--error--text " : ""
                }`}
              >
                <FiUpload
                  size={20}
                  color="#828282"
                  aria-hidden="true"
                  className={` ${
                    error ? "online-check-in__info--error--text" : ""
                  }`}
                />
                {t(label)}
              </div>

              <div className="file-input__button text-capitalize primary-font font-medium ">
                {rejectionRemarksTranslationKey ? t("replace") : t("browse")}
              </div>

              <input
                type="file"
                id={name}
                style={{ display: "none" }}
                accept={accept}
                aria-label={t(label)}
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) await handleFileChange(file, onChange);
                }}
              />
            </label>

            {value && value?.docType === docType && (
              <>
                {[".heic", ".pdf", "tiff"].some((item) =>
                  value?.url?.endsWith(item)
                ) ? (
                  <a
                    className=" d-block text-truncate mb-1 mt-2  text-primary "
                    aria-live="polite"
                    href={value?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {value?.name}
                  </a>
                ) : (
                  <small
                    className=" d-block text-truncate mb-1 mt-2  text-primary "
                    aria-live="polite"
                    onClick={() => setOpenModal(true)}
                    style={{ cursor: "pointer" }}
                    role="button"
                  >
                    {value?.name}
                  </small>
                )}
              </>
            )}

            {loading && (
              <div
                className="position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-start px-5 pt-2 rounded-3"
                style={{ background: "rgba(255,255,255,0.8)", zIndex: 1 }}
              >
                <Loading />
              </div>
            )}

            {error && error?.type === "required" && (
              <div className="online-check-in__info--error--text d-flex align-items-center gap-2 mt-1">
                <span>
                  <BsExclamationCircleFill />
                </span>
                <span>{t(error?.message)}</span>
              </div>
            )}

            {uploadError && (
              <div className="online-check-in__info--error--text d-flex align-items-center gap-2 mt-1">
                <span>{t("file_upload_failed")}</span>
              </div>
            )}

            <FloatingModal
              state={openModal}
              toggle={() => setOpenModal(!openModal)}
            >
              <div
                className="w-100 d-flex flex-column  align-items-center  "
                style={{
                  maxHeight: "80vh",
                  height: "100%",
                  overflowY: "scroll",
                }}
              >
                <small
                  className=" d-block text-truncate my-2  text-primary  pointer-event "
                  aria-live="polite"
                >
                  {value?.name}
                </small>
                <img
                  src={value?.url}
                  alt={value?.name}
                  className="rounded-3"
                  style={{ maxWidth: "250px", height: "auto" }}
                />
              </div>
            </FloatingModal>
          </div>
        );
      }}
      rules={{ required: t(errorRequiredMessage[docType]) }}
    />
  );
};

export default React.memo(FileInput);
// Define prop types for the component
FileInput.propTypes = {
  /** The name attribute for the input field */
  name: PropTypes.string.isRequired,
  /** The label text displayed next to the upload icon */
  label: PropTypes.string.isRequired,
  /** Additional class names for the container div */
  containerClassName: PropTypes.string,
  /** Acceptable file types for the input */
  accept: PropTypes.string,
  /** Validation errors for the form fields */
  error: PropTypes.object,
  /** Document Type either "id-front", "id-back", or "Passport" */
  docType: PropTypes.string,
  /** document rejection remarks translation key */
  rejectionRemarksTranslationKey: PropTypes.string,
};

FileInput.defaultProps = {
  containerClassName: "",
  accept: ".jpg, .png, .jpeg, .pdf, .webp, .heic, .tiff",
  error: undefined,
  rejectionRemarksTranslationKey: null,
};
