const fixListingName = (listingName) => {
  if (!listingName) return null;

  return listingName
    .replace("Stella Stays | ", "") // Remove brand
    .split("|")
    .map((s) => s.trim()) // Fix typos in backend if any
    .join(" | ");
};
export default fixListingName;
