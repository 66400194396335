import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { isPointInPolygon } from "geolib";
// Translation Package
import { useTranslation } from "react-i18next";

import { BsCheck } from "react-icons/bs";

import stringToBoolean from "../../../../../Utils/functions/stringToBoolean";
import getBounds from "./getBounds";
import detectClusterHover from "../functions/detectClusterHover";
import { useCookieConsent } from "../../../../../../Providers/CookieConsentProvider";

const cookies = new Cookies();
const autoPanCookieName = "autoPanGoogleMaps";

const AutoPan = (props) => {
  const { markerStates, markers, map, api, clusters } = props;
  //Translation hook
  const { t } = useTranslation();

  const cookieConsent = useCookieConsent();

  const cookie = cookies.get(autoPanCookieName);
  const cookieVal = stringToBoolean(cookie);
  const [autoPan, setAutoPan] = useState(cookieVal);

  const onClick = () => {
    const val = !autoPan;
    setAutoPan(val);

    if (!cookieConsent) return;

    let d = new Date();
    d.setTime(d.getTime() + 3600 * 1000 * 87660); // Ten years
    cookies.set(autoPanCookieName, val, { path: "/search", expires: d });
  };

  // Autopan if hovered marker not in scene
  useEffect(() => {
    if (!map) return;
    if (autoPan === false) return;

    // Do not recenter if there is a selected item
    if (markerStates.selected.index !== null) return;

    // Return if there is no hovered item
    if (markerStates.hovered.index === null) return;

    // Get currently hovered marker
    const item = markers.find((m) => m.id === markerStates.hovered.id);
    if (item.location === undefined) return;

    let point = { latitude: item.location.lat, longitude: item.location.lng };

    // Check if it is in a cluster
    if (clusters.clusters) {
      const hoveredCluster = clusters.clusters
        .filter((cluster) => cluster.properties.cluster === true)
        .map((cluster) => {
          const leaves = clusters.supercluster.getLeaves(
            cluster?.id,
            "Infinity"
          );

          const isClusterHovered = detectClusterHover(markers, leaves);
          return isClusterHovered ? cluster : false;
        })
        .filter((item) => item !== false);

      if (hoveredCluster.length > 0) {
        point = {
          latitude: hoveredCluster[0].geometry.coordinates[1],
          longitude: hoveredCluster[0].geometry.coordinates[0],
        };
      }
    }

    const bounds = getBounds(map);
    if (!bounds) return;

    const isInScene = isPointInPolygon(point, bounds);
    if (isInScene) return;

    map.panTo({ lat: point.latitude, lng: point.longitude });
  }, [markerStates, markers, map, api, autoPan, clusters]);

  return (
    <div className="google_maps__auto-pan">
      <button className="auto-pan-button" onClick={onClick}>
        <span className={`icon_holder ${autoPan ? "active" : ""}`}>
          {autoPan && <BsCheck className="icon" />}
        </span>
        {t("auto_move_map")}
      </button>
    </div>
  );
};

export default AutoPan;
