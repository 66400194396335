import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import QUERIES from "../../../Constants/Queries";
import * as ROUTES from "../../../Constants/Routes";
import {
  CONTACT_NUMBER,
  CONTACT_EMAIL,
  LEGAL_TITLES,
  PRESS,
} from "../../../Constants/globals";
import { MENU_TITLES } from "../../../Constants/globals";
import { FILTERS } from "../../../Constants/url_params";

import { AiFillLinkedin, AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

// Deps
import AppBadges from "../../AppBadges";
import Logo from "../Logo/footer-logo-white-en.svg";
import LogoAR from "./../Logo/footer-logo-white-ar.svg";
import useMobileDetect from "../../Utils/hooks/useMobileDetect";
import logGaEvent from "../../../lib/ga/logGaEvent";

// Translation Package
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "Components/i18n/LanguageSwitcher";

const Footer = ({ container, containerFluid }) => {
  // Translation hook
  const { t } = useTranslation();

  const isMobile = useMobileDetect();

  const mail = {
    address: CONTACT_EMAIL,
  };
  const whatsapp = {
    number: CONTACT_NUMBER.EMBED,
    numberHuman: CONTACT_NUMBER.HUMAN,
    text: `Hello!`,
  };

  const containerClass =
    `container` + (container ? ` container-max-${container}` : "");

  const handleGaEvent = (method) => {
    logGaEvent(`contact_us`, {
      method: method,
    });
  };

  // Check for direction and show appropriate logo
  const { i18n } = useTranslation();
  let LogoForHeader = Logo;
  if (i18n.dir() === "rtl") {
    LogoForHeader = LogoAR;
  }

  return (
    <footer className="container-fluid footer" id="footer">
      <div
        className={
          containerFluid ? (isMobile ? "px-4" : "px-5") : containerClass
        }
      >
        <div className="row">
          <div className="col-12">
            <div className="logo-holder">
              <a href="https://www.stellastays.com">
                <img
                  src={LogoForHeader}
                  alt={t("stella_stays")}
                  className="logo"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="row menu">
          <div className="col-12 col-md-6 col-lg-3 section">
            <h4>{t("nav_menu_about")}</h4>
            <ul>
              <li>
                <a href="https://www.stellastays.com">
                  {t(MENU_TITLES.FIND_A_STELLA)}
                </a>
              </li>
              <li>
                <Link to={ROUTES.ABOUT_US}>{t(MENU_TITLES.WHO_WE_ARE)}</Link>
              </li>
              <li>
                <Link to={ROUTES.PRESS}>{t(MENU_TITLES.PRESS)}</Link>
              </li>
              <li>
                <Link to={ROUTES.HELP_CENTER}>
                  {t(MENU_TITLES.HELP_CENTER)}
                </Link>
              </li>
              <li>
                <Link to={ROUTES.FOR_REAL_ESTATE_PARTNERS}>
                  {t(MENU_TITLES.FOR_REAL_ESTATE_PARTNERS)}
                </Link>
              </li>
              <li>
                <Link to={ROUTES.CAREERS}>{t(MENU_TITLES.CAREERS)}</Link>
              </li>
              <li>
                <Link to={ROUTES.CONTACT_US}>{t(MENU_TITLES.CONTACT_US)}</Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-2 section">
            <h4>{t("nav_menu_cities")}</h4>
            <CityList />
          </div>

          <div className="col-12 col-md-6 col-lg-3 section">
            <h4>{t("contact_us")}</h4>
            <ul>
              <li>
                {" "}
                <a
                  href={`https://wa.me/${CONTACT_NUMBER.EMBED.replace(
                    "+",
                    ""
                  ).replace(/\s/g, "")}?text=${encodeURI(whatsapp.text)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleGaEvent("whatsapp")}
                >
                  {t("whatsapp_number")}
                </a>
              </li>
              <li>
                <a
                  href={`mailto:${mail.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleGaEvent("email")}
                >
                  {mail.address}
                </a>
              </li>
            </ul>

            {/* Media Enquiries */}
            <h4 className="mt-4">{t("nav_menu_media_enquiries")}</h4>
            <ul>
              <li>
                <a
                  href={`mailto:${PRESS.EMAIL}?subject=${PRESS.SUBJECT}&body=${PRESS.BODY}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleGaEvent("press")}
                >
                  {PRESS.EMAIL}
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-3 section">
            <h4>{t("download")}</h4>
            <div className="badges">
              <AppBadges />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3 section">
            <h4>{t("Language")}</h4>
            <div className="d-flex flex-row gap-4">
              <LanguageSwitcher forFooter />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 ">
            <div className="copyright-holder">
              <div className="row copyright justify-content-between">
                <div className="col-12 col-md-8 ">
                  <div className="left">
                    <div className="row">
                      <div className="col-12 col-md-auto brand">
                        {t("copyrights_notice")}
                      </div>
                      <div className="col-12 col-md-auto legals">
                        <ul>
                          <li>
                            <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                              {t(LEGAL_TITLES.TERMS_AND_CONDITIONS)}
                            </Link>
                          </li>
                          <li>
                            <Link to={ROUTES.PRIVACY_POLICY}>
                              {t(LEGAL_TITLES.PRIVACY_POLICY)}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-auto right">
                  {/* Social Media */}
                  <div
                    className="social-media"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <a
                      href={ROUTES.FACEBOOK}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Stella Stays Facebook"
                    >
                      <FaFacebookF
                        className="icon"
                        style={{ transform: "scale(0.8)" }}
                      />
                    </a>
                    <a
                      href={ROUTES.LINKEDIN}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Stella Stays LinkedIn"
                    >
                      <AiFillLinkedin className="icon" />
                    </a>

                    <a
                      href={ROUTES.INSTAGRAM}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Stella Stays Instagram"
                    >
                      <AiFillInstagram className="icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const CityList = () => {
  // Translation hook
  const { t } = useTranslation();

  const variables = { limit: 10 };
  const fetch = useQuery(QUERIES.COM_ENUMS, { variables: variables });

  // return loading
  if (fetch.loading || fetch.error) {
    return (
      <ul className="loading-cities">
        <li />
        <li />
        <li />
        <li />
      </ul>
    );
  }
  return (
    <ul>
      {fetch.data.result.cities.map((city) => {
        return (
          <li key={city}>
            <a
              href={`${ROUTES.SEARCH}?${FILTERS.city.paramKey}=${city}&${FILTERS.guests.paramKey}=1`}
            >
              {t(city.split(" ").join("_").toLowerCase())}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Footer;
