import firebase from "firebase/app";

const logGaEvent = (eventName, eventValue) => {
  if (!eventName || !eventValue) return;

  switch (eventName) {
    case `screen_view`:
      firebase.analytics().logEvent("screen_view", {
        firebase_screen_id: eventValue,
      });
      break;

    case `login`:
      firebase.analytics().logEvent("login", {
        method: eventValue,
      });
      break;

    case `sign_up`:
      firebase.analytics().logEvent("sign_up", {
        method: eventValue,
      });
      break;

    case `check_availability`:
      firebase.analytics().logEvent("check_availability", {
        listing_name: eventValue,
      });
      break;

    default:
      firebase.analytics().logEvent(eventName, eventValue);
      break;
  }
};
export default logGaEvent;
