//TODO: validate English dates are always passed to URLs (example: /booking-payment/100/2022-07-03/2022-08-28/1)
import moment from "moment";

import * as ROUTES from "../../../../../Constants/Routes";
import { DATE_FORMAT } from "../../../../../Constants/url_params";

const goBookingPage = (history, suite, dates, guests) => {
  history.push({
    pathname: `${ROUTES.BOOKING_PAYMENT}/${suite.id}/${moment(
      dates.start
    ).locale('en').format(DATE_FORMAT.apiFormat)}/${moment(dates.end).locale('en').format(
      DATE_FORMAT.apiFormat
    )}/${guests}`,
  });
};

export default goBookingPage;
