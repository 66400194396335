import dayjs from "dayjs";

export const SEARCH_TYPES = {
  calendar: "calendar",
  flexible: "flexible",
};

export const FLEXIBLE_TYPES = {
  weekend: {
    value: "weekend",
    label: "Weekend",
  },
  week: {
    value: "week",
    label: "Week",
  },
  month: {
    value: "month",
    label: "Month",
  },
};

export const FLEXIBLE_MONTHS_FORMAT = "YYYY-MM";

export const FILTERS = {
  city: {
    paramKey: "city",
    apiKey: "city",
    defaultValue: null,
  },
  startDate: {
    paramKey: "start_date",
    apiKey: "start_date",
    defaultValue: null,
  },
  endDate: {
    paramKey: "end_date",
    apiKey: "end_date",
    defaultValue: null,
  },
  flexibility: {
    paramKey: "flexibility",
    apiKey: "flexibility",
    defaultValue: "0",
  },
  guests: {
    paramKey: "guests",
    apiKey: "guests",
    defaultValue: 1,
  },
  flexibility_type: {
    paramKey: "flexibility_type",
    apiKey: "flexibility_type",
    defaultValue: FLEXIBLE_TYPES.weekend.value,
  },
  flexibility_months: {
    paramKey: "flexibility_months",
    apiKey: "flexibility_months",
    defaultValue: `${dayjs().format(FLEXIBLE_MONTHS_FORMAT)}`,
  },
  searchType: {
    paramKey: "search_type",
    apiKey: null,
    defaultValue: SEARCH_TYPES.calendar,
  },
  buildings: {
    paramKey: "buildings",
    apiKey: "buildingIDs",
    defaultValue: '',
  }
};

export const DATE_FORMAT = {
  paramFormat: "YYYY-MM-DD",
  apiFormat: "YYYY-MM-DD",
};
