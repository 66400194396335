import moment from "moment";

const sortLosDates = (losRangeData) => {
  if (!losRangeData) return [];

  return losRangeData?.result
    .slice()
    .sort((a, b) => moment(a.day).diff(moment(b.day)));
};

export default sortLosDates;
