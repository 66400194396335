import React from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const NextArrow = (props) => {
  const { forceShowNav } = props;
  return (
    <div
      className="slick__navigation next"
      style={{ opacity: forceShowNav ? 1 : "" }}
    >
      <button onClick={props.onClick} aria-label="Next">
        <BsChevronRight className="icon" />
      </button>
    </div>
  );
};

export const PrevArrow = (props) => {
  const { forceShowNav } = props;
  return (
    <div
      className="slick__navigation prev"
      style={{ opacity: forceShowNav ? 1 : "" }}
    >
      <button onClick={props.onClick} aria-label="Previous">
        <BsChevronLeft className="icon" />
      </button>
    </div>
  );
};
