import { useState, useEffect } from "react";
import { getCenter } from "geolib";

const CITY_LOCATIONS = {
  Dubai: {
    lat: "25.2048",
    lng: "55.2708",
  },
  Montreal: {
    lat: "45.5017",
    lng: "-73.5673",
  },
  Manama: {
    lat: "26.2235",
    lng: "50.5876",
  },
  Istanbul: {
    lat: "41.015137",
    lng: "28.979530",
  },
  Riyadh: {
    lat: "24.774265",
    lng: "46.738586",
  },
  London: {
    lat: "51.52",
    lng: "-0.1566",
  },
};

const useMapLocation = (data, city) => {
  const [mapLocation, setMapLocation] = useState(null);
  useEffect(() => {
    if (!data || data.length === 0) {
      return setMapLocation(CITY_LOCATIONS[city]);
    }

    const locations = data.map((item) => ({
      latitude: item.location.lat,
      longitude: item.location.lng,
    }));

    const center = getCenter(locations);

    setMapLocation({
      lat: center.latitude,
      lng: center.longitude,
    });
  }, [data, city]);

  return mapLocation;
};
export default useMapLocation;
