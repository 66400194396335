import React from "react";

import * as ROUTES from "../../Constants/Routes";
import { URL_PARAMS } from "../../Constants/globals";
import { useLocation, Redirect } from "react-router";

import SignupOptions from "./SignUp/Options";
import SignupWithEmail from "./SignUp/WithEmail";
import VerifyBirthdate from "./Verify/Birthdate";
import VerifyEmail from "./Verify/Email";
import ResetPassword from "./Password/Reset";
import ForgotPassword from "./Password/Forgot";
import Login from "./Login";

import Topbar from "../Layout/Topbar";
import Footer from "../Layout/Footer";

import Loading from "../Utils/Loading";

import { useUser } from "../../Providers/UserProvider";
const AuthPage = () => {
  const { user, loading } = useUser();

  const location = useLocation();
  const route = location.pathname;
  const locationState = location.state;
  const routeStateEmail = locationState?.email || "";

  const searchQueries = new URLSearchParams(location.search);
  const redirectTo = searchQueries.get(URL_PARAMS.REDIRECT);

  // Prepare URL params string for redirect
  const urlParams = searchQueries?.toString();

  const props = {
    isRoute: true,
    redirectTo: redirectTo,
    urlParams: urlParams,
    routeStateEmail: routeStateEmail,
  };

  if (loading) return <Loading />;
  if (user) return <Redirect to={redirectTo ?? ROUTES.HOME} />;

  return (
    <>
      <Topbar hideFindAStella />
      <div className="container auth-container">
        {route === ROUTES.LOGIN && <Login {...props} />}
        {route === ROUTES.SIGN_UP && <SignupOptions {...props} />}
        {route === ROUTES.SIGN_UP_WITH_EMAIL && <SignupWithEmail {...props} />}
        {route === ROUTES.VERIFY_EMAIL && <VerifyEmail {...props} />}
        {route === ROUTES.VERIFY_BIRTHDATE && <VerifyBirthdate {...props} />}
        {route === ROUTES.FORGOT_PASSWORD && <ForgotPassword {...props} />}
        {route === ROUTES.RESET_PASSWORD && <ResetPassword {...props} />}
      </div>
      <Footer />
    </>
  );
};

export default AuthPage;
