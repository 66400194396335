import React from "react";

import composeJsxClass from "../../../Utils/functions/composeJsxClass";

const SectionTitle = ({ icon, title, subtitle, large }) => {
  const sectionTitleClass = composeJsxClass("section__title", { large: large });

  return (
    <div className={sectionTitleClass}>
      {icon && <div className="section__title__icon">{icon}</div>}
      {subtitle && <div className="section__title__subtitle">{subtitle}</div>}
      <div className="section__title__title">{title}</div>
    </div>
  );
};
export default SectionTitle;
