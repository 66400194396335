
const defaultSVG ={
	viewBox: '0 0 48 48',
	d: 'M 43.470703 8.9863281 A 1.50015 1.50015 0 0 0 42.439453 9.4394531 L 16.5 35.378906 L 5.5605469 24.439453 A 1.50015 1.50015 0 1 0 3.4394531 26.560547 L 15.439453 38.560547 A 1.50015 1.50015 0 0 0 17.560547 38.560547 L 44.560547 11.560547 A 1.50015 1.50015 0 0 0 43.470703 8.9863281 z'
}

const decodeSVG = (svg) =>{
	if(!svg) return defaultSVG;
	
	const svgDom = new DOMParser().parseFromString(svg, "text/xml");
	const error = svgDom.getElementsByTagName('parsererror')[0];
	if(error) {
		return defaultSVG;
	}
	

	const viewBox = svgDom.getElementsByTagName("svg")[0].getAttribute("viewBox");
	const d = svgDom.getElementsByTagName("path")[0].getAttribute("d");

	return {
		viewBox,
		d,
	}
}

export default decodeSVG
