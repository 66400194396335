import React, { useState } from "react";
import * as ROUTES from "../../../Constants/Routes";

// Deps
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import firebase from "firebase/app";

import FormInput from "../../Form/FormInput";
import trimWhiteSpace from "../../Utils/validate";

import { EMAIL_PATTERN } from "../constants";
import {
  useModal,
  useUpdateModal,
  MODALS,
} from "../../../Providers/FloatingModalProvider";

import PrimaryButton from "../../Buttons/Primary";
import Loading from "../../Utils/Loading";
// Translation Package
import { useTranslation } from "react-i18next";

const ForgotPassword = (pageProps) => {
  // Translation hook
  const { t } = useTranslation();
  const { isRoute, routeStateEmail } = pageProps;

  const history = useHistory();

  const { props } = useModal();
  const updateModal = useUpdateModal();

  const [email, setEmail] = useState(isRoute ? routeStateEmail : props?.email);
  const updateEmailValue = (e) => setEmail(e.target.value);

  // Form handling
  const { register, handleSubmit, errors } = useForm();
  const [firebaseError, setFirebaseError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    if (!data?.email) return;
    setSubmitting(true);

    await firebase
      .auth()
      .sendPasswordResetEmail(data.email)
      .then(() => {
        if (isRoute) {
          history.push(ROUTES.LOGIN);
          return;
        }

        updateModal({
          modal: MODALS.LOGIN,
          props: { email: email || null },
        });
      })
      .catch((error) => {
        setFirebaseError(error);
      });

    setSubmitting(false);
  };

  return (
    <div className="auth-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form__forgot-password"
      >
        <div className="row">
          <div className="col-12 title">
            <h6 className="auth-form__form-title">{t("forgot_password")}</h6>
          </div>
        </div>
        {/* EMAIL */}
        <div className="row">
          <div className="col-12 forgot-password-text">
            <p>{t("password_reset_instructions")}</p>
          </div>
          {/* Email */}
          <div className="col-12 ">
            <FormInput
              register={register({
                required: true,
                pattern: {
                  value: EMAIL_PATTERN,
                },
                validate: trimWhiteSpace(),
              })}
              type="email"
              name="email"
              label={t("email")}
              classSuffix={firebaseError && "error"}
              autoFocus={true}
              value={email}
              onChange={updateEmailValue}
              error={errors.email}
            />
          </div>

          <div className="col-12">
            <div className="forgot-password-button mt-5">
              <PrimaryButton
                label={
                  submitting ? <Loading light /> : t("password_reset_button")
                }
                disabled={submitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
